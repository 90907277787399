import axios from 'axios';
import { getApiBaseURL } from 'utils/api/api';
import { showApiConnectionError } from 'utils/validation/validation';

const url1 = getApiBaseURL() + '/api/status-overview/applications/';
const url2 = '/details';

export const getStatusOverviewApplicationsDetails = async (
	id: string,
	successCb: Function = () => {},
	errorCb: Function = () => {}
) => {
	await axios
		.get(url1 + id + url2)
		.then((res) => {
			onSuccess(res.data, successCb);
		})
		.catch((err: any) => {
			onError(err, errorCb);
		});
};

const onSuccess = (data: any, successCb: Function = () => {}) => {
	successCb(data);
};

const onError = (err: any, errorCb: Function = () => {}) => {
	showApiConnectionError();
	errorCb();
};
