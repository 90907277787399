import * as React from 'react';

type Props = {
	color?: string;
};

const SvgScale = (props: Props) => {
	const width = 20;
	const height = 20;
	const defaultColor = '#9E3667';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M3.51239 3.92264L7.67421 3.92132C8.63082 3.92132 9.41423 3.19106 9.49017 2.26422L9.4967 2.09392L9.49811 0.963445C9.49811 0.686996 9.72197 0.462891 9.99811 0.462891C10.2436 0.462891 10.4477 0.639962 10.4901 0.87347L10.4981 0.963445L10.4969 2.16563C10.5219 3.09019 11.2495 3.8426 12.172 3.91553L12.3192 3.92132L16.4126 3.92201C16.6238 3.90616 16.8457 4.01929 16.9273 4.26258L19.9743 13.3366C19.9945 13.3965 20.0026 13.4568 20.0005 13.5152C19.9897 15.4546 18.405 17.021 16.4531 17.021C14.5599 17.021 13.0115 15.5475 12.9104 13.6896L12.9052 13.4849L12.9169 13.3875L12.9315 13.3366L15.7566 4.92183L12.3192 4.92243C11.6252 4.92243 10.9895 4.67344 10.4977 4.26024L10.4979 18.9003C12.207 18.9364 13.8537 19.1416 15.3411 19.4979C15.6096 19.5622 15.7752 19.8324 15.711 20.1012C15.6467 20.3701 15.3769 20.5359 15.1083 20.4715C13.715 20.1377 12.1678 19.9423 10.5581 19.903L10.0973 19.8986C10.0653 19.905 10.0321 19.9084 9.99811 19.9084C9.96304 19.9084 9.92882 19.9048 9.89579 19.8979C8.10485 19.8997 6.38133 20.0953 4.84072 20.4598C4.57198 20.5234 4.30263 20.3569 4.23912 20.0878C4.1756 19.8188 4.34196 19.5492 4.6107 19.4856C6.06275 19.142 7.6626 18.9422 9.32538 18.902L9.49761 18.8993L9.49804 4.2576C9.04684 4.63794 8.47395 4.87979 7.846 4.91731L7.67421 4.92243L4.24761 4.92183L7.07804 13.3502C7.09864 13.4116 7.10672 13.4733 7.10398 13.5331C7.09096 15.4726 5.50494 17.0386 3.55161 17.0386C1.65651 17.0386 0.106123 15.5633 0.00486934 13.7035L0.000244141 13.4843L0.0119319 13.3988L0.0320832 13.3319L3.07818 4.26216C3.14944 4.04996 3.32736 3.93678 3.51239 3.92264ZM6.05358 14.0104L1.04966 14.0103C1.28413 15.166 2.31462 16.0375 3.55161 16.0375C4.78915 16.0375 5.81922 15.1662 6.05358 14.0104ZM13.9553 13.9969L18.95 13.9969C18.7157 15.1505 17.6878 16.0199 16.4531 16.0199C15.2182 16.0199 14.1897 15.1504 13.9553 13.9969ZM3.55211 5.99244L1.19571 13.0092H5.90851L3.55211 5.99244ZM14.1011 12.9956L16.4533 5.99274L18.8048 12.9956H14.1011Z"
			/>
		</svg>
	);
};
export default SvgScale;
