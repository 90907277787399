import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Occupation from './Occupation.d';
import OccupationType from 'types/enums/Occupation.d';
import { isEmpty } from 'utils/validation/validation';

const initialState: Occupation = {
	occupation: null,
	employer: null,
	occupationMonth: null,
	occupationYear: null,
	occupationLimited: false,
	occupationLimitedMonth: null,
	occupationLimitedYear: null,
	occupation2: null,
	employer2: null,
	occupationMonth2: null,
	occupationYear2: null,
	occupationLimited2: false,
	occupationLimitedMonth2: null,
	occupationLimitedYear2: null
};

export const slice = createSlice({
	name: 'Occupation',
	initialState: initialState,
	reducers: {
		setOccupation: (state, action: PayloadAction<OccupationType>) => {
			state.occupation = action.payload;
		},
		setEmployer: (state, action: PayloadAction<string>) => {
			state.employer = !isEmpty(action.payload) ? action.payload : null;
		},
		setOccupationMonth: (state, action: PayloadAction<string>) => {
			state.occupationMonth = !isEmpty(action.payload) ? action.payload : null;
		},
		setOccupationYear: (state, action: PayloadAction<string>) => {
			state.occupationYear = !isEmpty(action.payload) ? action.payload : null;
		},
		setOccupationLimited: (state, action: PayloadAction<boolean>) => {
			state.occupationLimited = action.payload;
		},
		setOccupationLimitedMonth: (state, action: PayloadAction<string>) => {
			state.occupationLimitedMonth = !isEmpty(action.payload) ? action.payload : null;
		},
		setOccupationLimitedYear: (state, action: PayloadAction<string>) => {
			state.occupationLimitedYear = !isEmpty(action.payload) ? action.payload : null;
		},
		setOccupation2: (state, action: PayloadAction<OccupationType>) => {
			state.occupation2 = action.payload;
		},
		setEmployer2: (state, action: PayloadAction<string>) => {
			state.employer2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setOccupationMonth2: (state, action: PayloadAction<string>) => {
			state.occupationMonth2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setOccupationYear2: (state, action: PayloadAction<string>) => {
			state.occupationYear2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setOccupationLimited2: (state, action: PayloadAction<boolean>) => {
			state.occupationLimited2 = action.payload;
		},
		setOccupationLimitedMonth2: (state, action: PayloadAction<string>) => {
			state.occupationLimitedMonth2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setOccupationLimitedYear2: (state, action: PayloadAction<string>) => {
			state.occupationLimitedYear2 = !isEmpty(action.payload) ? action.payload : null;
		},
		reset: () => initialState
	}
});

export const {
	setOccupation,
	setEmployer,
	setOccupationMonth,
	setOccupationYear,
	setOccupationLimited,
	setOccupationLimitedMonth,
	setOccupationLimitedYear,
	setOccupation2,
	setEmployer2,
	setOccupationMonth2,
	setOccupationYear2,
	setOccupationLimited2,
	setOccupationLimitedMonth2,
	setOccupationLimitedYear2,
	reset
} = slice.actions;
export default slice.reducer;
