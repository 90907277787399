import React from 'react';

const Svg = () => {
	const width = 24;
	const height = 24;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path d="m10.12,20.64c-.4,0-.78-.16-1.06-.44l-6.19-6.19c-.59-.59-.59-1.54,0-2.12s1.54-.59,2.12,0l4.96,4.96L19.54,3.97c.49-.66,1.43-.8,2.1-.31.66.49.8,1.43.31,2.1l-10.62,14.27c-.26.35-.66.57-1.09.6-.04,0-.07,0-.11,0Z" />
		</svg>
	);
};
export default Svg;
