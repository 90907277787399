import React from 'react';

const SvgCreditDone = () => {
	const width = 382;
	const height = 459;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<defs>
				<circle id="done-illustration_svg__a" cx={153.5} cy={251.5} r={153.5} />
				<path id="done-illustration_svg__c" d="M0 .427h14.293V14.73H0z" />
			</defs>
			<g fill="none" fillRule="evenodd">
				<path
					d="M189.883 99.191c28.18-8.69 45.769-16.37 52.763-23.036 10.493-10 13.576-11.988 23.837-32.645 6.84-13.772 23.86-1.677 51.059 36.283l28.795 34.821 12.47 14.61c-4.756 4.935-9.028 11.606-12.817 20.013-3.79 8.407-.588 27.82 9.604 58.235l-71.059-40.016-62.62-44.64-32.032-23.625z"
					fill="#DEF3F5"
				/>
				<g transform="translate(55)">
					<circle fill="#DEF3F5" cx={252} cy={75} r={75} />
					<mask id="done-illustration_svg__b" fill="#fff">
						<use xlinkHref="#done-illustration_svg__a" />
					</mask>
					<use fill="#DEF3F5" xlinkHref="#done-illustration_svg__a" />
					<g mask="url(#done-illustration_svg__b)">
						<path fill="#FFF" d="M206 357h48v-52h-48z" />
						<path fill="#FFF" d="M251 357h19v-59h-19z" />
						<path
							fill="#C3DDE6"
							d="M207 316h39v-3h-39zM207 326h39v-3h-39zM244 357h6.384l.616-51z"
						/>
						<path
							d="M299.963 295.01c3.24-.14 6.431 1.2 7.56 4.532.439 1.295.508 2.698.402 4.054-.217 2.78-2.447 5.67-1.685 8.483.501 1.853 2.094 3.13 3.379 4.425 2.257 2.273 2.775 5.386 2.114 9.552-.31 1.959-1.247 4.545-2.34 6.188-1.206 1.81-2.497 3.09-4.323 4.41-1.39 1.005-3.282 1.733-4.927 2.096-3.36.744-7.114-.232-9.956-2.132a12.272 12.272 0 01-.94-.696c-5.244-4.31-5.362-12.337-1.83-17.739.831-1.268 1.792-2.445 2.604-3.727.75-1.184 1.326-2.45 1.584-3.84.29-1.554-.288-2.849-.658-4.328-.735-2.943.168-6.235 2.238-8.417 1.625-1.712 4.216-2.75 6.778-2.861"
							fill="#D1ECEF"
						/>
						<path fill="#C3DDE6" d="M298 357h2v-29h-2z" />
					</g>
					<g mask="url(#done-illustration_svg__b)">
						<path
							d="M206.467 267.943s.79-25.114-37.725-47.281c0 0-19.487-10.244-21.129-38.32-1.566-26.744-20.823-62.898-66.894-62.148 0 0-48.693 1.875-56.56 48.735l80.155 92.22 102.153 6.794z"
							fill="#000"
						/>
						<path
							d="M63.712 293.763c0 10.352-8.386 18.745-18.727 18.745-10.345 0-18.731-8.393-18.731-18.745 0-10.35 8.386-18.742 18.73-18.742 10.342 0 18.728 8.392 18.728 18.742"
							fill="#F9C8BC"
						/>
						<path
							fill="#F9C8BC"
							d="M12.669 505.26L61.99 300.884l-35.735-7.12-43.55 211.494z"
						/>
						<path
							d="M107.313 255.151l75.66.375 17.653 35.757-5.44 177.993-83.794 2.246L43.41 367.426s-11.804-14.529-7.32-37.917l5.112-54.563 37.516-20.71 28.595.915z"
							fill="#9E3667"
						/>
						<path
							d="M65.361 233.783l41.952 8.622s15.357-1.499 16.48-12.37c0 0 5.244-37.49-3.37-56.983 0 0-9.365-55.856-64.802-38.612 0 0-35.207 17.618-27.715 54.734 0 0 9.362 32.239 37.455 44.609"
							fill="#F9C8BC"
						/>
						<path
							d="M55.622 218.04l18.948 62.693s4.253 10.777 20.786 2.449c0 0 16.707-9.645 15.217-21.349l-5.884-35.922-49.067-7.872z"
							fill="#F9C8BC"
						/>
						<path
							d="M129.73 263.057h-8.151s-5.62-.386-8.01 3.702l-10.388 17.578s2.588 2.386 5.134.384l9.496-15.217 8.115.079 3.804-6.526zM107.313 242.406l-33.026-9.874 35.588 26.777z"
							fill="#F9BEB0"
						/>
						<path
							d="M60.611 150.446s14.023 10.007 30.483 10.983c0 0 24.633 2.32 29.329 11.623 0 0-1.126-48.359-41.203-50.233 0 0-55.81-1.125-56.184 56.232 0 0-.75 22.118 16.106 41.612 0 0 5.811 5.963 6.228 17.65.525 14.75-1.243 21.851 13.248 27.011l9.686-5.336-12.683-41.949s-12.353 2.86-17.918-8.51c-1.474-3.52-3.64-12.266 2.087-15.702 1.544-.929 6.802-1.393 9.893 1.469 0 0 5.022 4.022 6.688 10.371 0 0 4.152-10.96-2.59-19.585 0 0-4.654-6.179.345-11.155 0 0 10.23-10.985 6.485-24.481"
							fill="#000"
						/>
						<path fill="#F9C8BC" d="M120.422 185.423l9.738 17.246-7.865.75z" />
						<path
							d="M131.737 266.747s27.639-23.217 59.85-10.471c0 0 23.633 8.931 34.086 37.862l34.834 96.721s1.874 4.496-6.741 8.248c-8.617 3.748-14.983 5.623-19.853 27.741-4.868 22.116-29.714 32.462-42.472 31.951l9.184-173.134-26.22-15.238s-30.387 2.719-38.626 15.09l-24.72 13.871s-1.61-19.58 7.007-25.202l13.67-7.439z"
							fill="#1BB3BE"
						/>
						<path
							d="M296.342 267.051c0 11.475-9.296 20.779-20.763 20.779-11.467 0-20.762-9.304-20.762-20.779 0-11.476 9.295-20.78 20.762-20.78s20.763 9.304 20.763 20.78"
							fill="#FFF"
						/>
						<path
							fill="#FC3"
							d="M301.46 177.587l-10.123-10.131 10.122-10.13 10.122 10.13z"
						/>
						<path
							d="M200.8 287.766c0 10.352-8.384 18.744-18.727 18.744-10.345 0-18.729-8.392-18.729-18.744s8.384-18.743 18.729-18.743c10.343 0 18.727 8.39 18.727 18.743M160.286 407.05c0 9.562-7.746 17.314-17.3 17.314s-17.298-7.752-17.298-17.314c0-9.563 7.744-17.315 17.298-17.315 9.554 0 17.3 7.752 17.3 17.315"
							fill="#F9C8BC"
						/>
						<path
							fill="#F9C8BC"
							d="M199.454 294.138L159.248 413l-30.959-15.77 35.58-114.337z"
						/>
						<path
							fill="#F9C8BC"
							d="M119.673 306.136l43.823 90.72-37.001 15.378-25.549-101.976z"
						/>
						<path
							d="M119.971 306.777l23.298-21.26s5.193-4.688.75-11.247l-7.492-9.745s-1.871-4.126-7.865-1.5l-13.882 7.136s-3.183 1.32-4.385 6.612l-2.333 9.494s4.12 1.123 5.994-3.75l2.165-5.926 11.167-4.688.526 4.241-16.203 11.718s-14.014 11.135-10.766 22.396l19.026-3.48z"
							fill="#F9C8BC"
						/>
						<path
							d="M281.799 274.268a.811.811 0 01-.81.811h-10.817a.81.81 0 01-.81-.811v-14.435a.81.81 0 01.81-.81h10.816c.445 0 .81.364.81.81v14.435zm-.81-16.33h-10.817c-1.046 0-1.893.851-1.893 1.895v14.435c0 1.044.847 1.894 1.893 1.894h10.816c1.044 0 1.891-.85 1.891-1.894v-14.435a1.896 1.896 0 00-1.89-1.894zm-1.805 9.563h-7.212a.452.452 0 000 .903h7.212a.453.453 0 000-.903zm0-2.705h-7.212a.45.45 0 000 .9h7.212a.45.45 0 100-.9zm0-2.708h-7.212a.452.452 0 000 .903h7.212a.45.45 0 100-.903z"
							fill="#C3DEE7"
						/>
						<path fill="#F9BEB0" d="M118.214 188.513l8.214 14.513-5.906.535z" />
						<path
							d="M127.914 276.145l-16.498 11.946s-.481 6.853 2.83 10.678c3.295-1.983 2.28-8.148 5.673-10.82 0 0 7.174-6.66 7.995-11.804"
							fill="#1BB3BE"
						/>
						<path fill="#F9BEB0" d="M142.153 352.73l8.861 20.355-4.048-35.87z" />
					</g>
				</g>
				<path
					d="M246.788 256.815s23.674 8.916 34.144 37.796l34.896 96.55s1.876 4.489-6.754 8.234c-8.631 3.741-15.008 5.613-19.886 27.692-4.877 22.078-29.766 32.405-42.547 31.895l9.2-172.83-26.265-15.21s-15.055-26.85 17.212-14.127z"
					fill="#1BB3BE"
				/>
				<path
					d="M215 406.5c0 9.665-7.836 17.5-17.501 17.5-9.665 0-17.499-7.835-17.499-17.5s7.834-17.5 17.499-17.5S215 396.835 215 406.5"
					fill="#F9C8BC"
				/>
				<path
					fill="#F9C8BC"
					d="M174.673 306.136l43.823 90.72-37.001 15.378-25.549-101.976z"
				/>
				<path
					fill="#F9C8BC"
					d="M254.454 294.138L214.248 413l-30.959-15.77 35.58-114.337z"
				/>
				<path
					d="M256 287.499c0 10.217-8.282 18.501-18.499 18.501-10.22 0-18.501-8.284-18.501-18.501S227.282 269 237.501 269c10.217 0 18.499 8.282 18.499 18.499"
					fill="#F9C8BC"
				/>
				<path
					d="M295 92.111c0 21.068-17.08 38.148-38.149 38.148-21.068 0-38.147-17.08-38.147-38.148 0-21.068 17.079-38.148 38.147-38.148 21.07 0 38.149 17.08 38.149 38.148"
					fill="#C3DEE7"
				/>
				<path
					d="M291.296 89.148c0 21.068-17.079 38.148-38.148 38.148-21.069 0-38.148-17.08-38.148-38.148C215 68.08 232.08 51 253.148 51c21.069 0 38.148 17.08 38.148 38.148"
					fill="#FFF"
				/>
				<path
					d="M264.794 99.423c-3.556 3.968-9.484 5.753-14.746 4.448-4.328-1.076-7.508-4.043-8.851-8.212h16.612a.754.754 0 100-1.505H240.2a.757.757 0 00-.733.932c1.288 5.219 5.013 8.954 10.218 10.246 1.307.325 2.636.483 3.96.483 4.634 0 9.18-1.933 12.276-5.389a.752.752 0 00-.06-1.062.756.756 0 00-1.066.059m-24.594-13.8h17.61a.754.754 0 100-1.505h-16.613c1.343-4.169 4.523-7.138 8.851-8.213 5.268-1.308 11.19.48 14.746 4.448a.754.754 0 101.126-1.004c-3.983-4.44-10.356-6.365-16.236-4.905-5.205 1.294-8.93 5.029-10.218 10.246a.753.753 0 00.734.933"
					fill="#231F20"
				/>
				<path
					d="M17 237c-9.39 0-17-7.612-17-17s7.61-17 17-17c9.387 0 17 7.612 17 17s-7.613 17-17 17z"
					fill="#C3DEE7"
				/>
				<path
					d="M14.26 229c-.274 0-.542-.108-.737-.306l-5.218-5.217a1.043 1.043 0 011.475-1.475l4.291 4.288 8.001-12.8a1.044 1.044 0 111.769 1.106l-8.697 13.914c-.167.27-.448.448-.765.483-.04.004-.08.007-.118.007M349.416 167.08v-.002h-6.572a6.466 6.466 0 00.34-2.065c0-3.321-2.467-6.013-5.512-6.013-2.59 0-4.758 1.952-5.348 4.58a3.654 3.654 0 00-.963-.134c-1.975 0-3.606 1.58-3.904 3.642-1.924.1-3.457 1.828-3.457 3.951 0 2.188 1.625 3.961 3.63 3.961.286 0 .562-.04.827-.107h20.086c.266.067.542.107.826.107 2.005 0 3.631-1.773 3.631-3.96 0-2.171-1.6-3.931-3.584-3.96"
					fill="#FFF"
				/>
				<path
					fill="#1BB3BE"
					d="M146.46 107.587l-10.123-10.131 10.122-10.13 10.122 10.13z"
				/>
				<g>
					<path fill="#FFF" d="M306 358h19v-59h-19z" />
					<path
						d="M354.963 296.01c3.24-.14 6.431 1.2 7.56 4.532.439 1.295.508 2.698.402 4.054-.217 2.78-2.447 5.67-1.685 8.483.501 1.853 2.094 3.13 3.379 4.425 2.257 2.273 2.775 5.386 2.114 9.552-.31 1.959-1.247 4.545-2.34 6.188-1.206 1.81-2.497 3.09-4.323 4.41-1.39 1.005-3.282 1.733-4.927 2.096-3.36.744-7.114-.232-9.956-2.132a12.272 12.272 0 01-.94-.696c-5.244-4.31-5.362-12.337-1.83-17.739.831-1.268 1.792-2.445 2.604-3.727.75-1.184 1.326-2.45 1.584-3.84.29-1.554-.288-2.849-.658-4.328-.735-2.943.168-6.235 2.238-8.417 1.625-1.712 4.216-2.75 6.778-2.861"
						fill="#D1ECEF"
					/>
					<path fill="#C3DDE6" d="M353 358h2v-29h-2z" />
				</g>
				<path d="M304.5 357.5H373" stroke="#D6F1F3" strokeLinecap="square" />
				<path
					d="M135.72 120.194c45.609-.742 64.94 34.684 66.84 61.342l.053.805c1.642 28.077 21.129 38.32 21.129 38.32 36.612 21.073 37.24 42.37 37.258 44.663l-1.029-1.177L197.987 226c-2.726-34.725-7.75-55.69-15.073-62.895-11.426-11.24-34.524-17.942-69.296-20.105-2.334-.398-17.9 50.269-18.828 50.827-5.728 3.436-3.561 12.181-2.087 15.702 4.953 10.12 15.283 8.967 17.496 8.591l.422-.08 12.683 41.948-9.686 5.336c-14.49-5.16-12.723-12.262-13.248-27.012-.363-10.17-4.811-16.006-5.96-17.349l-.268-.3c-15.676-18.13-16.125-38.528-16.113-41.295l.007-.317c.172-26.472 12.154-40.487 25.006-47.904 15.572-10.294 32.677-10.953 32.677-10.953z"
					fill="#000"
				/>
				<path
					d="M272.563 234.121v-.004h-9.973a9.6 9.6 0 00.518-3.098c0-4.982-3.745-9.019-8.364-9.019-3.931 0-7.22 2.929-8.115 6.87a5.604 5.604 0 00-1.461-.2c-2.995 0-5.471 2.368-5.922 5.462-2.92.149-5.246 2.741-5.246 5.927 0 3.28 2.466 5.941 5.509 5.941.432 0 .85-.06 1.254-.161h30.474c.404.1.823.161 1.254.161 3.042 0 5.509-2.66 5.509-5.941 0-3.256-2.428-5.895-5.437-5.938"
					fill="#FFF"
				/>
			</g>
		</svg>
	);
};
export default SvgCreditDone;
