import * as React from 'react';

const SvgProtectIncapacityForWork = () => {
	const width = 30;
	const height = 30;
	const style1 = { fill: '#9b3667' };
	const style2 = { fill: '#ffffff' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<rect style={style1} width="30" height="30" rx="4.62" />
			<path
				style={style2}
				d="M23.4,8.47h-4V6.72A1.16,1.16,0,0,0,18.2,5.57H11.8a1.16,1.16,0,0,0-1.17,1.15V8.47h-4a2.41,2.41,0,0,0-2.42,2.38V20.5A2.41,2.41,0,0,0,6.6,22.88H23.4a2.41,2.41,0,0,0,2.42-2.38V10.85A2.41,2.41,0,0,0,23.4,8.47Zm-12-1.75a.38.38,0,0,1,.37-.38h6.4a.38.38,0,0,1,.38.38V8.47H11.43ZM25,20.5a1.61,1.61,0,0,1-1.63,1.6H6.6A1.61,1.61,0,0,1,5,20.5V10.85A1.61,1.61,0,0,1,6.6,9.25H23.4A1.61,1.61,0,0,1,25,10.85V20.5Z"
			/>
			<path
				style={style2}
				d="M17.64,14.5H16.36V13.25a.63.63,0,0,0-.61-.65H14.28a.64.64,0,0,0-.62.65V14.5H12.39a.64.64,0,0,0-.62.65v1.28a.64.64,0,0,0,.62.65h1.27v1.23a.65.65,0,0,0,.62.66h1.47a.64.64,0,0,0,.61-.66V17.08h1.28a.63.63,0,0,0,.61-.65V15.15A.63.63,0,0,0,17.64,14.5Zm-.07,1.79h-2v2H14.47v-2h-2v-1h2v-2h1.08v2h2v1Z"
			/>
		</svg>
	);
};

export default SvgProtectIncapacityForWork;
