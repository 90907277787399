import React from 'react';
import classNames from 'classnames';

import styles from './Title.module.scss';

type Props = {
	isSubtitle?: boolean;
	hasMarginBottom?: boolean;
	isSmall?: boolean;
	children: any;
};

const Title = (props: Props) => {
	if (props.isSubtitle) {
		return (
			<h3
				className={classNames([
					styles.title,
					styles.subtitle,
					props.hasMarginBottom ? styles.marginBottom : null
				])}
			>
				{props.children}
			</h3>
		);
	} else {
		return (
			<h2
				className={classNames([
					styles.title,
					props.isSmall ? styles.isSmall : null,
					props.hasMarginBottom ? styles.marginBottom : null
				])}
			>
				{props.children}
			</h2>
		);
	}
};
export default Title;
