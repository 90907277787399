import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Name from './Name.d';
import Salutation from 'types/enums/Salutation.d';
import { isEmpty } from 'utils/validation/validation';

const initialState: Name = {
	salutation: null,
	firstName: null,
	lastName: null,
	salutation2: null,
	firstName2: null,
	lastName2: null
};

export const slice = createSlice({
	name: 'Name',
	initialState: initialState,
	reducers: {
		setSalutation: (state, action: PayloadAction<Salutation>) => {
			state.salutation = action.payload;
		},
		setFirstName: (state, action: PayloadAction<string>) => {
			state.firstName = !isEmpty(action.payload) ? action.payload : null;
		},
		setLastName: (state, action: PayloadAction<string>) => {
			state.lastName = !isEmpty(action.payload) ? action.payload : null;
		},
		setSalutation2: (state, action: PayloadAction<Salutation>) => {
			state.salutation2 = action.payload;
		},
		setFirstName2: (state, action: PayloadAction<string>) => {
			state.firstName2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setLastName2: (state, action: PayloadAction<string>) => {
			state.lastName2 = !isEmpty(action.payload) ? action.payload : null;
		},
		reset: () => initialState
	}
});

export const {
	setSalutation,
	setFirstName,
	setLastName,
	setSalutation2,
	setFirstName2,
	setLastName2,
	reset
} = slice.actions;
export default slice.reducer;
