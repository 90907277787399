import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Applications from './Applications.d';
import Application from 'types/interfaces/Application.d';

const initialState: Applications = {
	isEdit: false,
	applications: []
};

export const slice = createSlice({
	name: 'Applications',
	initialState: initialState,
	reducers: {
		setIsEdit: (state, action: PayloadAction<boolean>) => {
			state.isEdit = action.payload;
		},
		setApplications: (state, action: PayloadAction<Array<Application>>) => {
			state.applications = action.payload;
		},
		reset: () => initialState
	}
});

export const { setIsEdit, setApplications, reset } = slice.actions;
export default slice.reducer;
