import React from 'react';

type Props = {
	color?: string;
};

const SvgQuestionmarkCircle = (props: Props) => {
	const width = 20;
	const height = 20;
	const defaultColor = '#979797';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M10,18.8A8.8,8.8,0,1,1,18.8,10,8.81,8.81,0,0,1,10,18.8M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0m0,13.26a.75.75,0,1,0,.75.75.75.75,0,0,0-.75-.75m.6-7.42A3.65,3.65,0,0,0,8,6.62a.5.5,0,1,0,.65.76,2.7,2.7,0,0,1,1.81-.55,1.33,1.33,0,0,1,1.14,1,1,1,0,0,1-.42,1.09L11,9.05c-.9.74-1.61,1.32-1.52,2.74a.5.5,0,0,0,.5.47h0a.52.52,0,0,0,.47-.53h0c-.06-.91.32-1.22,1.16-1.91l.2-.16a2,2,0,0,0,.77-2.14,2.34,2.34,0,0,0-2-1.68"
			/>
		</svg>
	);
};
export default SvgQuestionmarkCircle;
