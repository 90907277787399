import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Overlay from './Overlay.d';

const initialState: Overlay = {
	showPopupAlreadyExists: false
};

export const slice = createSlice({
	name: 'Overlay',
	initialState: initialState,
	reducers: {
		setShowPopupAlreadyExists: (state, action: PayloadAction<boolean>) => {
			state.showPopupAlreadyExists = action.payload;
		},
		reset: () => initialState
	}
});

export const { setShowPopupAlreadyExists, reset } = slice.actions;
export default slice.reducer;
