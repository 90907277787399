import React from 'react';
import fileDownload from 'js-file-download';
import { base64ToFile } from 'utils/files/files';

import SvgDownload from 'components/Atoms/SVG/SvgDownload';

import styles from './ApplicationItemDocument.module.scss';

type Props = {
	document: any;
};

const ApplicationItemDocument = (props: Props) => {
	const getFileMimeType = (filename: string) => {
		const fileNameArray = filename.split('.');
		switch (fileNameArray[fileNameArray.length - 1].toLowerCase()) {
			case 'png':
				return 'image/png';
			case 'jpg':
			case 'jpeg':
				return 'image/jpeg';
			case 'pdf':
				return 'application/pdf';
			default:
				return null;
		}
	};

	const downloadDocument = () => {
		try {
			const mimeType = getFileMimeType(props.document.filename);
			if (mimeType) {
				base64ToFile(props.document.file, props.document.filename, mimeType).then(
					(file) => {
						fileDownload(file, props.document.filename);
					}
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className={styles.wrapper} onClick={downloadDocument}>
			<div className={styles.col}>
				<div className={styles.name}>{props.document.filename}</div>
			</div>
			<div className={styles.col}>
				<SvgDownload />
			</div>
		</div>
	);
};
export default ApplicationItemDocument;
