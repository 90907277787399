import React from 'react';

type Props = {
	color?: string;
};

const SvgInfoCircle = (props: Props) => {
	const width = 20;
	const height = 20;
	const defaultColor = '#979797';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M10.68,8.63c0-.39-.07-.78-.11-1.17A.45.45,0,0,0,10.14,7H9.86a.45.45,0,0,0-.43.46c0,.38-.08.77-.11,1.16,0,.23,0,.44,0,.67s0,.49,0,.73v5.5A.45.45,0,0,0,9.7,16h.6a.46.46,0,0,0,.43-.48V10c0-.47,0-.93-.05-1.4M10,4.25a.75.75,0,1,0,.75.75A.76.76,0,0,0,10,4.25M10,18.8A8.8,8.8,0,1,1,18.8,10,8.81,8.81,0,0,1,10,18.8M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0"
			/>
		</svg>
	);
};
export default SvgInfoCircle;
