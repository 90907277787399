import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Token from './Token.d';

const initialState: Token = {
	accessToken: null,
	vcJwtToken: null
};

export const slice = createSlice({
	name: 'Token',
	initialState: initialState,
	reducers: {
		setAccessToken: (state, action: PayloadAction<string>) => {
			state.accessToken = action.payload;
		},
		setVcJwtToken: (state, action: PayloadAction<string>) => {
			state.vcJwtToken = action.payload;
		},
		reset: () => initialState
	}
});

export const { setAccessToken, setVcJwtToken, reset } = slice.actions;
export default slice.reducer;
