import m from 'utils/messages/messages';
import store from 'store/store';

import DropdownItem from 'components/Atoms/Form/DropdownInput/types/DropdownItem.d';
import Salutation from 'types/enums/Salutation.d';
import MaritalStatus from 'types/enums/MaritalStatus.d';
import Occupation from 'types/enums/Occupation.d';
import TypeOfLiving from 'types/enums/TypeOfLiving.d';
import { formatMoneyValue } from 'utils/numbers/numbers';

export const getConditionProgramItems = (): Array<DropdownItem> => {
	const conditionPrograms = store.getState().Company.conditionPrograms;
	const conditionProgramItems: Array<DropdownItem> = [];
	for (const conditionProgram of conditionPrograms) {
		conditionProgramItems.push({
			text:
				formatMoneyValue(conditionProgram.minInterest, true) +
				'% bis ' +
				formatMoneyValue(conditionProgram.maxInterest, true) +
				'%',
			value: conditionProgram.program + ''
		});
	}
	return conditionProgramItems;
};

export const getSalutationItems = (): Array<DropdownItem> => [
	{ text: m('salutation.items.male', 'fields'), value: Salutation.MALE },
	{ text: m('salutation.items.female', 'fields'), value: Salutation.FEMALE }
];

export const getCountryCodeItems = (): Array<DropdownItem> => [
	{ text: '+49', value: '49', icon: 'flags/de' },
	{ text: '+34', value: '34', icon: 'flags/es' },
	{ text: '+48', value: '48', icon: 'flags/pl' },
	{ text: '+44', value: '44', icon: 'flags/gb' },
	{ text: '+31', value: '31', icon: 'flags/nl' },
	{ text: '+33', value: '33', icon: 'flags/fr' },
	{ text: '+41', value: '41', icon: 'flags/ch' },
	{ text: '+43', value: '43', icon: 'flags/at' },
	{ text: '+39', value: '39', icon: 'flags/it' },
	{ text: '+45', value: '45', icon: 'flags/dk' },
	{ text: '+32', value: '32', icon: 'flags/be' },
	{ text: '+420', value: '420', icon: 'flags/cz' },
	{ text: '+352', value: '352', icon: 'flags/lu' },
	{ text: '+423', value: '423', icon: 'flags/li' },
	{ text: '+30', value: '30', icon: 'flags/gr' },
	{ text: '+46', value: '46', icon: 'flags/se' },
	{ text: '+47', value: '47', icon: 'flags/no' },
	{ text: '+358', value: '358', icon: 'flags/fi' },
	{ text: '+1', value: '1', icon: 'flags/us' },
	// { text: '+1', value: '1', icon: 'flags/ca' },
	{ text: '+61', value: '61', icon: 'flags/au' },
	{ text: '+93', value: '93', icon: 'flags/af' },
	{ text: '+355', value: '355', icon: 'flags/al' },
	{ text: '+213', value: '213', icon: 'flags/dz' },
	{ text: '+1-684', value: '1684', icon: 'flags/as' },
	{ text: '+376', value: '376', icon: 'flags/ad' },
	{ text: '+244', value: '244', icon: 'flags/ao' },
	{ text: '+1-264', value: '1264', icon: 'flags/ai' },
	{ text: '+672', value: '672', icon: 'flags/aq' },
	{ text: '+1-268', value: '1268', icon: 'flags/ag' },
	{ text: '+54', value: '54', icon: 'flags/ar' },
	{ text: '+374', value: '374', icon: 'flags/am' },
	{ text: '+297', value: '297', icon: 'flags/aw' },
	{ text: '+994', value: '994', icon: 'flags/az' },
	{ text: '+1-242', value: '1242', icon: 'flags/bs' },
	{ text: '+973', value: '973', icon: 'flags/bh' },
	{ text: '+880', value: '880', icon: 'flags/bd' },
	{ text: '+1-246', value: '1246', icon: 'flags/bb' },
	{ text: '+375', value: '375', icon: 'flags/by' },
	{ text: '+501', value: '501', icon: 'flags/bz' },
	{ text: '+229', value: '229', icon: 'flags/bj' },
	{ text: '+1-441', value: '1441', icon: 'flags/bm' },
	{ text: '+975', value: '975', icon: 'flags/bt' },
	{ text: '+591', value: '591', icon: 'flags/bo' },
	{ text: '+387', value: '387', icon: 'flags/ba' },
	{ text: '+267', value: '267', icon: 'flags/bw' },
	{ text: '+55', value: '55', icon: 'flags/br' },
	{ text: '+246', value: '246', icon: 'flags/io' },
	{ text: '+1-284', value: '1284', icon: 'flags/vg' },
	{ text: '+673', value: '673', icon: 'flags/bn' },
	{ text: '+359', value: '359', icon: 'flags/bg' },
	{ text: '+226', value: '226', icon: 'flags/bf' },
	{ text: '+257', value: '257', icon: 'flags/bi' },
	{ text: '+855', value: '855', icon: 'flags/kh' },
	{ text: '+237', value: '237', icon: 'flags/cm' },
	{ text: '+238', value: '238', icon: 'flags/cv' },
	{ text: '+1-345', value: '1345', icon: 'flags/ky' },
	{ text: '+236', value: '236', icon: 'flags/cf' },
	{ text: '+235', value: '235', icon: 'flags/td' },
	{ text: '+56', value: '56', icon: 'flags/cl' },
	{ text: '+86', value: '86', icon: 'flags/cn' },
	{ text: '+61', value: '61', icon: 'flags/cx' },
	// { text: '+61', value: '61', icon: 'flags/cc' },
	{ text: '+57', value: '57', icon: 'flags/co' },
	{ text: '+269', value: '269', icon: 'flags/km' },
	{ text: '+682', value: '682', icon: 'flags/ck' },
	{ text: '+506', value: '506', icon: 'flags/cr' },
	{ text: '+385', value: '385', icon: 'flags/hr' },
	{ text: '+53', value: '53', icon: 'flags/cu' },
	{ text: '+599', value: '599', icon: 'flags/cw' },
	{ text: '+357', value: '357', icon: 'flags/cy' },
	{ text: '+243', value: '243', icon: 'flags/cd' },
	{ text: '+253', value: '253', icon: 'flags/dj' },
	{ text: '+1-767', value: '1767', icon: 'flags/dm' },
	{ text: '+1-809', value: '1809', icon: 'flags/do' },
	{ text: '+1-829', value: '1829', icon: 'flags/do' },
	{ text: '+1-849', value: '1849', icon: 'flags/do' },
	{ text: '+670', value: '670', icon: 'flags/tl' },
	{ text: '+593', value: '593', icon: 'flags/ec' },
	{ text: '+20', value: '20', icon: 'flags/eg' },
	{ text: '+503', value: '503', icon: 'flags/sv' },
	{ text: '+240', value: '240', icon: 'flags/gq' },
	{ text: '+291', value: '291', icon: 'flags/er' },
	{ text: '+372', value: '372', icon: 'flags/ee' },
	{ text: '+251', value: '251', icon: 'flags/et' },
	{ text: '+500', value: '500', icon: 'flags/fk' },
	{ text: '+298', value: '298', icon: 'flags/fo' },
	{ text: '+679', value: '679', icon: 'flags/fj' },
	{ text: '+689', value: '689', icon: 'flags/pf' },
	{ text: '+241', value: '241', icon: 'flags/ga' },
	{ text: '+220', value: '220', icon: 'flags/gm' },
	{ text: '+995', value: '995', icon: 'flags/ge' },
	{ text: '+233', value: '233', icon: 'flags/gh' },
	{ text: '+350', value: '350', icon: 'flags/gi' },
	{ text: '+299', value: '299', icon: 'flags/gl' },
	{ text: '+1-473', value: '1473', icon: 'flags/gd' },
	{ text: '+1-671', value: '1671', icon: 'flags/gu' },
	{ text: '+502', value: '502', icon: 'flags/gt' },
	{ text: '+44-1481', value: '441481', icon: 'flags/gg' },
	{ text: '+224', value: '224', icon: 'flags/gn' },
	{ text: '+245', value: '245', icon: 'flags/gw' },
	{ text: '+592', value: '592', icon: 'flags/gy' },
	{ text: '+509', value: '509', icon: 'flags/ht' },
	{ text: '+504', value: '504', icon: 'flags/hn' },
	{ text: '+852', value: '852', icon: 'flags/hk' },
	{ text: '+36', value: '36', icon: 'flags/hu' },
	{ text: '+354', value: '354', icon: 'flags/is' },
	{ text: '+91', value: '91', icon: 'flags/in' },
	{ text: '+62', value: '62', icon: 'flags/id' },
	{ text: '+98', value: '98', icon: 'flags/ir' },
	{ text: '+964', value: '964', icon: 'flags/iq' },
	{ text: '+353', value: '353', icon: 'flags/ie' },
	{ text: '+44-1624', value: '441624', icon: 'flags/im' },
	{ text: '+972', value: '972', icon: 'flags/il' },
	{ text: '+225', value: '225', icon: 'flags/ci' },
	{ text: '+1-876', value: '1876', icon: 'flags/jm' },
	{ text: '+81', value: '81', icon: 'flags/jp' },
	{ text: '+44-1534', value: '441534', icon: 'flags/je' },
	{ text: '+962', value: '962', icon: 'flags/jo' },
	// { text: '+7', value: '7', icon: 'flags/kz' },
	{ text: '+254', value: '254', icon: 'flags/ke' },
	{ text: '+686', value: '686', icon: 'flags/ki' },
	{ text: '+383', value: '383', icon: 'flags/xk' },
	{ text: '+965', value: '965', icon: 'flags/kw' },
	{ text: '+996', value: '996', icon: 'flags/kg' },
	{ text: '+856', value: '856', icon: 'flags/la' },
	{ text: '+371', value: '371', icon: 'flags/lv' },
	{ text: '+961', value: '961', icon: 'flags/lb' },
	{ text: '+266', value: '266', icon: 'flags/ls' },
	{ text: '+231', value: '231', icon: 'flags/lr' },
	{ text: '+218', value: '218', icon: 'flags/ly' },
	{ text: '+370', value: '370', icon: 'flags/lt' },
	{ text: '+853', value: '853', icon: 'flags/mo' },
	{ text: '+389', value: '389', icon: 'flags/mk' },
	{ text: '+261', value: '261', icon: 'flags/mg' },
	{ text: '+265', value: '265', icon: 'flags/mw' },
	{ text: '+60', value: '60', icon: 'flags/my' },
	{ text: '+960', value: '960', icon: 'flags/mv' },
	{ text: '+223', value: '223', icon: 'flags/ml' },
	{ text: '+356', value: '356', icon: 'flags/mt' },
	{ text: '+692', value: '692', icon: 'flags/mh' },
	{ text: '+222', value: '222', icon: 'flags/mr' },
	{ text: '+230', value: '230', icon: 'flags/mu' },
	{ text: '+262', value: '262', icon: 'flags/yt' },
	{ text: '+52', value: '52', icon: 'flags/mx' },
	{ text: '+691', value: '691', icon: 'flags/fm' },
	{ text: '+373', value: '373', icon: 'flags/md' },
	{ text: '+377', value: '377', icon: 'flags/mc' },
	{ text: '+976', value: '976', icon: 'flags/mn' },
	{ text: '+382', value: '382', icon: 'flags/me' },
	{ text: '+1-664', value: '1664', icon: 'flags/ms' },
	{ text: '+212', value: '212', icon: 'flags/ma' },
	{ text: '+258', value: '258', icon: 'flags/mz' },
	{ text: '+95', value: '95', icon: 'flags/mm' },
	{ text: '+264', value: '264', icon: 'flags/na' },
	{ text: '+674', value: '674', icon: 'flags/nr' },
	{ text: '+977', value: '977', icon: 'flags/np' },
	{ text: '+687', value: '687', icon: 'flags/nc' },
	{ text: '+64', value: '64', icon: 'flags/nz' },
	{ text: '+505', value: '505', icon: 'flags/ni' },
	{ text: '+227', value: '227', icon: 'flags/ne' },
	{ text: '+234', value: '234', icon: 'flags/ng' },
	{ text: '+683', value: '683', icon: 'flags/nu' },
	{ text: '+850', value: '850', icon: 'flags/kp' },
	{ text: '+1-670', value: '1670', icon: 'flags/mp' },
	{ text: '+968', value: '968', icon: 'flags/om' },
	{ text: '+92', value: '92', icon: 'flags/pk' },
	{ text: '+680', value: '680', icon: 'flags/pw' },
	{ text: '+970', value: '970', icon: 'flags/ps' },
	{ text: '+507', value: '507', icon: 'flags/pa' },
	{ text: '+675', value: '675', icon: 'flags/pg' },
	{ text: '+595', value: '595', icon: 'flags/py' },
	{ text: '+51', value: '51', icon: 'flags/pe' },
	{ text: '+63', value: '63', icon: 'flags/ph' },
	{ text: '+64', value: '64', icon: 'flags/pn' },
	{ text: '+351', value: '351', icon: 'flags/pt' },
	{ text: '+1-787', value: '1787', icon: 'flags/pr' },
	{ text: '+1-939', value: '1939', icon: 'flags/pr' },
	{ text: '+974', value: '974', icon: 'flags/qa' },
	{ text: '+242', value: '242', icon: 'flags/cg' },
	{ text: '+262', value: '262', icon: 'flags/re' },
	{ text: '+40', value: '40', icon: 'flags/ro' },
	{ text: '+7', value: '7', icon: 'flags/ru' },
	{ text: '+250', value: '250', icon: 'flags/rw' },
	{ text: '+590', value: '590', icon: 'flags/bl' },
	{ text: '+290', value: '290', icon: 'flags/sh' },
	{ text: '+1-869', value: '1869', icon: 'flags/kn' },
	{ text: '+1-758', value: '1758', icon: 'flags/lc' },
	{ text: '+590', value: '590', icon: 'flags/mf' },
	{ text: '+508', value: '508', icon: 'flags/pm' },
	{ text: '+1-784', value: '1784', icon: 'flags/vc' },
	{ text: '+685', value: '685', icon: 'flags/ws' },
	{ text: '+378', value: '378', icon: 'flags/sm' },
	{ text: '+239', value: '239', icon: 'flags/st' },
	{ text: '+966', value: '966', icon: 'flags/sa' },
	{ text: '+221', value: '221', icon: 'flags/sn' },
	{ text: '+381', value: '381', icon: 'flags/rs' },
	{ text: '+248', value: '248', icon: 'flags/sc' },
	{ text: '+232', value: '232', icon: 'flags/sl' },
	{ text: '+65', value: '65', icon: 'flags/sg' },
	{ text: '+1-721', value: '1721', icon: 'flags/sx' },
	{ text: '+421', value: '421', icon: 'flags/sk' },
	{ text: '+386', value: '386', icon: 'flags/si' },
	{ text: '+677', value: '677', icon: 'flags/sb' },
	{ text: '+252', value: '252', icon: 'flags/so' },
	{ text: '+27', value: '27', icon: 'flags/za' },
	{ text: '+82', value: '82', icon: 'flags/kr' },
	{ text: '+211', value: '211', icon: 'flags/ss' },
	{ text: '+94', value: '94', icon: 'flags/lk' },
	{ text: '+249', value: '249', icon: 'flags/sd' },
	{ text: '+597', value: '597', icon: 'flags/sr' },
	{ text: '+47', value: '47', icon: 'flags/sj' },
	{ text: '+268', value: '268', icon: 'flags/sz' },
	{ text: '+963', value: '963', icon: 'flags/sy' },
	{ text: '+886', value: '886', icon: 'flags/tw' },
	{ text: '+992', value: '992', icon: 'flags/tj' },
	{ text: '+255', value: '255', icon: 'flags/tz' },
	{ text: '+66', value: '66', icon: 'flags/th' },
	{ text: '+228', value: '228', icon: 'flags/tg' },
	{ text: '+690', value: '690', icon: 'flags/tk' },
	{ text: '+676', value: '676', icon: 'flags/to' },
	{ text: '+1-868', value: '1868', icon: 'flags/tt' },
	{ text: '+216', value: '216', icon: 'flags/tn' },
	{ text: '+90', value: '90', icon: 'flags/tr' },
	{ text: '+993', value: '993', icon: 'flags/tm' },
	{ text: '+1-649', value: '1649', icon: 'flags/tc' },
	{ text: '+688', value: '688', icon: 'flags/tv' },
	{ text: '+1-340', value: '1340', icon: 'flags/vi' },
	{ text: '+256', value: '256', icon: 'flags/ug' },
	{ text: '+380', value: '380', icon: 'flags/ua' },
	{ text: '+971', value: '971', icon: 'flags/ae' },
	{ text: '+598', value: '598', icon: 'flags/uy' },
	{ text: '+998', value: '998', icon: 'flags/uz' },
	{ text: '+678', value: '678', icon: 'flags/vu' },
	{ text: '+379', value: '379', icon: 'flags/va' },
	{ text: '+58', value: '58', icon: 'flags/ve' },
	{ text: '+84', value: '84', icon: 'flags/vn' },
	{ text: '+681', value: '681', icon: 'flags/wf' },
	{ text: '+212', value: '212', icon: 'flags/eh' },
	{ text: '+967', value: '967', icon: 'flags/ye' },
	{ text: '+260', value: '260', icon: 'flags/zm' },
	{ text: '+263', value: '263', icon: 'flags/zw' }
];

export const getGermanMobileNumberPrefixes = (): Array<string> => [
	'15019',
	'1511',
	'1512',
	'1514',
	'1515',
	'1516',
	'1517',
	'1520',
	'1521',
	'1522',
	'1523',
	'1525',
	'1526',
	'1529',
	'15566',
	'15630',
	'15678',
	'1570',
	'1573',
	'1575',
	'1577',
	'1578',
	'1579',
	'15888',
	'1590',
	'160',
	'162',
	'163',
	'170',
	'171',
	'172',
	'173',
	'174',
	'175',
	'176',
	'177',
	'178',
	'179',
	'151',
	'152',
	'157',
	'159'
];

export const getNationalityItems = (): Array<DropdownItem> => [
	{ text: 'Deutschland', value: 'DE' },
	{ text: 'Österreich', value: 'AT' },
	{ text: 'Niederlande', value: 'NL' },
	{ text: 'Polen', value: 'PL' },
	{ text: 'Türkei', value: 'TR' },
	{ text: 'Italien', value: 'IT' },
	{ text: 'Spanien', value: 'ES' },
	{ text: 'Rumänien', value: 'RO' },
	{ text: 'Ukraine', value: 'UA' },
	{ text: 'Afghanistan', value: 'AF' },
	{ text: 'Ägypten', value: 'EG' },
	{ text: 'Albanien', value: 'AL' },
	{ text: 'Algerien', value: 'DZ' },
	{ text: 'Angola', value: 'AO' },
	{ text: 'Anguilla', value: 'AI' },
	{ text: 'Antigua', value: 'AG' },
	{ text: 'Arabische', value: 'AE' },
	{ text: 'Argentinien', value: 'AR' },
	{ text: 'Armenien', value: 'AM' },
	{ text: 'Aruba', value: 'AW' },
	{ text: 'Aserbaidschan', value: 'AZ' },
	{ text: 'Äthiopien', value: 'ET' },
	{ text: 'Australien', value: 'AU' },
	{ text: 'Bahamas', value: 'BS' },
	{ text: 'Bahrain', value: 'BH' },
	{ text: 'Bangladesch', value: 'BD' },
	{ text: 'Barbados', value: 'BB' },
	{ text: 'Belarus', value: 'BY' },
	{ text: 'Belgien', value: 'BE' },
	{ text: 'Belize', value: 'BZ' },
	{ text: 'Benin', value: 'BJ' },
	{ text: 'Bermuda', value: 'BM' },
	{ text: 'Bhutan', value: 'BT' },
	{ text: 'Bolivien', value: 'BO' },
	{ text: 'Bosnien-Herzegowina', value: 'BA' },
	{ text: 'Botsuana', value: 'BW' },
	{ text: 'Brasilien', value: 'BR' },
	{ text: 'Brunei', value: 'BN' },
	{ text: 'Bulgarien', value: 'BG' },
	{ text: 'Burkina', value: 'BF' },
	{ text: 'Burundi', value: 'BI' },
	{ text: 'Chile', value: 'CL' },
	{ text: 'Costa Rica', value: 'CR' },
	{ text: 'Curacao', value: 'CW' },
	{ text: 'Dänemark', value: 'DK' },
	{ text: 'Demokr. Rep. Kongo', value: 'CD' },
	{ text: 'Dominica', value: 'DM' },
	{ text: 'Dominikanische Rep.', value: 'DO' },
	{ text: 'Dschibuti', value: 'DJ' },
	{ text: 'Ecuador', value: 'EC' },
	{ text: 'El Salvador', value: 'SV' },
	{ text: 'Elfenbeinküste', value: 'CI' },
	{ text: 'Eritrea', value: 'ER' },
	{ text: 'Estland', value: 'EE' },
	{ text: 'Fidschi', value: 'FJ' },
	{ text: 'Finnland', value: 'FI' },
	{ text: 'Frankreich', value: 'FR' },
	{ text: 'Gabun', value: 'GA' },
	{ text: 'Gambia', value: 'GM' },
	{ text: 'Georgien', value: 'GE' },
	{ text: 'Ghana', value: 'GH' },
	{ text: 'Gibraltar', value: 'GI' },
	{ text: 'Grenada', value: 'GD' },
	{ text: 'Griechenland', value: 'GR' },
	{ text: 'Grossbritannien', value: 'GB' },
	{ text: 'Guatemala', value: 'GT' },
	{ text: 'Guinea', value: 'GN' },
	{ text: 'Guyana', value: 'GY' },
	{ text: 'Haiti', value: 'HT' },
	{ text: 'Honduras', value: 'HN' },
	{ text: 'Indien', value: 'IN' },
	{ text: 'Indonesien', value: 'ID' },
	{ text: 'Irak', value: 'IQ' },
	{ text: 'Iran', value: 'IR' },
	{ text: 'Irland', value: 'IE' },
	{ text: 'Island', value: 'IS' },
	{ text: 'Israel', value: 'IL' },
	{ text: 'Italien', value: 'IT' },
	{ text: 'Jamaika', value: 'JM' },
	{ text: 'Japan', value: 'JP' },
	{ text: 'Jemen', value: 'YE' },
	{ text: 'Jersey', value: 'JE' },
	{ text: 'Jordanien', value: 'JO' },
	{ text: 'Kaimaninseln', value: 'KY' },
	{ text: 'Kambodscha', value: 'KH' },
	{ text: 'Kamerun', value: 'CM' },
	{ text: 'Kanada', value: 'CA' },
	{ text: 'Kap Verde', value: 'CV' },
	{ text: 'Kasachstan', value: 'KZ' },
	{ text: 'Katar', value: 'QA' },
	{ text: 'Kenia', value: 'KE' },
	{ text: 'Kirgisistan', value: 'KG' },
	{ text: 'Kiribati', value: 'KI' },
	{ text: 'Kolumbien', value: 'CO' },
	{ text: 'Komoren', value: 'KM' },
	{ text: 'Kosovo', value: 'XK' },
	{ text: 'Korea, Demokratische Volksrepublik (Nordkorea)', value: 'KP' },
	{ text: 'Korea, Republik (Südkorea)', value: 'KR' },
	{ text: 'Kroatien', value: 'HR' },
	{ text: 'Kuba', value: 'CU' },
	{ text: 'Kuwait', value: 'KW' },
	{ text: 'Laos', value: 'LA' },
	{ text: 'Lesotho', value: 'LS' },
	{ text: 'Lettland', value: 'LV' },
	{ text: 'Libanon', value: 'LB' },
	{ text: 'Liberia', value: 'LR' },
	{ text: 'Libyen', value: 'LY' },
	{ text: 'Liechtenstein', value: 'LI' },
	{ text: 'Litauen', value: 'LT' },
	{ text: 'Luxemburg', value: 'LU' },
	{ text: 'Madagaskar', value: 'MG' },
	{ text: 'Malawi', value: 'MW' },
	{ text: 'Malaysia', value: 'MY' },
	{ text: 'Malediven', value: 'MV' },
	{ text: 'Mali', value: 'ML' },
	{ text: 'Malta', value: 'MT' },
	{ text: 'Marokko', value: 'MA' },
	{ text: 'Marshall-Inseln', value: 'MH' },
	{ text: 'Martinique', value: 'MQ' },
	{ text: 'Mauretanien', value: 'MR' },
	{ text: 'Mauritius', value: 'MU' },
	{ text: 'Mazedonien', value: 'MK' },
	{ text: 'Mexiko', value: 'MX' },
	{ text: 'Mikronesien', value: 'FM' },
	{ text: 'Moldawien', value: 'MD' },
	{ text: 'Monaco', value: 'MC' },
	{ text: 'Mongolei', value: 'MN' },
	{ text: 'Monserrat', value: 'MS' },
	{ text: 'Montenegro', value: 'ME' },
	{ text: 'Mosambik', value: 'MZ' },
	{ text: 'Myanmar', value: 'MM' },
	{ text: 'Namibia', value: 'NA' },
	{ text: 'Nauru', value: 'NR' },
	{ text: 'Nepal', value: 'NP' },
	{ text: 'Neuseeland', value: 'NZ' },
	{ text: 'Nicaragua', value: 'NI' },
	{ text: 'Niger', value: 'NE' },
	{ text: 'Norwegen', value: 'NO' },
	{ text: 'Oman', value: 'OM' },
	{ text: 'Pakistan', value: 'PK' },
	{ text: 'Palästina', value: 'PS' },
	{ text: 'Palau', value: 'PW' },
	{ text: 'Panama', value: 'PA' },
	{ text: 'Papua-Neuguinea', value: 'PG' },
	{ text: 'Paraguay', value: 'PY' },
	{ text: 'Peru', value: 'PE' },
	{ text: 'Philippinen', value: 'PH' },
	{ text: 'Portugal', value: 'PT' },
	{ text: 'Puerto', value: 'PR' },
	{ text: 'Republik Kongo', value: 'CG' },
	{ text: 'Republik Serbien', value: 'RS' },
	{ text: 'Reunion', value: 'RE' },
	{ text: 'Ruanda', value: 'RW' },
	{ text: 'Russland', value: 'RU' },
	{ text: 'Salomonen', value: 'SB' },
	{ text: 'Sambia', value: 'ZM' },
	{ text: 'Samoa', value: 'WS' },
	{ text: 'San Marino', value: 'SM' },
	{ text: 'Sao Tome u. Princip', value: 'ST' },
	{ text: 'Saudi-Arabien', value: 'SA' },
	{ text: 'Schweden', value: 'SE' },
	{ text: 'Schweiz', value: 'CH' },
	{ text: 'Senegal', value: 'SN' },
	{ text: 'Serbien', value: 'CS' },
	{ text: 'Seychellen', value: 'SC' },
	{ text: 'Sierra Leone', value: 'SL' },
	{ text: 'Simbabwe', value: 'ZW' },
	{ text: 'Singapur', value: 'SG' },
	{ text: 'Slowakei', value: 'SK' },
	{ text: 'Slowenien', value: 'SI' },
	{ text: 'Somalia', value: 'SO' },
	{ text: 'Sri Lanka', value: 'LK' },
	{ text: 'Südafrika', value: 'ZA' },
	{ text: 'Sudan', value: 'SD' },
	{ text: 'Suriname', value: 'SR' },
	{ text: 'Swasiland', value: 'SZ' },
	{ text: 'Tadschikistan', value: 'TJ' },
	{ text: 'Taiwan', value: 'TW' },
	{ text: 'Tansania', value: 'TZ' },
	{ text: 'Thailand', value: 'TH' },
	{ text: 'Timor-Leste', value: 'TL' },
	{ text: 'Togo', value: 'TG' },
	{ text: 'Tonga', value: 'TO' },
	{ text: 'Trinidad', value: 'TT' },
	{ text: 'Tschad', value: 'TD' },
	{ text: 'Tschechien', value: 'CZ' },
	{ text: 'Tunesien', value: 'TN' },
	{ text: 'Turkmenistan', value: 'TM' },
	{ text: 'Uganda', value: 'UG' },
	{ text: 'Ungarn', value: 'HU' },
	{ text: 'Uruguay', value: 'UY' },
	{ text: 'USA', value: 'US' },
	{ text: 'Usbekistan', value: 'UZ' },
	{ text: 'Vanuatu', value: 'VU' },
	{ text: 'Vatikanstadt', value: 'VA' },
	{ text: 'Venezuela', value: 'VE' },
	{ text: 'Vietnam', value: 'VN' },
	{ text: 'Volksrep. China', value: 'CN' },
	{ text: 'Zentralafrika', value: 'CF' },
	{ text: 'Zypern', value: 'CY' }
];

export const getMaritalStatusItems = (): Array<DropdownItem> => [
	{
		text: m('maritalStatus.items.single', 'fields'),
		value: MaritalStatus.SINGLE
	},
	{
		text: m('maritalStatus.items.married', 'fields'),
		value: MaritalStatus.MARRIED
	},
	{
		text: m('maritalStatus.items.partnered', 'fields'),
		value: MaritalStatus.PARTNERED
	},
	{
		text: m('maritalStatus.items.separated', 'fields'),
		value: MaritalStatus.SEPARATED
	},
	{
		text: m('maritalStatus.items.divorced', 'fields'),
		value: MaritalStatus.DIVORCED
	},
	{
		text: m('maritalStatus.items.widowed', 'fields'),
		value: MaritalStatus.WIDOWED
	}
];

export const getOccupationItems = (): Array<DropdownItem> => [
	{ text: m('occupation.items.employed', 'fields'), value: Occupation.EMPLOYED },
	{
		text: m('occupation.items.pensioner', 'fields'),
		value: Occupation.PENSIONER
	},
	{ text: m('occupation.items.officer', 'fields'), value: Occupation.OFFICER },
	{ text: m('occupation.items.student', 'fields'), value: Occupation.STUDENT },
	{
		text: m('occupation.items.unemployed', 'fields'),
		value: Occupation.UNEMPLOYED
	},
	{
		text: m('occupation.items.freelancer', 'fields'),
		value: Occupation.FREELANCER
	},
	{
		text: m('occupation.items.public', 'fields'),
		value: Occupation.CIVIL_SERVICE_EMPLOYEE
	}
];

export const getTypeOfLivingItems = (): Array<DropdownItem> => [
	{
		text: m('typeOfLiving.items.rentedApartment', 'fields'),
		value: TypeOfLiving.MIETWOHNUNG
	},
	{
		text: m('typeOfLiving.items.ownerOccupiedApartment', 'fields'),
		value: TypeOfLiving.EIGENTUMSWOHNUNG
	},
	{
		text: m('typeOfLiving.items.ownHouse', 'fields'),
		value: TypeOfLiving.EIGENES_HAUS
	},
	{
		text: m('typeOfLiving.items.residentWithTheParents', 'fields'),
		value: TypeOfLiving.WOHNHAFT_BEI_ELTERN
	}
];
