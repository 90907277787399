import * as React from 'react';

const SvgProtectParentalLeave = () => {
	const width = 30;
	const height = 30;
	const style1 = { fill: '#9b3667' };
	const style2 = { fill: '#ffffff' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<rect style={style1} width="30" height="30" rx="4.62" />
			<path
				style={style2}
				d="M24.31,11.24a6.43,6.43,0,0,0-6.43-6.41.44.44,0,0,0-.44.43.15.15,0,0,0,0,.07s0,0,0,0v5.44H8.38L6,6.73A1.42,1.42,0,0,0,4.79,6H4.14a.44.44,0,1,0,0,.88h.65a.56.56,0,0,1,.47.26l2.49,4.3h0l3.48,6a2.66,2.66,0,0,0,2.31,1.32H14l-2,3.43a1.93,1.93,0,1,0,1.94,1.93,1.85,1.85,0,0,0-.15-.74h4.49a1.85,1.85,0,0,0-.15.74,1.94,1.94,0,1,0,2-1.92l-2-3.43h.35a2.67,2.67,0,0,0,2.3-1.33l3.47-6,0-.09h0Zm-.89-.43H18.34V5.72A5.57,5.57,0,0,1,23.42,10.81Zm-9.88,7.11A1.78,1.78,0,0,1,12,17l-3.1-5.35H23.12L20,17a1.78,1.78,0,0,1-1.54.89ZM20,23.1a1.06,1.06,0,1,1-1.06,1A1.05,1.05,0,0,1,20,23.1Zm-1-.58H13l-.14-.08L15,18.8h2.16l2.1,3.61A1,1,0,0,0,19,22.52Zm-7,.58a1.06,1.06,0,1,1-1.06,1A1.05,1.05,0,0,1,12,23.1Z"
			/>
		</svg>
	);
};

export default SvgProtectParentalLeave;
