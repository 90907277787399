import axios from 'axios';
import { getApiBaseURL } from 'utils/api/api';

import store from 'store/store';

import MissingDocument from 'types/interfaces/MissingDocument.d';

const url1 = getApiBaseURL() + '/api/applications/';
const url2 = '/documents';

export const postApplicationsDocuments = async (
	missingDocuments: Array<MissingDocument>,
	successCb: Function = () => {},
	errorCb: Function = () => {}
) => {
	let hasErrors = false;
	for (const missingDocument of missingDocuments) {
		const formData = new FormData();
		formData.append('id', missingDocument.id + '');
		formData.append('applicant', missingDocument.applicant + '');
		for (const file of missingDocument.file) {
			formData.append('files', file);
		}

		await axios
			.post(url1 + store.getState().Bco.applicationId + url2, formData, {
				headers: {
					'content-type': 'multipart/form-data',
					vcJwtToken: store.getState().Token.vcJwtToken
				}
			})
			// eslint-disable-next-line no-loop-func
			.catch((err: any) => {
				hasErrors = true;
				console.log(err);
			});
	}

	if (!hasErrors) {
		onSuccess(successCb);
	} else {
		onError(errorCb);
	}
};

const onSuccess = (successCb: Function = () => {}) => {
	console.log('Upload successful');
	successCb();
};

const onError = (errorCb: Function = () => {}) => {
	console.log('Upload failed');
	errorCb();
};
