import React, { useState } from 'react';
import classNames from 'classnames';
import Slider from 'react-rangeslider';
import { formatMoneyValue } from 'utils/numbers/numbers';

import styles from './SliderInput.module.scss';

type Props = {
	label: string | JSX.Element;
	min: number;
	max: number;
	value: number;
	valueSuffix?: string | JSX.Element;
	valueIsMoney?: boolean;
	step?: number;
	onChange: Function;
	setOneStepDown?: Function;
	setOneStepUp?: Function;
	highlightLabel?: boolean;
	scrollToOnFocusDesktop?: boolean;
	scrollToOnFocusMobile?: boolean;
	tabindex?: number;
	testId?: string;
};

const SliderInput = (props: Props) => {
	const [focus, setFocus] = useState<boolean>(false);

	const onFocus = (event: any) => {
		event.preventDefault();
		setFocus(true);
	};

	const onBlur = (event: any) => {
		event.preventDefault();
		setFocus(false);
	};

	const onChange = (value: any) => {
		props.onChange(value);
	};

	const formatValueForView = () => {
		return formatMoneyValue(props.value);
	};

	return (
		<div
			className={classNames([
				styles.wrapper,
				props.highlightLabel ? styles.highlightLabel : null,
				focus ? 'rangeslider--focus' : null
			])}
			onFocus={onFocus}
			onBlur={onBlur}
			tabIndex={props.tabindex}
			data-testid={props.testId || undefined}
		>
			<div className={styles.row}>
				<div className={styles.label}>{props.label}</div>
				<div className={styles.value}>
					{formatValueForView()}
					{props.valueSuffix ? ' ' + props.valueSuffix : ''}
				</div>
			</div>
			<Slider
				min={props.min}
				max={props.max}
				value={props.value}
				step={props.step || 1}
				orientation="horizontal"
				onChange={onChange}
				tooltip={false}
			/>
		</div>
	);
};
export default SliderInput;
