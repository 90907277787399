interface Environment {
	label: string;
	entryHosts: string[];
	showDebug: boolean;
	showDebugServer: boolean;
	isMatchingOrigin: () => boolean;
}

const localhost: Environment = {
	isMatchingOrigin(): boolean {
		return isLocationMatchingOneOf(this.entryHosts);
	},
	entryHosts: ['localhost'],
	label: 'localhost',
	showDebug: true,
	showDebugServer: true
};

const frontend: Environment = {
	isMatchingOrigin(): boolean {
		return isLocationMatchingOneOf(this.entryHosts);
	},
	entryHosts: process.env.REACT_APP_SERVER_LOCAL_URLS.split(','),
	label: 'frontend',
	showDebug: true,
	showDebugServer: true
};

const dev: Environment = {
	isMatchingOrigin(): boolean {
		return isLocationMatchingOneOf(this.entryHosts);
	},
	entryHosts: process.env.REACT_APP_SERVER_DEV_URLS.split(','),
	label: 'dev',
	showDebug: true,
	showDebugServer: true
};

const pre: Environment = {
	isMatchingOrigin(): boolean {
		return isLocationMatchingOneOf(this.entryHosts);
	},
	entryHosts: process.env.REACT_APP_SERVER_PRE_URLS.split(','),
	label: 'pre',
	showDebug: true,
	showDebugServer: true
};

const pro: Environment = {
	isMatchingOrigin(): boolean {
		return isLocationMatchingOneOf(this.entryHosts);
	},
	entryHosts: process.env.REACT_APP_SERVER_PRO_URLS.split(','),
	label: 'pro',
	showDebug: false,
	showDebugServer: false
};

const environments: Environment[] = [localhost, frontend, dev, pre, pro];

export const getCurrentEnvironment = (): Environment => {
	return environments.find((environment) => environment.isMatchingOrigin());
};

export const isDevelopment = (): boolean => {
	return process.env.NODE_ENV === 'development';
};

export const isTest = (): boolean => {
	return process.env.NODE_ENV === 'test';
};

export const isProduction = (): boolean => {
	return process.env.NODE_ENV === 'production';
};

const isLocationMatchingOneOf = (origins: Array<string>): boolean => {
	const { hostname } = window.location;
	return origins.includes(hostname);
};

export const isAuthEnabled = (): boolean => {
	return [frontend.label, dev.label, pre.label, pro.label].includes(
		getCurrentEnvironment().label
	);
};

export const isServer = (): boolean => {
	return !![dev, pre, pro]
		.map((env) => env.label)
		.find((label) => label === getCurrentEnvironment().label);
};
