import React, { useEffect, useState } from 'react';
import m from 'utils/messages/messages';

import { Row, Col, Container } from 'components/Atoms/Grid';
import Divider from 'components/Atoms/Divider/Divider';
import Button from 'components/Atoms/Button/Button';
import ErrorMessage from 'components/Atoms/Form/ErrorMessage/ErrorMessage';
import SuccessMessage from 'components/Atoms/Form/SuccessMessage/SuccessMessage';
import SvgSecureConnection from 'components/Atoms/SVG/SvgSecureConnection';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import ButtonStyle from 'components/Atoms/Button/types/ButtonStyle.d';
import ButtonType from 'components/Atoms/Button/types/ButtonType.d';

import styles from './Form.module.scss';
import { useValidationUtils } from 'utils/validation/validation';

type Props = {
	showSubmitDivider?: boolean;
	onSubmit?: Function;
	onSubmitLink?: string;
	onSubmitLinkNewTab?: boolean;
	clearErrors?: Function;
	tabindex?: number;
	submitText?: string | JSX.Element;
	submitDisabled?: boolean;
	submitDisabledTooltip?: string | JSX.Element;
	onPrevButton?: Function;
	prevButtonText?: string | JSX.Element;
	prevButtonPrependIcon?: string | JSX.Element;
	prevButtonAppendIcon?: string | JSX.Element;
	prevButtonExtension?: string | JSX.Element;
	onAdditionalButton?: Function;
	onAdditionalLink?: string;
	onAdditionaLinkNewTab?: boolean;
	additionalButtonText?: string | JSX.Element;
	additionalButtonPrependIcon?: string | JSX.Element;
	additionalButtonAppendIcon?: string | JSX.Element;
	additionalButtonStyle?: ButtonStyle;
	additionalButtonDisabled?: boolean;
	footerContent?: JSX.Element;
	disableAutocomplete?: boolean;
	children?: any;
};

const Form = (props: Props) => {
	const isLoadingForm = useSelector((state: RootState) => state.Loading.isLoadingForm);

	const { clear } = useValidationUtils();

	const [clearingErrors, setClearingErrors] = useState<boolean>(true);

	useEffect(() => {
		const firstInputArray: any = document.querySelectorAll('[tabindex="1"]');
		if (firstInputArray.length > 0) {
			firstInputArray[0].focus();
		}
	}, []);

	useEffect(() => {
		if (!clearingErrors) {
			if (props.onSubmit) {
				props.onSubmit();
			}
		}
	}, [clearingErrors]);

	const onSubmit = async (event: any) => {
		event.preventDefault();
		setClearingErrors(true);
		if (props.clearErrors) {
			await clear();
			props.clearErrors();
			setClearingErrors(false);
		} else {
			setClearingErrors(false);
		}
	};

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();
			}}
			autoComplete={props.disableAutocomplete ? 'off' : null}
		>
			{props.disableAutocomplete && (
				<input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
			)}
			{props.children}
			<div className={styles.alerts}>
				<Container>
					<Row justifyContent="center">
						<Col xs={12} md={10} lg={8} xl={7} xxl={6} xxxl={5}>
							<Row>
								<Col xs={12} md={9}>
									<ErrorMessage outline />
									<SuccessMessage outline />
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
			{props.showSubmitDivider && <Divider />}
			<Row noWrap flexColumnMobile>
				{(props.onPrevButton || props.prevButtonExtension) && (
					<Col noGrow order={4} orderSm={1}>
						{props.onPrevButton && (
							<Button
								buttonStyle={ButtonStyle.LINK}
								onClick={(event: any) => {
									props.onPrevButton(event);
								}}
								fullWidthMobile
								prependIcon={<>{props.prevButtonPrependIcon}</>}
								appendIcon={<>{props.prevButtonAppendIcon}</>}
							>
								{props.prevButtonText
									? props.prevButtonText
									: '< ' + m('prev.label', 'fields')}
							</Button>
						)}
						{props.prevButtonExtension && <>{props.prevButtonExtension}</>}
					</Col>
				)}
				<Col isSpacer order={3} orderSm={2} />
				{(props.onAdditionalButton || props.onAdditionalLink) &&
					props.additionalButtonText && (
						<Col noGrow order={2} orderSm={3}>
							<Button
								buttonStyle={
									props.additionalButtonStyle
										? props.additionalButtonStyle
										: ButtonStyle.SECONDARY
								}
								onClick={props.onAdditionalButton}
								link={props.onAdditionalLink}
								newTab={props.onAdditionaLinkNewTab}
								disabled={props.additionalButtonDisabled}
								prependIcon={<>{props.additionalButtonPrependIcon}</>}
								appendIcon={<>{props.additionalButtonAppendIcon}</>}
							>
								{props.additionalButtonText}
							</Button>
						</Col>
					)}
				{(props.onSubmit || props.onSubmitLink) && (
					<Col noGrow order={1} orderSm={4}>
						<Button
							buttonType={ButtonType.SUBMIT}
							onClick={onSubmit}
							link={props.onSubmitLink}
							newTab={props.onSubmitLinkNewTab}
							disabled={props.submitDisabled}
							tabindex={props.tabindex}
							testId="submit"
							loading={isLoadingForm}
							fullWidthMobile
						>
							{props.submitText ? props.submitText : m('next.label', 'fields')}
						</Button>
					</Col>
				)}
			</Row>
			<div className={styles.submitFooter}>
				<Divider onlyMobile />
				<Row noWrap flexColumnMobile>
					{props.footerContent && <Col noGrow>{props.footerContent}</Col>}
					<Col isSpacer />
					<Col noGrow>
						{(props.onSubmit || props.onSubmitLink) && <SvgSecureConnection />}
					</Col>
				</Row>
			</div>
		</form>
	);
};
export default Form;
