import React, { useMemo, useState } from 'react';
import m from 'utils/messages/messages';
import { continueInvalid, continueValid } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { goToPage } from 'utils/pages/pages';
import { formatFullDate } from 'utils/date/date';
import { postApplications } from 'api';

import Page from 'components/Organisms/Page/Page';
import { Col, Row } from 'components/Atoms/Grid';
import { Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import Divider from 'components/Atoms/Divider/Divider';
import { CheckboxInput, RadioButtonInput } from 'components/Atoms/Form';

// import Autofill from 'components/Dev/Autofill/Autofill';

import Name from 'components/Molecules/Form/Name/Name';
import Birthdate from 'components/Molecules/Form/Birthdate/Birthdate';
import CustomerNumber from 'components/Molecules/Form/CustomerNumber/CustomerNumber';
import Address from 'components/Molecules/Form/Address/Address';
import Contact from 'components/Molecules/Form/Contact/Contact';
import PersonalData from 'components/Molecules/Form/PersonalData/PersonalData';
import Occupation from 'components/Molecules/Form/Occupation/Occupation';
import FinancialStatus from 'components/Molecules/Form/FinancialStatus/FinancialStatus';
import PPI from 'components/Molecules/Form/PPI/PPI';
// import AcceptAdvertisingLabelWithPopup from 'components/Atoms/Partials/AcceptAdvertisingLabelWithPopup/AcceptAdvertisingLabelWithPopup';
import AcceptSchufaLabelWithPopup from 'components/Atoms/Partials/AcceptSchufaLabelWithPopup/AcceptSchufaLabelWithPopup';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import * as applicantStore from 'store/Applicant/Applicant';
import * as nameStore from 'store/Name/Name';
import * as birthdateStore from 'store/Birthdate/Birthdate';
import * as customerStore from 'store/Customer/Customer';
import * as addressStore from 'store/Address/Address';
import * as emailStore from 'store/Email/Email';
import * as phoneStore from 'store/Phone/Phone';
import * as nationalityStore from 'store/Nationality/Nationality';
import * as maritalStatusStore from 'store/MaritalStatus/MaritalStatus';
import * as occupationStore from 'store/Occupation/Occupation';
import * as financialStatusStore from 'store/FinancialStatus/FinancialStatus';
import * as optInStore from 'store/OptIn/OptIn';

import Salutation from 'types/enums/Salutation.d';
import MaritalStatus from 'types/enums/MaritalStatus.d';
import OccupationType from 'types/enums/Occupation.d';
import TypeOfLiving from 'types/enums/TypeOfLiving.d';

import Subpage from 'types/enums/Subpage.d';
import BcoResultCode from 'types/enums/BcoResultCode.d';

const CustomerData = () => {
	const {
		isEmpty,
		hasEmptyFieldsError,
		hasInvalidFieldsError,
		focusFirstErrorInput,
		isBirthdateValid,
		hasEmailError,
		isMobileNumberValid,
		hasDateMonthError,
		hasDateYearError,
		hasOccupationSinceError,
		hasOccupationUntilError,
		hasIbanError
	} = useValidationUtils();

	const dispatch = useDispatch();

	const storeIsEdit = useSelector((state: RootState) => state.Applications.isEdit);
	const applicationId = useSelector((state: RootState) => state.Bco.applicationId);

	const hasSecondApplicant = useSelector(
		(state: RootState) => state.Applicant.hasSecondApplicant
	);
	const setHasSecondApplicant = (val: boolean) => {
		dispatch(applicantStore.setHasSecondApplicant(val));
	};
	const sameHousehold = useSelector((state: RootState) => state.Applicant.sameHousehold);
	const setSameHousehold = (val: boolean) => {
		dispatch(applicantStore.setSameHousehold(val));
	};

	const salutation = useSelector((state: RootState) => state.Name.salutation);
	const setSalutation = (val: Salutation) => {
		dispatch(nameStore.setSalutation(val));
	};
	const firstName = useSelector((state: RootState) => state.Name.firstName);
	const setFirstName = (val: string) => {
		dispatch(nameStore.setFirstName(val));
	};
	const lastName = useSelector((state: RootState) => state.Name.lastName);
	const setLastName = (val: string) => {
		dispatch(nameStore.setLastName(val));
	};

	const birthdate = useSelector((state: RootState) => state.Birthdate.birthdate);
	const setBirthdate = (val: string) => {
		const formattedBirthdate = formatFullDate(val);
		dispatch(birthdateStore.setBirthdate(formattedBirthdate));
	};

	const customerNumber = useSelector((state: RootState) => state.Customer.customerNumber);
	const setCustomerNumber = (val: string) => {
		dispatch(customerStore.setCustomerNumber(val));
	};

	const postalCode = useSelector((state: RootState) => state.Address.postalCode);
	const setPostalCode = (val: string) => {
		dispatch(addressStore.setPostalCode(val));
	};
	const city = useSelector((state: RootState) => state.Address.city);
	const setCity = (val: string) => {
		dispatch(addressStore.setCity(val));
	};
	const street = useSelector((state: RootState) => state.Address.street);
	const setStreet = (val: string) => {
		dispatch(addressStore.setStreet(val));
	};
	const streetNumber = useSelector((state: RootState) => state.Address.streetNumber);
	const setStreetNumber = (val: string) => {
		dispatch(addressStore.setStreetNumber(val));
	};
	const hasPreviousAddress = useSelector((state: RootState) => state.Address.hasPreviousAddress);
	const setHasPreviousAddress = (val: boolean) => {
		dispatch(addressStore.setHasPreviousAddress(val));
	};
	const previousPostalCode = useSelector((state: RootState) => state.Address.previousPostalCode);
	const setPreviousPostalCode = (val: string) => {
		dispatch(addressStore.setPreviousPostalCode(val));
	};
	const previousCity = useSelector((state: RootState) => state.Address.previousCity);
	const setPreviousCity = (val: string) => {
		dispatch(addressStore.setPreviousCity(val));
	};
	const previousStreet = useSelector((state: RootState) => state.Address.previousStreet);
	const setPreviousStreet = (val: string) => {
		dispatch(addressStore.setPreviousStreet(val));
	};
	const previousStreetNumber = useSelector(
		(state: RootState) => state.Address.previousStreetNumber
	);
	const setPreviousStreetNumber = (val: string) => {
		dispatch(addressStore.setPreviousStreetNumber(val));
	};

	const email = useSelector((state: RootState) => state.Email.email);
	const setEmail = (val: string) => {
		dispatch(emailStore.setEmail(val));
	};

	const countryCode = useSelector((state: RootState) =>
		state.Phone.countryCode ? state.Phone.countryCode + '' : null
	);
	const setCountryCode = (val: string) => {
		dispatch(phoneStore.setCountryCode(parseInt(val)));
	};
	const mobileNumber = useSelector((state: RootState) =>
		state.Phone.mobileNumber ? state.Phone.mobileNumber + '' : null
	);
	const setMobileNumber = (val: string) => {
		dispatch(phoneStore.setMobileNumber(parseInt(val)));
	};

	const nationality = useSelector((state: RootState) => state.Nationality.nationality);
	const setNationality = (val: string) => {
		dispatch(nationalityStore.setNationality(val));
	};
	const maritalStatus = useSelector((state: RootState) => state.MaritalStatus.maritalStatus);
	const setMaritalStatus = (val: MaritalStatus) => {
		dispatch(maritalStatusStore.setMaritalStatus(val));
	};
	const numberAdults = useSelector((state: RootState) => state.MaritalStatus.numberAdults);
	const setNumberAdults = (val: string) => {
		dispatch(maritalStatusStore.setNumberAdults(parseInt(val)));
	};
	const numberChildren = useSelector((state: RootState) => state.MaritalStatus.numberChildren);
	const setNumberChildren = (val: string) => {
		dispatch(maritalStatusStore.setNumberChildren(parseInt(val)));
	};
	const numberChildrenWithBenefit = useSelector(
		(state: RootState) => state.MaritalStatus.numberChildrenWithBenefit
	);
	const setNumberChildrenWithBenefit = (val: string) => {
		dispatch(maritalStatusStore.setNumberChildrenWithBenefit(parseInt(val)));
	};

	const occupation = useSelector((state: RootState) => state.Occupation.occupation);
	const setOccupation = (val: OccupationType) => {
		dispatch(occupationStore.setOccupation(val));
	};
	const employer = useSelector((state: RootState) => state.Occupation.employer);
	const setEmployer = (val: string) => {
		dispatch(occupationStore.setEmployer(val));
	};
	const occupationMonth = useSelector((state: RootState) => state.Occupation.occupationMonth);
	const setOccupationMonth = (val: string) => {
		dispatch(occupationStore.setOccupationMonth(val));
	};
	const occupationYear = useSelector((state: RootState) => state.Occupation.occupationYear);
	const setOccupationYear = (val: string) => {
		dispatch(occupationStore.setOccupationYear(val));
	};
	const occupationLimited = useSelector((state: RootState) =>
		state.Occupation.occupationLimited !== null
			? state.Occupation.occupationLimited
				? 'Befristet'
				: 'Unbefristet'
			: null
	);
	const setOccupationLimited = (val: string) => {
		dispatch(occupationStore.setOccupationLimited(val === 'Befristet'));
	};
	const occupationLimitedMonth = useSelector(
		(state: RootState) => state.Occupation.occupationLimitedMonth
	);
	const setOccupationLimitedMonth = (val: string) => {
		dispatch(occupationStore.setOccupationLimitedMonth(val));
	};
	const occupationLimitedYear = useSelector(
		(state: RootState) => state.Occupation.occupationLimitedYear
	);
	const setOccupationLimitedYear = (val: string) => {
		dispatch(occupationStore.setOccupationLimitedYear(val));
	};

	const iban = useSelector((state: RootState) => state.FinancialStatus.iban);
	const setIban = (val: string) => {
		dispatch(financialStatusStore.setIban(val));
	};
	const income = useSelector((state: RootState) => state.FinancialStatus.income);
	const setIncome = (val: string) => {
		dispatch(financialStatusStore.setIncome(val));
	};
	const additionalIncome = useSelector(
		(state: RootState) => state.FinancialStatus.additionalIncome
	);
	const setAdditionalIncome = (val: string) => {
		dispatch(financialStatusStore.setAdditionalIncome(val));
	};
	const livingCosts = useSelector((state: RootState) => state.FinancialStatus.livingCosts);
	const setLivingCosts = (val: string) => {
		dispatch(financialStatusStore.setLivingCosts(val));
	};
	const childSupport = useSelector((state: RootState) => state.FinancialStatus.childSupport);
	const setChildSupport = (val: string) => {
		dispatch(financialStatusStore.setChildSupport(val));
	};
	const typeOfLiving = useSelector((state: RootState) => state.FinancialStatus.typeOfLiving);
	const setTypeOfLiving = (val: TypeOfLiving) => {
		dispatch(financialStatusStore.setTypeOfLiving(val));
	};

	const acceptTermsAndConditions = useSelector(
		(state: RootState) => state.OptIn.acceptTermsAndConditions
	);
	const setAcceptTermsAndConditions = (val: boolean) => {
		dispatch(optInStore.setAcceptTermsAndConditions(val));
	};
	const acceptOwnResponsibility = useSelector(
		(state: RootState) => state.OptIn.acceptOwnResponsibility
	);
	const setAcceptOwnResponsibility = (val: boolean) => {
		dispatch(optInStore.setAcceptOwnResponsibility(val));
	};
	const acceptSchufa = useSelector((state: RootState) => state.OptIn.acceptSchufa);
	const setAcceptSchufa = (val: boolean) => {
		dispatch(optInStore.setAcceptSchufa(val));
	};
	/*const acceptAdvertising = useSelector((state: RootState) => state.OptIn.acceptAdvertising);
	const setAcceptAdvertising = (val: boolean) => {
		dispatch(optInStore.setAcceptAdvertising(val));
	};*/

	// const showPpi = useSelector((state: RootState) => state.PPI.showPpi);

	// Errors
	const [salutationError, setSalutationError] = useState<string>(null);
	const [firstNameError, setFirstNameError] = useState<string>(null);
	const [lastNameError, setLastNameError] = useState<string>(null);
	const [birthdateError, setBirthdateError] = useState<string>(null);
	const [postalCodeError, setPostalCodeError] = useState<string>(null);
	const [cityError, setCityError] = useState<string>(null);
	const [streetError, setStreetError] = useState<string>(null);
	const [streetNumberError, setStreetNumberError] = useState<string>(null);
	const [previousPostalCodeError, setPreviousPostalCodeError] = useState<string>(null);
	const [previousCityError, setPreviousCityError] = useState<string>(null);
	const [previousStreetError, setPreviousStreetError] = useState<string>(null);
	const [previousStreetNumberError, setPreviousStreetNumberError] = useState<string>(null);
	const [emailError, setEmailError] = useState<string>(null);
	const [countryCodeError, setCountryCodeError] = useState<string>(null);
	const [mobileNumberError, setMobileNumberError] = useState<string>(null);
	const [nationalityError, setNationalityError] = useState<string>(null);
	const [maritalStatusError, setMaritalStatusError] = useState<string>(null);
	const [occupationError, setOccupationError] = useState<string>(null);
	const [occupationMonthError, setOccupationMonthError] = useState<string>(null);
	const [occupationYearError, setOccupationYearError] = useState<string>(null);
	const [employerError, setEmployerError] = useState<string>(null);
	const [occupationLimitedMonthError, setOccupationLimitedMonthError] = useState<string>(null);
	const [occupationLimitedYearError, setOccupationLimitedYearError] = useState<string>(null);
	const [ibanError, setIbanError] = useState<string>(null);
	const [incomeError, setIncomeError] = useState<string>(null);
	const [livingCostsError, setLivingCostsError] = useState<string>(null);
	const [typeOfLivingError, setTypeOfLivingError] = useState<string>(null);

	const [acceptTermsAndConditionsError, setAcceptTermsAndConditionsError] =
		useState<string>(null);
	const [acceptOwnResponsibilityError, setAcceptOwnResponsibilityError] = useState<string>(null);
	const [acceptSchufaError, setAcceptSchufaError] = useState<string>(null);

	const showEmployer = useMemo(() => {
		return (
			occupation === OccupationType.EMPLOYED ||
			occupation === OccupationType.OFFICER ||
			occupation === OccupationType.CIVIL_SERVICE_EMPLOYEE
		);
	}, []);

	const clearErrors = () => {
		setSalutationError(null);
		setFirstNameError(null);
		setLastNameError(null);
		setBirthdateError(null);
		setPostalCodeError(null);
		setCityError(null);
		setStreetError(null);
		setStreetNumberError(null);
		setPreviousPostalCodeError(null);
		setPreviousCityError(null);
		setPreviousStreetError(null);
		setPreviousStreetNumberError(null);
		setEmailError(null);
		setCountryCodeError(null);
		setMobileNumberError(null);
		setNationalityError(null);
		setMaritalStatusError(null);
		setOccupationError(null);
		setOccupationMonthError(null);
		setOccupationYearError(null);
		setEmployerError(null);
		setOccupationLimitedMonthError(null);
		setOccupationLimitedYearError(null);
		setIbanError(null);
		setIncomeError(null);
		setLivingCostsError(null);
		setTypeOfLivingError(null);
		setAcceptTermsAndConditionsError(null);
		setAcceptOwnResponsibilityError(null);
		setAcceptSchufaError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let hasInvalidFields = false;
		let isValidBirthdate = true;
		let isValidEmail = true;
		let isValidMobileNumber = true;
		let isOccupationMonthValid = true;
		let isOccupationYearValid = true;
		let isOccupationLimitedMonthValid = true;
		let isOccupationLimitedYearValid = true;
		let isOccupationSinceValid = true;
		let isOccupationUntilValid = true;
		let isIbanValid = true;

		if (isEmpty(salutation)) {
			setSalutationError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(firstName)) {
			setFirstNameError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(lastName)) {
			setLastNameError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}

		if (isEmpty(birthdate)) {
			setBirthdateError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else {
			const formattedBirthdate = formatFullDate(birthdate);
			isValidBirthdate = isBirthdateValid(formattedBirthdate, setBirthdateError);
		}

		if (isEmpty(postalCode)) {
			setPostalCodeError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else if (postalCode.length !== 5) {
			setPostalCodeError(m('postalCode.validation.length', 'fields') as string);
			hasInvalidFields = true;
		}
		if (isEmpty(city)) {
			setCityError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else if (city.length < 2) {
			setCityError(m('city.validation.length', 'fields') as string);
			hasInvalidFields = true;
		}
		if (isEmpty(street)) {
			setStreetError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else if (street.length < 3) {
			setStreetError(m('street.validation.length', 'fields') as string);
			hasInvalidFields = true;
		}
		if (isEmpty(streetNumber)) {
			setStreetNumberError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (hasPreviousAddress) {
			if (isEmpty(previousPostalCode)) {
				setPreviousPostalCodeError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			} else if (previousPostalCode.length !== 5) {
				setPreviousPostalCodeError(m('postalCode.validation.length', 'fields') as string);
				hasInvalidFields = true;
			}
			if (isEmpty(previousCity)) {
				setPreviousCityError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			} else if (previousCity.length < 2) {
				setPreviousCityError(m('city.validation.length', 'fields') as string);
				hasInvalidFields = true;
			}
			if (isEmpty(previousStreet)) {
				setPreviousStreetError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			} else if (previousStreet.length < 3) {
				setPreviousStreetError(m('street.validation.length', 'fields') as string);
				hasInvalidFields = true;
			}
			if (isEmpty(previousStreetNumber)) {
				setPreviousStreetNumberError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			}
		}

		if (isEmpty(email)) {
			setEmailError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else {
			isValidEmail = !hasEmailError(email);
			setEmailError(
				!isValidEmail ? (m('validation.error.invalid', 'fields') as string) : null
			);
		}

		if (isEmpty(countryCode)) {
			setCountryCodeError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(mobileNumber)) {
			setMobileNumberError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (!isEmpty(countryCode) && !isEmpty(mobileNumber)) {
			isValidMobileNumber = isMobileNumberValid(
				countryCode,
				mobileNumber,
				setMobileNumberError
			);
		}

		if (isEmpty(nationality)) {
			setNationalityError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(maritalStatus)) {
			setMaritalStatusError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}

		if (isEmpty(occupation)) {
			setOccupationError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(occupationMonth)) {
			setOccupationMonthError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else {
			const hasMonthErr = hasDateMonthError(occupationMonth);
			if (hasMonthErr) {
				setOccupationMonthError(m('validation.error.invalid', 'fields') as string);
				isOccupationMonthValid = false;
			}
		}
		if (isEmpty(occupationYear)) {
			setOccupationYearError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else {
			const hasYearErr = hasDateYearError(occupationYear);
			if (hasYearErr) {
				setOccupationYearError(m('validation.error.invalid', 'fields') as string);
				isOccupationYearValid = false;
			}
		}
		if (showEmployer) {
			if (isEmpty(employer)) {
				setEmployer(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			}
		}
		if (occupationLimited === 'Befristet') {
			if (isEmpty(occupationLimitedMonth)) {
				setOccupationLimitedMonthError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			} else {
				const hasMonthErr = hasDateMonthError(occupationLimitedMonth);
				if (hasMonthErr) {
					setOccupationLimitedMonthError(
						m('validation.error.invalid', 'fields') as string
					);
					isOccupationLimitedMonthValid = false;
				}
			}
			if (isEmpty(occupationLimitedYear)) {
				setOccupationLimitedYearError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			} else {
				const hasYearErr = hasDateYearError(occupationLimitedYear);
				if (hasYearErr) {
					setOccupationLimitedYearError(
						m('validation.error.invalid', 'fields') as string
					);
					isOccupationLimitedYearValid = false;
				}
			}
		}
		if (isOccupationMonthValid && isOccupationYearValid && birthdate && isValidBirthdate) {
			const birthdateArray = birthdate.split('.');
			isOccupationSinceValid = !hasOccupationSinceError(
				occupationMonth,
				occupationYear,
				birthdateArray[0],
				birthdateArray[1],
				birthdateArray[2]
			);
			if (!isOccupationSinceValid) {
				setOccupationMonthError(m('validation.error.invalid', 'fields') as string);
				setOccupationYearError(m('validation.error.invalid', 'fields') as string);
			}
		}
		if (
			occupationLimited === 'Befristet' &&
			isOccupationLimitedMonthValid &&
			isOccupationLimitedYearValid
		) {
			isOccupationUntilValid = !hasOccupationUntilError(
				occupationMonth,
				occupationYear,
				occupationLimitedMonth,
				occupationLimitedYear
			);
			if (!isOccupationUntilValid) {
				setOccupationLimitedMonthError(m('validation.error.invalid', 'fields') as string);
				setOccupationLimitedYearError(m('validation.error.invalid', 'fields') as string);
			}
		}
		if (isEmpty(iban)) {
			setIbanError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else {
			isIbanValid = !hasIbanError(iban);
			if (!isIbanValid) {
				setIbanError(m('validation.error.invalid', 'fields') as string);
			}
		}

		if (isEmpty(income)) {
			setIncomeError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(livingCosts)) {
			setLivingCostsError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(typeOfLiving)) {
			setTypeOfLivingError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}

		if (!acceptTermsAndConditions) {
			setAcceptTermsAndConditionsError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (!acceptOwnResponsibility) {
			setAcceptOwnResponsibilityError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (!acceptSchufa) {
			setAcceptSchufaError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}

		await hasEmptyFieldsError(hasEmptyFields);
		await hasInvalidFieldsError(hasInvalidFields);

		const isValid =
			!hasEmptyFields &&
			!hasInvalidFields &&
			isValidBirthdate &&
			isValidEmail &&
			isValidMobileNumber &&
			isOccupationMonthValid &&
			isOccupationYearValid &&
			isOccupationLimitedMonthValid &&
			isOccupationLimitedYearValid &&
			isOccupationSinceValid &&
			isOccupationUntilValid &&
			isIbanValid;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = async () => {
		continueValid();

		if (hasSecondApplicant) {
			goToPage(Subpage.CUSTOMER_DATA_2DN);
		} else {
			await postApplications(
				(resultCode: BcoResultCode, skipContract: boolean) => {
					if (skipContract) {
						goToPage(Subpage.SKIP_CONTRACT);
					} else if (resultCode === BcoResultCode.BCO_APPROVED) {
						goToPage(Subpage.APPLICATION_RECEIVED);
					} else {
						goToPage(Subpage.APPLICATION_REJECTED);
					}
				},
				() => {
					goToPage(Subpage.APPLICATION_ERROR);
				}
			);
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	// Only for dev
	/*const autofill = () => {
		setSalutation(Salutation.MALE);
		setFirstName('Max');
		setLastName('Random');
		setDay('31');
		setMonth('12');
		setYear('1990');
		setCustomerNumber('123456789');
		setPostalCode('41069');
		setCity('Mönchengladbach');
		setStreet('Madrider Straße');
		setStreetNumber('1');
		setEmail('test@santander.de');
		setCountryCode('49');
		setMobileNumber('163123456');
		setNationality('DE');
		setMaritalStatus(MaritalStatus.SINGLE);
		setNumberAdults(1);
		setNumberChildren(0);
		setNumberChildrenWithBenefit(0);
		setOccupation(OccupationType.EMPLOYED);
		setEmployer('Santander');
		setOccupationMonth('01');
		setOccupationYear('2010');
		setOccupationLimited(false);
		setIban('DE02 1203 0000 0000 2020 51');
		setIncome('5000');
		setLivingCosts('500');
		setTypeOfLiving(TypeOfLiving.MIETWOHNUNG);
		setAcceptTermsAndConditions(true);
		setAcceptOwnResponsibility(true);
		setAcceptSchufa(true);
	};*/

	return (
		<Page>
			<Title hasMarginBottom>
				{m('page.customerData.title', 'global')}
				{/*<Autofill autofill={autofill} />*/}
			</Title>
			<Title isSubtitle hasMarginBottom>
				{m('page.customerData.secondApplicant.title', 'global')}
			</Title>
			<RadioButtonInput
				label={m('page.customerData.secondApplicant.subtitle', 'global')}
				value={hasSecondApplicant ? 'true' : 'false'}
				setValue={(value: string) => {
					setHasSecondApplicant(value === 'true');
				}}
				items={[
					{
						value: 'false',
						text: m('page.customerData.secondApplicant.options.oneApplicant', 'global')
					},
					{
						value: 'true',
						text: m('page.customerData.secondApplicant.options.twoApplicants', 'global')
					}
				]}
				btnStyle="switch"
				tabindex={0}
			/>
			{hasSecondApplicant && (
				<>
					<RadioButtonInput
						label={m('page.customerData.secondApplicant.sameHousehold.title', 'global')}
						value={sameHousehold ? 'true' : 'false'}
						setValue={(value: string) => {
							setSameHousehold(value === 'true');
						}}
						items={[
							{
								value: 'true',
								text: m(
									'page.customerData.secondApplicant.sameHousehold.options.yes',
									'global'
								)
							},
							{
								value: 'false',
								text: m(
									'page.customerData.secondApplicant.sameHousehold.options.no',
									'global'
								)
							}
						]}
						btnStyle="switch"
						tabindex={0}
					/>
					<Title isSubtitle>{m('page.customerData.customer.one', 'global')}</Title>
					<Divider noMarginTop />
				</>
			)}
			<Form
				showSubmitDivider
				onSubmit={onSubmit}
				submitDisabled={!!applicationId && !storeIsEdit}
				onPrevButton={() => {
					goToPage(Subpage.CREDIT_DETAILS);
				}}
				clearErrors={clearErrors}
				tabindex={60}
				disableAutocomplete
			>
				<Name
					salutation={salutation}
					setSalutation={setSalutation}
					salutationError={salutationError}
					firstName={firstName}
					setFirstName={setFirstName}
					firstNameError={firstNameError}
					lastName={lastName}
					setLastName={setLastName}
					lastNameError={lastNameError}
				/>
				<Birthdate
					birthdate={birthdate}
					setBirthdate={setBirthdate}
					birthdateError={birthdateError}
				/>
				<CustomerNumber
					customerNumber={customerNumber}
					setCustomerNumber={setCustomerNumber}
				/>
				<Address
					postalCode={postalCode}
					setPostalCode={setPostalCode}
					postalCodeError={postalCodeError}
					city={city}
					setCity={setCity}
					cityError={cityError}
					street={street}
					setStreet={setStreet}
					streetError={streetError}
					streetNumber={streetNumber}
					setStreetNumber={setStreetNumber}
					streetNumberError={streetNumberError}
					hasPreviousAddress={hasPreviousAddress}
					setHasPreviousAddress={setHasPreviousAddress}
					previousPostalCode={previousPostalCode}
					setPreviousPostalCode={setPreviousPostalCode}
					previousPostalCodeError={previousPostalCodeError}
					previousCity={previousCity}
					setPreviousCity={setPreviousCity}
					previousCityError={previousCityError}
					previousStreet={previousStreet}
					setPreviousStreet={setPreviousStreet}
					previousStreetError={previousStreetError}
					previousStreetNumber={previousStreetNumber}
					setPreviousStreetNumber={setPreviousStreetNumber}
					previousStreetNumberError={previousStreetNumberError}
				/>
				<Contact
					email={email}
					setEmail={setEmail}
					emailError={emailError}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
					countryCodeError={countryCodeError}
					mobileNumber={mobileNumber}
					setMobileNumber={setMobileNumber}
					mobileNumberError={mobileNumberError}
				/>
				<PersonalData
					nationality={nationality}
					setNationality={setNationality}
					nationalityError={nationalityError}
					maritalStatus={maritalStatus}
					setMaritalStatus={setMaritalStatus}
					maritalStatusError={maritalStatusError}
					numberAdults={numberAdults}
					setNumberAdults={setNumberAdults}
					numberChildren={numberChildren}
					setNumberChildren={setNumberChildren}
					numberChildrenWithBenefit={numberChildrenWithBenefit}
					setNumberChildrenWithBenefit={setNumberChildrenWithBenefit}
				/>
				<Occupation
					occupation={occupation}
					setOccupation={setOccupation}
					occupationError={occupationError}
					employer={employer}
					setEmployer={setEmployer}
					employerError={employerError}
					occupationMonth={occupationMonth}
					setOccupationMonth={setOccupationMonth}
					occupationMonthError={occupationMonthError}
					occupationYear={occupationYear}
					setOccupationYear={setOccupationYear}
					occupationYearError={occupationYearError}
					occupationLimited={occupationLimited}
					setOccupationLimited={setOccupationLimited}
					occupationLimitedMonth={occupationLimitedMonth}
					setOccupationLimitedMonth={setOccupationLimitedMonth}
					occupationLimitedMonthError={occupationLimitedMonthError}
					occupationLimitedYear={occupationLimitedYear}
					setOccupationLimitedYear={setOccupationLimitedYear}
					occupationLimitedYearError={occupationLimitedYearError}
				/>
				<FinancialStatus
					iban={iban}
					setIban={setIban}
					ibanError={ibanError}
					income={income}
					setIncome={setIncome}
					incomeError={incomeError}
					additionalIncome={additionalIncome}
					setAdditionalIncome={setAdditionalIncome}
					livingCosts={livingCosts}
					setLivingCosts={setLivingCosts}
					livingCostsError={livingCostsError}
					childSupport={childSupport}
					setChildSupport={setChildSupport}
					typeOfLiving={typeOfLiving}
					setTypeOfLiving={setTypeOfLiving}
					typeOfLivingError={typeOfLivingError}
				/>
				<PPI />
				<Row>
					<Col xs={12}>
						<CheckboxInput
							label={m('acceptTermsAndConditions.label', 'fields', null, true)}
							value={acceptTermsAndConditions}
							setValue={setAcceptTermsAndConditions}
							hasError={!!acceptTermsAndConditionsError}
							message={acceptTermsAndConditionsError}
							tabindex={50}
							testId="accept-privacy-policies"
						/>
					</Col>
					<Col xs={12}>
						<CheckboxInput
							label={m('acceptOwnResponsibility.label', 'fields')}
							value={acceptOwnResponsibility}
							setValue={setAcceptOwnResponsibility}
							hasError={!!acceptOwnResponsibilityError}
							message={acceptOwnResponsibilityError}
							tabindex={51}
							testId="accept-own-responsibility"
						/>
					</Col>
					<Col xs={12}>
						<CheckboxInput
							label={<AcceptSchufaLabelWithPopup />}
							value={acceptSchufa}
							setValue={setAcceptSchufa}
							hasError={!!acceptSchufaError}
							message={acceptSchufaError}
							tabindex={52}
							testId="accept-schufa"
						/>
					</Col>
					{/*<Col xs={12}>
						<CheckboxInput
							label={<AcceptAdvertisingLabelWithPopup />}
							value={acceptAdvertising}
							setValue={setAcceptAdvertising}
							tabindex={53}
							testId="accept-advertising"
						/>
					</Col>*/}
				</Row>
			</Form>
		</Page>
	);
};
export default CustomerData;
