import axios from 'axios';
import { getApiBaseURL } from 'utils/api/api';
import { setIsLoadingPage } from 'store/Loading/Loading';
import { formatDateForApi, formatFullDateForApi } from 'utils/date/date';
import { getExternalCreditsWithTrimmedIbans, trimIban } from 'utils/iban/iban';

import store from 'store/store';
import { setApplicationId, setBco } from 'store/Bco/Bco';
import { setIsEdit } from 'store/Applications/Applications';
import { isEmpty } from 'utils/validation/validation';

const url = getApiBaseURL() + '/api/applications';

export const postApplications = async (
	successCb: Function = () => {},
	errorCb: Function = () => {}
) => {
	store.dispatch(setIsLoadingPage(true));
	const state = store.getState();
	const isEdit = !!(state.Applications.isEdit && state.Bco.applicationId);

	let commission = undefined;
	if (state.Company.conditionPrograms.length > 0) {
		const program = state.Company.conditionPrograms.find(
			(item) => item.program === state.Company.conditionProgram
		);
		if (program) {
			commission = program.commission;
		}
	}
	const data: any = {
		id: isEdit ? state.Bco.applicationId : undefined,
		amount: state.Credit.amount,
		runtime: state.Credit.runtime,
		conditionsProgram: state.Company.conditionProgram,
		commission: commission,
		applicant: {
			externalCredits: getExternalCreditsWithTrimmedIbans(),
			salutation: state.Name.salutation,
			firstName: state.Name.firstName,
			lastName: state.Name.lastName,
			dateOfBirth: !isEmpty(state.Birthdate.birthdate)
				? formatFullDateForApi(state.Birthdate.birthdate)
				: undefined,
			customerNumber: state.Customer.customerNumber ?? undefined,
			address: {
				street: state.Address.street,
				streetNumber: state.Address.streetNumber,
				postalCode: state.Address.postalCode,
				city: state.Address.city
			},
			previousAddress: state.Address.hasPreviousAddress
				? {
						street: state.Address.previousStreet,
						streetNumber: state.Address.previousStreetNumber,
						postalCode: state.Address.previousPostalCode,
						city: state.Address.previousCity
				  }
				: undefined,
			email: state.Email.email,
			countryCode: state.Phone.countryCode,
			mobileNumber: state.Phone.mobileNumber,
			nationality: state.Nationality.nationality,
			maritalStatus: state.MaritalStatus.maritalStatus,
			numberAdults: state.MaritalStatus.numberAdults,
			numberChildren: state.MaritalStatus.numberChildren,
			numberChildrenWithBenefit: state.MaritalStatus.numberChildrenWithBenefit,
			occupation: state.Occupation.occupation,
			employer: state.Occupation.employer,
			occupiedSince: formatDateForApi(
				state.Occupation.occupationYear,
				(state.Occupation.occupationMonth.length === 1 ? '0' : '') +
					state.Occupation.occupationMonth
			),
			occupiedUntil: state.Occupation.occupationLimited
				? formatDateForApi(
						state.Occupation.occupationLimitedYear,
						(state.Occupation.occupationLimitedMonth.length === 1 ? '0' : '') +
							state.Occupation.occupationLimitedMonth
				  )
				: undefined,
			iban: trimIban(state.FinancialStatus.iban),
			income: !isNaN(state.FinancialStatus.income) ? state.FinancialStatus.income : 0,
			additionalIncome: !isNaN(state.FinancialStatus.additionalIncome)
				? state.FinancialStatus.additionalIncome
				: undefined,
			livingCosts: !isNaN(state.FinancialStatus.livingCosts)
				? state.FinancialStatus.livingCosts
				: 0,
			childSupport: !isNaN(state.FinancialStatus.childSupport)
				? state.FinancialStatus.childSupport
				: undefined,
			typeOfLiving: state.FinancialStatus.typeOfLiving,
			acceptTermsAndConditions: state.OptIn.acceptTermsAndConditions,
			ownResponsibility: state.OptIn.acceptOwnResponsibility,
			acceptSchufa: state.OptIn.acceptSchufa,
			allowAdvertising: state.OptIn.acceptAdvertising,
			ppi: !!state.PPI.hasPpi ?? undefined,
			protect:
				state.PPI.protect && state.PPI.protect.length > 0 ? state.PPI.protect : undefined,
			protectRuntime:
				state.PPI.protect && state.PPI.protect.length > 0 && state.PPI.protectRuntime
					? state.PPI.protectRuntime
					: undefined,
			protectMonthlyRate:
				state.PPI.protect && state.PPI.protect.length > 0 && state.PPI.protectMonthlyRate
					? state.PPI.protectMonthlyRate
					: undefined
		}
	};

	if (state.Applicant.hasSecondApplicant) {
		const sameHousehold = state.Applicant.sameHousehold ?? true;
		data.secondApplicant = {
			sameHousehold: sameHousehold,
			salutation: state.Name.salutation2,
			firstName: state.Name.firstName2,
			lastName: state.Name.lastName2,
			dateOfBirth: !isEmpty(state.Birthdate.birthdate2)
				? formatFullDateForApi(state.Birthdate.birthdate2)
				: undefined,
			address: !sameHousehold
				? {
						street: state.Address.street2,
						streetNumber: state.Address.streetNumber2,
						postalCode: state.Address.postalCode2,
						city: state.Address.city2
				  }
				: undefined,
			previousAddress:
				!sameHousehold && state.Address.hasPreviousAddress2
					? {
							street: state.Address.previousStreet2,
							streetNumber: state.Address.previousStreetNumber2,
							postalCode: state.Address.previousPostalCode2,
							city: state.Address.previousCity2
					  }
					: undefined,
			email: state.Email.email,
			countryCode: state.Phone.countryCode2,
			mobileNumber: state.Phone.mobileNumber2,
			nationality: state.Nationality.nationality2,
			maritalStatus: state.MaritalStatus.maritalStatus2,
			numberAdults: !sameHousehold ? state.MaritalStatus.numberAdults2 : undefined,
			numberChildren: !sameHousehold ? state.MaritalStatus.numberChildren2 : undefined,
			numberChildrenWithBenefit: !sameHousehold
				? state.MaritalStatus.numberChildrenWithBenefit2
				: undefined,
			occupation: state.Occupation.occupation2,
			employer: state.Occupation.employer2,
			occupiedSince: formatDateForApi(
				state.Occupation.occupationYear2,
				(state.Occupation.occupationMonth2.length === 1 ? '0' : '') +
					state.Occupation.occupationMonth2
			),
			occupiedUntil: state.Occupation.occupationLimited2
				? formatDateForApi(
						state.Occupation.occupationLimitedYear2,
						(state.Occupation.occupationLimitedMonth2.length === 1 ? '0' : '') +
							state.Occupation.occupationLimitedMonth2
				  )
				: undefined,
			income: state.FinancialStatus.income2,
			additionalIncome: state.FinancialStatus.additionalIncome2
				? state.FinancialStatus.additionalIncome2
				: undefined,
			livingCosts: !sameHousehold ? state.FinancialStatus.livingCosts2 : undefined,
			childSupport:
				!sameHousehold && state.FinancialStatus.childSupport2
					? state.FinancialStatus.childSupport2
					: undefined,
			typeOfLiving: !sameHousehold ? state.FinancialStatus.typeOfLiving2 : undefined,
			acceptTermsAndConditions: state.OptIn.acceptTermsAndConditions2,
			ownResponsibility: state.OptIn.acceptOwnResponsibility,
			acceptSchufa: state.OptIn.acceptSchufa2,
			allowAdvertising: state.OptIn.acceptAdvertising
		};
	}

	if (isEdit) {
		await axios
			.patch(url, data)
			.then((res) => {
				onSuccess(res.data, successCb);
			})
			.catch((err: any) => {
				onError(err, errorCb);
			});
	} else {
		await axios
			.post(url, data)
			.then((res) => {
				onSuccess(res.data, successCb);
			})
			.catch((err: any) => {
				onError(err, errorCb);
			});
	}
};

const onSuccess = (data: any, successCb: Function = () => {}) => {
	store.dispatch(setApplicationId(data.id));
	store.dispatch(setBco(data.bcoResult));
	store.dispatch(setIsEdit(false));
	store.dispatch(setIsLoadingPage(false));
	successCb(data.resultCode, !!data.bcoResult?.skipContract);
};

const onError = (err: any, errorCb: Function = () => {}) => {
	store.dispatch(setIsLoadingPage(false));
	errorCb();
};
