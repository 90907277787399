import React from 'react';
import m from 'utils/messages/messages';
/*import { mapCurrentRuntimeToYears } from 'utils/credit/credit';
import { getAge, getAgeWithRuntime } from 'utils/date/date';*/

import { Text } from 'components/Atoms/Text';
import BoxCollapsible from 'components/Molecules/Box/BoxCollapsible/BoxCollapsible';
import SvgUmbrella from 'components/Atoms/SVG/SvgUmbrella';
import SvgScale from 'components/Atoms/SVG/SvgScale';
import Overview from 'components/Molecules/Form/PPI/Overview/Overview';
import PPIBody from 'components/Molecules/Form/PPI/PPI/PPI';
import ProtectBody from 'components/Molecules/Form/PPI/Protect/Protect';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

const PPI = () => {
	//const dispatch = useDispatch();

	const companyShowPpi = useSelector((state: RootState) => state.Company.showPpi);
	const companyShowProtect = useSelector((state: RootState) => state.Company.showProtect);

	/*const day = useSelector((state: RootState) => state.Birthdate.day);
	const month = useSelector((state: RootState) => state.Birthdate.month);
	const year = useSelector((state: RootState) => state.Birthdate.year);
*/
	// const hasBirthdate = day && month && year;

	// const showPpi = useSelector((state: RootState) => state.PPI.showPpi);
	/*const setShowPpi = (val: boolean) => {
		dispatch(ppiStore.setShowPpi(val));
		if (!val) {
			dispatch(ppiStore.reset());
		}
	};*/

	return (
		<>
			{(companyShowPpi || companyShowProtect) && (
				<>
					<Text isSubtitle hasMarginBottom>
						{m('page.customerData.ppi.title', 'global')}
					</Text>
					<PPIContent />
				</>
			)}
		</>
	);
};

const PPIContent = () => {
	const companyShowPpi = useSelector((state: RootState) => state.Company.showPpi);
	const companyShowProtect = useSelector((state: RootState) => state.Company.showProtect);

	/*const day = useSelector((state: RootState) => state.Birthdate.day);
	const month = useSelector((state: RootState) => state.Birthdate.month);
	const year = useSelector((state: RootState) => state.Birthdate.year);
	*/
	// const age = getAge(year, month, day);
	// const ageWithRuntime = getAgeWithRuntime(year, month, day, mapCurrentRuntimeToYears());
	// const showPpi = !isNaN(ageWithRuntime) && ageWithRuntime < ppiMaxAge;
	// const showProtect = !isNaN(age) && age < protectMaxAge;
	return (
		<>
			<Overview />
			{companyShowPpi && (
				<BoxCollapsible
					activeDefault
					icon={<SvgUmbrella />}
					title={m('page.customerData.ppi.ppi.title', 'global')}
					subtitle={m('page.customerData.ppi.ppi.subtitle', 'global')}
					contentBody={<PPIBody />}
				/>
			)}
			{companyShowProtect && (
				<BoxCollapsible
					activeDefault
					icon={<SvgScale />}
					title={m('page.customerData.ppi.protect.title', 'global')}
					subtitle={m('page.customerData.ppi.protect.subtitle', 'global')}
					contentBody={<ProtectBody />}
				/>
			)}
		</>
	);
};

export default PPI;
