import React from 'react';

type Props = {
	color?: string;
};

const SvgDocuments = (props: Props) => {
	const width = 24;
	const height = 24;
	const defaultColor = '#000000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M16 0H8C6.9 0 6 .9 6 2V18C6 19.1 6.9 20 8 20H20C21.1 20 22 19.1 22 18V6L16 0M20 18H8V2H15V7H20V18M4 4V22H20V24H4C2.9 24 2 23.1 2 22V4H4M10 10V12H18V10H10M10 14V16H15V14H10Z"
			/>
		</svg>
	);
};
export default SvgDocuments;
