import React from 'react';
import classNames from 'classnames';

import styles from './Text.module.scss';

type Props = {
	isTitle?: boolean;
	isSubtitle?: boolean;
	isSmall?: boolean;
	isExtraSmall?: boolean;
	isBlack?: boolean;
	prependIcon?: any;
	appendIcon?: any;
	isSmallIcon?: boolean;
	hasMarginTop?: boolean;
	hasMarginBottom?: boolean;
	children: any;
};

const Text = (props: Props) => {
	return (
		<p
			className={classNames([
				styles.text,
				props.isTitle ? styles.title : null,
				props.isSubtitle ? styles.subtitle : null,
				props.isSmall ? styles.small : null,
				props.isExtraSmall ? styles.extraSmall : null,
				props.isBlack ? styles.black : null,
				props.prependIcon ? styles.textPrependIcon : null,
				props.appendIcon ? styles.textAppendIcon : null,
				props.isSmallIcon ? styles.smallIcon : null,
				props.hasMarginTop ? styles.marginTop : null,
				props.hasMarginBottom ? styles.marginBottom : null
			])}
		>
			{props.prependIcon && (
				<span className={classNames([styles.icon, styles.prependIcon])}>
					{props.prependIcon}
				</span>
			)}
			{props.children}
			{props.appendIcon && (
				<span className={classNames([styles.icon, styles.appendIcon])}>
					{props.appendIcon}
				</span>
			)}
		</p>
	);
};
export default Text;
