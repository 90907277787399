import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import MaritalStatus from './MaritalStatus.d';
import MaritalStatusType from 'types/enums/MaritalStatus.d';

const initialState: MaritalStatus = {
	maritalStatus: null,
	numberAdults: 1,
	numberChildren: 0,
	numberChildrenWithBenefit: 0,
	maritalStatus2: null,
	numberAdults2: 1,
	numberChildren2: 0,
	numberChildrenWithBenefit2: 0
};

export const slice = createSlice({
	name: 'MaritalStatus',
	initialState: initialState,
	reducers: {
		setMaritalStatus: (state, action: PayloadAction<MaritalStatusType>) => {
			state.maritalStatus = action.payload;
		},
		setNumberAdults: (state, action: PayloadAction<number>) => {
			state.numberAdults = action.payload;
		},
		setNumberChildren: (state, action: PayloadAction<number>) => {
			state.numberChildren = action.payload;
		},
		setNumberChildrenWithBenefit: (state, action: PayloadAction<number>) => {
			state.numberChildrenWithBenefit = action.payload;
		},
		setMaritalStatus2: (state, action: PayloadAction<MaritalStatusType>) => {
			state.maritalStatus2 = action.payload;
		},
		setNumberAdults2: (state, action: PayloadAction<number>) => {
			state.numberAdults2 = action.payload;
		},
		setNumberChildren2: (state, action: PayloadAction<number>) => {
			state.numberChildren2 = action.payload;
		},
		setNumberChildrenWithBenefit2: (state, action: PayloadAction<number>) => {
			state.numberChildrenWithBenefit2 = action.payload;
		},
		reset: () => initialState
	}
});

export const {
	setMaritalStatus,
	setNumberAdults,
	setNumberChildren,
	setNumberChildrenWithBenefit,
	setMaritalStatus2,
	setNumberAdults2,
	setNumberChildren2,
	setNumberChildrenWithBenefit2,
	reset
} = slice.actions;
export default slice.reducer;
