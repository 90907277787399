import React, { useEffect, useState } from 'react';
import m from 'utils/messages/messages';
import { getLoanRates } from 'api';
import { continueValid } from 'utils/tracking/tracking';
import { goToPage } from 'utils/pages/pages';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { setAmount, setRuntime } from 'store/Credit/Credit';

import Page from 'components/Organisms/Page/Page';
import { Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import CreditCalculator from 'components/Molecules/CreditCalculator/CreditCalculator';
import SvgRefresh from 'components/Atoms/SVG/SvgRefresh';

import Subpage from 'types/enums/Subpage.d';
import CreditDetailsOrigin from 'types/enums/CreditDetailsOrigin.d';

const CreditDetailsEdit = () => {
	const dispatch = useDispatch();
	const { origin } = useParams();

	const storeAmount = useSelector((state: RootState) => state.Bco.netAmount);
	const storeRuntime = useSelector((state: RootState) => state.Bco.runtime);

	const [fromOrigin, setFromOrigin] = useState<CreditDetailsOrigin>(null);

	const [chosenAmount, setChosenAmount] = useState<number>(storeAmount);
	const [chosenRuntime, setChosenRuntime] = useState<number>(storeRuntime);

	useEffect(() => {
		if (origin === 'status-uebersicht') {
			setFromOrigin(CreditDetailsOrigin.STATUS_OVERVIEW);
		} else if (origin === 'antrag-empfangen') {
			setFromOrigin(CreditDetailsOrigin.APPLICATION_RECEIVED);
		} else {
			goToPage(Subpage.ERROR);
		}

		if (origin === 'status-uebersicht' || origin === 'antrag-empfangen') {
			getLoanRates();
		}
	}, []);

	const onReset = () => {
		setChosenAmount(storeAmount);
		setChosenRuntime(storeRuntime);
	};

	const onFormSubmit = () => {
		continueValid();

		dispatch(setAmount(chosenAmount));
		dispatch(setRuntime(chosenRuntime));

		// TODO: add application update call

		if (fromOrigin === CreditDetailsOrigin.STATUS_OVERVIEW) {
			goToPage(Subpage.STATUS_OVERVIEW);
		} else if (fromOrigin === CreditDetailsOrigin.APPLICATION_RECEIVED) {
			goToPage(Subpage.APPLICATION_RECEIVED);
		}
	};

	return (
		<Page>
			<Title hasMarginBottom>{m('page.creditDetailsEdit.title', 'global')}</Title>
			<Form
				showSubmitDivider
				onSubmit={onFormSubmit}
				submitText={m('save.label', 'fields')}
				onPrevButton={onReset}
				prevButtonText={m('resetData.label', 'fields')}
				prevButtonAppendIcon={<SvgRefresh color="#9E3667" />}
				tabindex={22}
			>
				<CreditCalculator
					chosenAmount={chosenAmount}
					setChosenAmount={setChosenAmount}
					chosenRuntime={chosenRuntime}
					setChosenRuntime={setChosenRuntime}
				/>
			</Form>
		</Page>
	);
};
export default CreditDetailsEdit;
