import React from 'react';
import classNames from 'classnames';

import SvgLoadingSpinner from 'components/Atoms/SVG/SvgLoadingSpinner';

import styles from './Spinner.module.scss';

type Props = {
	noMargin?: boolean;
};

const Spinner = (props: Props) => {
	return (
		<div className={classNames([styles.spinner, props.noMargin ? styles.noMargin : null])}>
			<SvgLoadingSpinner />
		</div>
	);
};
export default Spinner;
