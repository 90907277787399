import React, { useState } from 'react';
import classNames from 'classnames';
import m from 'utils/messages/messages';
import moment from 'moment';
import { formatMoneyValue } from 'utils/numbers/numbers';

import { Col, Row } from 'components/Atoms/Grid';
import { Title, Text } from 'components/Atoms/Text';
import SvgDropdownArrow from 'components/Atoms/SVG/SvgDropdownArrow';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import styles from './BcoResults.module.scss';

const BcoResults = () => {
	const applicationCapsId = useSelector((state: RootState) => state.Bco.applicationCapsId);
	const runtime = useSelector((state: RootState) => state.Bco.runtime);
	const monthlyRate = useSelector((state: RootState) => state.Bco.monthlyRate);
	const netAmount = useSelector((state: RootState) => state.Bco.netAmount);
	const effectiveYearlyInterestRate = useSelector(
		(state: RootState) => state.Bco.effectiveYearlyInterestRate
	);
	const nominalInterestRate = useSelector((state: RootState) => state.Bco.nominalInterestRate);
	const firstRateDate = useSelector((state: RootState) => state.Bco.firstRateDate);
	const interest = useSelector((state: RootState) => state.Bco.interest);
	const totalAmount = useSelector((state: RootState) => state.Bco.totalAmount);

	const storeShowPpi = useSelector((state: RootState) => state.Company.showPpi);
	const storeShowProtect = useSelector((state: RootState) => state.Company.showProtect);
	const ppiAmount = useSelector((state: RootState) => state.Bco.ppiAmount);
	const protectAmount = useSelector((state: RootState) => state.Bco.protectAmount);

	const [detailsAreOpen, setDetailsAreOpen] = useState<boolean>(false);

	return (
		<div className={styles.wrapper}>
			<div className={styles.header} />
			<div className={styles.content}>
				<Row>
					<Col xs={12} md={7} lg={8}>
						{applicationCapsId && (
							<div className={styles.applicationId}>
								{m('applicationId.label', 'fields')}: {applicationCapsId}
							</div>
						)}
						<Title>{m('partial.bcoResults.title', 'global')}</Title>
						<Text isSubtitle>{m('partial.bcoResults.subtitle', 'global')}</Text>
					</Col>
					<Col xs={12} md={5} lg={4}>
						<Text hasMarginBottom>
							<strong>{m('runtime.label', 'fields')}</strong>
							<br />
							{runtime}{' '}
							{runtime === 1
								? m('runtime.month', 'fields')
								: m('runtime.months', 'fields')}
						</Text>
						<Text hasMarginBottom>
							<strong>{m('monthlyRate.label', 'fields')}</strong>
							<br />
							{formatMoneyValue(monthlyRate, true, true)}
						</Text>
						<div className={styles.box}>
							<Text>
								<strong>{m('approvedAmount.label', 'fields')}</strong>
							</Text>
							<div className={styles.amount}>
								<strong>{formatMoneyValue(parseInt(netAmount + ''))}</strong>,00€
							</div>
						</div>
					</Col>
				</Row>
				<p
					onClick={() => {
						setDetailsAreOpen(!detailsAreOpen);
					}}
					className={classNames([
						'link',
						styles.moreDetailsLink,
						detailsAreOpen ? styles.moreDetailsLinkActive : null
					])}
				>
					{detailsAreOpen
						? m('lessCreditDetails', 'fields')
						: m('moreCreditDetails', 'fields')}
					<SvgDropdownArrow color="#9E3667" />
				</p>
				<div
					className={classNames([
						styles.details,
						detailsAreOpen ? styles.detailsActive : null
					])}
				>
					<Row>
						<Col xs={12} md={6}>
							<div className={styles.table}>
								<div className={styles.tableRow}>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellLabel
										])}
									>
										{m('effectiveYearlyInterest.label', 'fields')}:
									</div>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellValue
										])}
									>
										{formatMoneyValue(effectiveYearlyInterestRate, true) + ' %'}
									</div>
								</div>
								<div className={styles.tableRow}>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellLabel
										])}
									>
										{m('nominalInterestRate.label', 'fields')}:
									</div>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellValue
										])}
									>
										{formatMoneyValue(nominalInterestRate, true) + ' %'}
									</div>
								</div>
								<div className={styles.tableRow}>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellLabel
										])}
									>
										{m('firstRateDate.label', 'fields')}:
									</div>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellValue
										])}
									>
										{firstRateDate
											? moment(firstRateDate, 'YYYY-MM-DD').format(
													'DD/MM/YYYY'
											  )
											: '-'}
									</div>
								</div>
							</div>
						</Col>
						<Col xs={12} md={6}>
							<div className={styles.table}>
								<div className={styles.tableRow}>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellLabel
										])}
									>
										{m('interest.label', 'fields')}:
									</div>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellValue
										])}
									>
										{formatMoneyValue(interest, true, true)}
									</div>
								</div>
								{storeShowPpi && (
									<div className={styles.tableRow}>
										<div
											className={classNames([
												styles.tableCell,
												styles.tableCellLabel
											])}
										>
											{m('ppi.label', 'fields')}:
										</div>
										<div
											className={classNames([
												styles.tableCell,
												styles.tableCellValue
											])}
										>
											{ppiAmount
												? formatMoneyValue(ppiAmount, true, true)
												: 'Keine'}
										</div>
									</div>
								)}
								{storeShowProtect && (
									<div className={styles.tableRow}>
										<div
											className={classNames([
												styles.tableCell,
												styles.tableCellLabel
											])}
										>
											{m('protect.label', 'fields')}:
										</div>
										<div
											className={classNames([
												styles.tableCell,
												styles.tableCellValue
											])}
										>
											{protectAmount
												? formatMoneyValue(protectAmount, true, true)
												: 'Kein'}
										</div>
									</div>
								)}
								<div className={styles.tableRow}>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellLabel
										])}
									>
										{m('totalAmount.label', 'fields')}:
									</div>
									<div
										className={classNames([
											styles.tableCell,
											styles.tableCellValue
										])}
									>
										{formatMoneyValue(totalAmount, true, true)}
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};
export default BcoResults;
