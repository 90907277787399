import React, { useEffect, useState } from 'react';
import m from 'utils/messages/messages';
import { isEmpty } from 'utils/validation/validation';
import { continueInvalid, continueValid } from 'utils/tracking/tracking';
import { goToPage, preventHistoryBackFor } from 'utils/pages/pages';
import { getApplications } from 'api';
import { setApplicationDataToStore } from 'utils/application/application';

import Page from 'components/Organisms/Page/Page';
import Form from 'components/Molecules/Form/Form';
import { Text } from 'components/Atoms/Text';
import Button from 'components/Atoms/Button/Button';
import BcoResults from 'components/Molecules/BcoResults/BcoResults';
import NextSteps from 'components/Atoms/Partials/NextSteps/NextSteps';
import DownloadContracts from 'components/Atoms/Partials/DownloadContracts/DownloadContracts';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { setIsEdit } from 'store/Applications/Applications';

import ButtonStyle from 'components/Atoms/Button/types/ButtonStyle.d';
import Subpage from 'types/enums/Subpage.d';

import styles from './ApplicationReceived.module.scss';
import { getCurrentEnvironment } from 'utils/env/env';
import { RadioButtonInput } from 'components/Atoms/Form';

const ApplicationReceived = () => {
	const dispatch = useDispatch();

	const storeApplicationId = useSelector((state: RootState) => state.Bco.applicationId);
	const storeHasSecondApplicant = useSelector(
		(state: RootState) => state.Applicant.hasSecondApplicant
	);

	const [whoGoesOn, setWhoGoesOn] = useState<string>(null);

	useEffect(() => {
		preventHistoryBackFor(Subpage.CUSTOMER_DATA);
	}, []);

	const onFormSubmit = () => {
		continueValid();
		if (whoGoesOn === 'AGENT') {
			goToPage(Subpage.UPLOAD_MISSING_DOCUMENTS);
		} else {
			goToPage(Subpage.EMAIL_SENT_TO_CUSTOMER);
		}
	};

	const submitDisabled = () => {
		return isEmpty(whoGoesOn);
	};

	const editApplication = async () => {
		await getApplications(storeApplicationId, onEditSuccess, onEditError);
	};

	const onEditSuccess = (data: any) => {
		dispatch(setIsEdit(true));
		setApplicationDataToStore(data);
		continueValid();
		goToPage(Subpage.CREDIT_DETAILS);
	};

	const onEditError = () => {
		continueInvalid();
	};

	const isShowGoOn = (): boolean => {
		return (
			!storeHasSecondApplicant ||
			['dev', 'pre', 'localhost'].includes(getCurrentEnvironment().label)
		);
	};

	return (
		<Page>
			<BcoResults />
			<div className={styles.editButtonWrapper}>
				<Button onClick={editApplication} buttonStyle={ButtonStyle.LINK}>
					{m('page.applicationReceived.editButton', 'global')}
				</Button>
			</div>
			{isShowGoOn() && (
				<>
					<NextSteps />
					<RadioButtonInput
						label={m('page.applicationReceived.finishedBy', 'global')}
						value={whoGoesOn}
						setValue={(value: string) => {
							setWhoGoesOn(value);
						}}
						items={[
							{
								value: 'CUSTOMER',
								text: m('page.applicationReceived.customer', 'global')
							},
							{
								value: 'AGENT',
								text: m('page.applicationReceived.agent', 'global')
							}
						]}
						btnStyle="switch"
					/>
				</>
			)}
			<Form
				showSubmitDivider
				onSubmit={isShowGoOn() ? onFormSubmit : undefined}
				submitDisabled={submitDisabled()}
				prevButtonExtension={<DownloadContracts />}
			>
				{isShowGoOn() && (
					<>
						{whoGoesOn === 'CUSTOMER' && (
							<Text hasMarginTop>
								{m('page.applicationReceived.customerHint', 'global')}
							</Text>
						)}
						{whoGoesOn === 'AGENT' && (
							<Text hasMarginTop>
								{m('page.applicationReceived.agentHint', 'global')}
							</Text>
						)}
					</>
				)}
			</Form>
		</Page>
	);
};
export default ApplicationReceived;
