import React from 'react';
import classNames from 'classnames';

import SvgLogo from 'components/Atoms/SVG/SvgLogo';
import SvgLogoMobile from 'components/Atoms/SVG/SvgLogoMobile';

import styles from './Logo.module.scss';

const Logo = () => {
	return (
		<>
			<div className={classNames([styles.logo, styles.isDesktop])}>
				<SvgLogo />
			</div>
			<div className={classNames([styles.logo, styles.isMobile])}>
				<SvgLogoMobile />
			</div>
		</>
	);
};
export default Logo;
