import React, { useEffect } from 'react';
import m from 'utils/messages/messages';
import { formatMoneyValue } from 'utils/numbers/numbers';
import { getMonthlyRate, summedNetAmount, summedRuntime } from 'utils/credit/credit';

import styles from './Overview.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

const Overview = () => {
	const storeHasPpi = useSelector((state: RootState) => state.PPI.hasPpi);
	const storeProtect = useSelector((state: RootState) => state.PPI.protect);
	const storeProtectRuntime = useSelector((state: RootState) => state.PPI.protectRuntime);
	const storeProtectMonthlyRate = useSelector((state: RootState) => state.PPI.protectMonthlyRate);

	const formattedAmount = () => {
		return formatMoneyValue(summedNetAmount(), true, true);
	};

	const formattedRuntime = () => {
		const tempRuntime = summedRuntime();
		const extension = m(tempRuntime === 1 ? 'runtime.month' : 'runtime.months', 'fields');
		return tempRuntime + ' ' + extension;
	};

	const getFormattedMonthlyRate = () => {
		const value = getMonthlyRate();
		return formatMoneyValue(value, true, true);
	};

	let formattedMonthlyRate = getFormattedMonthlyRate();

	useEffect(() => {
		formattedMonthlyRate = getFormattedMonthlyRate();
	}, [storeHasPpi, storeProtect, storeProtectRuntime, storeProtectMonthlyRate]);

	return (
		<div className={styles.box}>
			<div className={styles.col}>
				<div className={styles.label}>{m('amount.label', 'fields')}</div>
				<div className={styles.text}>{formattedAmount()}</div>
			</div>
			<div className={styles.col}>
				<div className={styles.label}>{m('runtime.label', 'fields')}</div>
				<div className={styles.text}>{formattedRuntime()}</div>
			</div>
			<div className={styles.col}>
				<div className={styles.label}>{m('rate.label', 'fields')}</div>
				<div className={styles.text}>{formattedMonthlyRate}</div>
			</div>
		</div>
	);
};
export default Overview;
