import axios from 'axios';
import { find } from 'lodash';
import fileDownload from 'js-file-download';
import store from 'store/store';
import { getApiBaseURL } from 'utils/api/api';
import { showApiConnectionError } from 'utils/validation/validation';
import { setContracts } from 'store/Contract/Contract';
import { base64ToFile } from 'utils/files/files';

import ContractType from 'types/enums/ContractType.d';

const url1 = getApiBaseURL() + '/api/applications/';
const url2 = '/contract';

export const getApplicationsContract = async (
	filename: string,
	contractType: ContractType,
	successCb: Function = () => {},
	errorCb: Function = () => {},
	directDownloadId: string = null
) => {
	if (directDownloadId || store.getState().Contract.contracts.length === 0) {
		await axios
			.get(url1 + (directDownloadId ?? store.getState().Bco.applicationId) + url2, {
				headers: {
					vcJwtToken: store.getState().Token.vcJwtToken
				}
			})
			.then((res) => {
				store.dispatch(setContracts(res.data));
				onSuccess(filename, contractType, res.data, successCb);
			})
			.catch((err: any) => {
				onError(err, errorCb);
			});
	} else {
		onSuccess(filename, contractType, store.getState().Contract.contracts, successCb);
	}
};

const onSuccess = (
	filename: string,
	contractType: string,
	data: any,
	successCb: Function = () => {}
) => {
	try {
		const file = find(data, { type: contractType });
		base64ToFile(file.file, filename, 'application/pdf').then((file) => {
			fileDownload(file, filename);
		});
	} catch (err) {
		console.log(err);
	}
	successCb();
};

const onError = (err: any, errorCb: Function = () => {}) => {
	showApiConnectionError();
	errorCb();
};
