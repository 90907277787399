import React, { useState } from 'react';
import { m } from 'utils/messages/messages';
import { formatIbanForInput } from 'utils/iban/iban';
import { DIGIT, IBAN } from 'components/Atoms/Form/TextInput/utils/regex';

import { Col, Row } from 'components/Atoms/Grid';
import { TextInput } from 'components/Atoms/Form';
import SvgPlus from 'components/Atoms/SVG/SvgPlus';

import ExternalCredit from 'types/interfaces/ExternalCredit.d';

import styles from './ExternalCreditInputs.module.scss';

type Props = {
	externalCredit: ExternalCredit;
	onChange: Function;
	removeItem: Function;
	index: number;
	hasIbanErr?: boolean;
};

const ExternalCreditInputs = (props: Props) => {
	const [iban, setIban] = useState<string>(props.externalCredit.iban);
	const [remainingBalance, setRemainingBalance] = useState<number>(
		props.externalCredit.remainingBalance
	);

	const onChangeIban = (value: string) => {
		setIban(value);
		props.onChange(props.index, value, remainingBalance);
	};

	const onChangeRemainingBalance = (value: string) => {
		setRemainingBalance(value ? parseInt(value) : null);
		props.onChange(props.index, iban, value);
	};

	return (
		<div className={styles.wrapper}>
			<Row>
				<Col xs={12} sm={6}>
					<div className={styles.iban}>
						<TextInput
							label={m('iban.label', 'fields')}
							value={iban}
							setValue={onChangeIban}
							format={formatIbanForInput}
							regex={IBAN}
							maxLength={40}
							hasError={props.hasIbanErr}
							testId={'iban-' + props.index}
						/>
					</div>
				</Col>
				<Col xs={12} sm={6}>
					<div className={styles.remainingBalance}>
						<TextInput
							label={m('remainingBalance.label', 'fields')}
							tooltip={m('remainingBalance.info', 'fields')}
							value={remainingBalance ? remainingBalance + '' : ''}
							setValue={onChangeRemainingBalance}
							regex={DIGIT}
							testId={'remaining-balance-' + props.index}
						/>
					</div>
				</Col>
			</Row>
			<div
				className={styles.removeButton}
				onClick={() => {
					props.removeItem(props.index);
				}}
			>
				<SvgPlus />
			</div>
		</div>
	);
};
export default ExternalCreditInputs;
