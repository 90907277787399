import React from 'react';
import m from 'utils/messages/messages';

import { Title } from 'components/Atoms/Text';
import Spinner from 'components/Atoms/Loading/Spinner/Spinner';

type Props = {
	title?: string;
	icon?: JSX.Element;
};

const PageLoading = (props: Props) => {
	return (
		<div className={'text-align-center'}>
			<Spinner />
			<Title>{props.title ? props.title : m('partial.loading.title', 'global')}</Title>
		</div>
	);
};
export default PageLoading;
