import React, { useRef } from 'react';
import m from 'utils/messages/messages';
import {
	DIGIT,
	WORD_WITH_SPACE_AND_DASH,
	WORD_WITH_SPACE_AND_DASH_AND_NUMBERS
} from 'components/Atoms/Form/TextInput/utils/regex';

import { Text } from 'components/Atoms/Text';
import { Col, Row } from 'components/Atoms/Grid';
import { TextInput, CheckboxInput } from 'components/Atoms/Form';

type Props = {
	postalCode: string;
	setPostalCode: Function;
	postalCodeError?: string;
	city: string;
	setCity: Function;
	cityError?: string;
	street: string;
	setStreet: Function;
	streetError?: string;
	streetNumber: string;
	setStreetNumber: Function;
	streetNumberError?: string;
	hasPreviousAddress: boolean;
	setHasPreviousAddress: Function;
	previousPostalCode: string;
	setPreviousPostalCode: Function;
	previousPostalCodeError?: string;
	previousCity: string;
	setPreviousCity: Function;
	previousCityError?: string;
	previousStreet: string;
	setPreviousStreet: Function;
	previousStreetError?: string;
	previousStreetNumber: string;
	setPreviousStreetNumber: Function;
	previousStreetNumberError?: string;
};

const Address = (props: Props) => {
	const cityRef = useRef<HTMLInputElement>(null);
	const previousCityRef = useRef<HTMLInputElement>(null);
	return (
		<>
			<Text isSubtitle>{m('page.customerData.address.title', 'global')}</Text>
			<Row>
				<Col xs={8}>
					<TextInput
						label={m('street.label', 'fields')}
						value={props.street}
						setValue={props.setStreet}
						maxLength={50}
						regex={WORD_WITH_SPACE_AND_DASH}
						hasError={!!props.streetError}
						message={props.streetError}
						tabindex={9}
						testId="street"
					/>
				</Col>
				<Col xs={4}>
					<TextInput
						label={m('streetNumber.label', 'fields')}
						value={props.streetNumber}
						setValue={props.setStreetNumber}
						maxLength={8}
						regex={WORD_WITH_SPACE_AND_DASH_AND_NUMBERS}
						hasError={!!props.streetNumberError}
						message={props.streetNumberError}
						tabindex={10}
						testId="house-number"
					/>
				</Col>
				<Col xs={4}>
					<TextInput
						nextRefOnMaxLength={cityRef}
						label={m('postalCode.label', 'fields')}
						value={props.postalCode}
						setValue={props.setPostalCode}
						maxLength={5}
						regex={DIGIT}
						hasError={!!props.postalCodeError}
						message={props.postalCodeError}
						tabindex={11}
						testId="postalCode"
					/>
				</Col>
				<Col xs={8}>
					<TextInput
						ref={cityRef}
						label={m('city.label', 'fields')}
						value={props.city}
						setValue={props.setCity}
						maxLength={50}
						regex={WORD_WITH_SPACE_AND_DASH}
						hasError={!!props.cityError}
						message={props.cityError}
						tabindex={12}
						testId="city"
					/>
				</Col>
				<Col xs={12}>
					<CheckboxInput
						label={m('hasPreviousAddress.label', 'fields')}
						value={props.hasPreviousAddress}
						setValue={props.setHasPreviousAddress}
						tabindex={13}
						testId="has-previous-address"
					/>
				</Col>
				{props.hasPreviousAddress && (
					<>
						<Col xs={12}>
							<Text isSubtitle>
								{m('page.customerData.previousAddress.title', 'global')}
							</Text>
						</Col>
						<Col xs={8}>
							<TextInput
								label={m('street.label', 'fields')}
								value={props.previousStreet}
								setValue={props.setPreviousStreet}
								maxLength={50}
								regex={WORD_WITH_SPACE_AND_DASH}
								hasError={!!props.previousStreetError}
								message={props.previousStreetError}
								tabindex={14}
								testId="previous-street"
							/>
						</Col>
						<Col xs={4}>
							<TextInput
								label={m('streetNumber.label', 'fields')}
								value={props.previousStreetNumber}
								setValue={props.setPreviousStreetNumber}
								maxLength={8}
								regex={WORD_WITH_SPACE_AND_DASH_AND_NUMBERS}
								hasError={!!props.previousStreetNumberError}
								message={props.previousStreetNumberError}
								tabindex={15}
								testId="previous-house-number"
							/>
						</Col>
						<Col xs={4}>
							<TextInput
								nextRefOnMaxLength={previousCityRef}
								label={m('postalCode.label', 'fields')}
								value={props.previousPostalCode}
								setValue={props.setPreviousPostalCode}
								maxLength={5}
								regex={DIGIT}
								hasError={!!props.previousPostalCodeError}
								message={props.previousPostalCodeError}
								tabindex={16}
								testId="previous-postalCode"
							/>
						</Col>
						<Col xs={8}>
							<TextInput
								ref={previousCityRef}
								label={m('city.label', 'fields')}
								value={props.previousCity}
								setValue={props.setPreviousCity}
								maxLength={50}
								regex={WORD_WITH_SPACE_AND_DASH}
								hasError={!!props.previousCityError}
								message={props.previousCityError}
								tabindex={17}
								testId="previous-city"
							/>
						</Col>
					</>
				)}
			</Row>
		</>
	);
};
export default Address;
