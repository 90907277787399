import React, { useState, useEffect } from 'react';
import { findIndex } from 'lodash';
import { goToPage, replaceSubpageVariable } from 'utils/pages/pages';

import rootRoutes from 'routes/rootRoutes';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import SvgArrowLeft from 'components/Atoms/SVG/SvgArrowLeft';
import SvgArrowRight from 'components/Atoms/SVG/SvgArrowRight';

import RouteItem from 'types/interfaces/RouteItem.d';

import styles from '../Dev.module.scss';

const Pagination = () => {
	const currentSubpage = useSelector((state: RootState) => state.Page.currentSubpage);
	const [currentPageIndex, setCurrentPageIndex] = useState(0);

	const [hasPrevPage, setHasPrevPage] = useState(false);
	const [hasNextPage, setHasNextPage] = useState(false);

	useEffect(() => {
		prepareRoutes();
	}, [currentSubpage]);

	const prepareRoutes = () => {
		const storePath = currentSubpage ? '/' + currentSubpage.trim() : '';
		const index = findIndex(rootRoutes, (route: RouteItem) => {
			const routePath = route.subpage ? '/' + route.subpage.trim() : '';
			return storePath === routePath;
		});
		setCurrentPageIndex(index);
		setHasPrevPage(index > 0);
		setHasNextPage(index < rootRoutes.length - 1);
	};

	const goBack = () => {
		if (hasPrevPage) {
			goToPage(replaceSubpageVariable(rootRoutes[currentPageIndex - 1].subpage));
		}
	};

	const goNext = () => {
		if (hasNextPage) {
			goToPage(replaceSubpageVariable(rootRoutes[currentPageIndex + 1].subpage));
		}
	};

	return (
		<div className={styles.pagination}>
			<div className={styles.paginationItem}>
				<button
					className={styles.paginationButton}
					onClick={goBack}
					disabled={!hasPrevPage}
					title={hasPrevPage ? rootRoutes[currentPageIndex - 1].subpage : null}
				>
					<SvgArrowLeft />
				</button>
			</div>
			<div className={styles.paginationItem}>
				<button
					className={styles.paginationButton}
					onClick={goNext}
					disabled={!hasNextPage}
					title={hasNextPage ? rootRoutes[currentPageIndex + 1].subpage : null}
				>
					<SvgArrowRight />
				</button>
			</div>
		</div>
	);
};
export default Pagination;
