import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Customer from './Customer.d';
import { isEmpty } from 'utils/validation/validation';

const initialState: Customer = {
	customerNumber: null
};

export const slice = createSlice({
	name: 'Customer',
	initialState: initialState,
	reducers: {
		setCustomerNumber: (state, action: PayloadAction<string>) => {
			state.customerNumber = !isEmpty(action.payload) ? action.payload : null;
		},
		reset: () => initialState
	}
});

export const { setCustomerNumber, reset } = slice.actions;
export default slice.reducer;
