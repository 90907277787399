import axios from 'axios';
import { getApiBaseURL } from 'utils/api/api';
import { showApiConnectionError } from 'utils/validation/validation';

import store from 'store/store';
import { setIsLoadingPage } from 'store/Loading/Loading';
import { setCcbData } from 'store/Credit/Credit';

const url = getApiBaseURL() + '/api/loan-rates';

export const getLoanRates = async (
	successCb: Function = () => {},
	errorCb: Function = () => {}
) => {
	store.dispatch(setIsLoadingPage(true));
	await axios
		.get(url)
		.then((res) => {
			onSuccess(res.data, successCb);
		})
		.catch((err: any) => {
			onError(err, errorCb);
		});
};

const onSuccess = (data: any, successCb: Function = () => {}) => {
	store.dispatch(setCcbData(data));
	store.dispatch(setIsLoadingPage(false));
	successCb();
};

const onError = (err: any, errorCb: Function = () => {}) => {
	store.dispatch(setIsLoadingPage(false));
	showApiConnectionError();
	errorCb();
};
