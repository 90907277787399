import React from 'react';

type Props = {
	color?: string;
};

const SvgDownload = (props: Props) => {
	const width = 12;
	const height = 12;
	const defaultColor = '#9E3667';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<rect
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				x="1.26"
				y="11.14"
				width="9.49"
				height="0.86"
			/>
			<polygon
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				points="10.49 6.08 9.91 5.44 6.43 8.6 6.43 0 5.57 0 5.57 8.6 2.09 5.44 1.51 6.08 6 10.15 10.49 6.08"
			/>
		</svg>
	);
};
export default SvgDownload;
