import React, { useEffect } from 'react';
import m from 'utils/messages/messages';
import { goToPage } from 'utils/pages/pages';

import PageLoading from 'components/Atoms/Loading/PageLoading/PageLoading';

import Subpage from 'types/enums/Subpage.d';

const Loading = () => {
	useEffect(() => {
		const timeout = setTimeout(() => {
			goToPage(Subpage.LOGIN_SUCCESS);
		}, 5000);
		return function cleanup() {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<>
			<PageLoading title={m('partial.loading.title', 'global') + ''} />
		</>
	);
};

export default Loading;
