import React from 'react';
import m from 'utils/messages/messages';
import { formatMoneyValue } from 'utils/numbers/numbers';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import Page from 'components/Organisms/Page/Page';
import { Col, Row } from 'components/Atoms/Grid';
import { Title, Text } from 'components/Atoms/Text';
import Button from 'components/Atoms/Button/Button';
import SvgCreditDone from 'components/Atoms/SVG/SvgCreditDone';

import ButtonStyle from 'components/Atoms/Button/types/ButtonStyle.d';

import styles from './CreditDone.module.scss';

const CreditDone = () => {
	const netAmount = useSelector((state: RootState) => state.Bco.netAmount);

	return (
		<Page>
			<Row>
				<Col xs={12} md={5} alignSelfCenter>
					<div className={styles.img}>
						<SvgCreditDone />
					</div>
				</Col>
				<Col xs={12} md={1} />
				<Col xs={12} md={6} alignSelfCenter>
					<Title>{m('page.creditDone.title', 'global')}</Title>
					<div className={styles.amountBox}>
						<Text>{m('page.creditDone.amount', 'global')}</Text>
						<p className={styles.amount}>
							{formatMoneyValue(netAmount, false)}
							<span>,00 €</span>
						</p>
					</div>
					<Button
						buttonStyle={ButtonStyle.SECONDARY}
						onClick={() => {
							window.open('/', '_self');
							window.close();
						}}
					>
						{m('page.creditDone.button', 'global')}
					</Button>
				</Col>
			</Row>
		</Page>
	);
};
export default CreditDone;
