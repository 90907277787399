import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import classNames from 'classnames';
import m from 'utils/messages/messages';
import { goToPage } from 'utils/pages/pages';
import { formatMoneyValue } from 'utils/numbers/numbers';
import { continueInvalid, continueValid } from 'utils/tracking/tracking';
import {
	getApplications,
	getApplicationsContract,
	getStatusOverviewApplicationsDetails,
	getApplicationsAvailableDocuments
} from 'api';
import { setApplicationDataToStore } from 'utils/application/application';

import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Spinner from 'components/Atoms/Loading/Spinner/Spinner';
import Button from 'components/Atoms/Button/Button';
import { Popup } from 'components/Molecules/Overlay';
import ApplicationItemDocument from 'components/Pages/StatusOverview/Application/ApplicationItemDocument';
import SvgDownload from 'components/Atoms/SVG/SvgDownload';
import SvgDocuments from 'components/Atoms/SVG/SvgDocuments';
import SvgEye from 'components/Atoms/SVG/SvgEye';
import SvgEdit from 'components/Atoms/SVG/SvgEdit';

import { useDispatch, useSelector } from 'react-redux';
import { setIsEdit } from 'store/Applications/Applications';
import { RootState } from 'store/rootReducer';

import Application from 'types/interfaces/Application.d';
import ApplicationDetails from 'types/interfaces/ApplicationDetails.d';
import ButtonStyle from 'components/Atoms/Button/types/ButtonStyle.d';
import ButtonSize from 'components/Atoms/Button/types/ButtonSize.d';
import ApplicationStatusCode from 'types/enums/ApplicationStatusCode.d';
import ContractType from 'types/enums/ContractType.d';
import Subpage from 'types/enums/Subpage.d';
import PopupSize from 'components/Molecules/Overlay/Popup/types/PopupSize.d';

import styles from './ApplicationItemBody.module.scss';

type Props = {
	application: Application;
};

const ApplicationItemBody = (props: Props) => {
	const dispatch = useDispatch();

	const storeShowPpi = useSelector((state: RootState) => state.Company.showPpi);
	const storeShowProtect = useSelector((state: RootState) => state.Company.showProtect);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [applicationDetails, setApplicationDetails] = useState<ApplicationDetails>(null);
	const [hasSecondApplicant, setHasSecondApplicant] = useState<boolean>(false);
	const [missingDocumentsApplicant, setMissingDocumentsApplicant] = useState<Array<any>>([]);
	const [missingDocumentsSecondApplicant, setMissingDocumentsSecondApplicant] = useState<
		Array<any>
	>([]);
	const [showAvailableDocumentsModal, setShowAvailableDocumentsModal] = useState<boolean>(false);
	const [availableDocumentsLoading, setAvailableDocumentsLoading] = useState<boolean>(true);
	const [availableDocuments, setAvailableDocuments] = useState<Array<any>>([]);

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		setIsLoading(true);
		await getStatusOverviewApplicationsDetails(props.application.id, successLoadData);
	};

	const successLoadData = (data: any) => {
		setApplicationDetails(data);
		setMissingDocumentsApplicant(filter(data.missingDocuments, { applicant: 1 }));
		if (data?.customer2?.lastname) {
			setHasSecondApplicant(true);
			setMissingDocumentsSecondApplicant(filter(data.missingDocuments, { applicant: 2 }));
		}
		setIsLoading(false);
	};

	const continueApplication = async () => {
		await getApplications(props.application.id, onContinueSuccess, onContinueError);
	};

	const onContinueSuccess = (data: any) => {
		setApplicationDataToStore(data);
		continueValid();
		if (props.application.hasMissingDocs) {
			continueValid();
			goToPage(Subpage.UPLOAD_MISSING_DOCUMENTS);
		} else {
			continueValid();
			goToPage(Subpage.FINISH_PROCESS);
		}
	};

	const onContinueError = () => {
		continueInvalid();
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const editApplication = async () => {
		await getApplications(props.application.id, onEditSuccess, onEditError);
	};

	const onEditSuccess = (data: any) => {
		dispatch(setIsEdit(true));
		setApplicationDataToStore(data);
		continueValid();
		goToPage(Subpage.CREDIT_DETAILS);
	};

	const onEditError = () => {
		continueInvalid();
	};

	const calcMonthlyRate = () => {
		if (
			applicationDetails?.contract?.grossLoanAmount &&
			applicationDetails?.contract?.numberOfInstalments
		) {
			return formatMoneyValue(
				applicationDetails?.contract?.grossLoanAmount /
					applicationDetails?.contract?.numberOfInstalments,
				true,
				true
			);
		} else {
			return '-';
		}
	};

	const viewDocuments = async () => {
		setAvailableDocumentsLoading(true);
		setShowAvailableDocumentsModal(true);
		await getApplicationsAvailableDocuments(
			props.application.id,
			(data: any) => {
				if (data && data.length > 0) {
					setAvailableDocuments(data);
				}
				setAvailableDocumentsLoading(false);
			},
			() => {}
		);
	};

	return (
		<div className={styles.wrapper}>
			{isLoading ? (
				<div className={styles.loading}>
					<Spinner noMargin />
				</div>
			) : (
				<>
					<div className={styles.inner}>
						<Row>
							<Col xs={12} sm={6} md={4}>
								<div className={styles.item}>
									<Text isExtraSmall>
										<strong>
											{m('page.statusOverview.body.amount', 'global')}
										</strong>
									</Text>
									<Text isExtraSmall>
										{applicationDetails?.contract?.netLoanAmount
											? formatMoneyValue(
													applicationDetails.contract.netLoanAmount,
													false,
													true
											  )
											: '-'}
									</Text>
								</div>
							</Col>

							<Col xs={12} sm={6} md={4}>
								<div className={styles.item}>
									<Text isExtraSmall>
										<strong>
											{m('page.statusOverview.body.runtime', 'global')}
										</strong>
									</Text>
									<Text isExtraSmall>
										{applicationDetails?.contract?.numberOfInstalments ?? '-'}{' '}
										{m('runtime.months', 'fields')}
									</Text>
								</div>
							</Col>

							<Col xs={12} sm={6} md={4}>
								<div className={styles.item}>
									<Text isExtraSmall>
										<strong>
											{m('page.statusOverview.body.conditions', 'global')}
										</strong>
									</Text>
									{hasSecondApplicant && (
										<Text isExtraSmall>
											<strong>
												{m(
													'page.statusOverview.body.firstApplicant',
													'global'
												)}
											</strong>
										</Text>
									)}
									<Text isExtraSmall>
										{missingDocumentsApplicant &&
										missingDocumentsApplicant.length > 0 ? (
											<>
												{missingDocumentsApplicant.map(
													(document: any, index) => (
														<span
															key={
																props.application.applicationId +
																'-condition-' +
																index
															}
														>
															{index > 0 ? <br /> : ''}
															{document.name}
														</span>
													)
												)}
											</>
										) : (
											'-'
										)}
									</Text>
									{hasSecondApplicant && (
										<>
											<div className={styles.textSpacer} />
											<Text isExtraSmall>
												<strong>
													{m(
														'page.statusOverview.body.secondApplicant',
														'global'
													)}
												</strong>
											</Text>
											<Text isExtraSmall>
												{missingDocumentsSecondApplicant &&
												missingDocumentsSecondApplicant.length > 0 ? (
													<>
														{missingDocumentsSecondApplicant.map(
															(document: any, index) => (
																<span
																	key={
																		props.application
																			.applicationId +
																		'-condition-2-' +
																		index
																	}
																>
																	{index > 0 ? <br /> : ''}
																	{document.name}
																</span>
															)
														)}
													</>
												) : (
													'-'
												)}
											</Text>
										</>
									)}
								</div>
							</Col>
						</Row>

						<Row>
							<Col xs={12} sm={6} md={4}>
								<div className={styles.item}>
									<Text isExtraSmall>
										<strong>
											{m(
												'page.statusOverview.body.effectiveYearlyInterestRate',
												'global'
											)}
										</strong>
									</Text>
									<Text isExtraSmall>
										{applicationDetails?.contract?.effectiveInterest
											? formatMoneyValue(
													applicationDetails.contract.effectiveInterest,
													false
											  ) + ' %'
											: '-'}
									</Text>
								</div>
							</Col>

							<Col xs={12} sm={6} md={4}>
								<div className={styles.item}>
									<Text isExtraSmall>
										<strong>
											{m('page.statusOverview.body.monthlyRate', 'global')}
										</strong>
									</Text>
									<Text isExtraSmall>{calcMonthlyRate()}</Text>
								</div>
							</Col>

							<Col xs={12} sm={6} md={4}>
								<div className={styles.item}>
									<Text isExtraSmall>
										<strong>
											{m('page.statusOverview.body.hints', 'global')}
										</strong>
									</Text>
									<Text isExtraSmall>{applicationDetails?.info ?? '-'}</Text>
								</div>
							</Col>
						</Row>
					</div>

					<div className={styles.footer}>
						{(props.application.status === ApplicationStatusCode.APPROVED ||
							props.application.status === ApplicationStatusCode.IN_DECISION ||
							props.application.status === ApplicationStatusCode.ACCOUNTED) && (
							<>
								<div className={styles.footerItem}>
									<Button
										buttonStyle={ButtonStyle.LINK}
										buttonSize={ButtonSize.SMALL}
										onClick={async () => {
											await getApplicationsContract(
												'Kreditantrag.pdf',
												ContractType.CONTRACT_QES,
												() => {},
												() => {},
												props.application.id
											);
										}}
										prependIcon={<SvgEye color="#9E3667" />}
									>
										{m('page.statusOverview.footer.contractPreview', 'global')}
									</Button>
									{((storeShowPpi &&
										!!applicationDetails?.contract?.ppi?.premiumGross) ||
										(storeShowProtect &&
											!!applicationDetails?.contract?.protect1
												?.premiumGross)) && (
										<>
											<br />
											<Button
												buttonStyle={ButtonStyle.LINK}
												buttonSize={ButtonSize.SMALL}
												onClick={async () => {
													await getApplicationsContract(
														'Versicherungsvertrag.pdf',
														ContractType.INSURANCE_CONTRACT_QES,
														() => {},
														() => {},
														props.application.id
													);
												}}
												prependIcon={<SvgEye color="#9E3667" />}
											>
												{m('downloadInsuranceContract.label', 'fields')}
											</Button>
										</>
									)}
									<br />
									<Button
										buttonStyle={ButtonStyle.LINK}
										buttonSize={ButtonSize.SMALL}
										onClick={viewDocuments}
										prependIcon={<SvgDownload color="#9E3667" />}
									>
										{m('page.statusOverview.footer.viewDocuments', 'global')}
									</Button>
								</div>
							</>
						)}
						<div className={styles.footerItemSpacer} />
						<div className={styles.footerItem}>
							<div
								className={classNames([
									styles.footerItemInner,
									styles.contentRight
								])}
							>
								<Button
									buttonSize={ButtonSize.SMALL}
									onClick={editApplication}
									prependIcon={<SvgEdit color="#ffffff" />}
								>
									{m('page.statusOverview.footer.editApplication', 'global')}
								</Button>
							</div>
							{(props.application.status === ApplicationStatusCode.APPROVED ||
								props.application.status === ApplicationStatusCode.IN_DECISION ||
								props.application.status === ApplicationStatusCode.ACCOUNTED) && (
								<div
									className={classNames([
										styles.footerItemInner,
										styles.contentRight
									])}
								>
									<Button
										buttonSize={ButtonSize.SMALL}
										onClick={continueApplication}
										prependIcon={<SvgDocuments color="#ffffff" />}
									>
										{m(
											'page.statusOverview.footer.continueApplication',
											'global'
										)}
									</Button>
								</div>
							)}
						</div>
					</div>
				</>
			)}
			<Popup
				show={showAvailableDocumentsModal}
				close={() => {
					setShowAvailableDocumentsModal(false);
				}}
				size={PopupSize.SM}
			>
				<>
					<Title isSubtitle hasMarginBottom>
						{m('page.statusOverview.footer.availableDocuments', 'global')}
					</Title>
					{availableDocumentsLoading ? (
						<div className={styles.loading}>
							<Spinner noMargin />
						</div>
					) : (
						<div>
							{availableDocuments && availableDocuments.length > 0 ? (
								<>
									{availableDocuments.map((document, index) => (
										<ApplicationItemDocument
											key={'available-document-' + index}
											document={document}
										/>
									))}
								</>
							) : (
								<Text>
									{m('page.statusOverview.footer.noDocumentsAvailable', 'global')}
								</Text>
							)}
						</div>
					)}
				</>
			</Popup>
		</div>
	);
};
export default ApplicationItemBody;
