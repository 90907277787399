import React from 'react';

const Svg = () => {
	const width = 24;
	const height = 24;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
		</svg>
	);
};
export default Svg;
