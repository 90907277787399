import store from 'store/store';

import { reset as resetToken } from 'store/Token/Token';
import { reset as resetOptIn } from 'store/OptIn/OptIn';
import { reset as resetApplicant } from 'store/Applicant/Applicant';
import { reset as resetName } from 'store/Name/Name';
import { reset as resetBirthdate } from 'store/Birthdate/Birthdate';
import { reset as resetCustomer } from 'store/Customer/Customer';
import { reset as resetEmail } from 'store/Email/Email';
import { reset as resetPhone } from 'store/Phone/Phone';
import { reset as resetNationality } from 'store/Nationality/Nationality';
import { reset as resetAddress } from 'store/Address/Address';
import { reset as resetMaritalStatus } from 'store/MaritalStatus/MaritalStatus';
import { reset as resetOccupation } from 'store/Occupation/Occupation';
import { reset as resetFinancialStatus } from 'store/FinancialStatus/FinancialStatus';
import { reset as resetCredit } from 'store/Credit/Credit';
import { reset as resetBco } from 'store/Bco/Bco';
import { reset as resetContract } from 'store/Contract/Contract';
import { reset as resetPPI } from 'store/PPI/PPI';
import { reset as resetApplications } from 'store/Applications/Applications';
// import { setConditionProgram } from 'store/Company/Company';

export const resetApplicationStore = async () => {
	const resets = [
		resetToken,
		resetOptIn,
		resetApplicant,
		resetName,
		resetBirthdate,
		resetCustomer,
		resetEmail,
		resetPhone,
		resetNationality,
		resetAddress,
		resetMaritalStatus,
		resetOccupation,
		resetFinancialStatus,
		resetCredit,
		resetBco,
		resetContract,
		resetPPI,
		resetApplications
	];
	for (const reset of resets) {
		await store.dispatch(reset());
	}
	// store.dispatch(setConditionProgram(null));
};
