export const DEFAULT_INPUT = /^[0-9A-Za-zäöüÄÖÜß\-,@.+_!#$%&/=?^{|}~\s]+$/;

export const EMAIL = /^[0-9A-Za-z"\-@.+_!#$%&/=?^{|}~]+$/;

export const BIRTHDATE = /^[0-9]?[0-9]?[./]?[0-9]?[0-9]?[./]?[0-9]?[0-9]?[0-9]?[0-9]?$/;

export const DIGIT = /^\d*$/;

export const NUMBER = /^[0-9]\d*$/;

export const NUMBER_WITHOUT_ZERO = /^[1-9]\d*$/;

export const NUMBER_WITHOUT_LEADING_ZERO = /^[1-9][0-9]*$/;

export const NUMBER_WITH_DOT = /^[\d.]*$/;

export const NUMBER_WITH_COMMA = /^\d+(,)?(\d{1,2})?$/;

export const NUMBER_WITH_DOT_AND_COMMA = /^[\d.,]*$/;

export const DATE = /^(0[1-9]{1}|1[0-2]{1})\.\d{4}$/;

export const LETTERS_AND_NUMBERS = /^[0-9A-Za-zäöüÄÖÜß]+$/;

export const WORD_WITH_SPACE = /^[A-Za-zäöüÄÖÜß\s]+$/;

export const WORD_WITH_SPACE_AND_DASH = /^[A-Za-zäöüÄÖÜß\-\s]+$/;

export const WORD_WITH_SPACE_AND_DASH_AND_NUMBERS = /^[0-9A-Za-zäöüÄÖÜß\-\s]+$/;

export const WORD_WITH_SPACE_AND_DASH_AND_COMMA = /^[A-Za-zäöüÄÖÜß\-,\s]+$/;

export const WORD_WITH_SPACE_AND_DASH_AND_COMMA_AND_DOT = /^[A-Za-zäöüÄÖÜß\-,.\s]+$/;

export const WORD_WITHOUT_SPACE = /^[A-Za-zäöüÄÖÜß]+$/;

export const TIN = /^[0-9A-Za-zäöüÄÖÜß.\-/]+$/;

export const TIN_WITH_SPACE_AND_COMMA = /^[0-9A-Za-zäöüÄÖÜß.\-/,\s]+$/;

export const IBAN = /^(?:[A-Za-z]|$){2}(?:\d|$){2}(?:[a-zA-Z0-9\s]|$){11,35}$/;

export const DATE_INPUT = /^[0-9]?[0-9]?[./]?[0-9]?[0-9]?[./]?[0-9]?[0-9]?[0-9]?[0-9]?$/;
