import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Loading from './Loading.d';

const initialState: Loading = {
	isLoadingPage: false,
	isLoadingForm: false
};

export const slice = createSlice({
	name: 'Loading',
	initialState: initialState,
	reducers: {
		setIsLoadingPage: (state, action: PayloadAction<boolean>) => {
			state.isLoadingPage = action.payload;
		},
		setIsLoadingForm: (state, action: PayloadAction<boolean>) => {
			state.isLoadingForm = action.payload;
		},
		reset: () => initialState
	}
});

export const { setIsLoadingPage, setIsLoadingForm, reset } = slice.actions;
export default slice.reducer;
