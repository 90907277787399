import React, { useState } from 'react';
import classNames from 'classnames';

import SvgDropdownArrow from 'components/Atoms/SVG/SvgDropdownArrow';

import styles from './AccordionItem.module.scss';

type Props = {
	header: string | JSX.Element;
	body: string | JSX.Element;
	onOpen?: Function;
};

const AccordionItem = (props: Props) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const onClick = () => {
		if (!isOpen && props.onOpen) {
			props.onOpen();
		}
		setIsOpen(!isOpen);
	};

	return (
		<div className={classNames([styles.wrapper, isOpen ? styles.isOpen : null])}>
			<div className={styles.header} onClick={onClick}>
				<div className={styles.headerContent}>{props.header}</div>
				<div className={styles.headerArrow}>
					<SvgDropdownArrow color="#9E3667" />
				</div>
			</div>
			{isOpen && <div className={styles.body}>{props.body}</div>}
		</div>
	);
};
export default AccordionItem;
