import React from 'react';
import m from 'utils/messages/messages';
import { continueValid } from 'utils/tracking/tracking';
import { goToPage } from 'utils/pages/pages';

import Page from 'components/Organisms/Page/Page';
import { Row, Col } from 'components/Atoms/Grid';
import { Title } from 'components/Atoms/Text';
import SvgCheckCircle from 'components/Atoms/SVG/SvgCheckCircle';
import Button from 'components/Atoms/Button/Button';

import Subpage from 'types/enums/Subpage.d';

import styles from './UploadMissingDocumentsSuccess.module.scss';

const UploadMissingDocumentsSuccess = () => {
	const onFormSubmit = () => {
		continueValid();
		goToPage(Subpage.FINISH_PROCESS);
	};

	return (
		<Page>
			<Row justifyContent="center">
				<Col textAlign="center">
					<div className={styles.iconWrapper}>
						<SvgCheckCircle />
					</div>
					<Title>{m('page.uploadMissingDocumentsSuccess.title', 'global')}</Title>
					<div className={styles.buttonWrapper}>
						<Button onClick={onFormSubmit} tabindex={1} testId="submit">
							{m('next.label', 'fields')}
						</Button>
					</div>
				</Col>
			</Row>
		</Page>
	);
};
export default UploadMissingDocumentsSuccess;
