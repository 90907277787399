import GtmType from 'types/enums/GtmType.d';
import GtmSubtype from 'types/enums/GtmSubtype.d';
import Subpage from 'types/enums/Subpage.d';

import RouteItem from 'types/interfaces/RouteItem';

import Index from 'components/Pages/Index';
import Error404 from 'components/Pages/Error/Error404';
import LoginSuccess from 'components/Pages/LoginSuccess/LoginSuccess';
import StatusOverview from 'components/Pages/StatusOverview/StatusOverview';
import CreditDetails from 'components/Pages/CreditDetails/CreditDetails';
import CustomerData from 'components/Pages/CustomerData/CustomerData';
import CustomerData2DN from 'components/Pages/CustomerData/CustomerData2DN';
import ApplicationRejected from 'components/Pages/ApplicationRejected/ApplicationRejected';
import ApplicationError from 'components/Pages/ApplicationError/ApplicationError';
import ApplicationReceived from 'components/Pages/ApplicationReceived/ApplicationReceived';
import EmailSentToCustomer from 'components/Pages/EmailSentToCustomer/EmailSentToCustomer';
import UploadMissingDocuments from 'components/Pages/UploadMissingDocuments/UploadMissingDocuments';
import UploadMissingDocumentsSuccess from 'components/Pages/UploadMissingDocuments/UploadMissingDocumentsSuccess';
import FinishProcess from 'components/Pages/FinishProcess/FinishProcess';
import UploadContractDocuments from 'components/Pages/UploadContractDocuments/UploadContractDocuments';
import CreditDone from 'components/Pages/CreditDone/CreditDone';
import CreditDetailsEdit from 'components/Pages/CreditDetails/CreditDetailsEdit';
import SkipContract from 'components/Pages/SkipContract/SkipContract';

export const routes = (gtmType: GtmType, gtmSubtype: GtmSubtype) => {
	const routes: Array<RouteItem> = [
		{
			subpage: Subpage.INDEX,
			component: Index,
			hideBackLink: true
		},
		{
			subpage: Subpage.LOGIN_SUCCESS,
			component: LoginSuccess,
			gtmStep: 'login_success',
			gtmStatus: 'application_pending',
			hideBackLink: true
		},
		{
			subpage: Subpage.STATUS_OVERVIEW,
			component: StatusOverview,
			gtmStep: 'status_overview',
			gtmStatus: 'application_pending',
			hideBackLink: true
		},
		{
			subpage: Subpage.CREDIT_DETAILS,
			component: CreditDetails,
			gtmStep: 'credit_details',
			gtmStatus: 'application_pending'
		},
		{
			subpage: Subpage.CUSTOMER_DATA,
			component: CustomerData,
			gtmStep: 'customer_application_details',
			gtmStatus: 'application_pending'
		},
		{
			subpage: Subpage.CUSTOMER_DATA_2DN,
			component: CustomerData2DN,
			gtmStep: 'customer_two_application_details',
			gtmStatus: 'application_pending'
		},
		{
			subpage: Subpage.APPLICATION_REJECTED,
			component: ApplicationRejected,
			gtmStep: 'bco_response',
			gtmStatus: 'bco_response',
			gtmSubstatus: 'bco_red'
		},
		{
			subpage: Subpage.APPLICATION_ERROR,
			component: ApplicationError,
			gtmStep: 'bco_response',
			gtmStatus: 'application_error'
		},
		{
			subpage: Subpage.APPLICATION_RECEIVED,
			component: ApplicationReceived,
			gtmStep: 'bco_response',
			gtmStatus: 'bco_response',
			gtmSubstatus: 'bco_green'
		},
		{
			subpage: Subpage.EMAIL_SENT_TO_CUSTOMER,
			component: EmailSentToCustomer,
			gtmStep: 'customer_email_sent',
			gtmStatus: 'documents_pending'
		},
		{
			subpage: Subpage.UPLOAD_MISSING_DOCUMENTS,
			component: UploadMissingDocuments,
			gtmStep: 'advisor_upload_documents',
			gtmStatus: 'documents_pending'
		},
		{
			subpage: Subpage.UPLOAD_MISSING_DOCUMENTS_SUCCESS,
			component: UploadMissingDocumentsSuccess,
			gtmStep: 'advisor_upload_documents_success',
			gtmStatus: 'documents_uploaded'
		},
		{
			subpage: Subpage.FINISH_PROCESS,
			component: FinishProcess,
			gtmStep: 'advisor_process_choice',
			gtmStatus: 'legitimation_pending'
		},
		{
			subpage: Subpage.UPLOAD_CONTRACT_DOCUMENTS + '/:processOption',
			component: UploadContractDocuments,
			gtmStep: 'advisor_upload_contract_documents',
			gtmStatus: 'legitimation_pending'
		},
		{
			subpage: Subpage.CREDIT_DONE,
			component: CreditDone,
			gtmStep: 'credit_requested',
			gtmStatus: 'application_complete'
		},
		{
			subpage: Subpage.EDIT_CREDIT_DETAILS + '/:origin',
			component: CreditDetailsEdit,
			gtmStep: 'application_update',
			gtmStatus: 'application_pending',
			gtmSubstatus: 'post_bco'
		},
		{
			subpage: Subpage.SKIP_CONTRACT,
			component: SkipContract,
			gtmStep: 'thank_you_skip_contract',
			gtmStatus: 'bco_response'
		},
		{
			subpage: Subpage.ERROR,
			component: Error404,
			gtmStep: 'error_404',
			gtmStatus: 'application_error'
		}
	];

	for (const route of routes) {
		route.gtmType = gtmType;
		route.gtmSubtype = gtmSubtype;
	}

	return routes;
};
export default routes;
