import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Nationality from './Nationality.d';
import { isEmpty } from 'utils/validation/validation';

const initialState: Nationality = {
	nationality: 'DE',
	nationality2: 'DE'
};

export const slice = createSlice({
	name: 'Nationality',
	initialState: initialState,
	reducers: {
		setNationality: (state, action: PayloadAction<string>) => {
			state.nationality = !isEmpty(action.payload) ? action.payload : null;
		},
		setNationality2: (state, action: PayloadAction<string>) => {
			state.nationality2 = !isEmpty(action.payload) ? action.payload : null;
		},
		reset: () => initialState
	}
});

export const { setNationality, setNationality2, reset } = slice.actions;
export default slice.reducer;
