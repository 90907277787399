import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FinancialStatus from './FinancialStatus.d';
import TypeOfLiving from 'types/enums/TypeOfLiving.d';

const initialState: FinancialStatus = {
	iban: null,
	income: null,
	additionalIncome: null,
	livingCosts: null,
	childSupport: null,
	typeOfLiving: null,
	income2: null,
	additionalIncome2: null,
	livingCosts2: null,
	childSupport2: null,
	typeOfLiving2: null
};

export const slice = createSlice({
	name: 'FinancialStatus',
	initialState: initialState,
	reducers: {
		setIban: (state, action: PayloadAction<string>) => {
			state.iban = action.payload;
		},
		setIncome: (state, action: PayloadAction<number | string>) => {
			state.income =
				action.payload || action.payload === 0 ? parseInt(action.payload + '') : null;
		},
		setAdditionalIncome: (state, action: PayloadAction<number | string>) => {
			state.additionalIncome =
				action.payload || action.payload === 0 ? parseInt(action.payload + '') : null;
		},
		setLivingCosts: (state, action: PayloadAction<number | string>) => {
			state.livingCosts =
				action.payload || action.payload === 0 ? parseInt(action.payload + '') : null;
		},
		setChildSupport: (state, action: PayloadAction<number | string>) => {
			state.childSupport =
				action.payload || action.payload === 0 ? parseInt(action.payload + '') : null;
		},
		setTypeOfLiving: (state, action: PayloadAction<TypeOfLiving>) => {
			state.typeOfLiving = action.payload;
		},
		setIncome2: (state, action: PayloadAction<number | string>) => {
			state.income2 =
				action.payload || action.payload === 0 ? parseInt(action.payload + '') : null;
		},
		setAdditionalIncome2: (state, action: PayloadAction<number | string>) => {
			state.additionalIncome2 =
				action.payload || action.payload === 0 ? parseInt(action.payload + '') : null;
		},
		setLivingCosts2: (state, action: PayloadAction<number | string>) => {
			state.livingCosts2 =
				action.payload || action.payload === 0 ? parseInt(action.payload + '') : null;
		},
		setChildSupport2: (state, action: PayloadAction<number | string>) => {
			state.childSupport2 =
				action.payload || action.payload === 0 ? parseInt(action.payload + '') : null;
		},
		setTypeOfLiving2: (state, action: PayloadAction<TypeOfLiving>) => {
			state.typeOfLiving2 = action.payload;
		},
		reset: () => initialState
	}
});

export const {
	setIban,
	setIncome,
	setAdditionalIncome,
	setLivingCosts,
	setChildSupport,
	setTypeOfLiving,
	setIncome2,
	setAdditionalIncome2,
	setLivingCosts2,
	setChildSupport2,
	setTypeOfLiving2,
	reset
} = slice.actions;
export default slice.reducer;
