import MissingDocument from 'types/interfaces/MissingDocument.d';

export const hasMissingDocuments = (missingDocuments: Array<MissingDocument>): boolean => {
	let hasMissingsDocuments = false;
	if (missingDocuments && missingDocuments.length > 0) {
		for (const missingDocument of missingDocuments) {
			if (
				!missingDocument.file ||
				(missingDocument.file && missingDocument.file.length === 0)
			) {
				hasMissingsDocuments = true;
			}
		}
	}
	return hasMissingsDocuments;
};
