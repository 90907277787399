import React from 'react';
import m from 'utils/messages/messages';
import classNames from 'classnames';

import KoopLogo from 'components/Atoms/Navbar/KoopLogo/KoopLogo';
import NavbarName from 'components/Atoms/Navbar/NavbarName/NavbarName';

import styles from './NavbarTitle.module.scss';

type Props = {
	customExtension?: string;
};

const NavbarTitle = (props: Props) => {
	return (
		<h1 className={classNames([styles.title, styles.hasLine])}>
			<>
				<KoopLogo />
				<strong>{m('title', 'global')}</strong>
				<br />
			</>
			{!!props.customExtension || props.customExtension === '' ? (
				<>{props.customExtension}</>
			) : (
				<NavbarName />
			)}
		</h1>
	);
};
export default NavbarTitle;
