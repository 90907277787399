import React, { useMemo } from 'react';
import m from 'utils/messages/messages';
import { EMAIL, NUMBER_WITHOUT_LEADING_ZERO } from 'components/Atoms/Form/TextInput/utils/regex';
import { getCountryCodeItems } from 'utils/items/items';

import { Text } from 'components/Atoms/Text';
import { Col, Row } from 'components/Atoms/Grid';
import { DropdownInput, TextInput } from 'components/Atoms/Form';

import TextInputType from 'components/Atoms/Form/TextInput/types/TextInputType.d';

type Props = {
	email?: string;
	setEmail?: Function;
	emailError?: string;
	countryCode: string;
	setCountryCode: Function;
	countryCodeError?: string;
	mobileNumber: string;
	setMobileNumber: Function;
	mobileNumberError?: string;
};

const Contact = (props: Props) => {
	const countryCodeItems = getCountryCodeItems();

	const hasEmail = useMemo(() => {
		return !!props.setEmail;
	}, [props.setEmail]);

	return (
		<>
			<Text isSubtitle>{m('page.customerData.contact.title', 'global')}</Text>
			<Row>
				{hasEmail && (
					<Col xs={12}>
						<TextInput
							label={m('email.label', 'fields')}
							value={props.email}
							setValue={props.setEmail}
							regex={EMAIL}
							maxLength={50}
							hasError={!!props.emailError}
							message={props.emailError}
							tabindex={18}
							testId="email"
						/>
					</Col>
				)}
				<Col xs={4} sm={3}>
					<DropdownInput
						label={m('countryCode.label', 'fields')}
						value={props.countryCode}
						setValue={props.setCountryCode}
						items={countryCodeItems}
						hasError={!!props.countryCodeError}
						message={props.countryCodeError}
						tabindex={19}
						testId="country-code"
					/>
				</Col>
				<Col xs={8} sm={9}>
					<TextInput
						label={m('mobileNumber.label', 'fields')}
						value={props.mobileNumber}
						setValue={props.setMobileNumber}
						type={TextInputType.NUMBER}
						disabled={!props.countryCode}
						maxLength={12}
						regex={NUMBER_WITHOUT_LEADING_ZERO}
						hasError={!!props.mobileNumberError}
						message={
							props.mobileNumberError ?? props.countryCode === '49'
								? 'z.B. "17012345678"'
								: ''
						}
						tabindex={20}
						testId="mobile-phone"
					/>
				</Col>
			</Row>
		</>
	);
};
export default Contact;
