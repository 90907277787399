import { findIndex, clone } from 'lodash';
import moment from 'moment';
import store from 'store/store';
import {
	setErrorMessages,
	pushErrorMessage,
	setSuccessMessages,
	pushSuccessMessage
} from 'store/Form/Form';
import m from 'utils/messages/messages';
import { getLastDayOfMonth, getMaxDaysOfMonth } from 'utils/date/date';
import { getGermanMobileNumberPrefixes } from 'utils/items/items';
import { isIbanValid } from 'utils/iban/iban';

import FormMessage from 'types/interfaces/FormMessage.d';
import FormSuccessCode from 'types/enums/FormSuccessCode.d';
import FormErrorCode from 'types/enums/FormErrorCode.d';
import ExternalCredit from 'types/interfaces/ExternalCredit.d';
import { getSummedExternalCreditAmount } from 'utils/credit/credit';
import { amountMaxValue } from 'constants/Credit';
import { formatMoneyValue } from 'utils/numbers/numbers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

export const useValidationUtils = () => {
	const dispatch = useDispatch();
	const storeErrorMessages = useSelector((state: RootState) => state.Form.errorMessages);
	const storeSuccessMessages = useSelector((state: RootState) => state.Form.successMessages);

	const isEmpty = (value: any, zeroIsTrue = false) => {
		if (value === undefined || value === null) {
			return true;
		}
		if (typeof value === 'object') {
			return Object.keys(value).length === 0;
		} else {
			if (zeroIsTrue) {
				return value !== 0 && value !== '0' && (!value || (value && value.length < 1));
			} else {
				return !value || (value && value.length < 1);
			}
		}
	};

	const clear = () => {
		clearSuccesses();
		clearErrors();
	};

	const clearSuccesses = () => {
		if (storeSuccessMessages.length > 0) {
			dispatch(setSuccessMessages([]));
		}
	};

	const hasSuccessMessage = (code: string) => {
		const index = findIndex(storeSuccessMessages, (message: any) => {
			return message.code === code;
		});
		return index !== -1;
	};

	const addSuccessMessage = (code: FormErrorCode, successMessage: string | JSX.Element) => {
		if (!hasSuccessMessage(code)) {
			dispatch(pushSuccessMessage({ code: code, message: successMessage }));
		}
	};

	const clearErrors = () => {
		if (storeErrorMessages.length > 0) {
			dispatch(setErrorMessages([]));
		}
	};

	const hasErrorMessage = (code: string) => {
		const index = findIndex(storeErrorMessages, (message: any) => {
			return message.code === code;
		});
		return index !== -1;
	};

	const addErrorMessage = (code: FormErrorCode, errorMessage: string | JSX.Element) => {
		if (!hasErrorMessage(code)) {
			dispatch(pushErrorMessage({ code: code, message: errorMessage }));
		}
	};

	const hasEmptyFieldsError = (hasEmptyFields: boolean) => {
		if (hasEmptyFields) {
			addErrorMessage(
				FormErrorCode.EMPTY_FIELDS,
				m('validation.error.hasEmptyFields', 'global')
			);
			return true;
		} else {
			return false;
		}
	};

	const hasInvalidFieldsError = (hasInvalidFields: boolean) => {
		if (hasInvalidFields) {
			addErrorMessage(FormErrorCode.INVALID_FIELDS, m('validation.error.invalid', 'global'));
			return true;
		} else {
			return false;
		}
	};

	const showDefaultConnectionError = () => {
		addErrorMessage(FormErrorCode.API_CONNECTION, m('validation.error.connection', 'fields'));
	};

	const showDefaultMissingDataError = () => {
		addErrorMessage(
			FormErrorCode.API_MISSING_DATA,
			m('validation.error.missingData', 'fields')
		);
	};

	const focusFirstErrorInput = () => {
		const errorInputs: any = document.querySelectorAll('.input--error');
		if (errorInputs.length > 0) {
			errorInputs[0].focus();
		}
	};

	const hasDateDayError = (day: string, month: string): boolean => {
		const maxDays = getMaxDaysOfMonth(parseInt(month));
		const dayInt = parseInt(day);
		if (dayInt < 1 || day.length > 2 || dayInt > maxDays) {
			addErrorMessage(
				FormErrorCode.DAY,
				m('day.validation.invalid', 'fields', { maxDays: maxDays })
			);
			return true;
		} else {
			return false;
		}
	};

	const hasDateMonthError = (month: string): boolean => {
		const monthInt = parseInt(month);
		if (monthInt < 1 || month.length > 2 || monthInt > 12) {
			addErrorMessage(FormErrorCode.MONTH, m('month.validation.invalid', 'fields'));
			return true;
		} else {
			return false;
		}
	};

	const hasDateYearError = (year: string): boolean => {
		if (year.length !== 4) {
			addErrorMessage(FormErrorCode.YEAR, m('year.validation.invalid', 'fields'));
			return true;
		} else {
			return false;
		}
	};

	const isYearLessThan1900Error = (year: string): boolean => {
		if (parseInt(year) < 1900) {
			addErrorMessage(
				FormErrorCode.YEAR_LESS_THAN_1900,
				m('birthdate.validation.notYearLessThan1900', 'fields')
			);
			return true;
		} else {
			return false;
		}
	};

	const isDateUnder18Error = (day: string, month: string, year: string): boolean => {
		const birthdate = moment(
			year +
				'-' +
				(month.length === 1 ? '0' + month : month) +
				'-' +
				(day.length === 1 ? '0' + day : day),
			'YYYY-MM-DD'
		);
		const years = moment().diff(birthdate, 'years');
		if (years < 18) {
			addErrorMessage(FormErrorCode.UNDER_18, m('birthdate.validation.notOver18', 'fields'));
			return true;
		} else {
			return false;
		}
	};

	const isBirthdateValid = (birthdate: string, setBirthdateError: Function): boolean => {
		let isValidBirthdate = true;
		const birthdateArray = birthdate.split('.');
		if (
			birthdateArray.length === 3 &&
			birthdateArray[0].length === 2 &&
			birthdateArray[1].length === 2 &&
			birthdateArray[2].length === 4
		) {
			const hasDayErr = hasDateDayError(birthdateArray[0], birthdateArray[1]);
			const hasMonthErr = hasDateMonthError(birthdateArray[1]);
			const hasYearErr = hasDateYearError(birthdateArray[2]);
			if (hasDayErr || hasMonthErr || hasYearErr) {
				isValidBirthdate = false;
			} else {
				const isUnder18 = isDateUnder18Error(
					birthdateArray[0],
					birthdateArray[1],
					birthdateArray[2]
				);
				if (isUnder18) {
					isValidBirthdate = false;
				} else {
					const yearLessThan1900 = isYearLessThan1900Error(birthdateArray[2]);
					if (yearLessThan1900) {
						isValidBirthdate = false;
					}
				}
			}
		} else {
			addErrorMessage(
				FormErrorCode.BIRTHDATE_INVALID,
				m('birthdate.validation.invalid', 'fields')
			);
			isValidBirthdate = false;
		}

		if (!isValidBirthdate) {
			setBirthdateError(m('validation.error.invalid', 'fields'));
		}

		return isValidBirthdate;
	};

	const hasEmailError = (email: string): boolean => {
		const emailRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!emailRegex.test(email)) {
			addErrorMessage(FormErrorCode.EMAIL, m('email.validation.invalid', 'fields'));
			return true;
		} else {
			return false;
		}
	};

	const hasMobileNumberTooShortError = (mobileNumber: string): boolean => {
		if (mobileNumber.trim().length < 5) {
			addErrorMessage(
				FormErrorCode.MOBILE_NUMBER_TOO_SHORT,
				m('mobileNumber.validation.tooShort', 'fields')
			);
			return true;
		} else {
			return false;
		}
	};

	const hasSameMobileNumberError = (
		countryCode1: number,
		mobileNumber1: number,
		countryCode2: number,
		mobileNumber2: number
	): boolean => {
		if (countryCode1 === countryCode2 && mobileNumber1 === mobileNumber2) {
			addErrorMessage(
				FormErrorCode.HAS_SAME_MOBILE_NUMBER,
				m('mobileNumber.validation.hasSameMobileNumber', 'fields')
			);
			return true;
		} else {
			return false;
		}
	};

	const hasGermanPhonePrefixError = (countryCode: string, mobileNumber: string) => {
		if (countryCode === '49') {
			const germanMobileNumberPrefixes = getGermanMobileNumberPrefixes();
			let hasGermanPhonePrefix = false;
			for (const prefix of germanMobileNumberPrefixes) {
				if (mobileNumber.startsWith(prefix)) {
					hasGermanPhonePrefix = true;
					break;
				}
			}
			if (!hasGermanPhonePrefix) {
				addErrorMessage(
					FormErrorCode.MOBILE_NUMBER_NO_GERMAN_PREFIX,
					m('mobileNumber.validation.noGermanPrefix', 'fields')
				);
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const isMobileNumberValid = (
		countryCode: string,
		mobileNumber: string,
		setMobileNumberError: Function
	) => {
		const hasMobileNumberTooShortErr = hasMobileNumberTooShortError(mobileNumber);
		const hasGermanPhonePrefixErr = hasGermanPhonePrefixError(countryCode, mobileNumber);
		const isValidMobileNumber = !hasMobileNumberTooShortErr && !hasGermanPhonePrefixErr;
		setMobileNumberError(
			!isValidMobileNumber ? (m('validation.error.invalid', 'fields') as string) : null
		);
		return isValidMobileNumber;
	};

	const hasOccupationSinceError = (
		occupationMonth: string,
		occupationYear: string,
		day: string,
		month: string,
		year: string
	) => {
		const currentDate = moment();
		const occupationDate = moment(occupationYear + '-' + occupationMonth + '-01', 'YYYY-MM-DD');
		const birthdate =
			year && month && day
				? moment(parseInt(year) + 16 + '-' + month + '-' + day, 'YYYY-MM-DD')
				: moment(moment().year() - 80 + '-01-01', 'YYYY-MM-DD');
		if (occupationDate.isAfter(currentDate)) {
			addErrorMessage(
				FormErrorCode.OCCUPATION_START_IN_THE_FUTURE,
				m('occupation.since.startInTheFuture', 'fields')
			);
			return true;
		} else if (birthdate.isAfter(occupationDate)) {
			addErrorMessage(
				FormErrorCode.OCCUPATION_START_BEFORE_BIRTHDATE,
				m('occupation.since.startBeforeBirthdate', 'fields')
			);
			return true;
		} else {
			return false;
		}
	};

	const hasOccupationUntilError = (
		occupationMonth: string,
		occupationYear: string,
		occupationLimitedMonth: string,
		occupationLimitedYear: string
	) => {
		const currentDate = moment();
		const occupationDate = moment(occupationYear + '-' + occupationMonth + '-01', 'YYYY-MM-DD');
		const occupationLimitedDate = moment(
			occupationLimitedYear + '-' + occupationLimitedMonth + '-01',
			'YYYY-MM-DD'
		);
		const occupationLimitedDateEnd = moment(
			occupationLimitedYear +
				'-' +
				occupationLimitedMonth +
				'-' +
				getLastDayOfMonth(occupationLimitedYear, occupationLimitedMonth),
			'YYYY-MM-DD'
		);

		if (occupationLimitedDateEnd.isBefore(currentDate)) {
			addErrorMessage(
				FormErrorCode.OCCUPATION_LIMITED_START_IN_THE_PAST,
				m('occupation.until.startInThePast', 'fields')
			);
			return true;
		} else if (occupationLimitedDate.isBefore(occupationDate)) {
			addErrorMessage(
				FormErrorCode.OCCUPATION_LIMITED_START_BEFORE_OCCUPATION_START,
				m('occupation.until.startBeforeOccupationStart', 'fields')
			);
			return true;
		} else {
			return false;
		}
	};

	const hasIbanError = (iban: string) => {
		const isValid = isIbanValid(iban);
		if (!isValid) {
			addErrorMessage(FormErrorCode.IBAN, m('iban.validation.invalid', 'fields'));
			return true;
		} else {
			return false;
		}
	};

	return {
		isEmpty,
		clear,
		clearSuccesses,
		hasSuccessMessage,
		addSuccessMessage,
		clearErrors,
		hasErrorMessage,
		addErrorMessage,
		hasEmptyFieldsError,
		hasInvalidFieldsError,
		showDefaultConnectionError,
		showDefaultMissingDataError,
		focusFirstErrorInput,
		hasDateDayError,
		hasDateMonthError,
		hasDateYearError,
		isBirthdateValid,
		hasEmailError,
		isMobileNumberValid,
		hasSameMobileNumberError,
		hasOccupationSinceError,
		hasOccupationUntilError,
		hasIbanError
	};
};

export const isEmpty = (value?: string) => {
	return !value || (value && value.length < 1);
};

export const clear = () => {
	clearSuccesses();
	clearErrors();
};

export const clearSuccesses = () => {
	if (store.getState().Form.successMessages.length > 0) {
		store.dispatch(setSuccessMessages([]));
	}
};

export const hasSuccessMessage = (code: FormSuccessCode) => {
	const index = findIndex(store.getState().Form.successMessages, (message: FormMessage) => {
		return message.code === code;
	});
	return index !== -1;
};

export const addSuccessMessage = (code: FormSuccessCode, successMessage: string | JSX.Element) => {
	if (!hasSuccessMessage(code)) {
		const messages: Array<FormMessage> = clone(store.getState().Form.successMessages);
		messages.push({ code: code, message: successMessage });
		store.dispatch(setSuccessMessages(messages));
	}
};

export const clearErrors = () => {
	if (store.getState().Form.errorMessages.length > 0) {
		store.dispatch(setErrorMessages([]));
	}
};

export const hasErrorMessage = (code: FormErrorCode) => {
	const index = findIndex(store.getState().Form.errorMessages, (message: FormMessage) => {
		return message.code === code;
	});
	return index !== -1;
};

export const addErrorMessage = (code: FormErrorCode, errorMessage: string | JSX.Element) => {
	if (!hasErrorMessage(code)) {
		const messages: Array<FormMessage> = clone(store.getState().Form.errorMessages);
		messages.push({ code: code, message: errorMessage });
		store.dispatch(setErrorMessages(messages));
	}
};

export const showApiConnectionError = () => {
	addErrorMessage(FormErrorCode.API_CONNECTION, m('api.error.connection', 'global'));
};

export const showApiMissingDataError = () => {
	addErrorMessage(FormErrorCode.API_MISSING_DATA, m('api.error.missingData', 'global'));
};

export const showApiMobileNotVerifiedError = () => {
	addErrorMessage(
		FormErrorCode.API_MOBILE_NOT_VERIFIED,
		m('api.registration.error.mobileNotVerified', 'global')
	);
};

export const hasEmptyFieldsError = (hasEmptyFields: boolean): boolean => {
	if (hasEmptyFields) {
		addErrorMessage(FormErrorCode.EMPTY_FIELDS, m('validation.error.hasEmptyFields', 'global'));
		return true;
	} else {
		return false;
	}
};

export const hasDateDayError = (day: string, month: string): boolean => {
	const maxDays = getMaxDaysOfMonth(parseInt(month));
	const dayInt = parseInt(day);
	if (dayInt < 1 || day.length > 2 || dayInt > maxDays) {
		addErrorMessage(
			FormErrorCode.DAY,
			m('day.validation.invalid', 'fields', { maxDays: maxDays }) as string
		);
		return true;
	} else {
		return false;
	}
};

export const hasDateMonthError = (month: string): boolean => {
	const monthInt = parseInt(month);
	if (monthInt < 1 || month.length > 2 || monthInt > 12) {
		console.log('TEST');
		addErrorMessage(FormErrorCode.MONTH, m('month.validation.invalid', 'fields') as string);
		return true;
	} else {
		return false;
	}
};

export const hasDateYearError = (year: string): boolean => {
	if (year.length !== 4) {
		addErrorMessage(FormErrorCode.YEAR, m('year.validation.invalid', 'fields') as string);
		return true;
	} else {
		return false;
	}
};

export const isYearLessThan1900Error = (year: string): boolean => {
	if (parseInt(year) < 1900) {
		addErrorMessage(
			FormErrorCode.YEAR_LESS_THAN_1900,
			m('birthdate.validation.notYearLessThan1900', 'fields')
		);
		return true;
	} else {
		return false;
	}
};

export const isDateUnder18Error = (day: string, month: string, year: string): boolean => {
	const birthdate = moment(
		year +
			'-' +
			(month.length === 1 ? '0' + month : month) +
			'-' +
			(day.length === 1 ? '0' + day : day),
		'YYYY-MM-DD'
	);
	const years = moment().diff(birthdate, 'years');
	if (years < 18) {
		addErrorMessage(FormErrorCode.UNDER_18, m('birthdate.validation.notOver18', 'fields'));
		return true;
	} else {
		return false;
	}
};

export const isBirthdateValid = (birthdate: string, setBirthdateError: Function) => {
	let isValidBirthdate = true;
	const birthdateArray = birthdate.split('.');
	if (
		birthdateArray.length === 3 &&
		birthdateArray[0].length === 2 &&
		birthdateArray[1].length === 2 &&
		birthdateArray[2].length === 4
	) {
		const hasDayErr = hasDateDayError(birthdateArray[0], birthdateArray[1]);
		const hasMonthErr = hasDateMonthError(birthdateArray[1]);
		const hasYearErr = hasDateYearError(birthdateArray[2]);
		if (hasDayErr || hasMonthErr || hasYearErr) {
			isValidBirthdate = false;
		} else {
			const isUnder18 = isDateUnder18Error(
				birthdateArray[0],
				birthdateArray[1],
				birthdateArray[2]
			);
			if (isUnder18) {
				isValidBirthdate = false;
			} else {
				const yearLessThan1900 = isYearLessThan1900Error(birthdateArray[2]);
				if (yearLessThan1900) {
					isValidBirthdate = false;
				}
			}
		}
	} else {
		addErrorMessage(
			FormErrorCode.BIRTHDATE_INVALID,
			m('birthdate.validation.invalid', 'fields')
		);
		isValidBirthdate = false;
	}

	if (!isValidBirthdate) {
		setBirthdateError(m('validation.error.invalid', 'fields'));
	}

	return isValidBirthdate;
};

export const hasEmailError = (email: string): boolean => {
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!emailRegex.test(email)) {
		addErrorMessage(FormErrorCode.EMAIL, m('email.validation.invalid', 'fields'));
		return true;
	} else {
		return false;
	}
};

export const showEmailError = () => {
	addErrorMessage(FormErrorCode.EMAIL, m('email.validation.invalid', 'fields'));
};

export const hasMobileNumberTooShortError = (mobileNumber: string): boolean => {
	if (mobileNumber.trim().length < 5) {
		addErrorMessage(
			FormErrorCode.MOBILE_NUMBER_TOO_SHORT,
			m('mobileNumber.validation.tooShort', 'fields')
		);
		return true;
	} else {
		return false;
	}
};

export const hasSameMobileNumberError = (
	countryCode1: number,
	mobileNumber1: number,
	countryCode2: number,
	mobileNumber2: number
): boolean => {
	if (countryCode1 === countryCode2 && mobileNumber1 === mobileNumber2) {
		addErrorMessage(
			FormErrorCode.HAS_SAME_MOBILE_NUMBER,
			m('mobileNumber.validation.hasSameMobileNumber', 'fields')
		);
		return true;
	} else {
		return false;
	}
};

export const hasGermanPhonePrefixError = (countryCode: string, mobileNumber: string): boolean => {
	if (countryCode === '49') {
		const germanMobileNumberPrefixes = getGermanMobileNumberPrefixes();
		let hasGermanPhonePrefix = false;
		for (const prefix of germanMobileNumberPrefixes) {
			if (mobileNumber.startsWith(prefix)) {
				hasGermanPhonePrefix = true;
				break;
			}
		}
		if (!hasGermanPhonePrefix) {
			addErrorMessage(
				FormErrorCode.MOBILE_NUMBER_NO_GERMAN_PREFIX,
				m('mobileNumber.validation.noGermanPrefix', 'fields')
			);
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const isMobileNumberValid = (
	countryCode: number,
	mobileNumber: number,
	setMobileNumberError: Function
) => {
	const hasMobileNumberTooShortErr = hasMobileNumberTooShortError(mobileNumber + '');
	const hasGermanPhonePrefixErr = hasGermanPhonePrefixError(countryCode + '', mobileNumber + '');
	const isValidMobileNumber = !hasMobileNumberTooShortErr && !hasGermanPhonePrefixErr;
	setMobileNumberError(!isValidMobileNumber);
	return isValidMobileNumber;
};

export const hasOccupationMonthError = (occupationMonth: string): boolean => {
	const monthInt = parseInt(occupationMonth);
	if (monthInt < 1 || occupationMonth.length > 2 || monthInt > 12) {
		addErrorMessage(
			FormErrorCode.OCCUPATION_MONTH,
			m('occupation.month.validation.invalid', 'fields')
		);
		return true;
	} else {
		return false;
	}
};

export const hasOccupationYearError = (occupationYear: string): boolean => {
	if (occupationYear.length !== 4) {
		addErrorMessage(
			FormErrorCode.OCCUPATION_YEAR,
			m('occupation.year.validation.invalid', 'fields')
		);
		return true;
	} else {
		return false;
	}
};

export const hasOccupationDateError = (
	occupationMonth: string,
	occupationYear: string,
	day: string,
	month: string,
	year: string
): boolean => {
	const currentDate = moment();
	const occupationDate = moment(occupationYear + '-' + occupationMonth + '-01', 'YYYY-MM-DD');
	const birthdate =
		year && month && day
			? moment(parseInt(year) + 16 + '-' + month + '-' + day, 'YYYY-MM-DD')
			: moment(moment().year() - 80 + '-01-01', 'YYYY-MM-DD');

	if (occupationDate.isAfter(currentDate)) {
		addErrorMessage(
			FormErrorCode.OCCUPATION_START_IN_THE_FUTURE,
			m('occupation.date.validation.startInTheFuture', 'fields')
		);
		return true;
	} else if (birthdate.isAfter(occupationDate)) {
		addErrorMessage(
			FormErrorCode.OCCUPATION_START_BEFORE_BIRTHDATE,
			m('occupation.date.validation.startBeforeBirthdate', 'fields')
		);
		return true;
	} else {
		return false;
	}
};

export const hasOccupationLimitedMonthError = (occupationLimitedMonth: string): boolean => {
	const monthInt = parseInt(occupationLimitedMonth);
	if (monthInt < 1 || occupationLimitedMonth.length > 2 || monthInt > 12) {
		addErrorMessage(
			FormErrorCode.OCCUPATION_LIMITED_MONTH,
			m('occupation.limited.month.validation.invalid', 'fields')
		);
		return true;
	} else {
		return false;
	}
};

export const hasOccupationLimitedYearError = (occupationLimitedYear: string): boolean => {
	if (occupationLimitedYear.length !== 4) {
		addErrorMessage(
			FormErrorCode.OCCUPATION_LIMITED_YEAR,
			m('occupation.limited.year.validation.invalid', 'fields')
		);
		return true;
	} else {
		return false;
	}
};

export const hasOccupationLimitedDateError = (
	occupationMonth: string,
	occupationYear: string,
	occupationLimitedMonth: string,
	occupationLimitedYear: string
): boolean => {
	const currentDate = moment();
	const occupationDate = moment(occupationYear + '-' + occupationMonth + '-01', 'YYYY-MM-DD');
	const occupationLimitedDate = moment(
		occupationLimitedYear + '-' + occupationLimitedMonth + '-01',
		'YYYY-MM-DD'
	);
	const occupationLimitedDateEnd = moment(
		occupationLimitedYear +
			'-' +
			occupationLimitedMonth +
			'-' +
			getLastDayOfMonth(occupationLimitedYear, occupationLimitedMonth),
		'YYYY-MM-DD'
	);

	if (occupationLimitedDateEnd.isBefore(currentDate)) {
		addErrorMessage(
			FormErrorCode.OCCUPATION_LIMITED_START_IN_THE_PAST,
			m('occupation.limited.date.validation.startInThePast', 'fields')
		);
		return true;
	} else if (occupationLimitedDate.isBefore(occupationDate)) {
		addErrorMessage(
			FormErrorCode.OCCUPATION_LIMITED_START_BEFORE_OCCUPATION_START,
			m('occupation.limited.date.validation.startBeforeOccupationStart', 'fields')
		);
		return true;
	} else {
		return false;
	}
};

export const isOccupationValid = (
	day: string,
	month: string,
	year: string,
	occupationMonth: string,
	occupationYear: string,
	occupationLimited: boolean,
	occupationLimitedMonth: string,
	occupationLimitedYear: string,
	setOccupationMonthError: Function,
	setOccupationYearError: Function,
	setOccupationLimitedMonthError: Function,
	setOccupationLimitedYearError: Function
) => {
	const hasOccupationMonthErr = hasOccupationMonthError(occupationMonth);
	const hasOccupationYearErr = hasOccupationYearError(occupationYear);
	const hasOccupationDateErr = hasOccupationDateError(
		occupationMonth,
		occupationYear,
		day,
		month,
		year
	);

	setOccupationMonthError(hasOccupationMonthErr || hasOccupationDateErr);
	setOccupationYearError(hasOccupationYearErr || hasOccupationDateErr);

	if (occupationLimited) {
		const hasOccupationLimitedMonthErr = hasOccupationLimitedMonthError(occupationLimitedMonth);
		const hasOccupationLimitedYearErr = hasOccupationLimitedYearError(occupationLimitedYear);
		const hasOccupationLimitedDateErr = hasOccupationLimitedDateError(
			occupationMonth,
			occupationYear,
			occupationLimitedMonth,
			occupationLimitedYear
		);

		setOccupationLimitedMonthError(hasOccupationLimitedMonthErr || hasOccupationLimitedDateErr);
		setOccupationLimitedYearError(hasOccupationLimitedYearErr || hasOccupationLimitedDateErr);

		return (
			!hasOccupationMonthErr &&
			!hasOccupationYearErr &&
			!hasOccupationDateErr &&
			!hasOccupationLimitedMonthErr &&
			!hasOccupationLimitedYearErr &&
			!hasOccupationLimitedDateErr
		);
	} else {
		return !hasOccupationMonthErr && !hasOccupationYearErr && !hasOccupationDateErr;
	}
};

export const hasIbanError = (iban: string): boolean => {
	if (!isIbanValid(iban)) {
		addErrorMessage(FormErrorCode.IBAN, m('iban.validation.invalid', 'fields'));
		return true;
	} else {
		return false;
	}
};

export const hasExternalCreditsError = (externalCredits: Array<ExternalCredit>): boolean => {
	const ibanErrNumbers: Array<number> = getExternalCreditsErrorNumbers(externalCredits);
	if (ibanErrNumbers.length > 0) {
		let errorMessage: string = '';
		let index: number = 0;
		for (const ibanErrNumber of ibanErrNumbers) {
			errorMessage +=
				(index > 0 ? '<br />' : '') +
				(ibanErrNumber + 1) +
				'. ' +
				m('iban.validation.invalid', 'fields');
			index++;
		}
		addErrorMessage(FormErrorCode.IBAN, errorMessage);
		return true;
	} else {
		return false;
	}
};

export const getExternalCreditsErrorNumbers = (
	externalCredits: Array<ExternalCredit>
): Array<number> => {
	const ibanErrNumbers: Array<number> = [];
	let counter: number = 1;
	for (const externalCredit of externalCredits) {
		if (!isIbanValid(externalCredit.iban)) {
			ibanErrNumbers.push(counter - 1);
		}
		counter++;
	}
	return ibanErrNumbers;
};

export const hasAmountTooHighError = (
	amount: number,
	externalCredits: Array<ExternalCredit>
): boolean => {
	const totalAmount = amount + getSummedExternalCreditAmount(externalCredits);
	if (totalAmount > amountMaxValue) {
		addErrorMessage(
			FormErrorCode.AMOUNT_TOO_HIGH,
			m('amount.validation.tooHigh', 'fields', {
				amount: formatMoneyValue(amountMaxValue, false, true)
			})
		);
		return true;
	} else {
		return false;
	}
};
