import moment from 'moment';

export const getMaxDaysOfMonth = (month: number) => {
	let maxDays = 31;
	if (month === 2) {
		maxDays = 29;
	} else if (month === 4 || month === 6 || month === 9 || month === 11) {
		maxDays = 30;
	}
	return maxDays;
};

export const getLastDayOfMonth = (year: string, month: string) => {
	const intDate: any = new Date(parseInt(year), parseInt(month), 1);
	const date: any = new Date(intDate - 1);
	return date.getDate();
};

export const formatFullDateForApi = (date: string) => {
	if (date) {
		const dateReplaced = formatFullDate(date);
		const dateArray = dateReplaced.split('.');
		return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
	} else {
		return undefined;
	}
};

export const formatDateForApi = (
	year?: string,
	month?: string,
	day?: string
): string | undefined => {
	if (month && year) {
		let date = year + '-';
		let formattedMonth = month;
		if (formattedMonth.length === 1) {
			formattedMonth = '0' + formattedMonth;
		}

		date += formattedMonth;

		if (day) {
			let formattedDay = day;
			if (formattedDay.length === 1) {
				formattedDay = '0' + formattedDay;
			}
			date += '-' + formattedDay;
		}
		return date;
	} else {
		return undefined;
	}
};

export const formatDateFromApi = (date?: string): any => {
	if (date) {
		const dateArray = date.trim().split('-');
		if (dateArray.length === 3) {
			return {
				day: dateArray[2],
				month: dateArray[1],
				year: dateArray[0]
			};
		} else {
			return null;
		}
	} else {
		return null;
	}
};

export const formatYearMonthFromApi = (date?: string): any => {
	if (date) {
		const dateArray = date.trim().split('-');
		if (dateArray.length === 2) {
			return {
				month: dateArray[1],
				year: dateArray[0]
			};
		} else {
			return null;
		}
	} else {
		return null;
	}
};

export const getAge = (year: string, month: string, day: string) => {
	const birthdate = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD');
	return moment().diff(birthdate, 'years');
};

export const getAgeWithRuntime = (
	year: string,
	month: string,
	day: string,
	runtimeYears: number
): number => {
	const birthdate = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD');
	return moment().diff(birthdate, 'years') + runtimeYears;
};

export const formatFullDate = (date: string) => {
	if (date) {
		let dateReplaced = date.replace(/\//g, '.');
		dateReplaced = dateReplaced.replace(/-/g, '.');
		return dateReplaced;
	} else {
		return undefined;
	}
};

export const formatDateForInput = (event: any, prevValue: any, ref: any, successCb: Function) => {
	const valueBeforeChange = event.target.value;
	const cursorPosition = event.target.selectionStart;

	let formattedValue = valueBeforeChange;

	if (valueBeforeChange && valueBeforeChange.length > 0) {
		if (prevValue && prevValue > valueBeforeChange) {
			// Skip space on Backspace
			if (formattedValue.charAt(cursorPosition - 1) === '.') {
				formattedValue = formattedValue.slice(0, -1).slice(0, -1);
			}
		} else {
			// set . automatically
			formattedValue = formatFullDate(formattedValue);
			if (formattedValue.length === 2 || formattedValue.length === 5) {
				formattedValue += '.';
			}
		}
	}

	successCb(formattedValue);
};
