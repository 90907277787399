export enum MaritalStatus {
	SINGLE = 'SINGLE',
	MARRIED = 'MARRIED',
	PARTNERED = 'PARTNERED',
	SEPARATED = 'SEPARATED',
	DIVORCED = 'DIVORCED',
	WIDOWED = 'WIDOWED',
	OTHER = 'OTHER'
}
export default MaritalStatus;
