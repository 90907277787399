export enum Subpage {
	INDEX = ' ',
	LOGIN_SUCCESS = 'login-erfolgreich',
	STATUS_OVERVIEW = 'status-uebersicht',
	CREDIT_DETAILS = 'kreditdetails',
	EDIT_CREDIT_DETAILS = 'betrag-bearbeiten',
	CUSTOMER_DATA = 'kundendaten',
	CUSTOMER_DATA_2DN = 'kundendaten-2dn',
	APPLICATION_REJECTED = 'antrag-abgelehnt',
	APPLICATION_ERROR = 'antrag-fehlgeschlagen',
	APPLICATION_RECEIVED = 'antrag-empfangen',
	EMAIL_SENT_TO_CUSTOMER = 'kundenmail-versendet',
	UPLOAD_MISSING_DOCUMENTS = 'fehlende-dokumente-hochladen',
	UPLOAD_MISSING_DOCUMENTS_SUCCESS = 'dokumente-erfolgreich-hochgeladen',
	FINISH_PROCESS = 'abschluss-prozess',
	UPLOAD_CONTRACT_DOCUMENTS = 'vertrags-dokumente-hochladen',
	CREDIT_DONE = 'kredit-beantragt',
	SKIP_CONTRACT = 'dankeschoen',
	ERROR = 'error'
}
export default Subpage;
