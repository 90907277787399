import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Address from './Address.d';
import { isEmpty } from 'utils/validation/validation';

const initialState: Address = {
	postalCode: null,
	city: null,
	street: null,
	streetNumber: null,
	hasPreviousAddress: false,
	previousPostalCode: null,
	previousCity: null,
	previousStreet: null,
	previousStreetNumber: null,
	postalCode2: null,
	city2: null,
	street2: null,
	streetNumber2: null,
	hasPreviousAddress2: false,
	previousPostalCode2: null,
	previousCity2: null,
	previousStreet2: null,
	previousStreetNumber2: null
};

export const slice = createSlice({
	name: 'Address',
	initialState: initialState,
	reducers: {
		setPostalCode: (state, action: PayloadAction<string>) => {
			state.postalCode = !isEmpty(action.payload) ? action.payload : null;
		},
		setCity: (state, action: PayloadAction<string>) => {
			state.city = !isEmpty(action.payload) ? action.payload : null;
		},
		setStreet: (state, action: PayloadAction<string>) => {
			state.street = !isEmpty(action.payload) ? action.payload : null;
		},
		setStreetNumber: (state, action: PayloadAction<string>) => {
			state.streetNumber = !isEmpty(action.payload) ? action.payload : null;
		},
		setHasPreviousAddress: (state, action: PayloadAction<boolean>) => {
			state.hasPreviousAddress = action.payload;
		},
		setPreviousPostalCode: (state, action: PayloadAction<string>) => {
			state.previousPostalCode = !isEmpty(action.payload) ? action.payload : null;
		},
		setPreviousCity: (state, action: PayloadAction<string>) => {
			state.previousCity = !isEmpty(action.payload) ? action.payload : null;
		},
		setPreviousStreet: (state, action: PayloadAction<string>) => {
			state.previousStreet = !isEmpty(action.payload) ? action.payload : null;
		},
		setPreviousStreetNumber: (state, action: PayloadAction<string>) => {
			state.previousStreetNumber = !isEmpty(action.payload) ? action.payload : null;
		},
		setPostalCode2: (state, action: PayloadAction<string>) => {
			state.postalCode2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setCity2: (state, action: PayloadAction<string>) => {
			state.city2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setStreet2: (state, action: PayloadAction<string>) => {
			state.street2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setStreetNumber2: (state, action: PayloadAction<string>) => {
			state.streetNumber2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setHasPreviousAddress2: (state, action: PayloadAction<boolean>) => {
			state.hasPreviousAddress2 = action.payload;
		},
		setPreviousPostalCode2: (state, action: PayloadAction<string>) => {
			state.previousPostalCode2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setPreviousCity2: (state, action: PayloadAction<string>) => {
			state.previousCity2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setPreviousStreet2: (state, action: PayloadAction<string>) => {
			state.previousStreet2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setPreviousStreetNumber2: (state, action: PayloadAction<string>) => {
			state.previousStreetNumber2 = !isEmpty(action.payload) ? action.payload : null;
		},
		reset: () => initialState
	}
});

export const {
	setPostalCode,
	setCity,
	setStreet,
	setStreetNumber,
	setHasPreviousAddress,
	setPreviousPostalCode,
	setPreviousCity,
	setPreviousStreet,
	setPreviousStreetNumber,
	setPostalCode2,
	setCity2,
	setStreet2,
	setStreetNumber2,
	setHasPreviousAddress2,
	setPreviousPostalCode2,
	setPreviousCity2,
	setPreviousStreet2,
	setPreviousStreetNumber2,
	reset
} = slice.actions;
export default slice.reducer;
