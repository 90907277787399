import React from 'react';

type Props = {
	color?: string;
};

const SvgCustomerCircle = (props: Props) => {
	const width = 30;
	const height = 30;
	const defaultColor = '#000000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm8.9,26V21.62h0a5.8,5.8,0,0,0-2.36-4.37,7.91,7.91,0,0,1-12.88,0,5.77,5.77,0,0,0-2.36,4.37h0V26.1A14.12,14.12,0,1,1,23.9,26Z"
			/>
			<circle
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				cx="15.09"
				cy="12.63"
				r="6.19"
			/>
		</svg>
	);
};
export default SvgCustomerCircle;
