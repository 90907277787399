import React, { useEffect } from 'react';
// import { loadData } from '../utils/pageLoader';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

const LoadData = () => {
	const storeCurrentSubpage = useSelector((state: RootState) => state.Page.currentSubpage);

	useEffect(() => {
		// loadData();
	}, [storeCurrentSubpage]);

	return <></>;
};
export default LoadData;
