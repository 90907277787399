import React from 'react';

type Props = {
	color?: string;
};

const Svg = (props: Props) => {
	const width = 12;
	const height = 12;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : '#000000',
					fillRule: 'evenodd'
				}}
				d="m0,3.44c0-.47.38-.86.85-.86,0,0,0,0,0,0,.23,0,.45.09.61.25l4.53,4.53,4.53-4.53c.34-.34.88-.34,1.22,0,.34.33.34.88,0,1.21,0,0,0,0,0,0l-5.14,5.13c-.33.34-.88.34-1.21,0,0,0,0,0,0,0L.25,4.04C.09,3.88,0,3.66,0,3.44Z"
			/>
		</svg>
	);
};
export default Svg;
