import React, { useEffect } from 'react';
import classNames from 'classnames';
import { toggleBodyScroll } from 'utils/scroll/scroll';

import SvgCross from 'components/Atoms/SVG/SvgCross';
import Button from 'components/Atoms/Button/Button';

import PopupSize from './types/PopupSize.d';

import styles from './Popup.module.scss';

type Props = {
	show?: boolean;
	close: Function;
	size: PopupSize;
	footerButtonText?: string | JSX.Element;
	onFooterButtonClick?: Function;
	children: string | JSX.Element;
};

const Popup = (props: Props) => {
	useEffect(() => {
		return () => {
			toggleBodyScroll(false);
		};
	}, []);

	useEffect(() => {
		toggleBodyScroll(props.show);
	}, [props.show]);

	return (
		<div
			className={classNames(styles.popup, props.show ? styles.active : null, {
				[styles['size-' + props.size]]: props.size
			})}
		>
			<div
				className={styles.closeLayer}
				onClick={() => {
					props.close();
				}}
			/>
			<div
				className={classNames(
					styles.box,
					props.footerButtonText && props.onFooterButtonClick
						? styles.hasFooterButton
						: null
				)}
			>
				{props.size === PopupSize.LG && (
					<button
						className={styles.closeIcon}
						onClick={() => {
							props.close();
						}}
					>
						<SvgCross />
					</button>
				)}
				<div className={styles.content}>{props.children}</div>
				{props.footerButtonText && props.onFooterButtonClick && (
					<div className={styles.footerButton}>
						<Button
							onClick={() => {
								props.onFooterButtonClick();
								props.close();
							}}
						>
							{props.footerButtonText}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
export default Popup;
