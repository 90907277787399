import React from 'react';

type Props = {
	color?: string;
};

const SvgPlus = (props: Props) => {
	const width = 24;
	const height = 24;
	const defaultColor = '#6F7779';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M12.6 11.4V6.343a.6.6 0 00-1.2 0V11.4H6.344a.599.599 0 100 1.2h5.058v5.057a.599.599 0 101.199 0V12.6h5.058a.595.595 0 00.599-.6.6.6 0 00-.6-.6H12.6z"
			/>
		</svg>
	);
};
export default SvgPlus;
