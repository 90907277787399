export enum Occupation {
	EMPLOYED = 'EMPLOYED',
	PENSIONER = 'PENSIONER',
	OFFICER = 'OFFICER',
	STUDENT = 'STUDENT',
	UNEMPLOYED = 'UNEMPLOYED',
	FREELANCER = 'FREELANCER',
	CIVIL_SERVICE_EMPLOYEE = 'CIVIL_SERVICE_EMPLOYEE'
}
export default Occupation;
