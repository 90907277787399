import i18next from 'i18next';

export const m = (
	messagePath: string,
	currentFlow: string,
	replacementObject: object = null,
	convertToJsx: boolean = false
) => {
	const message = i18next.t(currentFlow + ':' + messagePath, replacementObject);
	if (convertToJsx) {
		return <span dangerouslySetInnerHTML={{ __html: message }} />;
	} else {
		return message;
	}
};
export default m;
