import React from 'react';

type Props = {
	color?: string;
};

const SvgPlusCircle = (props: Props) => {
	const width = 26;
	const height = 26;
	const defaultColor = '#000000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<g transform="translate(1 1)" fill="none" fillRule="evenodd">
				<circle cx={12} cy={12} r={12} stroke={props.color ? props.color : defaultColor} />
				<path
					d="M12.001 7c.368 0 .667.298.667.667l-.002 3.666 3.667.001c.335 0 .612.247.66.569L17 12a.667.667 0 01-.667.667l-3.667-.002v3.667a.668.668 0 01-.569.66L12 17a.667.667 0 01-.667-.667l.001-3.667H7.667a.668.668 0 01-.66-.569L7 12c0-.368.299-.667.667-.667h3.666l.001-3.666c0-.334.247-.611.569-.659z"
					fill={props.color ? props.color : defaultColor}
				/>
			</g>
		</svg>
	);
};
export default SvgPlusCircle;
