import React, { useEffect } from 'react';
import m from 'utils/messages/messages';
import { formatMoneyValue } from 'utils/numbers/numbers';
import { getPpiMonthlyRate } from 'utils/credit/credit';

import { Text } from 'components/Atoms/Text';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { setHasPpi } from 'store/PPI/PPI';
import { RadioButtonInput } from 'components/Atoms/Form';

const PPI = () => {
	const dispatch = useDispatch();
	const storeHasPpi = useSelector((state: RootState) => state.PPI.hasPpi);

	const ppiMonthlyRate = getPpiMonthlyRate();

	useEffect(() => {
		setTimeout(() => {
			if (storeHasPpi === null) {
				dispatch(setHasPpi(true));
			}
		}, 1);
	}, []);

	const setValue = (value: string) => {
		if (value === 'death') {
			dispatch(setHasPpi(true));
		} else if (value === 'noPpi') {
			dispatch(setHasPpi(false));
		} else {
			dispatch(setHasPpi(null));
		}
	};

	const getValue = () => {
		if (storeHasPpi === true) {
			return 'death';
		} else if (storeHasPpi === false) {
			return 'noPpi';
		} else {
			return null;
		}
	};

	const ppiItems = [
		{
			text: m('page.customerData.ppi.ppi.body.death.label', 'global', null, true),
			textExtension:
				formatMoneyValue(ppiMonthlyRate, true) + ' €/' + m('month.label', 'fields'),
			value: 'death'
		},
		{
			text: m('page.customerData.ppi.ppi.body.noPpi.label', 'global'),
			value: 'noPpi'
		}
	];

	return (
		<>
			<Text isBlack hasMarginBottom>
				{m('page.customerData.ppi.ppi.body.title', 'global')}:
			</Text>
			<RadioButtonInput
				value={getValue()}
				setValue={(value: string) => {
					setValue(value);
				}}
				items={ppiItems}
				tabindex={40}
				testId="ppi"
			/>
			<Text isExtraSmall>{m('page.customerData.ppi.ppi.body.hint1', 'global')}</Text>
		</>
	);
};
export default PPI;
