import React, { useEffect } from 'react';
import { goToPage } from 'utils/pages/pages';

import Subpage from 'types/enums/Subpage.d';

const Index = () => {
	useEffect(() => {
		goToPage(Subpage.STATUS_OVERVIEW);
	}, []);

	return <></>;
};

export default Index;
