import React from 'react';

const SvgLoadingSpinner = () => {
	const width = 50;
	const height = 50;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<circle style={{ fill: '#ffffff' }} cx="25" cy="25" r="25" />
			<circle
				style={{ fill: 'none', stroke: '#deedf2', strokeWidth: '3px' }}
				cx="25"
				cy="25"
				r="18"
			/>
			<path
				style={{ fill: '#1bb3be', fillRule: 'evenodd' }}
				d="M25,44.5A19.5,19.5,0,1,0,5.5,25a1.5,1.5,0,0,0,3,0A16.5,16.5,0,1,1,25,41.5a1.5,1.5,0,0,0,0,3Z"
			/>
		</svg>
	);
};
export default SvgLoadingSpinner;
