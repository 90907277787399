import React, { useMemo, useRef } from 'react';
import { getOccupationItems } from 'utils/items/items';

import OccupationType from 'types/enums/Occupation.d';
import { Text } from 'components/Atoms/Text';
import m from 'utils/messages/messages';
import { Col, Row } from 'components/Atoms/Grid';
import { DropdownInput, RadioButtonInput, TextInput } from 'components/Atoms/Form';
import { DIGIT } from 'components/Atoms/Form/TextInput/utils/regex';

type Props = {
	occupation: OccupationType;
	setOccupation: Function;
	occupationError?: string;
	employer: string;
	setEmployer: Function;
	employerError?: string;
	occupationMonth: string;
	setOccupationMonth: Function;
	occupationMonthError?: string;
	occupationYear: string;
	setOccupationYear: Function;
	occupationYearError?: string;
	occupationLimited: string;
	setOccupationLimited: Function;
	occupationLimitedMonth: string;
	setOccupationLimitedMonth: Function;
	occupationLimitedMonthError?: string;
	occupationLimitedYear: string;
	setOccupationLimitedYear: Function;
	occupationLimitedYearError?: string;
};

const Occupation = (props: Props) => {
	const occupationItems = getOccupationItems();

	const occupationYearRef = useRef<HTMLInputElement>(null);
	const occupationLimitedYearRef = useRef<HTMLInputElement>(null);

	const showEmployer = useMemo(() => {
		return (
			props.occupation === OccupationType.EMPLOYED ||
			props.occupation === OccupationType.OFFICER ||
			props.occupation === OccupationType.CIVIL_SERVICE_EMPLOYEE
		);
	}, [props.occupation]);

	const occupationLimitedItems = [
		{ text: m('occupation.limited.limited', 'fields'), value: 'Befristet' },
		{ text: m('occupation.limited.unlimited', 'fields'), value: 'Unbefristet' }
	];

	const showOccupationUntil = props.occupationLimited === 'Befristet';

	return (
		<>
			<Text isSubtitle>{m('page.customerData.occupation.title', 'global')}</Text>
			<Row>
				<Col xs={12}>
					<DropdownInput
						label={m('occupation.label', 'fields')}
						value={props.occupation}
						setValue={props.setOccupation}
						items={occupationItems}
						hasError={!!props.occupationError}
						message={props.occupationError}
						tabindex={28}
						testId="occupation"
					/>
				</Col>
				<Col xs={12} sm={6}>
					<TextInput
						nextRefOnMaxLength={occupationYearRef}
						label={m('occupation.month.label', 'fields')}
						value={props.occupationMonth}
						setValue={props.setOccupationMonth}
						regex={DIGIT}
						maxLength={2}
						hasError={!!props.occupationMonthError}
						message={props.occupationMonthError ?? 'MM'}
						tabindex={29}
						testId="occupation-month"
					/>
				</Col>
				<Col xs={12} sm={6}>
					<TextInput
						ref={occupationYearRef}
						label={m('occupation.year.label', 'fields')}
						value={props.occupationYear}
						setValue={props.setOccupationYear}
						regex={DIGIT}
						maxLength={4}
						hasError={!!props.occupationYearError}
						message={props.occupationYearError ?? 'JJJJ'}
						tabindex={30}
						testId="occupation-year"
					/>
				</Col>
				<Col xs={12}>
					<RadioButtonInput
						label={m('page.customerData.occupation.isLimited', 'global')}
						value={props.occupationLimited}
						setValue={props.setOccupationLimited}
						items={occupationLimitedItems}
						btnStyle="switch"
						tabindex={31}
						testId="occupation-limited"
					/>
				</Col>
			</Row>
			{showOccupationUntil && (
				<>
					<Text isSubtitle>
						{m('page.customerData.occupation.limited.title', 'global')}
					</Text>
					<Row>
						<Col xs={12} sm={6}>
							<TextInput
								nextRefOnMaxLength={occupationLimitedYearRef}
								label={m('occupation.limited.month.label', 'fields')}
								value={props.occupationLimitedMonth}
								setValue={props.setOccupationLimitedMonth}
								regex={DIGIT}
								maxLength={2}
								hasError={!!props.occupationLimitedMonthError}
								message={props.occupationLimitedMonthError ?? 'MM'}
								tabindex={32}
								testId="occupation-limited-month"
							/>
						</Col>
						<Col xs={12} sm={6}>
							<TextInput
								ref={occupationLimitedYearRef}
								label={m('occupation.limited.year.label', 'fields')}
								value={props.occupationLimitedYear}
								setValue={props.setOccupationLimitedYear}
								regex={DIGIT}
								maxLength={4}
								hasError={!!props.occupationLimitedYearError}
								message={props.occupationLimitedYearError ?? 'JJJJ'}
								tabindex={33}
								testId="occupation-limited-year"
							/>
						</Col>
					</Row>
				</>
			)}
			{showEmployer && (
				<Row>
					<Col xs={12}>
						<TextInput
							label={m('occupation.employer.label', 'fields')}
							value={props.employer}
							setValue={props.setEmployer}
							maxLength={30}
							hasError={!!props.employerError}
							message={props.employerError}
							tabindex={32}
							testId="employer-name"
						/>
					</Col>
				</Row>
			)}
		</>
	);
};
export default Occupation;
