import i18n from 'i18next';

import global from 'messages/global';
import fields from 'messages/fields';

i18n.init({
	fallbackLng: 'de',
	debug: process.env.NODE_ENV === 'development',
	ns: ['global', 'fields'],
	defaultNS: 'global',
	resources: {
		de: {
			global: global,
			fields: fields
		}
	}
});

export default i18n;
