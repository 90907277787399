import React from 'react';
import m from 'utils/messages/messages';
import { getApplicationsContract } from 'api';

import Button from 'components/Atoms/Button/Button';
import SvgDownload from 'components/Atoms/SVG/SvgDownload';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import ButtonStyle from 'components/Atoms/Button/types/ButtonStyle.d';
import ContractType from 'types/enums/ContractType.d';

import styles from './DownloadContracts.module.scss';

const DownloadContracts = () => {
	const storeShowPpi = useSelector((state: RootState) => state.Company.showPpi);
	const storeShowProtect = useSelector((state: RootState) => state.Company.showProtect);
	const storePpiAmount = useSelector((state: RootState) => state.Bco.ppiAmount);
	const storeProtectAmount = useSelector((state: RootState) => state.Bco.protectAmount);
	return (
		<div className={styles.wrapper}>
			<Button
				buttonStyle={ButtonStyle.LINK}
				onClick={async () => {
					await getApplicationsContract('Kreditantrag.pdf', ContractType.CONTRACT_QES);
				}}
				testId="download-contract"
				appendIcon={<SvgDownload />}
			>
				{m('downloadContract.label', 'fields')}
			</Button>
			{((storeShowPpi && !!storePpiAmount) || (storeShowProtect && !!storeProtectAmount)) && (
				<Button
					buttonStyle={ButtonStyle.LINK}
					onClick={async () => {
						await getApplicationsContract(
							'Versicherungsvertrag.pdf',
							ContractType.INSURANCE_CONTRACT_QES
						);
					}}
					testId="download-insurance-contract"
					appendIcon={<SvgDownload />}
				>
					{m('downloadInsuranceContract.label', 'fields')}
				</Button>
			)}
		</div>
	);
};

export default DownloadContracts;
