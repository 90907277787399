import React from 'react';
import classNames from 'classnames';

import styles from './Divider.module.scss';

type Props = {
	onlyMobile?: boolean;
	noMarginTop?: boolean;
	noMarginBottom?: boolean;
	noLine?: boolean;
};

const Divider = (props: Props) => {
	return (
		<div
			className={classNames([
				styles.divider,
				props.onlyMobile ? styles.dividerMobile : null,
				props.noMarginTop ? styles.noMarginTop : null,
				props.noMarginBottom ? styles.noMarginBottom : null,
				props.noLine ? styles.noLine : null
			])}
		/>
	);
};
export default Divider;
