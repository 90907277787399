import React from 'react';

type Props = {
	color?: string;
};

const SvgLogoMobile = (props: Props) => {
	const width = 35;
	const height = 33.46;
	const defaultColor = '#ec0000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M24.08,11.84,18.6,2.31A7.63,7.63,0,0,1,17.73,0L17.5.4A7.64,7.64,0,0,0,17.5,8l4.38,7.63a7.64,7.64,0,0,1,0,7.63l-.22.4a7.9,7.9,0,0,0-.87-2.31L14.21,9.94a7.9,7.9,0,0,1-.87-2.31l-.22.4a7.66,7.66,0,0,0,0,7.6l4.4,7.66a7.64,7.64,0,0,1,0,7.63l-.23.4A7.63,7.63,0,0,0,16.4,29l-5.48-9.54a7.59,7.59,0,0,1-1-4.14C4.05,16.87,0,20.14,0,23.92c0,5.27,7.84,9.54,17.5,9.54S35,29.19,35,23.92c0-3.78-4.05-7-9.91-8.59A7.77,7.77,0,0,0,24.08,11.84Z"
			/>
		</svg>
	);
};
export default SvgLogoMobile;
