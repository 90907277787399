import React from 'react';
import m from 'utils/messages/messages';

import { Title, Text } from 'components/Atoms/Text';

import styles from './SkipContract.module.scss';

const SkipContract = () => {
	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.header} />
				<div className={styles.content}>
					<Title hasMarginBottom>{m('page.skipContract.title', 'global')}</Title>
					<Text hasMarginBottom>
						{m('page.skipContract.subtitle', 'global', null, true)}
					</Text>
					<Text>{m('page.skipContract.hotlineDescription', 'global', null, true)}</Text>
				</div>
			</div>
		</>
	);
};

export default SkipContract;
