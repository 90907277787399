import React, { useState } from 'react';

import SvgInfoCircle from 'components/Atoms/SVG/SvgInfoCircle';
import Popup from 'components/Molecules/Overlay/Popup/Popup';

import PopupSize from 'components/Molecules/Overlay/Popup/types/PopupSize.d';

type Props = {
	children: string | JSX.Element;
};

const PopupIcon = (props: Props) => {
	const [isOpenPopup, setIsOpenPopup] = useState(false);

	const openPopup = (event: any) => {
		event.stopPropagation();
		setIsOpenPopup(true);
	};

	return (
		<>
			<div onClick={openPopup}>
				<SvgInfoCircle />
			</div>
			<Popup
				show={isOpenPopup}
				close={() => {
					setIsOpenPopup(false);
				}}
				size={PopupSize.LG}
			>
				{props.children}
			</Popup>
		</>
	);
};
export default PopupIcon;
