import React from 'react';

type Props = {
	color?: string;
};

const SvgArrowRight = (props: Props) => {
	const width = 12;
	const height = 25;
	const defaultColor = '#9e3667';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M1,0a1,1,0,0,1,.74.34l10,11.48a1.06,1.06,0,0,1,0,1.36l-10,11.48a1,1,0,0,1-1.4.08A1,1,0,0,1,.25,23.3L9.67,12.5.25,1.7A1,1,0,0,1,.33.26,1,1,0,0,1,1,0Z"
			/>
		</svg>
	);
};
export default SvgArrowRight;
