import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Bco from './Bco.d';
import BcoResult from 'types/interfaces/BcoResult';
import { isEmpty } from 'utils/validation/validation';

const initialState: Bco = {
	applicationId: null,
	applicationCapsId: null,
	netAmount: 0,
	totalAmount: 0,
	runtime: 0,
	monthlyRate: 0,
	effectiveYearlyInterestRate: 0,
	nominalInterestRate: 0,
	firstRateDate: null,
	interest: 0,
	ppiAmount: 0,
	protect: null,
	protectAmount: 0,
	protectRuntime: 0,
	skipContract: false
};

export const slice = createSlice({
	name: 'Bco',
	initialState: initialState,
	reducers: {
		setApplicationId: (state, action: PayloadAction<string>) => {
			state.applicationId = !isEmpty(action.payload) ? action.payload : null;
		},
		setApplicationCapsId: (state, action: PayloadAction<string>) => {
			state.applicationCapsId = !isEmpty(action.payload) ? action.payload : null;
		},
		setNetAmount: (state, action: PayloadAction<number>) => {
			state.netAmount = action.payload;
		},
		setTotalAmount: (state, action: PayloadAction<number>) => {
			state.totalAmount = action.payload;
		},
		setRuntime: (state, action: PayloadAction<number>) => {
			state.runtime = action.payload;
		},
		setMonthlyRate: (state, action: PayloadAction<number>) => {
			state.monthlyRate = action.payload;
		},
		setEffectiveYearlyInterestRate: (state, action: PayloadAction<number>) => {
			state.effectiveYearlyInterestRate = action.payload;
		},
		setNominalInterestRate: (state, action: PayloadAction<number>) => {
			state.nominalInterestRate = action.payload;
		},
		setFirstRateDate: (state, action: PayloadAction<Date>) => {
			state.firstRateDate = action.payload;
		},
		setInterest: (state, action: PayloadAction<number>) => {
			state.interest = action.payload;
		},
		setPpiAmount: (state, action: PayloadAction<number>) => {
			state.ppiAmount = action.payload;
		},
		setProtect: (state, action: PayloadAction<string>) => {
			state.protect = action.payload;
		},
		setProtectAmount: (state, action: PayloadAction<number>) => {
			state.protectAmount = action.payload;
		},
		setProtectRuntime: (state, action: PayloadAction<number>) => {
			state.protectRuntime = action.payload;
		},
		setSkipContract: (state, action: PayloadAction<boolean>) => {
			state.skipContract = action.payload;
		},
		setBco: (state, action: PayloadAction<BcoResult>) => {
			state.applicationId = action.payload?.id ? action.payload.id : state.applicationId;
			state.applicationCapsId = action.payload?.applicationId ?? null;
			state.netAmount = action.payload?.netAmount ?? 0;
			state.totalAmount = action.payload?.totalAmount ?? 0;
			state.runtime = action.payload?.runtime ?? 0;
			state.monthlyRate = action.payload?.monthlyRate ?? 0;
			state.effectiveYearlyInterestRate = action.payload?.effectiveYearlyInterestRate ?? 0;
			state.nominalInterestRate = action.payload?.nominalInterestRate ?? 0;
			state.firstRateDate = action.payload?.firstRateDate ?? null;
			state.interest = action.payload?.interest ?? 0;
			state.ppiAmount = action.payload?.ppiAmount ?? 0;
			state.protect = action.payload?.protect ?? null;
			state.protectAmount = action.payload?.protectAmount ?? 0;
			state.protectRuntime = action.payload?.protectRuntime ?? 0;
			state.skipContract = action.payload?.skipContract ?? false;
		},
		reset: () => initialState
	}
});

export const {
	setApplicationId,
	setApplicationCapsId,
	setNetAmount,
	setTotalAmount,
	setRuntime,
	setMonthlyRate,
	setEffectiveYearlyInterestRate,
	setNominalInterestRate,
	setFirstRateDate,
	setInterest,
	setPpiAmount,
	setProtect,
	setProtectAmount,
	setProtectRuntime,
	setBco,
	setSkipContract,
	reset
} = slice.actions;
export default slice.reducer;
