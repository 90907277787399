import React from 'react';
import classNames from 'classnames';

import styles from './Row.module.scss';

type Props = {
	justifyContent?: string;
	noWrap?: boolean;
	flexColumnMobile?: boolean;
	children: any;
};

const Row = (props: Props) => {
	const classes = classNames(
		styles.row,
		{
			[styles['justify-content-' + props.justifyContent]]: props.justifyContent
		},
		props.noWrap ? styles.noWrap : null,
		props.flexColumnMobile ? styles.flexColumnMobile : null
	);

	return <div className={classes}>{props.children}</div>;
};
export default Row;
