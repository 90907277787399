import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PPI from './PPI.d';
import Protect from 'types/enums/Protect.d';

const initialState: PPI = {
	showPpi: false,
	hasPpi: null,
	protect: null,
	protectRuntime: null,
	protectMonthlyRate: null
};

export const slice = createSlice({
	name: 'PPI',
	initialState: initialState,
	reducers: {
		setShowPpi: (state, action: PayloadAction<boolean>) => {
			state.showPpi = action.payload;
		},
		setHasPpi: (state, action: PayloadAction<boolean>) => {
			state.hasPpi = action.payload;
		},
		setProtect: (state, action: PayloadAction<Protect>) => {
			state.protect = action.payload;
		},
		setProtectRuntime: (state, action: PayloadAction<number>) => {
			state.protectRuntime = action.payload;
		},
		setProtectMonthlyRate: (state, action: PayloadAction<number>) => {
			state.protectMonthlyRate = action.payload;
		},
		reset: () => initialState
	}
});

export const {
	setShowPpi,
	setHasPpi,
	setProtect,
	setProtectRuntime,
	setProtectMonthlyRate,
	reset
} = slice.actions;
export default slice.reducer;
