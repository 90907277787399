import { isEmpty } from 'lodash';
import store from 'store/store';
import {
	amountDefault,
	amountMaxValue,
	// ppiMinSeniorAge,
	runtimeDefault,
	runtimeSteps
} from 'constants/Credit';

import { roundUp } from 'utils/numbers/numbers';
// import { getAgeWithRuntime } from 'utils/date/date';

import ExternalCredit from 'types/interfaces/ExternalCredit.d';
import Protect from 'types/enums/Protect.d';

export const getMonthlyRate = (
	amount?: number,
	runtime?: number,
	summedExternalCredits?: number
) => {
	if (amount !== undefined && runtime !== undefined && summedExternalCredits !== undefined) {
		if (store.getState().PPI.hasPpi || store.getState().PPI.protect) {
			return (amount + summedExternalCredits) / getNormalizedRuntime(runtime);
		} else {
			const jsonData = getMonthlyRateJsonData(amount, runtime, summedExternalCredits);
			return jsonData ? jsonData[jsonData.length - 1] : 0;
		}
	} else {
		if (store.getState().PPI.hasPpi || store.getState().PPI.protect) {
			return totalAmount() / getNormalizedRuntime(summedRuntime());
		} else {
			const jsonData = getMonthlyRateJsonData();
			return jsonData ? jsonData[jsonData.length - 1] : 0;
		}
	}
};

export const getNormalizedRuntime = (runtime: number) => {
	let normalizedRuntime: number;
	const index: number = runtimeSteps.findIndex((defaultRuntime) => {
		return runtime < defaultRuntime;
	});

	if (index === -1) {
		normalizedRuntime = runtimeSteps[runtimeSteps.length - 1];
	} else if (index === 0) {
		normalizedRuntime = runtimeSteps[0];
	} else {
		normalizedRuntime = runtimeSteps[index - 1];
	}

	return normalizedRuntime;
};

export const getNormalizedAmount = (amount: number) => {
	return roundUp(amount, 500, amountMaxValue);
};

export const getJsonData = () => {
	return store.getState().Credit.ccbData.creditValues
		? store.getState().Credit.ccbData.creditValues
		: {};
};

export const getMonthlyRateJsonData = (
	amount?: number,
	runtime?: number,
	summedExternalCredits?: number
) => {
	const jsonData = getJsonData();
	if (!isEmpty(jsonData)) {
		let normalizedAmountString;
		let normalizedRuntimeString;
		if (amount !== undefined && runtime !== undefined && summedExternalCredits !== undefined) {
			normalizedAmountString = getNormalizedAmount(amount + summedExternalCredits).toString();
			normalizedRuntimeString = getNormalizedRuntime(runtime).toString();
		} else {
			normalizedAmountString = getNormalizedAmount(
				summedNetAmount() +
					(store.getState().PPI.hasPpi ? getPpiValue() : 0) +
					getProtectValue()
			).toString();
			normalizedRuntimeString = getNormalizedRuntime(summedRuntime()).toString();
		}
		try {
			return jsonData[normalizedAmountString][normalizedRuntimeString];
		} catch (e) {
			return null;
		}
	} else {
		return null;
	}
};

export const getSummedExternalCreditAmount = (
	customExternalCredits: Array<ExternalCredit> = null
) => {
	const externalCredits: Array<ExternalCredit> = customExternalCredits
		? customExternalCredits
		: store.getState().Credit.externalCredits;
	let summedRemainingBalance: number = 0;
	for (const externalCredit of externalCredits) {
		summedRemainingBalance += externalCredit.remainingBalance
			? externalCredit.remainingBalance
			: 0;
	}
	return summedRemainingBalance;
};

export const summedNetAmount = () => {
	const amount = store.getState().Credit.amount ?? amountDefault;
	return amount + getSummedExternalCreditAmount();
};

export const summedRuntime = () => {
	const runtime = store.getState().Credit.runtime ?? runtimeDefault;
	return runtime ?? 0;
};

export const totalAmount = () => {
	return (
		summedNetAmount() +
		(store.getState().PPI.hasPpi ? getPpiValue() : 0) +
		getProtectValue() +
		getInterest()
	);
};

export const mapCurrentRuntimeToYears = () => {
	const runtime = store.getState().Credit.runtime ?? runtimeDefault;
	return Math.ceil(runtime / 12);
};

export const getPpiValue = () => {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const jsonData = require('data/ppi.json');
	const amount = getNormalizedAmount(summedNetAmount() + getProtectValue()).toString();
	// const state = store.getState();
	/*const ageWithRuntime = getAgeWithRuntime(
		state.Birthdate.year,
		(state.Birthdate.month.length === 1 ? '0' : '') + state.Birthdate.month,
		(state.Birthdate.day.length === 1 ? '0' : '') + state.Birthdate.day,
		mapCurrentRuntimeToYears()
	);*/
	/*if (ageWithRuntime >= ppiMinSeniorAge) {
		return jsonData[amount][1];
	} else {
		return jsonData[amount][0];
	}*/
	return jsonData[amount][0];
};

export const getPpiMonthlyRate = () => {
	const runtime = summedRuntime();
	return getPpiValue() / runtime;
};

export const getProtectValues = () => {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const jsonData = require('data/protect.json');
	if (store.getState().PPI.protectMonthlyRate && store.getState().PPI.protectRuntime) {
		return jsonData[store.getState().PPI.protectMonthlyRate + ''][
			store.getState().PPI.protectRuntime + ''
		];
	} else {
		return null;
	}
};

export const getProtectMonthlyRate = (protectValue: number) => {
	return protectValue
		? protectValue /
				(store.getState().Credit.runtime ? store.getState().Credit.runtime : runtimeDefault)
		: 0;
};

export const getProtectValue = () => {
	const protectValues = getProtectValues();
	const selectedProtect = store.getState().PPI.protect;
	let protectValue = 0;
	if (selectedProtect === Protect.STANDARD) {
		protectValue = protectValues[1];
	} else if (selectedProtect === Protect.PREMIUM) {
		protectValue = protectValues[2];
	} else if (selectedProtect === Protect.BASIC) {
		protectValue = protectValues[0];
	}
	return protectValue;
};

export const getInterest = () => {
	try {
		const jsonData = getMonthlyRateJsonData();
		return jsonData[2];
	} catch (e) {
		return 0;
	}
};

export const getEffectiveYearlyInterestRate = () => {
	try {
		const jsonData = getMonthlyRateJsonData();
		return jsonData[0];
	} catch (e) {
		return 0;
	}
};
