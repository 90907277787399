const messages = {
	validation: {
		error: {
			required: 'Dieses Feld ist erforderlich.',
			invalid: 'Die Eingabe ist nicht valide.'
		}
	},
	yes: 'Ja',
	no: 'Nein',
	next: {
		label: 'Weiter'
	},
	prev: {
		label: 'Zurück'
	},
	pleaseSelect: {
		label: 'Bitte wählen'
	},
	salutation: {
		label: 'Anrede',
		for: 'für {{salutation}} {{firstName}} {{lastName}}',
		items: {
			male: 'Herr',
			maleExt: 'Herrn',
			female: 'Frau'
		}
	},
	firstName: {
		label: 'Vorname'
	},
	lastName: {
		label: 'Nachname'
	},
	birthdate: {
		label: 'Geburtsdatum',
		validation: {
			notOver18:
				'Bitte beachten Sie, dass der Kunde den Kredit nur beantragen kann, wenn er volljährig ist.',
			notYearLessThan1900: 'Die Eingabe für das Jahr muss über 1900 liegen.',
			invalid: 'Bitte geben Sie ein valides Geburtsdatum im Format TT.MM.JJJJ an.'
		}
	},
	day: {
		label: 'Tag',
		validation: {
			invalid: 'Die Eingabe für den Tag muss zwischen 1 und {{maxDays}} liegen.'
		}
	},
	month: {
		label: 'Monat',
		plural: 'Monate',
		validation: {
			invalid: 'Die Eingabe für den Monat muss zwischen 1 und 12 liegen.'
		}
	},
	year: {
		label: 'Jahr',
		validation: {
			invalid: 'Die Eingabe für das Jahr ist inkorrekt.'
		}
	},
	customerNumber: {
		label: 'Kundennummer'
	},
	email: {
		label: 'E-Mail',
		validation: {
			invalid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
		}
	},
	countryCode: {
		label: 'Ländervorwahl'
	},
	mobileNumber: {
		label: 'Mobilfunknummer',
		validation: {
			tooShort: 'Die Mobilfunknummer darf nicht weniger als 5 Zeichen enthalten.',
			noGermanPrefix:
				'Die eingegebene Vorwahl der Mobilfunknummer entspricht nicht dem gültigen Format.',
			hasSameMobileNumber:
				'Die Mobilfunktnummer muss sich von der Mobilfunknummer des ersten Kreditnehmers unterscheiden.'
		}
	},
	passcode: {
		label: 'Code eingeben',
		validation: {
			invalid: 'Der eingegebene Code ist falsch.',
			blockedUntil:
				'Auf Grund von Fehleingaben des Bestätigungscodes ist Ihre Anmeldung bis zum {date} um {time} Uhr deaktiviert.'
		},
		success: {
			newCode: 'Wir haben Ihnen eine SMS mit einem neuen Code zugesandt.'
		}
	},
	save: {
		label: 'Speichern'
	},
	resetData: {
		label: 'Daten zurücksetzen'
	},
	nationality: {
		label: 'Staatsangehörigkeit'
	},
	state: {
		label: 'Bundesland'
	},
	postalCode: {
		label: 'PLZ',
		validation: {
			length: 'Die PLZ muss 5 Ziffern haben.'
		}
	},
	city: {
		label: 'Ort',
		validation: {
			length: 'Der Ort muss min. 2 Zeichen haben.'
		}
	},
	street: {
		label: 'Straße',
		validation: {
			length: 'Die Straße muss min. 3 Zeichen haben.'
		}
	},
	streetNumber: {
		label: 'Nr.'
	},
	hasPreviousAddress: {
		label: 'Der Kunde wohnt weniger als 3 Jahre hier.'
	},
	maritalStatus: {
		label: 'Familienstand',
		items: {
			single: 'ledig',
			married: 'verheiratet',
			other: 'andere',
			partnered: 'eingetragene Lebensgemeinschaft',
			separated: 'getrennt lebend',
			divorced: 'geschieden',
			widowed: 'verwitwet'
		}
	},
	numberAdults: {
		label: 'Anzahl Erwachsene im Haushalt'
	},
	numberChildren: {
		label: 'Anzahl im Haushalt lebende Kinder'
	},
	numberChildrenWithBenefit: {
		label: 'Anzahl davon Kindergeldberechtigte Kinder'
	},
	creditCalculator: {
		creditAmount: {
			label: 'Kreditbetrag'
		},
		runtime: {
			label: 'Laufzeit'
		},
		wishRuntime: {
			label: 'Wunschlaufzeit',
			extension: 'Monate'
		},
		rate: {
			label: 'Rate'
		},
		monthlyRate: {
			label: 'Monatliche Rate',
			info: 'Für dieses unverbindliche Berechnungsbeispiel wird der Zinssatz von {{interest}}% effekt. Jahreszins verwendet. Ihr tatsächlicher Zinssatz kann davon abweichen. Bitte beachten Sie unser 2/3 Beispiel gem. §6a PAngV welches Sie auf der Santander Website beim Kreditrechner sehen.'
		}
	},
	externalCredit: {
		add: 'Ablösekredit hinzufügen'
	},
	iban: {
		label: 'IBAN',
		placeholder: 'z.B. DE62 8888 8888 0012 3456 78',
		validation: {
			invalid: 'Bitte geben Sie eine gültige IBAN ein.'
		}
	},
	income: {
		label: 'Mtl. Nettoeinkommen'
	},
	additionalIncome: {
		label: 'Sonstige Nettoeinnahmen'
	},
	livingCosts: {
		label: 'Mtl. Wohnkosten',
		sameHousehold: 'Mtl. Wohnkosten beider Kreditnehmer'
	},
	childSupport: {
		label: 'Mtl. Unterhaltszahlungen',
		sameHousehold: 'Mtl. Unterhaltszahlungen beider Kreditnehmer'
	},
	remainingBalance: {
		label: 'Restsaldo',
		info: 'Die noch ausstehende Schuld des Kreditnehmers an den Kreditgeber, wenn der Kreditnehmer den Kredit heute vollständig zurückzahlen möchte.'
	},
	typeOfLiving: {
		label: 'Wohnart',
		items: {
			rentedApartment: 'Mietwohnung',
			ownerOccupiedApartment: 'Eigentumswohnung',
			ownHouse: 'Eigenes Haus',
			residentWithTheParents: 'Wohnhaft bei den Eltern'
		}
	},
	occupation: {
		label: 'Beschäftigungsverhältnis',
		employer: {
			label: 'Arbeitgeber'
		},
		items: {
			employed: 'Angestellte(r)',
			pensioner: 'Rentner(in)/Pensionär(in)',
			officer: 'Beamter/Beamtin',
			freelancer: 'Selbstständige(r)',
			student: 'Student(in)',
			unemployed: 'Arbeitslos',
			public: 'Angestellte(r) im Öffentlichen Dienst'
		},
		month: {
			label: 'Beschäftigt seit Monat',
			validation: {
				invalid:
					'Die Eingabe für den Monat des Beschäftigungsbeginns muss zwischen 1 und 12 liegen.'
			}
		},
		year: {
			label: 'Beschäftigt seit Jahr',
			validation: {
				invalid: 'Die Eingabe für das Jahr des Beschäftigungsbeginns ist inkorrekt.'
			}
		},
		date: {
			validation: {
				startInTheFuture: 'Der Beschäftigungsstart darf nicht in der Zukunft liegen.',
				startBeforeBirthdate: 'Ihr Beschäftigungsstart passt nicht zu Ihrem Geburtsdatum.'
			}
		},
		limited: {
			limited: 'Befristet',
			unlimited: 'Unbefristet',
			month: {
				label: 'Befristet bis Monat',
				validation: {
					invalid:
						'Die Eingabe für den Monat der Befristung muss zwischen 1 und 12 liegen.'
				}
			},
			year: {
				label: 'Befristet bis Jahr',
				validation: {
					invalid: 'Die Eingabe für das Jahr der Befristung ist inkorrekt.'
				}
			},
			date: {
				validation: {
					startInThePast: 'Die Befristung darf nicht in der Vergangenheit liegen.',
					startBeforeOccupationStart:
						'Die Befristung darf nicht vor dem Beschäftigungsstart liegen.'
				}
			}
		},
		since: {
			startInTheFuture: 'Der Beschäftigungsstart darf nicht in der Zukunft liegen.',
			startBeforeBirthdate: 'Ihr Beschäftigungsstart passt nicht zu Ihrem Geburtsdatum.'
		},
		until: {
			startInThePast: 'Die Befristung darf nicht in der Vergangenheit liegen.',
			startBeforeOccupationStart:
				'Die Befristung darf nicht vor dem Beschäftigungsstart liegen.'
		},
		noBirthdate: 'Es wurde noch kein Geburtsdatum angegeben.'
	},
	acceptTermsAndConditions: {
		label: 'Der Kunde willigt ein, dass seine personenbezogenen Daten durch Santander zur Beantragung des o.g. Produkts und zur Kontaktaufnahme im Rahmen der Geschäftsbeziehung erhoben, verarbeitet und genutzt werden. <a class="link linkExternalIcon" href="https://www.santander.de/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutzhinweise</a>'
	},
	acceptOwnResponsibility: {
		label: 'Der Kunde handelt auf eigene Rechnung (§11 Abs. 5 GwG) und ist verpflichtet, sich ergebende Änderungen der Pflichtangaben gem. §11 Abs. 6 GwG der Bank unverzüglich mitzuteilen.'
	},
	acceptSchufa: {
		text: 'Der Kunde stimmt der',
		linkText: 'Datenübermittlung an die SCHUFA',
		text2: 'zu.',
		popup: {
			headline: 'Datenübermittlung an die Schufa',
			text1: 'Die Santander übermittelt im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten über die Beantragung, die Durchführung und Beendigung dieser Geschäftsbeziehung sowie Daten über nicht vertragsgemäßes Verhalten oder betrügerisches Verhalten an die SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden. Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 Buchstabe b und Artikel 6 Absatz 1 Buchstabe f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der Bank oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen. Der Datenaustausch mit der SCHUFA dient auch der Erfüllung gesetzlicher Pflichten zur Durchführung von Kreditwürdigkeitsprüfungen von Kunden (§ 505a des Bürgerlichen Gesetzbuches, § 18a des Kreditwesengesetzes).',
			text2: 'Der Kunde befreit Santander insoweit auch vom Bankgeheimnis.',
			text3: 'Die SCHUFA verarbeitet die erhaltenen Daten und verwendet sie auch zum Zwecke der Profilbildung (Scoring), um ihren Vertragspartnern im Europäischen Wirtschaftsraum und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein Angemessenheitsbeschluss der Europäischen Kommission besteht) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen Personen zu geben. Nähere Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt nach Art. 14 DSGVO entnommen oder online unter <a class="link linkExternalIcon" href="https://www.schufa.de/datenschutz" target="_blank" rel="noopener noreferer">www.schufa.de/datenschutz</a> eingesehen werden.'
		}
	},
	acceptAdvertising: {
		text: 'Darf Santander den Kunden',
		linkText: 'beraten und informieren?',
		popup: {
			headline: 'Darf Santander den Kunden beraten und informieren?',
			text1: 'Der Kunde ist einverstanden, dass ihn die Santander Consumer Bank AG und die Santander Consumer Leasing GmbH per E-Mail, telefonisch, per Brief oder per sonstiger elektronischer Kommunikationsdienste (SMS o.ä.) personalisiert über neue Services und interessante Produktangebote informieren kann und stimmt insoweit der Verarbeitung seiner personenbezogenen Daten zu (Details hierzu siehe <a class="link linkExternalIcon" href="https://www.santander.de/werbehinweise" target="_blank" rel="noopener noreferer">Santander-Werbehinweise</a>). Dieses Einverständnis bezieht sich auf eigene und auf vermittelte Produkte und Services. Ihm ist bewusst, dass er dieses Einverständnis jederzeit <span class="underlined">widerrufen</span> kann.',
			text2: 'Er hat das Recht, auch ohne Einwilligung zulässiger Werbung jederzeit zu <span class="underlined">widersprechen</span> (Details hierzu siehe <a class="link linkExternalIcon" href="https://www.santander.de/werbehinweise" target="_blank" rel="noopener noreferer">Santander-Werbehinweise</a> Ziffer III.).'
		}
	},
	applicationId: {
		label: 'Auftragsnummer'
	},
	amount: {
		label: 'Betrag',
		full: 'Kreditbetrag',
		validation: {
			tooHigh:
				'Der Kreditbetrag inkl. Ablösesumme darf einen Wert von {{amount}} nicht überschreiten.'
		}
	},
	runtime: {
		label: 'Laufzeit',
		month: 'Monat',
		months: 'Monate'
	},
	more: {
		label: 'Mehr'
	},
	rate: {
		label: 'Rate'
	},
	monthlyRate: {
		label: 'Monatliche Rate'
	},
	approvedAmount: {
		label: 'Genehmigter Betrag'
	},
	moreCreditDetails: 'Mehr Kreditdetails',
	lessCreditDetails: 'Weniger Kreditdetails',
	effectiveYearlyInterest: {
		label: 'Effektiver Jahreszins'
	},
	nominalInterestRate: {
		label: 'Fester Sollzinssatz (p.a)'
	},
	firstRateDate: {
		label: 'Erste Rate fällig am'
	},
	interest: {
		label: 'Zinsen'
	},
	ppi: {
		label: 'Ratenschutzversicherung'
	},
	protect: {
		label: 'Protect Tarif'
	},
	totalAmount: {
		label: 'Gesamtbetrag'
	},
	downloadContract: {
		label: 'Kreditantrag herunterladen'
	},
	downloadInsuranceContract: {
		label: 'Versicherungsvertrag herunterladen'
	},
	upload: {
		defaultError: 'Es ist ein Fehler beim Hochladen aufgetreten.',
		maxUploadSize: 'Upload nicht möglich. Die Datei ist größer als 10 MB.'
	},
	dropzone: {
		default: 'Datei auswählen',
		drop: 'Dokumente hier loslassen.',
		reject: 'Erlaubte Dateien:',
		addAdditionalFile: 'Weitere Datei <span class="link">hinzufügen</span>'
	},
	condition: {
		label: 'Kondition'
	},
	box: {
		readMore: 'Mehr erfahren',
		close: 'Schließen'
	}
};
export default messages;
