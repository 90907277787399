import React from 'react';
import m from 'utils/messages/messages';

import { Title, Text } from 'components/Atoms/Text';

const ErrorSession = () => {
	return (
		<>
			<Title>{m('page.error.session.title', 'global')}</Title>
			<Text isSubtitle>{m('page.error.session.subtitle', 'global')}</Text>
		</>
	);
};

export default ErrorSession;
