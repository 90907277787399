import React from 'react';

type Props = {
	color?: string;
};

const SvgPayout = (props: Props) => {
	const width = 24;
	const height = 24;
	const defaultColor = '#9BC3D3';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M18.5 2a.5.5 0 01.5.5v19a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5zM18 3H6v18h12V3zm-5.361 12.978a.5.5 0 010 1h-1.554c.308.406.73.614 1.31.614.513 0 .762-.15 1.173-.467a.501.501 0 01.612.792c-.479.37-.944.675-1.784.675-1.155 0-2-.56-2.471-1.614h-.41a.5.5 0 110-1zm-.243-3.46c.84 0 1.305.305 1.784.674a.5.5 0 11-.612.792c-.41-.317-.66-.466-1.172-.466-.7 0-1.173.3-1.485.895h1.728a.5.5 0 010 1H9.515a.5.5 0 110-1h.295c.44-1.238 1.332-1.895 2.586-1.895zM15.5 9a.5.5 0 010 1h-7a.5.5 0 010-1zm0-3a.5.5 0 010 1h-7a.5.5 0 010-1z"
			/>
		</svg>
	);
};
export default SvgPayout;
