import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from 'config/history';
import axios from 'axios';

import 'assets/styles/styles.scss';

import App from 'components/App/App';

import store from 'store/store';
import { setIsLoadingForm } from 'store/Loading/Loading';
import { Provider } from 'react-redux';

import 'config/i18n';

import reportWebVitals from 'reportWebVitals';

declare global {
	interface Window {
		usercentrics: any;
		applicationInfo: any;
	}
}

axios.interceptors.request.use(
	(config) => {
		store.dispatch(setIsLoadingForm(true));
		return config;
	},
	(error) => {
		store.dispatch(setIsLoadingForm(false));
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response) => {
		store.dispatch(setIsLoadingForm(false));
		return response;
	},
	(error) => {
		store.dispatch(setIsLoadingForm(false));
		return Promise.reject(error);
	}
);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router history={history}>
				<App />
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
