import React from 'react';

type Props = {
	color?: string;
};

const SvgCheck = (props: Props) => {
	const width = 30;
	const height = 30;
	const defaultColor = '#000000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M12.1,28.32a1.71,1.71,0,0,1-1.22-.51L2.19,19.12a1.74,1.74,0,0,1,2.46-2.45l7.14,7.14L25.11,2.5a1.73,1.73,0,1,1,2.94,1.84L13.58,27.5a1.74,1.74,0,0,1-1.28.81h-.2"
			/>
		</svg>
	);
};
export default SvgCheck;
