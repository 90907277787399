import React from 'react';
import { v4 as uuid } from 'uuid';
import ReactTooltip from 'react-tooltip';

import TooltipTheme from './types/TooltipTheme.d';
import TooltipPosition from './types/TooltipPosition.d';

import styles from './Tooltip.module.scss';

type Props = {
	theme?: TooltipTheme;
	position?: TooltipPosition;
	content: string | JSX.Element;
	children: any;
};

const Tooltip = (props: Props) => {
	const id = uuid();

	return (
		<div className={styles.tooltip}>
			<div data-tip="" data-for={id}>
				{props.children}
			</div>
			<ReactTooltip
				id={id}
				effect="solid"
				type={props.theme ? props.theme : TooltipTheme.LIGHT}
				place={props.position ? props.position : TooltipPosition.TOP}
			>
				{props.content}
			</ReactTooltip>
		</div>
	);
};
export default Tooltip;
