import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Tracking from './Tracking.d';

import GtmType from 'types/enums/GtmType.d';

const initialState: Tracking = {
	sanc: null,
	uid: null,
	gtmType: null,
	gtmSubtype: null,
	gtmStep: null,
	gtmStatus: null,
	gtmSubstatus: null
};

export const slice = createSlice({
	name: 'Tracking',
	initialState: initialState,
	reducers: {
		setSanc: (state, action: PayloadAction<string>) => {
			state.sanc = action.payload;
		},
		setUid: (state, action: PayloadAction<string>) => {
			state.uid = action.payload;
		},
		setGtmSubtype: (state, action: PayloadAction<string>) => {
			state.gtmSubtype = action.payload;
		},
		setGtmType: (state, action: PayloadAction<GtmType>) => {
			state.gtmType = action.payload;
		},
		setGtmStep: (state, action: PayloadAction<string>) => {
			state.gtmStep = action.payload;
		},
		setGtmStatus: (state, action: PayloadAction<string>) => {
			state.gtmStatus = action.payload;
		},
		setGtmSubstatus: (state, action: PayloadAction<string>) => {
			state.gtmSubstatus = action.payload;
		},
		reset: () => initialState
	}
});

export const {
	setSanc,
	setUid,
	setGtmSubtype,
	setGtmType,
	setGtmStep,
	setGtmStatus,
	setGtmSubstatus,
	reset
} = slice.actions;
export default slice.reducer;
