import React from 'react';
import m from 'utils/messages/messages';

import { RootState } from 'store/rootReducer';
import { useSelector } from 'react-redux';

import Salutation from 'types/enums/Salutation.d';

type Props = {
	customExtension?: string | JSX.Element;
};

const NavbarName = (props: Props) => {
	const salutation = useSelector((state: RootState) => state.Name.salutation);
	const firstName = useSelector((state: RootState) => state.Name.firstName);
	const lastName = useSelector((state: RootState) => state.Name.lastName);

	return (
		<>
			{props.customExtension ? (
				<>{props.customExtension}</>
			) : (
				<>
					{!!salutation && !!firstName && !!lastName && (
						<>
							{m('salutation.for', 'fields', {
								salutation:
									salutation === Salutation.FEMALE
										? m('salutation.items.female', 'fields')
										: m('salutation.items.maleExt', 'fields'),
								firstName: firstName,
								lastName: lastName
							})}
						</>
					)}
				</>
			)}
		</>
	);
};
export default NavbarName;
