import React from 'react';
import m from 'utils/messages/messages';

import { Title } from 'components/Atoms/Text';
import ServiceHotline from 'components/Atoms/Partials/ServiceHotline/ServiceHotline';

const Error404 = () => {
	return (
		<>
			<Title>{m('page.error.404.title', 'global')}</Title>
			<ServiceHotline />
		</>
	);
};

export default Error404;
