import React, { useEffect } from 'react';
import m from 'utils/messages/messages';
import { goToPage } from 'utils/pages/pages';
import { getStatusOverviewApplications } from 'api';

import Page from 'components/Organisms/Page/Page';
import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Button from 'components/Atoms/Button/Button';
import Divider from 'components/Atoms/Divider/Divider';
import AccordionItem from 'components/Molecules/Accordion/AccordionItem';
import ApplicationItemHeader from 'components/Pages/StatusOverview/Application/ApplicationItemHeader';
import ApplicationItemBody from 'components/Pages/StatusOverview/Application/ApplicationItemBody';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import Subpage from 'types/enums/Subpage.d';
import ButtonSize from 'components/Atoms/Button/types/ButtonSize.d';

import styles from './StatusOverview.module.scss';
import { setIsEdit } from 'store/Applications/Applications';

const StatusOverview = () => {
	const dispatch = useDispatch();
	const applications = useSelector((state: RootState) => state.Applications.applications);

	useEffect(() => {
		dispatch(setIsEdit(false));
		getStatusOverviewApplications();
	}, []);

	return (
		<Page>
			<Row>
				<Col xs={12} sm="auto" grow>
					<Title>{m('page.statusOverview.title', 'global')}</Title>
				</Col>
				<Col xs={12} sm="auto" noGrow>
					<div className={styles.buttonWrapper}>
						<Button
							buttonSize={ButtonSize.SMALL}
							onClick={() => {
								goToPage(Subpage.CREDIT_DETAILS);
							}}
						>
							{m('page.statusOverview.newApplicationButton', 'global')}
						</Button>
					</div>
				</Col>
			</Row>
			<Divider noLine />
			{applications.length > 0 ? (
				<>
					<div className={styles.header}>
						<div className={styles.headerItem}>
							{m('page.statusOverview.header.date', 'global')}
						</div>
						<div className={styles.headerItem}>
							{m('page.statusOverview.header.oid', 'global')}
						</div>
						<div className={styles.headerItem}>
							{m('page.statusOverview.header.customerName', 'global')}
						</div>
						<div className={styles.headerItem}>
							{m('page.statusOverview.header.result', 'global')}
						</div>
						<div className={styles.headerItem}>
							{m('page.statusOverview.header.hint', 'global')}
						</div>
						<div className={styles.headerItem}>
							{m('page.statusOverview.header.conditions', 'global')}
						</div>
					</div>
					<div>
						{applications.map((application, index) => (
							<AccordionItem
								key={'status-overview-accordion-item-' + index}
								header={<ApplicationItemHeader application={application} />}
								body={<ApplicationItemBody application={application} />}
							/>
						))}
					</div>
				</>
			) : (
				<Text>{m('page.statusOverview.noItems', 'global')}</Text>
			)}
		</Page>
	);
};
export default StatusOverview;
