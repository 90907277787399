import React from 'react';
import m from 'utils/messages/messages';

import { Text } from 'components/Atoms/Text';
import SvgSpeechBubble from 'components/Atoms/SVG/SvgSpeechBubble';

import styles from './ServiceHotline.module.scss';

type Props = {
	phoneHref?: any;
	phone?: any;
};

const ServiceHotline = (props: Props) => {
	return (
		<div className={styles.serviceHotline}>
			<Text prependIcon={<SvgSpeechBubble />}>
				{m(
					'partial.serviceHotline.description',
					'global',
					{
						phoneHref:
							props.phoneHref ?? m('partial.serviceHotline.phoneHref', 'global'),
						phone: props.phone ?? m('partial.serviceHotline.phone', 'global')
					},
					true
				)}
			</Text>
		</div>
	);
};
export default ServiceHotline;
