import * as React from 'react';

type Props = {
	color?: string;
};

const SvgUmbrella = (props: Props) => {
	const width = 20;
	const height = 20;
	const defaultColor = '#9E3667';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M19.8498 3.34993C15.4144 -1.03848 8.32314 -1.11139 3.80087 3.10831L2.47744 1.7861L2.40815 1.72828C2.2132 1.5934 1.94379 1.61285 1.77033 1.78652C1.57519 1.9819 1.57538 2.29849 1.77076 2.49363L3.09377 3.81608C-1.09606 8.31334 -1.03524 15.332 3.30672 19.7441L3.37354 19.8109L3.44276 19.8688C3.63754 20.0037 3.90684 19.9845 4.08044 19.8111L11.611 12.289L19.1438 19.8531L19.2129 19.9111C19.4075 20.0465 19.677 20.0278 19.8509 19.8546L19.9089 19.7854C20.0443 19.5908 20.0256 19.3214 19.8524 19.1474L12.319 11.582L19.8514 4.05913L19.9095 3.98962C20.045 3.79398 20.025 3.5233 19.8498 3.34993ZM3.32505 5.07175L3.34403 5.04848C3.63033 8.69682 5.20415 12.3145 7.6565 14.8268L3.73472 18.7431L3.52803 18.5099C0.218436 14.6642 0.158209 8.99154 3.32505 5.07175ZM4.30449 4.2884L4.31558 4.57928C4.49764 8.12481 5.9959 11.6894 8.36373 14.1197L14.1133 8.37665C11.7429 6.06265 8.18012 4.54943 4.67929 4.30924L4.30449 4.2884ZM5.0654 3.33642C9.08821 0.103048 14.9093 0.227839 18.7806 3.71534L14.8216 7.67063C12.3485 5.25126 8.69839 3.66004 5.0654 3.33642Z"
			/>
		</svg>
	);
};
export default SvgUmbrella;
