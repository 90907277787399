import React from 'react';

type Props = {
	color?: string;
};

const SvgSpeechBubble = (props: Props) => {
	const width = 30;
	const height = 27;
	const defaultColor = '#B0BDC0';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<g
				style={{
					stroke: props.color ? props.color : defaultColor,
					fill: 'none',
					fillRule: 'evenodd'
				}}
			>
				<path
					style={{ fill: props.color ? props.color : defaultColor, fillRule: 'nonzero' }}
					d="M26.875 4.033A10.367 10.367 0 0019.492.925h-9.167A9.942 9.942 0 003.1 3.958a9.725 9.725 0 00-2.767 7.067 9.558 9.558 0 001.184 4.317c.26-.228.54-.434.833-.617A8.583 8.583 0 011.333 11a8.708 8.708 0 012.484-6.333A8.983 8.983 0 0110.3 1.942h9.167a9.408 9.408 0 016.666 2.8 8.6 8.6 0 012.5 6.216 8.8 8.8 0 01-7.05 8.442c-.783.167-.783.833-.775 1.383v4.042c.01.425-.272.8-.683.908a.9.9 0 01-.975-.416l-2.95-5a1.425 1.425 0 00-1.2-.742h-3.758v.092a6.567 6.567 0 01-.075.908H15c.15.001.289.08.367.208l2.95 5c.348.587.984.943 1.666.934.143 0 .286-.017.425-.05a1.925 1.925 0 001.467-1.884v-4.166-.325a9.808 9.808 0 007.792-9.325 9.525 9.525 0 00-2.792-6.934z"
					strokeWidth={0.5}
				/>
				<path
					d="M5.417 15.5a4.167 4.167 0 100 8.333 4.167 4.167 0 000-8.333z"
					strokeWidth={1.5}
				/>
			</g>
		</svg>
	);
};
export default SvgSpeechBubble;
