import React, { useEffect } from 'react';
import m from 'utils/messages/messages';
import { preventHistoryBackFor } from 'utils/pages/pages';

import Page from 'components/Organisms/Page/Page';
import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';

import Subpage from 'types/enums/Subpage.d';

const ApplicationError = () => {
	useEffect(() => {
		preventHistoryBackFor(Subpage.CUSTOMER_DATA);
	}, []);

	return (
		<Page>
			<Row>
				<Col xs={12}>
					<Title hasMarginBottom>{m('page.applicationError.title', 'global')}</Title>
					<Text isSubtitle>
						{m('page.applicationError.subtitle', 'global', null, true)}
					</Text>
				</Col>
			</Row>
		</Page>
	);
};
export default ApplicationError;
