import React from 'react';

import SvgInfoCircle from 'components/Atoms/SVG/SvgInfoCircle';
import SvgQuestionmarkCircle from 'components/Atoms/SVG/SvgQuestionmarkCircle';

import Tooltip from 'components/Atoms/Tooltip/Tooltip';

import TooltipIconType from './types/TooltipIconType.d';

import styles from './TooltipIcon.module.scss';

type Props = {
	text: string | JSX.Element;
	type?: TooltipIconType;
};

const TooltipIcon = (props: Props) => {
	const getIcon = () => {
		if (props.type === TooltipIconType.QUESTION) {
			return <SvgQuestionmarkCircle />;
		} else {
			return <SvgInfoCircle />;
		}
	};

	return (
		<div className={styles.tooltipIcon}>
			<Tooltip content={props.text}>{getIcon()}</Tooltip>
		</div>
	);
};
export default TooltipIcon;
