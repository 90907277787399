import React from 'react';
import classNames from 'classnames';
import { goToPage, replaceSubpageVariable } from 'utils/pages/pages';

import rootRoutes from 'routes/rootRoutes';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import styles from '../Dev.module.scss';

const SubpageSelector = () => {
	const currentSubpage = useSelector((state: RootState) => state.Page.currentSubpage);

	const getSubpageItems = () => {
		const subpageItems = [];
		for (const route of rootRoutes) {
			subpageItems.push({
				text: '/' + route.subpage,
				value: route.subpage
			});
		}
		return subpageItems;
	};

	const subpageItems = getSubpageItems();

	const onChange = (event: any) => {
		const index = event.nativeEvent.target.selectedIndex;
		const selectedItem: any = subpageItems[index - 1];
		const subpage = replaceSubpageVariable(selectedItem.value);
		goToPage(subpage);
	};

	return (
		<div className={styles.selector}>
			<select
				className={classNames(
					styles.selectorInput,
					!currentSubpage ? styles.selectorInputDisabled : null
				)}
				onChange={onChange}
				value={currentSubpage ? currentSubpage : ''}
			>
				<option disabled hidden value="">
					Keine Unterseiten
				</option>
				{subpageItems.map((item, index) => (
					<option key={'subpage-selector-item-' + index} value={item.value}>
						{item.text}
					</option>
				))}
			</select>
		</div>
	);
};
export default SubpageSelector;
