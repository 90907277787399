import React, { useEffect } from 'react';
import m from 'utils/messages/messages';
import { preventHistoryBackFor } from 'utils/pages/pages';

import Page from 'components/Organisms/Page/Page';
import { Col, Row } from 'components/Atoms/Grid';
import { Title } from 'components/Atoms/Text';
import SvgHandHoldingCreditCard from 'components/Atoms/SVG/SvgHandHoldingCreditCard';

import Subpage from 'types/enums/Subpage.d';

const ApplicationRejected = () => {
	useEffect(() => {
		preventHistoryBackFor(Subpage.CUSTOMER_DATA);
	}, []);

	return (
		<Page>
			<Row>
				<Col xs={12} md={5} alignSelfCenter>
					<SvgHandHoldingCreditCard />
				</Col>
				<Col xs={12} md={1} />
				<Col xs={12} md={6} alignSelfCenter>
					<Title>{m('page.applicationRejected.title', 'global')}</Title>
				</Col>
			</Row>
		</Page>
	);
};
export default ApplicationRejected;
