import React from 'react';

type Props = {
	color?: string;
};

const SvgUpload = (props: Props) => {
	const width = 24;
	const height = 24;
	const defaultColor = '#9BC3D3';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M12.384 9.68l2.5 3.002a.5.5 0 01-.769.64l-1.616-1.94L12.5 21.5a.5.5 0 01-.41.492L12 22a.5.5 0 01-.5-.5l-.001-10.12-1.616 1.942a.5.5 0 01-.63.115l-.074-.05a.502.502 0 01-.064-.705l2.5-3.002.063-.062.006-.006.024-.018a.439.439 0 01.051-.032l.037-.019a.34.34 0 01.048-.018l.038-.011A.46.46 0 0112 9.5l-.068.005.046-.005h.044l.048.005a.24.24 0 01.048.01.492.492 0 01.096.033l.022.01.06.038a.536.536 0 01.088.084l-.068-.068.006.006.062.062zM9.975 4.294c1.17-1.763 3.445-2.647 5.646-2.163 2.354.517 4.022 2.466 4.004 4.718a4.592 4.592 0 01-.143 1.06c1.81.856 2.827 2.728 2.434 4.62-.423 2.03-2.349 3.481-4.57 3.471a.499.499 0 11.005-1c1.76.008 3.263-1.125 3.586-2.675.32-1.539-.61-3.078-2.235-3.658a.501.501 0 01-.302-.644c.143-.386.219-.788.225-1.186.014-1.76-1.312-3.31-3.219-3.73-1.917-.42-3.893.422-4.765 2.016a.5.5 0 01-.608.23 2.867 2.867 0 00-.971-.167 2.598 2.598 0 00-1.784.64c-.463.414-.72.974-.724 1.613-.017.159-.017.32 0 .48a.5.5 0 01-.433.552c-1.886.241-3.232 1.754-3.114 3.454.12 1.705 1.672 3.055 3.58 3.075a.5.5 0 01-.011 1c-2.412-.026-4.41-1.762-4.566-4.005-.147-2.104 1.374-3.957 3.533-4.437.003-.063.008-.121.015-.175 0-.871.38-1.701 1.053-2.302a3.583 3.583 0 012.463-.895c.305 0 .608.037.901.108z"
			/>
		</svg>
	);
};
export default SvgUpload;
