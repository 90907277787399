const messages = {
	title: 'Kredit',
	page: {
		error: {
			404: {
				title: '404 - Die Seite wurde nicht gefunden.'
			},
			session: {
				title: 'Ihre Session ist abgelaufen.',
				subtitle: 'Bitte loggen Sie sich erneut ein.',
				submit: 'Einloggen'
			}
		},
		statusOverview: {
			title: 'Antragsübersicht',
			newApplicationButton: 'Neuen Antrag starten',
			noItems: 'Sie haben keine offenen Anträge.',
			header: {
				date: 'Erstellt am',
				oid: 'Auftragsnr.',
				customerName: 'Kundenname',
				result: 'Status',
				hint: 'Hinweis',
				conditions: 'Auflagen'
			},
			result: {
				inDecision: 'in Entscheidung',
				pending: 'zurückgestellt',
				approved: 'genehmigt',
				rejected: 'abgelehnt',
				accounted: 'in Aktivierung',
				disbursed: 'aktiviert',
				cancelled: 'storniert'
			},
			hint: {
				yes: 'ja',
				no: 'kein'
			},
			conditions: {
				yes: 'ja',
				no: 'keine'
			},
			body: {
				amount: 'Kreditbetrag',
				runtime: 'Laufzeit',
				conditions: 'Auflagen',
				effectiveYearlyInterestRate: 'Effektiver Jahreszins',
				monthlyRate: 'Monatliche Rate',
				hints: 'Hinweise',
				firstApplicant: '1. Darlehensnehmer',
				secondApplicant: '2. Darlehensnehmer'
			},
			footer: {
				contractPreview: 'Vertragsvorschau',
				editAmount: 'Kreditbetrag ändern',
				editApplication: 'Antrag bearbeiten',
				continueApplication: 'Antrag fortsetzen',
				viewDocuments: 'Dokumente ansehen',
				availableDocuments: 'Verfügbare Dokumente',
				noDocumentsAvailable: 'Es sind keine Dokumente verfügbar'
			}
		},
		creditDetails: {
			title: 'Geben Sie die Kreditdetails an.'
		},
		creditDetailsEdit: {
			title: 'Geben Sie die Änderung der Kreditdetails an.'
		},
		customerData: {
			title: 'Geben Sie die Kundendaten ein.',
			secondApplicant: {
				title: 'Wie möchten Sie den Kredit beantragen?',
				subtitle:
					'Ein zweiter Kreditnehmer kann sich positiv auf die Kreditentscheidung auswirken.',
				options: {
					oneApplicant: 'Eine Person',
					twoApplicants: 'Zwei Personen'
				},
				sameHousehold: {
					title: 'Leben die Darlehensnehmer im gleichen Haushalt?',
					options: {
						yes: 'Gemeinsamer Haushalt',
						no: 'Getrennter Haushalt'
					}
				}
			},
			customer: {
				one: 'Kunde 1',
				two: 'Kunde 2'
			},
			name: {
				title: 'Wie heißt der Kunde?'
			},
			birthdate: {
				title: 'Wann ist der Geburtstag des Kunden?'
			},
			customerNumber: {
				title: 'Wie ist die Kundennummer des Kunden?'
			},
			address: {
				title: 'Wo wohnt der Kunde?'
			},
			previousAddress: {
				title: 'Wo hat der Kunde vorher gewohnt?'
			},
			contact: {
				title: 'Wie kann der Kunde erreicht werden?'
			},
			personalData: {
				title: 'Persönliche Angaben'
			},
			occupation: {
				title: 'Beschäftigungssituation',
				isLimited: 'Ist das Arbeitsverhältnis befristet?',
				limited: {
					title: 'Wann endet das Arbeitsverhältnis?'
				}
			},
			iban: {
				title: 'Wie lautet die aktuelle Bankverbindung des Kunden?'
			},
			financialStatus: {
				title: 'Was sind die aktuellen Einnahmen des Kunden?',
				livingCosts: 'Was sind die aktuellen Ausgaben des Kunden?'
			},
			ppi: {
				title: 'Wünscht der Kunde noch eine Absicherung?',
				error: {
					noBirthdate: 'Bitte geben Sie zuerst das Geburtsdatum des Kunden an.',
					tooOld: 'Der Kunde ist zu alt, um ihm eine Absicherung anzubieten. Das Maximalalter beträgt {{maxAge}}.'
				},
				ppi: {
					title: 'Kreditraten absichern',
					subtitle:
						'Mit Santander RSV Plus stellen Sie die Rückzahlungen Ihrer Kreditraten auch im Ernstfall sicher. Wir empfehlen den Abschluss der Santander RSV Plus soweit Sie eine Absicherung der Rückzahlung Ihrer Kreditraten wünschen.',
					body: {
						title: 'Ratenschutzversicherung',
						death: {
							title: 'Ratenschutzversicherung: Todesfall',
							label: 'Kreditraten werden im Todesfall übernommen<sup>1)</sup>'
						},
						noPpi: {
							label: 'Ich will die Rückzahlung meiner Raten nicht absichern'
						},
						hint1: '1) Eine Absicherung bei unverschuldeter Arbeitslosigkeit oder Arbeitsunfähigkeit durch Krankheit oder Unfall wird beitragsfrei durch den Versicherer CNP Insurance Europe DAC bereitgestellt. Es gelten die jeweils gültigen Versicherungsbedingungen des Versicherers.'
					}
				},
				protect: {
					title: 'Einkommensausfall absichern',
					subtitle:
						'Mit Santander Protect können Sie Ihr monatliches Einkommen gegen eine Vielzahl von Risiken absichern.',
					body: {
						title: 'Deckungs- und Absicherungslaufzeit wählen',
						subtitle:
							'Aufgrund Ihrer zuvor eingegebenen Gehaltsangaben haben wir hiermit Ihre Einkommenslücke berechnet, bei einer max. monatlichen Absicherung von 800 €. Die Deckungs- und Absicherungslaufzeit können Sie individuell anpassen.',
						runtime: {
							label: 'Laufzeit'
						},
						monthlyProtect: {
							label: 'Monatliche Absicherung'
						},
						selectTitle: 'Protect Tarif wählen',
						table: {
							row1: {
								title: 'Kurzzeitpflege, Elternzeit',
								popup: {
									headline1: 'Kurzzeitpflege',
									content1:
										'<p>Sie sorgen für den Menschen, der Ihnen wichtig ist. Wir sorgen für Ihr Einkommen</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der Kurzzeitpflege ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 6 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Die Pflege direkter und naher Verwandter ist mitversichert.</li></ul>',
									headline2: 'Elternzeit',
									content2:
										'<p>Nehmen Sie sich Zeit für Ihre Familie – ohne Sorgen um Ihr Einkommen</p><ul><li>Beispiel: Das Basiselterngeld beträgt in der Regel 65-67% des Nettoeinkommens. Bei einem Nettoeinkommen von 2000 € beträgt der Einkommensausfall während der Elternzeit ca. 660 € pro Monat.</li><li>Sie erhalten bis zu 6 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Adoptionen sind mitversichert.</li></ul>'
								}
							},
							row2: {
								title: 'Kurzarbeit, Arbeitslosigkeit, schwere Erkrankungen',
								popup: {
									headline1: 'Kurzarbeit',
									content1:
										'<p>Auch in schwierigen Zeiten bleibt Ihr Einkommen stabil</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der konjunkturellen Kurzarbeit ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 12 Monate 50% der vereinbarten monatlichen Leistung.</li><li>Auch bei befristeten Arbeitsverträgen.</li></ul>',
									headline2: 'Arbeitslosigkeit',
									content2:
										'<p>Fokussieren Sie sich auf Ihren nächsten Job – und nicht auf Ihre finanziellen Sorgen</p><ul><li>Planbares Einkommen trotz Arbeitslosigkeit: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der Arbeitslosigkeit ca. 800 € pro Monat.</li><li>Sie erhalten bis zu 12 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Arbeitnehmer und Angestellte im öffentlichen Dienst sind mitversichert.</li></ul>',
									headline3: 'Schwere Erkrankungen',
									content3:
										'<p>Konzentrieren Sie sich auf Ihre Genesung – ohne Geldsorgen</p><ul><li>Gilt nur für Beamte, Selbstständige und Rentner.</li><li>Versichert sind Krankheiten wie beispielsweise Krebs, Herzinfarkt, Schlaganfall, etc.</li><li>Sie erhalten bis zu 12 Monate 100% der vereinbarten monatlichen Leistung.</li></ul>'
								}
							},
							row3: {
								title: 'Arbeitsunfähigkeit',
								popup: {
									headline1: 'Arbeitsunfähigkeit',
									content1:
										'<p>Konzentrieren Sie sich auf Ihre Genesung - ohne Geldsorgen</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt der Einkommensausfall während der Arbeitsunfähigkeit ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 12 Monate 50% der vereinbarten monatlichen Leistung.</li><li>Ohne Gesundheitsfragen.</li><li>Psychische Erkrankungen sind mitversichert.</li></ul>'
								}
							},
							row4: {
								title: 'Unfalltod',
								popup: {
									headline1: 'Unfalltod',
									content1:
										'<p>Sorgen Sie vor – für Ihre Liebsten</p><ul><li>Hinterbliebene absichern mit einer einmaligen Versicherungsleistung ohne Wartezeit.</li><li>Die Versicherungsleistung beträgt das 50-fache des gewählten Leistungsbetrages (maximal 150.000 €).</li></ul>'
								}
							},
							standard: {
								title: 'Protect<br />Standard'
							},
							premium: {
								title: 'Protect<br />Premium',
								higlightLabel: 'Top Seller'
							},
							basic: {
								title: 'Protect<br />Basis'
							},
							noProtect: 'Ich will meinen Einkommensausfall nicht absichern'
						}
					}
				}
			}
		},
		applicationRejected: {
			title: 'Es tut uns leid, aber wir können den Kredit derzeit nicht anbieten.'
		},
		applicationError: {
			title: 'Es ist ein technischer Fehler aufgetreten.',
			subtitle:
				'Bitte melden Sie sich noch einmal neu an und erstellen Sie einen neuen Antrag oder rufen Sie uns an: <a class="link" href="tel:+4921616907057">02161 - 69 07 057</a>'
		},
		applicationReceived: {
			editButton: 'Kreditdetails bearbeiten',
			finishedBy: 'Der Antragsprozess wird zu Ende geführt von',
			customer: 'Kunde',
			agent: 'Mitarbeiter',
			customerHint:
				'Beim Klicken auf "Weiter" erhält der Kunde eine Mail, um die letzten Schritte im Antragsprozess selbstständig durchzuführen.',
			agentHint:
				'Beim Klicken auf "Weiter" übernehmen Sie den Prozess für den Kunden (Nachweise hochladen, Legitimation, etc.)'
		},
		emailSentToCustomer: {
			title: 'Die E-Mail an den Kunden wurde erfolgreich verschickt.'
		},
		uploadMissingDocuments: {
			title: 'Zur weiteren Bearbeitung des Antrags, laden Sie bitte folgende Dokumente hoch.',
			subtitle:
				'Bitte benutzen Sie Dateien im Format PDF, JPG, oder PNG mit einer Maximalgröße von 10 MB pro Datei.',
			submitDisabledTooltip: 'Um weiter zu machen, müssen Sie alle Dokumente hochladen'
		},
		uploadMissingDocumentsSuccess: {
			title: 'Sie haben alle Dokumente erfolgreich hochgeladen.'
		},
		finishProcess: {
			title: 'Wer soll die Legitimation und Unterschrift ausführen?',
			subtitle: 'Wählen Sie eine der Optionen, um den Prozess abzuschließen.',
			options: {
				customer: {
					title: 'Kunde',
					subtitle:
						'Kunde wird via E-Mail eingeladen selbstständig bei WebID die Legitimation und digitale Unterschrift auszuführen.'
				},
				combination: {
					title: 'Kombination',
					subtitle:
						'Mitarbeiter legitimiert den Kunden und lädt die Ausweisdokumente hoch.<br/><br/>Kunde wird via E-Mail eingeladen selbstständig bei WebID die digitale Unterschrift zu leisten.'
				},
				agent: {
					title: 'Mitarbeiter',
					subtitle: 'Mitarbeiter lädt Ausweisdokumente und unterschriebenen Vertrag hoch.'
				}
			}
		},
		creditDone: {
			title: 'Geschafft! Nach einer finalen, positiven Bewertung ist das Geld bald auf dem Konto Ihres Kunden.',
			amount: 'Betrag',
			button: 'schließen'
		},
		skipContract: {
			title: 'Ihre Anfrage ist erfolgreich eingegangen.',
			subtitle:
				'Unser Team prüft gerade Ihren Antrag und <strong>kontaktiert Sie heute</strong> innerhalb der nächsten 10 Minuten.',
			hotlineDescription:
				'Wenn Sie die Entscheidung zu Ihrem Antrag sofort erfahren möchten, rufen Sie einfach direkt unseren Vermittlerberater unter <a class="link primary" href="tel:+4921616907057" rel="noopener noreferer">02161 - 690 7057</a> an.'
		}
	},
	api: {
		error: {
			connection: 'Verbindungsfehler, bitte versuchen Sie es später noch einmal.',
			missingData: 'Es fehlen Daten, um eine Verbindung herzustellen.'
		}
	},
	partial: {
		header: {
			backToOverview: 'Zurück zur Statusübersicht'
		},
		footer: {
			imprint: 'Impressum',
			privacy: 'Datenschutz',
			cookieSettings: 'Cookie Einstellungen'
		},
		loading: {
			title: 'Wir prüfen Ihre Daten. Das kann einige Sekunden dauern.',
			login: 'Sie werden eingeloggt.'
		},
		serviceHotline: {
			description:
				'Bei Fragen steht Ihnen unser Service Team 24 Stunden und 7 Tage die Woche unter der Rufnummer <a class="link" href="tel:{{phoneHref}}" rel="noopener noreferrer">{{phone}}</a> zur Verfügung.',
			phone: '02161 - 90 60 599',
			phoneHref: '+4921619060599'
		},
		bcoResults: {
			title: 'Gute Neuigkeiten!',
			subtitle: 'Ihr Kredit wurde vorläufig genehmigt.'
		},
		nextSteps: {
			title: 'So gehts weiter',
			uploadDocuments: 'Dokumente hochladen',
			identificationAndSigning: 'Identifikation und Unterschrift',
			payout: 'Auszahlung'
		},
		editMarker: {
			text: 'bestehender Antrag in Bearbeitung'
		},
		applicant: {
			first: 'Kreditnehmer 1',
			second: 'Kreditnehmer 2'
		}
	},
	validation: {
		error: {
			hasEmptyFields: 'Es gibt nicht ausgefüllte Pflichtfelder.',
			invalid: 'Es gibt ungültige Feldeingaben.'
		}
	}
};
export default messages;
