import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Page from './Page.d';

import Subpage from 'types/enums/Subpage.d';

const initialState: Page = {
	currentSubpage: null,
	prevHistorySubpage: null,
	hideBackLink: false
};

export const slice = createSlice({
	name: 'Page',
	initialState: initialState,
	reducers: {
		setCurrentSubpage: (state, action: PayloadAction<Subpage | string>) => {
			state.currentSubpage = action.payload;
		},
		setPrevHistorySubpage: (state, action: PayloadAction<Subpage | string>) => {
			state.prevHistorySubpage = action.payload;
		},
		setHideBackLink: (state, action: PayloadAction<boolean>) => {
			state.hideBackLink = action.payload;
		},
		reset: () => initialState
	}
});

export const { setCurrentSubpage, setPrevHistorySubpage, setHideBackLink, reset } = slice.actions;
export default slice.reducer;
