import React from 'react';

import styles from './CSSIcon.module.scss';

type Props = {
	name: string;
};

const CSSIcon = (props: Props) => {
	return (
		<div
			className={styles.cssIcon}
			data-iconname={props.name}
			style={{ backgroundImage: 'url(/img/icons/' + props.name + '.svg)' }}
		/>
	);
};
export default CSSIcon;
