const mobileBreakpoint = 576;

export const isDesktop = () => {
	return window.innerWidth > mobileBreakpoint;
};

export const isMobile = () => {
	return window.innerWidth <= mobileBreakpoint;
};

export const useViewUtils = () => {
	const mobileBreakpoint = 576;

	const isDesktop = () => {
		return window.innerWidth > mobileBreakpoint;
	};

	const isMobile = () => {
		return window.innerWidth <= mobileBreakpoint;
	};

	return {
		isDesktop,
		isMobile
	};
};
