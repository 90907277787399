import axios from 'axios';
import { showApiConnectionError, showApiMissingDataError } from 'utils/validation/validation';
import store from 'store/store';
import { setIsLoadingPage } from 'store/Loading/Loading';
import { getApiBaseURL } from 'utils/api/api';

const url = getApiBaseURL() + '/api/applications/';

export const getApplications = async (
	applicationId: string,
	successCb: Function = () => {},
	errorCb: Function = () => {}
) => {
	if (applicationId) {
		store.dispatch(setIsLoadingPage(true));
		await axios
			.get(url + applicationId)
			.then((res) => {
				onSuccess(res.data, successCb);
			})
			.catch(() => {
				onError(false, errorCb);
			});
	} else {
		onError(true, errorCb);
	}
};

const onSuccess = (data: any, successCb: Function = () => {}) => {
	store.dispatch(setIsLoadingPage(false));
	successCb(data);
};

const onError = (showMissingDataError: boolean = false, errorCb: Function = () => {}) => {
	store.dispatch(setIsLoadingPage(false));
	if (showMissingDataError) {
		showApiMissingDataError();
	} else {
		showApiConnectionError();
	}
	errorCb();
};
