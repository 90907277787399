import { cloneDeep, findIndex } from 'lodash';
import Document from 'types/interfaces/Document.d';
import MissingDocument from 'types/interfaces/MissingDocument.d';

export const fileToBase64 = (file: any) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		// Read file content on file loaded event
		reader.onload = function (event) {
			resolve(event.target.result);
		};

		// Convert data to base64
		reader.readAsDataURL(file);
	});
};

export const base64ToFile = (bas64String: string, filename: string, mimeType: string) => {
	return fetch('data:text/plain;base64,' + bas64String)
		.then((res) => {
			return res.arrayBuffer();
		})
		.then((buf) => {
			return new File([buf], filename, { type: mimeType });
		});
};

export const prepareDocumentsForUpload = (
	id: number,
	applicant: number,
	droppedFiles: Array<any>,
	uploadedFiles: Array<Document>,
	setUploadedFiles: Function,
	missingDocuments: Array<any>,
	setMissingDocuments: Function,
	successCb: Function = () => {},
	errorCb: Function = () => {}
) => {
	const maxFilesize = 10 * 1000000; // 10MB
	let fileSizesAreValid = true;

	for (const file of droppedFiles) {
		if (file.size > maxFilesize) {
			fileSizesAreValid = false;
		}
	}

	if (fileSizesAreValid) {
		const newUploadedFiles = cloneDeep(uploadedFiles);
		const newMissingDocuments = cloneDeep(missingDocuments);
		for (const file of droppedFiles) {
			newUploadedFiles.push(file);

			// eslint-disable-next-line no-loop-func
			const index = findIndex(newMissingDocuments, (item: Document) => {
				return item.id === id && item.applicant === applicant;
			});
			if (index !== -1) {
				if (!newMissingDocuments[index].file) {
					newMissingDocuments[index].file = [];
				}
				newMissingDocuments[index].file.push(file);
			}
		}
		setUploadedFiles(newUploadedFiles);
		setMissingDocuments(newMissingDocuments);
		successCb();
	} else {
		console.log('File size too big');
		errorCb();
	}
};

export const removeDocumentForUpload = (
	index: number,
	id: number,
	applicant: number,
	uploadedFiles: Array<Document>,
	setUploadedFiles: Function,
	missingDocuments: Array<MissingDocument>,
	setMissingDocuments: Function,
	successCb: Function = () => {}
) => {
	const newUploadedFiles = cloneDeep(uploadedFiles);
	newUploadedFiles.splice(index, 1);
	setUploadedFiles(newUploadedFiles);

	const newMissingDocuments = cloneDeep(missingDocuments);
	const documentIndex = findIndex(newMissingDocuments, (item: Document) => {
		return item.id === id && item.applicant === applicant;
	});

	if (documentIndex !== -1) {
		if (newMissingDocuments[documentIndex].file) {
			newMissingDocuments[documentIndex].file.splice(index, 1);
		}
	}
	setMissingDocuments(newMissingDocuments);
	successCb();
};
