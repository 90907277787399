import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import m from 'utils/messages/messages';

import Application from 'types/interfaces/Application.d';
import ApplicationStatusCode from 'types/enums/ApplicationStatusCode.d';

import styles from './ApplicationItemHeader.module.scss';

type Props = {
	application: Application;
};

const ApplicationItemHeader = (props: Props) => {
	const mapStatus = () => {
		switch (props.application.status) {
			case ApplicationStatusCode.IN_DECISION:
				return m('page.statusOverview.result.inDecision', 'global');
			case ApplicationStatusCode.PENDING:
				return m('page.statusOverview.result.pending', 'global');
			case ApplicationStatusCode.APPROVED:
				return m('page.statusOverview.result.approved', 'global');
			case ApplicationStatusCode.REJECTED:
				return m('page.statusOverview.result.rejected', 'global');
			case ApplicationStatusCode.ACCOUNTED:
				return m('page.statusOverview.result.accounted', 'global');
			case ApplicationStatusCode.DISBURSED:
				return m('page.statusOverview.result.disbursed', 'global');
			case ApplicationStatusCode.CANCELLED:
				return m('page.statusOverview.result.cancelled', 'global');
			default:
				return '-';
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={classNames([styles.item, styles.itemBorderRight])}>
				<span className={styles.itemHint}>
					{m('page.statusOverview.header.date', 'global')}
				</span>
				{props.application.createDate
					? moment(props.application.createDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
					: '-'}
			</div>
			<div className={classNames([styles.item, styles.itemBold])}>
				<span className={styles.itemHint}>
					{m('page.statusOverview.header.oid', 'global')}
				</span>
				{props.application.applicationId ?? '-'}
			</div>
			<div className={styles.item}>
				<span className={styles.itemHint}>
					{m('page.statusOverview.header.customerName', 'global')}
				</span>
				{props.application.lastname ?? '-'}
			</div>
			<div className={styles.item}>
				<span className={styles.itemHint}>
					{m('page.statusOverview.header.result', 'global')}
				</span>
				{mapStatus()}
			</div>
			<div className={styles.item}>
				<span className={styles.itemHint}>
					{m('page.statusOverview.header.hint', 'global')}
				</span>
				{!!props.application.hasInfo
					? m('page.statusOverview.hint.yes', 'global')
					: m('page.statusOverview.hint.no', 'global')}
			</div>
			<div className={styles.item}>
				<span className={styles.itemHint}>
					{m('page.statusOverview.header.conditions', 'global')}
				</span>
				{!!props.application.hasMissingDocs
					? m('page.statusOverview.conditions.yes', 'global')
					: m('page.statusOverview.conditions.no', 'global')}
			</div>
		</div>
	);
};
export default ApplicationItemHeader;
