import React from 'react';

type Props = {
	color?: string;
};

const SvgRefresh = (props: Props) => {
	const width = 22;
	const height = 18;
	const defaultColor = '#000000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M13.376 15.456a7.352 7.352 0 01-2.776.543c-3.92 0-7.13-3.067-7.377-6.927l.952.952a.596.596 0 00.847 0 .6.6 0 00.001-.849L3.024 7.176a.599.599 0 00-.848 0l-2 1.999a.6.6 0 10.847.849l1-.998C2.245 13.57 6.002 17.2 10.6 17.2a8.556 8.556 0 003.227-.632.599.599 0 10-.451-1.112m7.647-8.28a.597.597 0 00-.847 0l-.998.998C18.953 3.63 15.198 0 10.6 0a8.55 8.55 0 00-3.228.632.6.6 0 00.451 1.112A7.354 7.354 0 0110.6 1.2c3.921 0 7.13 3.068 7.377 6.928l-.954-.952a.597.597 0 00-.847 0 .598.598 0 000 .848l2 2c.117.118.27.176.424.176a.593.593 0 00.423-.176l2-2a.598.598 0 000-.848"
			/>
		</svg>
	);
};
export default SvgRefresh;
