import React from 'react';

const SvgCheckCircle = () => {
	const width = 138;
	const height = 125;
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<g fill="none" fillRule="evenodd">
				<path
					fill="#62B967"
					d="M58.142 97.19c-.046 0-.09 0-.136-.002a6.14 6.14 0 01-4.285-1.871l-18.076-18.73a6.025 6.025 0 01.19-8.575c2.441-2.316 6.32-2.231 8.653.19l13.846 14.346 43.488-41.247c2.446-2.316 6.317-2.229 8.65.188a6.028 6.028 0 01-.19 8.577L62.373 95.507a6.16 6.16 0 01-4.231 1.683"
				/>
				<path
					fill="#FFF"
					d="M30.069 124.138a2.734 2.734 0 01-1.625-.534C13.739 112.754 4.166 96.858 1.488 78.852-1.19 60.844 3.351 42.88 14.27 28.265c22.54-30.168 65.574-36.487 95.928-14.09 30.356 22.4 36.716 65.168 14.175 95.34a2.738 2.738 0 01-3.816.56 2.702 2.702 0 01-.567-3.795c20.744-27.769 14.892-67.132-13.046-87.746C79.002-2.085 39.396 3.734 18.654 31.5 8.604 44.95 4.426 61.487 6.89 78.06c2.464 16.572 11.273 31.198 24.808 41.186a2.7 2.7 0 01.565 3.795 2.73 2.73 0 01-2.194 1.097"
				/>
			</g>
		</svg>
	);
};
export default SvgCheckCircle;
