import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Credit from './Credit.d';
import ExternalCredit from 'types/interfaces/ExternalCredit.d';

import {
	amountDefault,
	amountMaxValue,
	amountMinValue,
	runtimeDefault,
	runtimeSteps
} from 'constants/Credit';

const initialState: Credit = {
	amount: amountDefault,
	runtime: runtimeDefault,
	externalCredits: [],
	ccbData: {}
};

export const slice = createSlice({
	name: 'Credit',
	initialState: initialState,
	reducers: {
		setAmount: (state, action: PayloadAction<number>) => {
			if (action.payload > amountMaxValue) {
				state.amount = amountMaxValue;
			} else if (action.payload < amountMinValue) {
				state.amount = amountMinValue;
			} else {
				state.amount = action.payload;
			}
		},
		setRuntime: (state, action: PayloadAction<number>) => {
			if (action.payload > runtimeSteps[runtimeSteps.length - 1]) {
				state.runtime = runtimeSteps[runtimeSteps.length - 1];
			} else if (action.payload < runtimeSteps[0]) {
				state.runtime = runtimeSteps[0];
			} else {
				state.runtime = action.payload;
			}
		},
		setExternalCredits: (state, action: PayloadAction<Array<ExternalCredit>>) => {
			state.externalCredits = action.payload;
		},
		setCcbData: (state, action: PayloadAction<any>) => {
			state.ccbData = action.payload;
		},
		reset: () => initialState
	}
});

export const { setAmount, setRuntime, setExternalCredits, setCcbData, reset } = slice.actions;
export default slice.reducer;
