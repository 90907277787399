import history from 'config/history';

import Subpage from 'types/enums/Subpage.d';

export const goToPage = (subpage: Subpage | string, queryString?: string) => {
	let subpageString = '';
	subpageString = subpage.trim() ? '/' + subpage : '/';
	history.push({
		pathname: subpageString,
		search: queryString
	});
};

export const replaceSubpageVariable = (subpage: Subpage | string) => {
	let formattedSubapage: string = subpage + '';
	if (subpage) {
		formattedSubapage = formattedSubapage.replace(':processOption', 'kombination');
		formattedSubapage = formattedSubapage.replace(':origin', 'antrag-empfangen');
	}
	return formattedSubapage;
};

export const preventHistoryBackFor = (preventedLocation: Subpage | string) => {
	history.listen((newLocation, action) => {
		if (action === 'POP' && newLocation.pathname === '/' + preventedLocation) {
			history.go(1);
		}
	});
};
