export const useKeycodeUtils = () => {
	const getReturn = (event: any) => {
		return event.keyCode === 13;
	};

	const getTab = (event: any) => {
		return event.keyCode === 'Tab' || event.keyCode === 9;
	};

	const getShift = (event: any) => {
		return event.shiftKey;
	};

	const getReturnOrTab = (event: any) => {
		return getReturn(event) || (!getShift(event) && getTab(event));
	};

	const getNotShiftAndTab = (event: any) => {
		return !getShift(event) && getTab(event);
	};

	const getShiftAndTab = (event: any) => {
		return getShift(event) && getTab(event);
	};

	const getBackspace = (event: any) => {
		return event.keyCode === 8 || event.keyCode === 'Backspace';
	};

	const getArrowUp = (event: any) => {
		return event.keyCode === 38;
	};

	const getArrowDown = (event: any) => {
		return event.keyCode === 40;
	};

	const getArrowLeft = (event: any) => {
		return event.keyCode === 37;
	};

	const getArrowRight = (event: any) => {
		return event.keyCode === 39;
	};

	const getAuml = (event: any) => {
		return event.keyCode === 222;
	};

	const getOuml = (event: any) => {
		return event.keyCode === 186;
	};

	const getUuml = (event: any) => {
		return event.keyCode === 219;
	};

	return {
		getReturn,
		getTab,
		getShift,
		getReturnOrTab,
		getNotShiftAndTab,
		getShiftAndTab,
		getBackspace,
		getArrowUp,
		getArrowDown,
		getArrowLeft,
		getArrowRight,
		getAuml,
		getOuml,
		getUuml
	};
};

export const getReturn = (event: any) => {
	return event.keyCode === 13;
};

export const getTab = (event: any) => {
	return event.keyCode === 'Tab' || event.keyCode === 9;
};

export const getShift = (event: any) => {
	return event.shiftKey;
};

export const getReturnOrTab = (event: any) => {
	return getReturn(event) || (!getShift(event) && getTab(event));
};

export const getNotShiftAndTab = (event: any) => {
	return !getShift(event) && getTab(event);
};

export const getShiftAndTab = (event: any) => {
	return getShift(event) && getTab(event);
};

export const getBackspace = (event: any) => {
	return event.keyCode === 8 || event.keyCode === 'Backspace';
};

export const getArrowUp = (event: any) => {
	return event.keyCode === 38;
};

export const getArrowDown = (event: any) => {
	return event.keyCode === 40;
};

export const getArrowLeft = (event: any) => {
	return event.keyCode === 37;
};

export const getArrowRight = (event: any) => {
	return event.keyCode === 39;
};
