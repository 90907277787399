import React from 'react';
import classNames from 'classnames';

import ButtonStyle from './types/ButtonStyle.d';
import ButtonSize from './types/ButtonSize.d';
import ButtonType from './types/ButtonType.d';

import styles from './Button.module.scss';

type Props = {
	onClick?: Function;
	link?: string;
	newTab?: boolean;
	buttonStyle?: ButtonStyle;
	buttonSize?: ButtonSize;
	buttonType?: ButtonType;
	fullWidthDesktop?: boolean;
	fullWidthTablet?: boolean;
	fullWidthMobile?: boolean;
	disabled?: boolean;
	loading?: boolean;
	tabindex?: number;
	testId?: string;
	prependIcon?: JSX.Element;
	appendIcon?: JSX.Element;
	children: any;
};

export const Button = (props: Props) => {
	const getButtonStyle = () => {
		switch (props.buttonStyle) {
			case ButtonStyle.SECONDARY:
				return styles.secondary;
			case ButtonStyle.LINK:
				return styles.link;
			default:
				return styles.primary;
		}
	};

	const getButtonSize = () => {
		switch (props.buttonSize) {
			case ButtonSize.SMALL:
				return styles.s;
			case ButtonSize.LARGE:
				return styles.l;
			default:
				return styles.m;
		}
	};

	const onClick = (event: any) => {
		props.onClick(event);
	};

	const getClasses = () => {
		return [
			styles.button,
			getButtonStyle(),
			getButtonSize(),
			props.disabled ? styles.disabled : null,
			props.loading ? styles.loading : null,
			props.fullWidthDesktop ? styles.fullWidthDesktop : null,
			props.fullWidthTablet ? styles.fullWidthTablet : null,
			props.fullWidthMobile ? styles.fullWidthMobile : null
		];
	};

	const getContent = () => {
		return (
			<>
				{props.loading ? (
					<span className={styles.loadingAnimation}>
						<span className={styles.loadingAnimationBounce1} />
						<span className={styles.loadingAnimationBounce2} />
						<span className={styles.loadingAnimationBounce3} />
					</span>
				) : (
					<span className={styles.text}>{props.children}</span>
				)}
			</>
		);
	};

	return (
		<>
			{props.link ? (
				<a
					className={classNames(getClasses())}
					href={props.link}
					target={props.newTab ? '_blank' : null}
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
					rel="noopener noreferrer"
				>
					{props.prependIcon && <span className={styles.icon}>{props.prependIcon}</span>}
					{getContent()}
				</a>
			) : (
				<button
					className={classNames(getClasses())}
					onClick={onClick}
					type={props.buttonType ?? 'button'}
					disabled={props.disabled}
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
				>
					{props.prependIcon && <span className={styles.icon}>{props.prependIcon}</span>}
					{getContent()}
					{props.appendIcon && <span className={styles.icon}>{props.appendIcon}</span>}
				</button>
			)}
		</>
	);
};
export default Button;
