import React from 'react';

type Props = {
	color?: string;
};

const SvgSecureConnection = (props: Props) => {
	const width = 139;
	const height = 20;
	const defaultColor = '#000000';
	const style = {
		fill: props.color ? props.color : defaultColor
	};
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={style}
				d="M30.15,13.92a3.66,3.66,0,0,1-1-.36c0-.18,0-.34,0-.48a3.15,3.15,0,0,1,.09-.42,4.12,4.12,0,0,0,1,.37,5,5,0,0,0,1.05.12,2.2,2.2,0,0,0,1.34-.35,1.16,1.16,0,0,0,.49-1,1.36,1.36,0,0,0-.17-.69,1.38,1.38,0,0,0-.48-.48,6.52,6.52,0,0,0-.87-.42L31,10a3.41,3.41,0,0,1-1.37-.9,2.08,2.08,0,0,1-.49-1.41,1.93,1.93,0,0,1,.7-1.6,3.19,3.19,0,0,1,2-.56A5.35,5.35,0,0,1,34,5.88a4.3,4.3,0,0,1-.07.45,4.19,4.19,0,0,1-.1.42,5.42,5.42,0,0,0-.92-.26,5,5,0,0,0-.93-.09,1.94,1.94,0,0,0-1.25.34,1.06,1.06,0,0,0-.43.9,1.26,1.26,0,0,0,.59,1.14,4.75,4.75,0,0,0,.8.37l.65.24a5.94,5.94,0,0,1,1,.5,2.26,2.26,0,0,1,.69.75,2.14,2.14,0,0,1,.27,1.12A2.17,2.17,0,0,1,33.93,13a2.3,2.3,0,0,1-1,.81,3.81,3.81,0,0,1-1.6.29A5.17,5.17,0,0,1,30.15,13.92Z"
			/>
			<path
				style={style}
				d="M35.66,6.59a.65.65,0,0,1-.19-.48.69.69,0,0,1,.18-.49.64.64,0,0,1,.47-.18.66.66,0,0,1,.47.18.69.69,0,0,1,.18.49.68.68,0,0,1-.18.48.67.67,0,0,1-.47.19A.64.64,0,0,1,35.66,6.59Zm0,1.41h1v5.88h-1Z"
			/>
			<path
				style={style}
				d="M39.31,13.68a2.39,2.39,0,0,1-1-1,3.73,3.73,0,0,1-.35-1.7,3.7,3.7,0,0,1,.36-1.73,2.23,2.23,0,0,1,1-1,3.19,3.19,0,0,1,1.5-.33,3.54,3.54,0,0,1,1.3.23,3.19,3.19,0,0,1,0,.42,4.3,4.3,0,0,1-.07.43,2.94,2.94,0,0,0-1.15-.22,1.69,1.69,0,0,0-1.36.59A2.53,2.53,0,0,0,39.05,11a3.09,3.09,0,0,0,.21,1.19,1.67,1.67,0,0,0,1.61,1,2.54,2.54,0,0,0,.65-.09,2.66,2.66,0,0,0,.62-.22,7.71,7.71,0,0,1-.14.88,2.11,2.11,0,0,1-.57.19,3.12,3.12,0,0,1-2.12-.26Z"
			/>
			<path
				style={style}
				d="M47.72,8.4a2.15,2.15,0,0,1,.49,1.48v4h-1V10.2a1.75,1.75,0,0,0-.3-1.13,1.15,1.15,0,0,0-1-.37,1.68,1.68,0,0,0-.77.18,2.21,2.21,0,0,0-.62.47v4.53h-1V5.68a2.8,2.8,0,0,1,1-.16V8.88a2.19,2.19,0,0,1,.74-.77,2,2,0,0,1,1-.25A1.81,1.81,0,0,1,47.72,8.4Z"
			/>
			<path
				style={style}
				d="M54.49,11a2.27,2.27,0,0,1,0,.36H50.56a2.12,2.12,0,0,0,.57,1.42,2,2,0,0,0,1.38.45,3.49,3.49,0,0,0,1.8-.5c0,.16,0,.32-.07.5a3.53,3.53,0,0,1-.08.44,4,4,0,0,1-1.71.4,2.82,2.82,0,0,1-2.22-.82,3.31,3.31,0,0,1-.73-2.3,4.05,4.05,0,0,1,.28-1.56,2.41,2.41,0,0,1,.87-1.09,2.6,2.6,0,0,1,1.47-.39,2.44,2.44,0,0,1,1.32.33,2,2,0,0,1,.8.92,3.35,3.35,0,0,1,.27,1.39C54.51,10.64,54.5,10.79,54.49,11ZM51,9.16a2.29,2.29,0,0,0-.47,1.3h2.91a2.17,2.17,0,0,0-.35-1.29,1.21,1.21,0,0,0-1-.47A1.3,1.3,0,0,0,51,9.16Z"
			/>
			<path
				style={style}
				d="M59.11,8c0,.15,0,.31,0,.48a2.24,2.24,0,0,1-.08.42,3.17,3.17,0,0,0-.71-.07,1.92,1.92,0,0,0-.74.15,1.65,1.65,0,0,0-.61.43v4.52h-1V8h1l0,.85a2.06,2.06,0,0,1,.63-.73,1.5,1.5,0,0,1,.86-.25A3.08,3.08,0,0,1,59.11,8Z"
			/>
			<path
				style={style}
				d="M64.44,11c0,.16,0,.28,0,.36H60.51a2,2,0,0,0,.57,1.42,1.93,1.93,0,0,0,1.37.45,3.49,3.49,0,0,0,1.8-.5c0,.16,0,.32-.06.5s-.06.32-.09.44a4,4,0,0,1-1.71.4,2.8,2.8,0,0,1-2.21-.82,3.31,3.31,0,0,1-.74-2.3,4.05,4.05,0,0,1,.29-1.56,2.41,2.41,0,0,1,.87-1.09,2.56,2.56,0,0,1,1.46-.39,2.5,2.5,0,0,1,1.33.33,2.11,2.11,0,0,1,.8.92,3.52,3.52,0,0,1,.26,1.39C64.45,10.64,64.45,10.79,64.44,11ZM61,9.16a2.22,2.22,0,0,0-.46,1.3h2.9a2.17,2.17,0,0,0-.34-1.29,1.23,1.23,0,0,0-1-.47A1.3,1.3,0,0,0,61,9.16Z"
			/>
			<path
				style={style}
				d="M74.14,5.68l-2.65,8.2H70.32l-2.64-8.2H68.8l2.12,7.15,2.17-7.15Z"
			/>
			<path
				style={style}
				d="M79.38,11c0,.16,0,.28,0,.36H75.45A2.12,2.12,0,0,0,76,12.73a1.94,1.94,0,0,0,1.38.45,3.49,3.49,0,0,0,1.8-.5c0,.16,0,.32-.07.5s-.06.32-.09.44a4,4,0,0,1-1.7.4,2.82,2.82,0,0,1-2.22-.82,3.31,3.31,0,0,1-.74-2.3,4.05,4.05,0,0,1,.29-1.56,2.41,2.41,0,0,1,.87-1.09A2.58,2.58,0,0,1,77,7.86a2.47,2.47,0,0,1,1.33.33,2,2,0,0,1,.8.92,3.35,3.35,0,0,1,.27,1.39C79.4,10.64,79.39,10.79,79.38,11ZM75.91,9.16a2.29,2.29,0,0,0-.46,1.3h2.9A2.17,2.17,0,0,0,78,9.17a1.22,1.22,0,0,0-1-.47A1.32,1.32,0,0,0,75.91,9.16Z"
			/>
			<path
				style={style}
				d="M84,8a4.12,4.12,0,0,1,0,.48,2.29,2.29,0,0,1-.07.42,3.23,3.23,0,0,0-.71-.07,1.92,1.92,0,0,0-.74.15,1.65,1.65,0,0,0-.61.43v4.52h-1V8h1l0,.85a1.88,1.88,0,0,1,.63-.73,1.5,1.5,0,0,1,.86-.25A3.08,3.08,0,0,1,84,8Z"
			/>
			<path
				style={style}
				d="M88.8,8.19a2.22,2.22,0,0,1,.82,1,4.4,4.4,0,0,1,.29,1.68,3.45,3.45,0,0,1-.71,2.31A2.72,2.72,0,0,1,87,14,6,6,0,0,1,85,13.69v-8a2.8,2.8,0,0,1,1-.16V8.76a1.8,1.8,0,0,1,.64-.67,1.69,1.69,0,0,1,.93-.23A2.27,2.27,0,0,1,88.8,8.19Zm-.36,4.38a2.75,2.75,0,0,0,.44-1.64,2.86,2.86,0,0,0-.41-1.67,1.39,1.39,0,0,0-1.19-.56A1.69,1.69,0,0,0,86,9.28v3.77a4.11,4.11,0,0,0,1,.13A1.6,1.6,0,0,0,88.44,12.57Z"
			/>
			<path
				style={style}
				d="M91.37,6.59a.65.65,0,0,1-.19-.48.69.69,0,0,1,.18-.49.7.7,0,0,1,.94,0,.69.69,0,0,1,.18.49.68.68,0,0,1-.18.48.66.66,0,0,1-.93,0ZM91.32,8h1v5.88h-1Z"
			/>
			<path
				style={style}
				d="M98.34,8.4a2.14,2.14,0,0,1,.48,1.48v4h-1V10.2a1.75,1.75,0,0,0-.3-1.13,1.13,1.13,0,0,0-1-.37,1.71,1.71,0,0,0-.77.18,2.16,2.16,0,0,0-.61.47v4.53h-1V8h1l.06.9a2,2,0,0,1,.74-.79,2,2,0,0,1,1-.25A1.83,1.83,0,0,1,98.34,8.4Z"
			/>
			<path
				style={style}
				d="M105.1,5.52v8.36h-.95l-.05-.79a1.86,1.86,0,0,1-.64.69,1.76,1.76,0,0,1-.95.24,2.2,2.2,0,0,1-1.24-.34,2.12,2.12,0,0,1-.82-1,4.31,4.31,0,0,1-.29-1.67,3.45,3.45,0,0,1,.71-2.31A2.69,2.69,0,0,1,103,7.86a6.8,6.8,0,0,1,1,.07V5.68A2.88,2.88,0,0,1,105.1,5.52Zm-1,7.08V8.83a3.68,3.68,0,0,0-1-.13,1.62,1.62,0,0,0-1.41.6,2.75,2.75,0,0,0-.45,1.66,2.8,2.8,0,0,0,.41,1.66,1.39,1.39,0,0,0,1.2.56A1.72,1.72,0,0,0,104.08,12.6Z"
			/>
			<path
				style={style}
				d="M111.51,8v5.88h-.94l-.06-.9a2.23,2.23,0,0,1-.74.78,2,2,0,0,1-1,.26,1.81,1.81,0,0,1-1.42-.54,2.11,2.11,0,0,1-.49-1.49V8h1v3.67a1.82,1.82,0,0,0,.3,1.15,1.18,1.18,0,0,0,1,.36,1.6,1.6,0,0,0,.77-.19,2.18,2.18,0,0,0,.62-.46V8Z"
			/>
			<path
				style={style}
				d="M117.5,8.4A2.15,2.15,0,0,1,118,9.88v4h-1V10.2a1.82,1.82,0,0,0-.29-1.13,1.15,1.15,0,0,0-1-.37,1.65,1.65,0,0,0-.77.18,2.08,2.08,0,0,0-.62.47v4.53h-1V8h.95l.06.9a2,2,0,0,1,.74-.79,2,2,0,0,1,1-.25A1.82,1.82,0,0,1,117.5,8.4Z"
			/>
			<path
				style={style}
				d="M124.27,8.19v5.6a2.79,2.79,0,0,1-.69,2,2.81,2.81,0,0,1-2.09.71,3.82,3.82,0,0,1-1.7-.37,5.88,5.88,0,0,1,.09-.9,3.12,3.12,0,0,0,.79.29,3.36,3.36,0,0,0,.8.1,1.56,1.56,0,0,0,1.76-1.75v-.76a1.69,1.69,0,0,1-.62.65,1.84,1.84,0,0,1-.94.23,2.22,2.22,0,0,1-1.24-.34,2.18,2.18,0,0,1-.82-1,4.31,4.31,0,0,1-.29-1.67A3.45,3.45,0,0,1,120,8.68a2.69,2.69,0,0,1,2.17-.82A6,6,0,0,1,124.27,8.19Zm-1,4.42V8.83a3.48,3.48,0,0,0-1-.13,1.66,1.66,0,0,0-1.42.6,2.75,2.75,0,0,0-.45,1.66,2.8,2.8,0,0,0,.41,1.66,1.43,1.43,0,0,0,1.21.56A1.68,1.68,0,0,0,123.23,12.61Z"
			/>
			<path
				style={style}
				d="M4.54,5.19a.6.6,0,0,0,.6-.6,3.36,3.36,0,0,1,6.71,0,.6.6,0,1,0,1.19,0A4.55,4.55,0,0,0,4,4.59.6.6,0,0,0,4.54,5.19Z"
			/>
			<circle style={style} cx="8.49" cy="12.49" r="0.99" />
			<path
				style={style}
				d="M14.42,6H2.57A2.57,2.57,0,0,0,0,8.54v8.89A2.57,2.57,0,0,0,2.57,20H14.42A2.57,2.57,0,0,0,17,17.43V8.54A2.57,2.57,0,0,0,14.42,6ZM15.8,17.43a1.38,1.38,0,0,1-1.38,1.38H2.57a1.38,1.38,0,0,1-1.38-1.38V8.54A1.38,1.38,0,0,1,2.57,7.16H14.42A1.38,1.38,0,0,1,15.8,8.54Z"
			/>
		</svg>
	);
};
export default SvgSecureConnection;
