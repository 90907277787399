export enum ApplicationStatusCode {
	IN_DECISION = 'IN_DECISION',
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	ACCOUNTED = 'ACCOUNTED',
	DISBURSED = 'DISBURSED',
	CANCELLED = 'CANCELLED'
}

export default ApplicationStatusCode;
