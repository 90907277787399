/* eslint-disable */
import TagManager from 'react-gtm-module';
import axios from 'axios';
import { isServer } from 'utils/env/env';

import store from 'store/store';
import {
	setGtmType,
	setGtmStep,
	setGtmStatus,
	setGtmSubstatus, setGtmSubtype
} from 'store/Tracking/Tracking';

import ApplicationInfo from 'types/interfaces/ApplicationInfo.d';

export const initConsent = () => {
	if (isServer() && window) {
		window.addEventListener('UC_UI_INITIALIZED', function () {
			/* prettier-ignore-start */
			// @ts-ignore
			const enableGTM = window.UC_UI.getServicesBaseInfo()
				// @ts-ignore
				.filter(function (event: any) {
					return 'Google Tag Manager' === event.name;
				})
				.map(function (event: any) {
					return event.consent.status;
				})[0];
			if (enableGTM) {
				initGtm();
			}
			/* prettier-ignore-end */
		});

		window.addEventListener('UC_UI_VIEW_CHANGED', function (event: any) {
			/* prettier-ignore-start */
			if ('PRIVACY_BUTTON' === event.detail.view && 'SECOND_LAYER' === event.detail.previousView) {
				// @ts-ignore
				window['ga-disable-UA-58567623-28'] = !event.srcElement.UC_UI.getServicesBaseInfo().filter(function (item: any) {
					return 'Google Analytics' === item.name;
				}).map(function (item: any) {
					return item.consent.status;
				})[0], location.reload();
			} else {
				try {
					// @ts-ignore
					window['ga-disable-UA-58567623-28'] = !window.UC_UI.getServicesBaseInfo()
						.filter(function (event: any) {
							return 'Google Analytics' === event.name;
						})
						.map(function (event: any) {
							return event.consent.status;
						})[0];
				} catch (error: any) {
					// @ts-ignore
					window['ga-disable-UA-58567623-28'] = !0;
				}
				/* prettier-ignore-end */
			}
		});
	}
};

const initGtm = () => {
	axios
		.get(window.location + '')
		.then((response) => {
			if (
				response.headers &&
				response.headers.gtmid &&
				response.headers.gtmauth &&
				response.headers.gtmpreview
			) {
				TagManager.initialize({
					gtmId: response.headers.gtmid,
					auth: response.headers.gtmauth,
					preview: response.headers.gtmpreview
				});
			}
		})
		.catch(() => {});
};

export const openCookieLayer = () => {
	if (isServer() && window) {
		// @ts-ignore
		window.UC_UI.showSecondLayer();
	}
};

export const setDataToWindowObject = () => {
	const type = store.getState().Tracking.gtmType;
	const subtype = store.getState().Tracking.gtmSubtype;
	const step = store.getState().Tracking.gtmStep;
	const status = store.getState().Tracking.gtmStatus;
	const substatus = store.getState().Tracking.gtmSubstatus;
	const sanc = store.getState().Tracking.sanc;
	const uid = store.getState().Tracking.uid;

	const applicationInfo: ApplicationInfo = {
		type: type,
		subtype: subtype,
		id: null,
		branch: 'A',
		sanc: sanc,
		uid: uid
	};

	if (step) {
		applicationInfo.step = step;
	} else if (window.applicationInfo && window.applicationInfo.step) {
		applicationInfo.step = window.applicationInfo.step;
	}
	if (status) {
		applicationInfo.status = status;
	} else if (window.applicationInfo && window.applicationInfo.status) {
		applicationInfo.status = window.applicationInfo.status;
	}
	if (substatus) {
		applicationInfo.substatus = substatus;
	} else if (window.applicationInfo && window.applicationInfo.substatus) {
		applicationInfo.substatus = window.applicationInfo.substatus;
	}

	window.applicationInfo = applicationInfo;

	store.dispatch(setGtmType(null));
	store.dispatch(setGtmSubtype(null));
	store.dispatch(setGtmStep(null));
	store.dispatch(setGtmStatus(null));
	store.dispatch(setGtmSubstatus(null));

	virtualPageLoad();
};

/**
 * Should be fired on page load and page change
 */
export const virtualPageLoad = () => {
	if (process.env.NODE_ENV !== 'development') {
		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'virtual_pageview',
					page_path:
						'/' +
						store.getState().Page.currentSubpage
				}
			});
		} catch (err) {
			console.log('GTM: DataLayer.push on "event": "virtual_pageview" failed');
		}
	}
};

/**
 * Should be fired on valid next button
 */
export const continueValid = () => {
	try {
		TagManager.dataLayer({
			dataLayer: {
				event: 'continue_valid'
			}
		});
	} catch (err) {
		console.log('GTM: DataLayer.push on "event": "continue_valid" failed');
	}
};

/**
 * Should be fired on invalid next button
 */
export const continueInvalid = () => {
	try {
		TagManager.dataLayer({
			dataLayer: {
				event: 'continue_invalid'
			}
		});
	} catch (err) {
		console.log('GTM: DataLayer.push on "event": "continue_invalid" failed');
	}
};

/**
 * Should be fired on click to the previous button
 */
export const goBack = () => {
	try {
		TagManager.dataLayer({
			dataLayer: {
				event: 'return'
			}
		});
	} catch (err) {
		console.log('GTM: DataLayer.push on "event": "return" failed');
	}
};

/**
 * Should be fired after successfull application api call
 */
export const bcoResults = (bcoResults: object) => {
	try {
		TagManager.dataLayer({
			dataLayer: {
				event: 'bcoResults_application',
				bcoResults: bcoResults
			}
		});
	} catch (err) {
		console.log('GTM: bcoResults push on dataLayer failed');
	}
};

/**
 *
 * Should be fired when there is an error with DAC
 */
export const dacError = (errorCode: any) => {
	try {
		const tagManagerArgs = {
			dataLayer: {
				event: 'dca_error_tracking',
				errorCode
			}
		};
		TagManager.dataLayer(tagManagerArgs);
	} catch (err) {
		console.log('GTM: DataLayer.push on "event": "dca_error_tracking" failed');
	}
};
