import React from 'react';

type Props = {
	color?: string;
};

const SvgDropdownArrow = (props: Props) => {
	const width = 10;
	const height = 6;
	const defaultColor = '#000000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					stroke: 'none',
					fillRule: 'evenodd'
				}}
				d="M5.0029,5.5977 C4.8499,5.5977 4.6959,5.5387 4.5789,5.4217 L0.5799,1.4227 C0.3459,1.1887 0.3459,0.8087 0.5799,0.5747 C0.8149,0.3397 1.1929,0.3397 1.4279,0.5747 L5.0029,4.1487 L8.5759,0.5767 C8.8109,0.3417 9.1899,0.3417 9.4239,0.5767 C9.6579,0.8107 9.6579,1.1907 9.4239,1.4247 L5.4269,5.4217 C5.3099,5.5387 5.1559,5.5977 5.0029,5.5977"
			/>
		</svg>
	);
};
export default SvgDropdownArrow;
