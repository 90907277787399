export const roundUp = (number: number, nearestInterval: number, maxRange: number) => {
	let roundedNumber = Math.ceil(number / nearestInterval) * nearestInterval;

	if (roundedNumber <= 0) {
		roundedNumber = nearestInterval;
	} else if (roundedNumber > maxRange) {
		roundedNumber = maxRange;
	}

	return roundedNumber;
};

export const formatMoneyValue = (
	number: number,
	isDecimal: boolean = false,
	addEuroSign: boolean = false
) => {
	let formattedNumber: string;

	if (isDecimal) {
		formattedNumber = parseFloat(number + '').toFixed(2);

		const beforeComma: string = (formattedNumber + '').split('.')[0];
		let afterComma: string = (formattedNumber + '').split('.')[1];

		if (!afterComma) {
			afterComma = '00';
		} else if (afterComma && afterComma.length === 1) {
			afterComma = afterComma + '0';
		}

		formattedNumber = parseInt(beforeComma, 10).toLocaleString('de-DE') + ',' + afterComma;
	} else {
		formattedNumber = number.toLocaleString('de-DE');
	}

	return formattedNumber + (addEuroSign ? ' €' : '');
};
