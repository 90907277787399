import React from 'react';
import { Switch, Route } from 'react-router-dom';

import rootRoutes from 'routes/rootRoutes';

import RouteInit from './RouteInit/RouteInit';

import Error404 from 'components/Pages/Error/Error404';
import Version from 'components/Pages/Version/Version';

const Routes = () => {
	const routeComponents = rootRoutes.map(
		(
			{
				subpage,
				component,
				gtmType,
				gtmSubtype,
				gtmStep,
				gtmStatus,
				gtmSubstatus,
				hideBackLink
			},
			key
		) => (
			<Route exact path={subpage ? '/' + subpage.trim() : ''} key={key}>
				<RouteInit
					subpage={subpage}
					gtmType={gtmType}
					gtmSubtype={gtmSubtype}
					gtmStep={gtmStep}
					gtmStatus={gtmStatus}
					gtmSubstatus={gtmSubstatus}
					hideBackLink={hideBackLink}
				>
					{React.createElement(component)}
				</RouteInit>
			</Route>
		)
	);

	return (
		<Switch>
			{routeComponents}
			<Route path="/version">
				<Version />
			</Route>
			<Route>
				<RouteInit>
					<Error404 />
				</RouteInit>
			</Route>
		</Switch>
	);
};
export default Routes;
