import React, { useState } from 'react';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';

import SvgDropdownArrow from 'components/Atoms/SVG/SvgDropdownArrow';

import styles from './BoxCollapsible.module.scss';

type Props = {
	activeDefault?: boolean;
	icon?: string | JSX.Element;
	title: string | JSX.Element;
	subtitle?: string | JSX.Element;
	contentBody?: string | JSX.Element;
};

const BoxCollapsible = (props: Props) => {
	const [active, setActive] = useState<boolean>(!!props.activeDefault);
	return (
		<div className={classNames([styles.box, active ? styles.active : null])}>
			<div className={styles.header}>
				{props.icon && <div className={styles.headerIcon}>{props.icon}</div>}
				<div className={styles.headerContent}>
					<h3 className={styles.title}>{props.title}</h3>
					{props.subtitle && <p className={styles.subtitle}>{props.subtitle}</p>}
				</div>
			</div>
			<div className={styles.body}>
				<div className={styles.divider} />
				{props.contentBody}
			</div>
			<div className={styles.toggle}>
				<div
					className={classNames(['link', active ? styles.active : null])}
					onClick={() => {
						setActive(!active);
					}}
				>
					{active ? m('box.close', 'fields') : m('box.readMore', 'fields')}
					<SvgDropdownArrow />
				</div>
			</div>
		</div>
	);
};
export default BoxCollapsible;
