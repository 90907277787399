import React from 'react';

type Props = {
	color?: string;
};

const SvgWarning = (props: Props) => {
	const width = 18;
	const height = 17;
	const defaultColor = '#000000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<circle
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				cx="9"
				cy="12.93"
				r="0.95"
			/>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M9,5.45a.82.82,0,0,0-.82.83L8.5,11a.5.5,0,0,0,1,0l.32-4.68A.82.82,0,0,0,9,5.45Z"
			/>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M9,1a1,1,0,0,1,.91.55l7,12.93A1,1,0,0,1,16,16H2.05a1,1,0,0,1-.92-1.52l7-12.93A1,1,0,0,1,9,1M9,0A2,2,0,0,0,7.2,1.07L.24,14a2,2,0,0,0,1.81,3H16a2,2,0,0,0,1.81-3l-7-12.93A2,2,0,0,0,9,0Z"
			/>
		</svg>
	);
};
export default SvgWarning;
