const { protocol, host } = window.location;

const oidcConfig = {
	client_id: 'application-management-frontend',
	redirect_uri: `${protocol}//${host}/login-erfolgreich`,
	scope: 'openid',
	authority: `${protocol}//${host}`,
	client_secret: 'secret',
	service_worker_relative_url: '/OidcServiceWorker.js',
	service_worker_only: false
};

export default oidcConfig;
