import React, { useEffect } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc-context';
import { goToPage } from 'utils/pages/pages';
import { isAuthEnabled } from 'utils/env/env';

import Page from 'components/Organisms/Page/Page';

import Subpage from 'types/enums/Subpage.d';

const DefaultLoginSuccess = () => {
	return <></>;
};

const AuthLoginSuccess = () => {
	const { accessToken } = useOidcAccessToken();

	useEffect(() => {
		if (accessToken) {
			goToPage(Subpage.STATUS_OVERVIEW);
		}
	}, [accessToken]);

	return (
		<>
			<DefaultLoginSuccess />
		</>
	);
};

const LoginSuccess = () => {
	return <Page>{isAuthEnabled() ? <AuthLoginSuccess /> : <DefaultLoginSuccess />}</Page>;
};
export default LoginSuccess;
