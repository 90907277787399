import React from 'react';
import classNames from 'classnames';

import SvgCheckBold from 'components/Atoms/SVG/SvgCheckBold';

import styles from './Check.module.scss';

type Props = {
	active?: boolean;
};

const Check = (props: Props) => {
	return (
		<span className={classNames(styles.wrapper, props.active ? styles.active : null)}>
			<SvgCheckBold />
		</span>
	);
};
export default Check;
