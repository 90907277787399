import React, { forwardRef, useState } from 'react';
import classNames from 'classnames';
import {
	getArrowLeft,
	getArrowRight,
	getNotShiftAndTab,
	getShiftAndTab
} from 'utils/keycodes/keycodes';
import { focusRef } from 'utils/refs/refs';

import { Text } from 'components/Atoms/Text';
import Check from 'components/Atoms/Partials/Check/Check';
import SvgDropdownArrow from 'components/Atoms/SVG/SvgDropdownArrow';

import styles from './ColumnInputItem.module.scss';

type Props = {
	stretch?: boolean;
	currentValue: string | null;
	value: string;
	onChange: Function;
	title: string | JSX.Element;
	subtitle?: string | JSX.Element;
	iconHeadlinePrepend?: JSX.Element;
	iconHeadlineAppend?: JSX.Element;
	list?: string | JSX.Element;
	toggleButton?: string | JSX.Element;
	toggleText?: string | JSX.Element;
	nextRef?: any;
	prevRef?: any;
	testId?: string;
};

const ColumnInputItem = forwardRef((props: Props, ref: any) => {
	const [show, setShow] = useState(false);

	const toggle = (event: any) => {
		event.stopPropagation();
		setShow(!show);
	};

	const onFocus = (event: any) => {
		event.preventDefault();
		onChange();
	};

	const onBlur = (event: any) => {
		event.preventDefault();
		onChange();
	};

	const onChange = () => {
		props.onChange(props.value);
	};

	const handleKeyDown = (event: any) => {
		if (getNotShiftAndTab(event)) {
			event.preventDefault();
			focusRef(ref, props.nextRef);
		} else if (getShiftAndTab(event)) {
			event.preventDefault();
			focusRef(ref, props.prevRef);
		} else if (getArrowLeft(event)) {
			event.preventDefault();
			focusRef(ref, props.prevRef);
		} else if (getArrowRight(event)) {
			event.preventDefault();
			focusRef(ref, props.nextRef);
		}
	};

	return (
		<div
			ref={ref}
			onFocus={onFocus}
			onBlur={onBlur}
			onKeyDown={handleKeyDown}
			tabIndex={0}
			className={classNames([
				styles.wrapper,
				props.stretch ? styles.stretch : null,
				props.currentValue === props.value ? styles.active : null
			])}
			data-testid={props.testId}
		>
			<div className={styles.content}>
				<h3
					className={classNames([
						styles.headline,
						props.iconHeadlinePrepend ? styles.hasPrependIcon : null,
						props.iconHeadlineAppend ? styles.hasAppendIcon : null
					])}
				>
					{!!props.iconHeadlinePrepend && (
						<span className={styles.prependIcon}>{props.iconHeadlinePrepend}</span>
					)}
					{props.title}
					{!!props.iconHeadlineAppend && (
						<span className={styles.appendIcon}>{props.iconHeadlineAppend}</span>
					)}
				</h3>
				<Text isSmall>{props.subtitle}</Text>
				{props.list && <ul>{props.list}</ul>}
				{props.toggleButton && props.toggleText && (
					<>
						<Text>
							<span
								className={classNames([
									'link',
									styles.toggleButton,
									show ? styles.toggleButtonActive : null
								])}
								onClick={toggle}
							>
								{props.toggleButton}
								<SvgDropdownArrow />
							</span>
						</Text>
						{show && <div className={styles.contentToggle}>{props.toggleText}</div>}
					</>
				)}
				<div className={styles.check}>
					<Check active={props.currentValue === props.value} />
				</div>
			</div>
		</div>
	);
});
export default ColumnInputItem;
