import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import Dropzone from 'react-dropzone';
import m from 'utils/messages/messages';
import { prepareDocumentsForUpload, removeDocumentForUpload } from 'utils/files/files';

import { Text } from 'components/Atoms/Text';
import ErrorMessage from 'components/Atoms/Form/ErrorMessage/ErrorMessage';
import SvgCross from 'components/Atoms/SVG/SvgCross';
import SvgCheck from 'components/Atoms/SVG/SvgCheck';
import SvgLoader from 'components/Atoms/SVG/SvgLoader';
import SvgWarning from 'components/Atoms/SVG/SvgWarning';
import SvgPlusCircle from 'components/Atoms/SVG/SvgPlusCircle';
import SvgUpload from 'components/Atoms/SVG/SvgUpload';

import styles from './MultipleFileUpload.module.scss';

type Props = {
	index: number;
	id: number;
	name: string;
	description?: string | JSX.Element;
	allowJpg: boolean;
	allowPng: boolean;
	allowPdf: boolean;
	init?: Array<any>;
	missingDocuments: Array<any>;
	setMissingDocuments: Function;
	applicant: number;
	onDrop?: Function;
	deleteItem?: Function;
};

const MultipleFileUpload = (props: Props) => {
	const id: string = uuid();
	const maxFiles = 5;
	const [loadingFiles, setLoadingFiles] = useState([]);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [isShowError, setIsShowError] = useState(false);
	const defaultErrorMessage = 'upload.defaultError';
	const fileSizeTooBigErrorMessage = 'upload.maxUploadSize';
	const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

	useEffect(() => {
		if (props.init && props.init.length > 0) {
			setUploadedFiles(props.init);
		}
	}, []);

	const onDrop = async (droppedFiles: any) => {
		if (loadingFiles.length + uploadedFiles.length < maxFiles) {
			const allowedFiles = maxFiles - (loadingFiles.length + uploadedFiles.length);
			if (allowedFiles > 0) {
				const droppedFilesSliced = droppedFiles.slice(0, allowedFiles);
				setLoadingFiles(droppedFilesSliced);
				setIsShowError(false);
				setErrorMessage(defaultErrorMessage);
				prepareDocumentsForUpload(
					props.id,
					props.applicant,
					droppedFilesSliced,
					uploadedFiles,
					setUploadedFiles,
					props.missingDocuments,
					props.setMissingDocuments,
					() => {
						setLoadingFiles([]);
					},
					() => {
						setErrorMessage(fileSizeTooBigErrorMessage);
						setIsShowError(true);
						setLoadingFiles([]);
					}
				);
			}
		}
	};

	const customOnDropWrapper = (droppedFiles: any) => {
		props.onDrop(droppedFiles, setUploadedFiles);
	};

	const deleteItem = (index: number) => {
		removeDocumentForUpload(
			index,
			props.id,
			props.applicant,
			uploadedFiles,
			setUploadedFiles,
			props.missingDocuments,
			props.setMissingDocuments,
			() => {
				setIsShowError(false);
				setErrorMessage(defaultErrorMessage);
			}
		);
	};

	const getFileTypes = () => {
		const mimeTypeArray = [];

		if (props.allowJpg) {
			mimeTypeArray.push('image/jpeg');
		}
		if (props.allowPng) {
			mimeTypeArray.push('image/png');
		}
		if (props.allowPdf) {
			mimeTypeArray.push('application/pdf');
		}

		return mimeTypeArray.join(',').trim();
	};

	const getFileTypesPrint = () => {
		const mimeTypeArray = [];

		if (props.allowJpg) {
			mimeTypeArray.push('JPG');
		}
		if (props.allowPng) {
			mimeTypeArray.push('PNG');
		}
		if (props.allowPdf) {
			mimeTypeArray.push('PDF');
		}

		return mimeTypeArray.join(', ').trim();
	};

	const getUploadedFileItem = (file: any, index: number, isLoading: boolean) => {
		return (
			<div
				key={
					id +
					'-files-' +
					index +
					'-applicant-' +
					props.applicant +
					(isLoading ? '-loading' : '')
				}
				className={styles.itemWrapper}
			>
				<div className={styles.item}>
					<div className={styles.itemText}>
						<Text>{file.name}</Text>
					</div>
					{!isLoading && (
						<div
							className={styles.itemIcon}
							data-testid="delete-item"
							onClick={() => {
								props.deleteItem
									? props.deleteItem(setUploadedFiles)
									: deleteItem(index);
							}}
						>
							<SvgCross color="#6F7779" />
						</div>
					)}
				</div>
				<div
					className={classNames([
						styles.itemUploadedFileIcon,
						isLoading ? styles.itemUploadedFileIconLoading : null
					])}
				>
					{isLoading ? <SvgLoader color="#6F7779" /> : <SvgCheck color="#ffffff" />}
				</div>
			</div>
		);
	};

	return (
		<div
			className={classNames([
				styles.wrapper,
				!(loadingFiles.length > 0 || uploadedFiles.length > 0)
					? styles.hasNoFilesMobile
					: null
			])}
		>
			<div className={styles.content}>
				{props.name && (
					<div className={styles.title}>
						<h3>{props.name}</h3>
					</div>
				)}
				{props.description && <Text isExtraSmall>{props.description}</Text>}
			</div>

			{(loadingFiles.length > 0 || uploadedFiles.length > 0) && (
				<div className={styles.list}>
					{uploadedFiles.length > 0 && (
						<>
							{uploadedFiles.map((file, index) =>
								getUploadedFileItem(file, index, false)
							)}
						</>
					)}
					{loadingFiles && loadingFiles.length > 0 && (
						<>
							{loadingFiles.map((file, index) =>
								getUploadedFileItem(file, index, true)
							)}
						</>
					)}
				</div>
			)}

			{loadingFiles.length + uploadedFiles.length < maxFiles && (
				<Dropzone
					onDrop={props.onDrop ? customOnDropWrapper : onDrop}
					accept={getFileTypes()}
				>
					{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
						<div className={styles.dropzoneWrapper} {...getRootProps()}>
							<input {...getInputProps()} multiple data-testid="dropzone" />
							<div
								className={classNames([
									styles.dropzone,
									!(loadingFiles.length > 0 || uploadedFiles.length > 0)
										? styles.dropzoneBg
										: null
								])}
							>
								<div
									className={
										styles.dropzoneDragActiveOverlay +
										(isDragActive ? ' ' + styles.dropzoneIsDragActive : '')
									}
								>
									<p>
										<SvgCheck color="#1BB3BC" />
										{m('dropzone.drop', 'fields')}
									</p>
								</div>

								<div
									className={
										styles.dropzoneDragRejectedOverlay +
										(isDragReject ? ' ' + styles.dropzoneIsDragReject : '')
									}
								>
									<p>
										<SvgWarning color="#EC0000" />
										{m('dropzone.reject', 'fields')} {getFileTypesPrint()}
									</p>
								</div>
								{loadingFiles.length > 0 || uploadedFiles.length > 0 ? (
									<div className={styles.dropzoneContentWrapperAdditional}>
										<div
											className={styles.dropzoneContentWrapperAdditionalIcon}
										>
											<SvgPlusCircle color="#9E3667" />
										</div>
										<div
											className={styles.dropzoneContentWrapperAdditionalText}
										>
											<p>
												{m(
													'dropzone.addAdditionalFile',
													'fields',
													null,
													true
												)}
											</p>
										</div>
									</div>
								) : (
									<div className={styles.dropzoneContentWrapper}>
										<div className={styles.dropzoneIcon}>
											<SvgUpload />
										</div>
										<div className={styles.dropzoneText}>
											<p className={'link'}>
												{m('dropzone.default', 'fields')}
											</p>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</Dropzone>
			)}

			{isShowError && errorMessage && (
				<div className={styles.uploadErrorWrapper}>
					<ErrorMessage customMessage={m(errorMessage, 'fields')} />
				</div>
			)}
		</div>
	);
};
export default MultipleFileUpload;
