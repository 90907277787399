import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Phone from './Phone.d';
import { isEmpty } from 'utils/validation/validation';

const initialState: Phone = {
	countryCode: 49,
	mobileNumber: null,
	countryCode2: 49,
	mobileNumber2: null
};

export const slice = createSlice({
	name: 'Phone',
	initialState: initialState,
	reducers: {
		setCountryCode: (state, action: PayloadAction<number | string>) => {
			state.countryCode = !isEmpty(action.payload + '')
				? parseInt(action.payload + '')
				: null;
		},
		setMobileNumber: (state, action: PayloadAction<number | string>) => {
			state.mobileNumber = !isEmpty(action.payload + '')
				? parseInt(action.payload + '')
				: null;
		},
		setCountryCode2: (state, action: PayloadAction<number | string>) => {
			state.countryCode2 = !isEmpty(action.payload + '')
				? parseInt(action.payload + '')
				: null;
		},
		setMobileNumber2: (state, action: PayloadAction<number | string>) => {
			state.mobileNumber2 = !isEmpty(action.payload + '')
				? parseInt(action.payload + '')
				: null;
		},
		reset: () => initialState
	}
});

export const { setCountryCode, setMobileNumber, setCountryCode2, setMobileNumber2, reset } =
	slice.actions;
export default slice.reducer;
