import React from 'react';

type Props = {
	color?: string;
};

const SvgAgentCircle = (props: Props) => {
	const width = 30;
	const height = 30;
	const defaultColor = '#000000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm8.9,26V21.62h0a5.8,5.8,0,0,0-2.36-4.37,7.91,7.91,0,0,1-12.88,0,5.77,5.77,0,0,0-2.36,4.37h0V26.1A14.12,14.12,0,1,1,23.9,26Z"
			/>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M20.67,11.9A1.92,1.92,0,0,1,21,10.81a6.19,6.19,0,0,0-11.83,0,2,2,0,0,1,.34,1.1v1.85a8.88,8.88,0,0,0,4.7,1.76.71.71,0,0,1,.6-.34h.58a.73.73,0,1,1,0,1.45h-.58a.73.73,0,0,1-.62-.36,9.75,9.75,0,0,1-4.78-1.64,2.5,2.5,0,0,1-.09.25,6.18,6.18,0,0,0,11.54,0,1.9,1.9,0,0,1-.19-.82Z"
			/>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M7.55,10.94h0a1,1,0,0,1,1,1v2.15a1,1,0,0,1-1,.95h0a1,1,0,0,1-1-.95V11.9A1,1,0,0,1,7.55,10.94Z"
			/>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M23.58,11.9v2.15a1,1,0,0,1-1,.95h0a1,1,0,0,1-.95-.95V11.9a1,1,0,0,1,.95-1h0A1,1,0,0,1,23.58,11.9Z"
			/>
			<path
				style={{ fill: props.color ? props.color : defaultColor, fillRule: 'evenodd' }}
				d="M22.62,9.94a2,2,0,0,1,.27,0A8.28,8.28,0,0,0,7.28,10l.27,0a1.85,1.85,0,0,1,.78.17,7.25,7.25,0,0,1,13.51,0A1.89,1.89,0,0,1,22.62,9.94Z"
			/>
		</svg>
	);
};
export default SvgAgentCircle;
