import React, { useEffect, useMemo } from 'react';
import m from 'utils/messages/messages';
import { isEmpty } from 'utils/validation/validation';
import { getMaritalStatusItems, getNationalityItems } from 'utils/items/items';

import { Text } from 'components/Atoms/Text';
import { Col, Row } from 'components/Atoms/Grid';
import { DropdownInput, RadioButtonInput } from 'components/Atoms/Form';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import MaritalStatus from 'types/enums/MaritalStatus.d';

type Props = {
	nationalityRef?: any;
	maritalStatusRef?: any;
	numberAdultsRef?: any;
	numberChildrenRef?: any;
	numberChildrenWithBenefitRef?: any;
	nextRef?: any;
	prevRef?: any;
	nationality: string;
	setNationality: Function;
	nationalityError?: string;
	maritalStatus: MaritalStatus;
	setMaritalStatus: Function;
	maritalStatusError?: string;
	numberAdults?: number;
	setNumberAdults?: Function;
	numberChildren?: number;
	setNumberChildren?: Function;
	numberChildrenWithBenefit?: number;
	setNumberChildrenWithBenefit?: Function;
};

const PersonalData = (props: Props) => {
	const nationalityItems = getNationalityItems();
	const maritalStatusDropdownItems = getMaritalStatusItems();
	const maritalStatusItemSingle = maritalStatusDropdownItems[0];
	const maritalStatusItemMarried = maritalStatusDropdownItems[1];
	maritalStatusDropdownItems.shift();
	maritalStatusDropdownItems.shift();
	const maritalStatusItemOther = {
		text: m('maritalStatus.items.other', 'fields'),
		value: MaritalStatus.OTHER
	};
	const maritalStatusRadioButtonItems = [
		maritalStatusItemSingle,
		maritalStatusItemMarried,
		maritalStatusItemOther
	];
	const numberOfAdultsButtonItems = [
		{ text: '1', value: '1' },
		{ text: m('more.label', 'fields'), value: '2' }
	];
	const numberOfChildrenButtonItems = [
		{ text: '0', value: '0' },
		{ text: '1', value: '1' },
		{ text: '2', value: '2' },
		{ text: m('more.label', 'fields'), value: '-1' }
	];
	const numberOfChildrenDropdownItems = [
		{ text: '3', value: '3' },
		{ text: '4', value: '4' },
		{ text: '5', value: '5' },
		{ text: '6', value: '6' },
		{ text: '7', value: '7' },
		{ text: '8', value: '8' },
		{ text: '9', value: '9' },
		{ text: '10', value: '10' }
	];

	const hasSecondApplicant = useSelector(
		(state: RootState) => state.Applicant.hasSecondApplicant
	);
	const sameHousehold = useSelector((state: RootState) => state.Applicant.sameHousehold);

	const hasAdultsAndChildren = useMemo(() => {
		return !!(
			(props.numberAdults || props.numberAdults === 0) &&
			props.setNumberAdults &&
			(props.numberChildren || props.numberChildren === 0) &&
			props.setNumberChildren &&
			(props.numberChildrenWithBenefit || props.numberChildrenWithBenefit === 0) &&
			props.setNumberChildrenWithBenefit
		);
	}, [
		props.numberAdults,
		props.setNumberAdults,
		props.numberChildren,
		props.setNumberChildren,
		props.numberChildrenWithBenefit,
		props.setNumberChildrenWithBenefit
	]);

	const showNumberAdults = useMemo(() => {
		if (!hasAdultsAndChildren) return false;
		return (
			(!hasSecondApplicant || (hasSecondApplicant && !sameHousehold)) &&
			!isEmpty(props.maritalStatus) &&
			(props.maritalStatus === MaritalStatus.SINGLE ||
				props.maritalStatus === MaritalStatus.SEPARATED ||
				props.maritalStatus === MaritalStatus.DIVORCED ||
				props.maritalStatus === MaritalStatus.WIDOWED)
		);
	}, [hasAdultsAndChildren, hasSecondApplicant, sameHousehold, props.maritalStatus]);

	useEffect(() => {
		if (props.maritalStatus === MaritalStatus.OTHER) {
			props.setMaritalStatus(maritalStatusDropdownItems[0].value);
		}
		if (!showNumberAdults) {
			if (
				(hasSecondApplicant && sameHousehold) ||
				(!sameHousehold && props.maritalStatus === MaritalStatus.MARRIED)
			) {
				props.setNumberAdults && props.setNumberAdults(2);
			} else {
				props.setNumberAdults && props.setNumberAdults(1);
			}
		} else {
			props.setNumberAdults && props.setNumberAdults(1);
		}
	}, [showNumberAdults, hasSecondApplicant, sameHousehold, props.maritalStatus]);

	const showNumberChildrenWithBenefit = useMemo(() => {
		if (!hasAdultsAndChildren) return false;
		return props.numberChildren > 0;
	}, [hasAdultsAndChildren, props.numberChildren, props.numberChildrenWithBenefit]);

	useEffect(() => {
		if (props.numberChildren === -1) {
			props.setNumberChildren && props.setNumberChildren(3);
		}
		if (
			!showNumberChildrenWithBenefit ||
			props.numberChildrenWithBenefit > props.numberChildren
		) {
			props.setNumberChildrenWithBenefit && props.setNumberChildrenWithBenefit(0);
		}
	}, [showNumberChildrenWithBenefit, props.numberChildren, props.numberChildrenWithBenefit]);

	const isMaritalStatusButton = useMemo(
		() =>
			props.maritalStatus === MaritalStatus.SINGLE ||
			props.maritalStatus === MaritalStatus.MARRIED,
		[props.maritalStatus]
	);

	const isChildrenButton = useMemo(
		() =>
			props.numberChildren === 0 || props.numberChildren === 1 || props.numberChildren === 2,
		[props.numberChildren]
	);

	const numberOfChildrenWithBenefitItems = useMemo(() => {
		const items = [];
		for (let i = 0; i <= props.numberChildren; i++) {
			items.push({ text: i + '', value: i + '' });
		}
		return items;
	}, [props.numberChildren]);

	return (
		<>
			<Text isSubtitle>{m('page.customerData.personalData.title', 'global')}</Text>
			<Row>
				<Col xs={12}>
					<DropdownInput
						label={m('nationality.label', 'fields')}
						value={props.nationality}
						setValue={props.setNationality}
						items={nationalityItems}
						hasError={!!props.nationalityError}
						message={props.nationalityError}
						tabindex={21}
						testId="nationality"
					/>
				</Col>
				<Col xs={12}>
					<RadioButtonInput
						label={m('maritalStatus.label', 'fields')}
						value={
							props.maritalStatus
								? isMaritalStatusButton
									? props.maritalStatus
									: MaritalStatus.OTHER
								: null
						}
						setValue={props.setMaritalStatus}
						items={maritalStatusRadioButtonItems}
						btnStyle="button"
						tabindex={22}
						testId="marital-status-buttons"
					/>
					{props.maritalStatus && !isMaritalStatusButton && (
						<>
							<DropdownInput
								value={!isMaritalStatusButton ? props.maritalStatus : null}
								setValue={props.setMaritalStatus}
								items={maritalStatusDropdownItems}
								label={m('maritalStatus.label', 'fields')}
								tabindex={23}
								testId="marital-status-dropdown"
							/>
							<div className="mt-15" />
						</>
					)}
				</Col>
				{hasAdultsAndChildren && showNumberAdults && (
					<Col xs={12}>
						<RadioButtonInput
							label={m('numberAdults.label', 'fields')}
							value={
								props.numberAdults || props.numberAdults === 0
									? props.numberAdults + ''
									: null
							}
							setValue={props.setNumberAdults}
							items={numberOfAdultsButtonItems}
							btnStyle="button"
							tabindex={24}
							testId="number-adults"
						/>
					</Col>
				)}
			</Row>
			{hasAdultsAndChildren && (
				<Row>
					<Col xs={12}>
						<RadioButtonInput
							label={m('numberChildren.label', 'fields')}
							value={
								props.numberChildren || props.numberChildren === 0
									? isChildrenButton
										? props.numberChildren + ''
										: '-1'
									: null
							}
							setValue={props.setNumberChildren}
							items={numberOfChildrenButtonItems}
							btnStyle="button"
							tabindex={25}
							testId="number-of-children-buttons"
						/>
						{(props.numberChildren || props.numberChildren === 0) &&
							!isChildrenButton && (
								<DropdownInput
									value={!isChildrenButton ? props.numberChildren + '' : null}
									setValue={props.setNumberChildren}
									items={numberOfChildrenDropdownItems}
									label={m('numberChildren.label', 'fields')}
									tabindex={26}
									testId="number-of-children-dropdown"
								/>
							)}
						{showNumberChildrenWithBenefit && (
							<RadioButtonInput
								label={m('numberChildrenWithBenefit.label', 'fields')}
								value={
									props.numberChildrenWithBenefit ||
									props.numberChildrenWithBenefit === 0
										? props.numberChildrenWithBenefit + ''
										: null
								}
								setValue={props.setNumberChildrenWithBenefit}
								items={numberOfChildrenWithBenefitItems}
								btnStyle="button"
								tabindex={27}
								testId="number-children-with-benefit"
							/>
						)}
					</Col>
				</Row>
			)}
		</>
	);
};
export default PersonalData;
