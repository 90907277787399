import React from 'react';
import m from 'utils/messages/messages';

import SvgEdit from 'components/Atoms/SVG/SvgEdit';

import styles from './EditMarker.module.scss';

const EditMarker = () => {
	return (
		<div className={styles.wrapper}>
			<span className={styles.icon}>
				<SvgEdit color="#63818E" />
			</span>
			<span className={styles.text}>{m('partial.editMarker.text', 'global')}</span>
		</div>
	);
};
export default EditMarker;
