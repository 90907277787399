import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import OptIn from './OptIn.d';

const initialState: OptIn = {
	acceptTermsAndConditions: false,
	acceptOwnResponsibility: false,
	acceptSchufa: false,
	acceptAdvertising: false,
	acceptTermsAndConditions2: false,
	acceptSchufa2: false
};

export const slice = createSlice({
	name: 'OptIn',
	initialState: initialState,
	reducers: {
		setAcceptTermsAndConditions: (state, action: PayloadAction<boolean>) => {
			state.acceptTermsAndConditions = action.payload;
		},
		setAcceptOwnResponsibility: (state, action: PayloadAction<boolean>) => {
			state.acceptOwnResponsibility = action.payload;
		},
		setAcceptSchufa: (state, action: PayloadAction<boolean>) => {
			state.acceptSchufa = action.payload;
		},
		setAcceptAdvertising: (state, action: PayloadAction<boolean>) => {
			state.acceptAdvertising = action.payload;
		},
		setAcceptTermsAndConditions2: (state, action: PayloadAction<boolean>) => {
			state.acceptTermsAndConditions2 = action.payload;
		},
		setAcceptSchufa2: (state, action: PayloadAction<boolean>) => {
			state.acceptSchufa2 = action.payload;
		},
		reset: () => initialState
	}
});

export const {
	setAcceptTermsAndConditions,
	setAcceptOwnResponsibility,
	setAcceptSchufa,
	setAcceptAdvertising,
	setAcceptTermsAndConditions2,
	setAcceptSchufa2,
	reset
} = slice.actions;
export default slice.reducer;
