import { animateScroll as scroll } from 'react-scroll';

export const scrollTo = (ref: any, offset: number) => {
	if (ref && ref.current) {
		const rect = ref.current.getBoundingClientRect();
		const scrollTop = window.scrollY || document.documentElement.scrollTop;
		const finalOffset = offset ? offset : 0;
		scroll.scrollTo(rect.top + scrollTop - finalOffset, {
			duration: 0
		});
	}
};

export const toggleBodyScroll = (disabled: boolean) => {
	const body = document.getElementsByTagName('body')[0];
	if (disabled) {
		body.classList.add('no-scroll');
	} else {
		body.classList.remove('no-scroll');
	}
};
