import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { RootState } from 'store/rootReducer';

import SvgWarning from 'components/Atoms/SVG/SvgWarning';

import styles from './SuccessMessage.module.scss';

type Props = {
	outline?: boolean;
};

const SuccessMessage = (props: Props) => {
	const successMessages = useSelector((state: RootState) => state.Form.successMessages);

	return (
		<>
			{successMessages && successMessages.length > 0 && (
				<div className={classNames(styles.message, props.outline ? styles.outline : null)}>
					{successMessages.map((successMessage, index) => (
						<span
							key={'success-message-' + successMessage.code + '-' + index}
							className={styles.text}
							dangerouslySetInnerHTML={{ __html: successMessage.message + '' }}
						/>
					))}
					<div className={styles.icon}>
						<SvgWarning color="#3A8340" />
					</div>
				</div>
			)}
		</>
	);
};
export default SuccessMessage;
