import React from 'react';
import { openCookieLayer } from 'utils/tracking/tracking';
import m from 'utils/messages/messages';

import { Container, Row, Col } from 'components/Atoms/Grid';

import styles from './Footer.module.scss';

const Footer = () => {
	return (
		<footer className={styles.footer}>
			<div className={styles.footerInner}>
				<Container>
					<Row justifyContent="center">
						<Col xs={12} md={10} lg={8} xl={7} xxl={6} xxxl={5}>
							<a
								className="link"
								href="https://www.santander.de/privatkunden/service-kontakt/impressum/?_ga=2.203959888.1328201376.1613030133-1589327932.1613030133"
								target="_blank"
								rel="noreferrer"
							>
								{m('partial.footer.imprint', 'global')}
							</a>
							<span className={styles.footerTextSpacer}>|</span>
							<a
								className="link"
								href="https://www.santander.de/privatkunden/service-kontakt/datenschutz/?_ga=2.259360458.1328201376.1613030133-1589327932.1613030133"
								target="_blank"
								rel="noreferrer"
							>
								{m('partial.footer.privacy', 'global')}
							</a>
							<span className={styles.footerTextSpacer}>|</span>
							<span className="link" onClick={openCookieLayer}>
								{m('partial.footer.cookieSettings', 'global')}
							</span>
						</Col>
					</Row>
				</Container>
			</div>
		</footer>
	);
};
export default Footer;
