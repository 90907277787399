import React from 'react';
import classNames from 'classnames';
import m from 'utils/messages/messages';
import { resetApplicationStore } from 'utils/store/store';
import { goToPage } from 'utils/pages/pages';

import { Col, Container, Row } from 'components/Atoms/Grid';
import Logo from 'components/Atoms/Navbar/Logo/Logo';
import NavbarTitle from 'components/Atoms/Navbar/NavbarTitle/NavbarTitle';
import Button from 'components/Atoms/Button/Button';
import EditMarker from 'components/Atoms/Partials/EditMarker/EditMarker';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import ButtonSize from 'components/Atoms/Button/types/ButtonSize.d';
import Subpage from 'types/enums/Subpage.d';

import styles from './Navbar.module.scss';

const Navbar = () => {
	const hideBackLink = useSelector((state: RootState) => state.Page.hideBackLink);
	const storeIsEdit = useSelector((state: RootState) => state.Applications.isEdit);

	const backToStatusOverview = async () => {
		await resetApplicationStore();
		goToPage(Subpage.STATUS_OVERVIEW);
	};

	return (
		<header className={styles.navbar}>
			<Container>
				<Row justifyContent="center">
					<Col xs={12} xl={9} xxl={10}>
						<div className={styles.navbarInner}>
							<div className={classNames([styles.item, styles.itemNoShrink])}>
								<Logo />
							</div>
							<div className={classNames([styles.item, styles.itemNoShrink])}>
								<NavbarTitle />
							</div>
							<div className={classNames([styles.item, styles.itemGrow])} />
							{storeIsEdit && (
								<div className={classNames([styles.item, styles.itemNoShrink])}>
									<EditMarker />
								</div>
							)}
							{!hideBackLink && (
								<div className={classNames([styles.item, styles.itemNoShrink])}>
									<Button
										onClick={backToStatusOverview}
										buttonSize={ButtonSize.SMALL}
									>
										{m('partial.header.backToOverview', 'global')}
									</Button>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</header>
	);
};
export default Navbar;
