import React from 'react';
import m from 'utils/messages/messages';
import { getSalutationItems } from 'utils/items/items';
import { WORD_WITH_SPACE_AND_DASH } from 'components/Atoms/Form/TextInput/utils/regex';

import { Text } from 'components/Atoms/Text';
import { Col, Row } from 'components/Atoms/Grid';
import { TextInput, DropdownInput } from 'components/Atoms/Form';

import Salutation from 'types/enums/Salutation.d';

type Props = {
	salutation: Salutation;
	setSalutation: Function;
	salutationError?: string;
	firstName: string;
	setFirstName: Function;
	firstNameError?: string;
	lastName: string;
	setLastName: Function;
	lastNameError?: string;
};

const Name = (props: Props) => {
	const salutationItems = getSalutationItems();

	return (
		<>
			<Text isSubtitle>{m('page.customerData.name.title', 'global')}</Text>
			<Row>
				<Col xs={12} sm={4}>
					<DropdownInput
						label={m('salutation.label', 'fields')}
						value={props.salutation}
						setValue={props.setSalutation}
						items={salutationItems}
						hasError={!!props.salutationError}
						message={props.salutationError}
						tabindex={2}
						testId="salutation"
					/>
				</Col>
				<Col xs={12} sm={4}>
					<TextInput
						label={m('firstName.label', 'fields')}
						value={props.firstName}
						setValue={props.setFirstName}
						maxLength={50}
						regex={WORD_WITH_SPACE_AND_DASH}
						hasError={!!props.firstNameError}
						message={props.firstNameError}
						tabindex={3}
						testId="first-name"
					/>
				</Col>
				<Col xs={12} sm={4}>
					<TextInput
						label={m('lastName.label', 'fields')}
						value={props.lastName}
						setValue={props.setLastName}
						maxLength={50}
						regex={WORD_WITH_SPACE_AND_DASH}
						hasError={!!props.lastNameError}
						message={props.lastNameError}
						tabindex={4}
						testId="last-name"
					/>
				</Col>
			</Row>
		</>
	);
};
export default Name;
