import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Applicant from './Applicant.d';

const initialState: Applicant = {
	hasSecondApplicant: false,
	sameHousehold: true
};

export const slice = createSlice({
	name: 'Applicant',
	initialState: initialState,
	reducers: {
		setHasSecondApplicant: (state, action: PayloadAction<boolean>) => {
			state.hasSecondApplicant = action.payload;
		},
		setSameHousehold: (state, action: PayloadAction<boolean>) => {
			state.sameHousehold = action.payload;
		},
		reset: () => initialState
	}
});

export const { setHasSecondApplicant, setSameHousehold, reset } = slice.actions;
export default slice.reducer;
