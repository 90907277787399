import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import SvgWarning from 'components/Atoms/SVG/SvgWarning';

import styles from './ErrorMessage.module.scss';

type Props = {
	customMessage?: string | JSX.Element;
	outline?: boolean;
};

const ErrorMessage = (props: Props) => {
	const errorMessages = useSelector((state: RootState) => state.Form.errorMessages);

	return (
		<>
			{!props.customMessage && errorMessages && errorMessages.length > 0 && (
				<div className={classNames(styles.message, props.outline ? styles.outline : null)}>
					{errorMessages.map((errorMessage, index) => (
						<span
							key={'error-message-' + errorMessage.code + '-' + index}
							className={styles.text}
							dangerouslySetInnerHTML={{ __html: errorMessage.message + '' }}
						/>
					))}
					<div className={styles.icon}>
						<SvgWarning color="#EC0000" />
					</div>
				</div>
			)}
			{props.customMessage && (
				<div className={classNames(styles.message, props.outline ? styles.outline : null)}>
					<span
						className={styles.text}
						dangerouslySetInnerHTML={{ __html: props.customMessage + '' }}
					/>
					<div className={styles.icon}>
						<SvgWarning color="#EC0000" />
					</div>
				</div>
			)}
		</>
	);
};
export default ErrorMessage;
