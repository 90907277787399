import axios from 'axios';
import { getApiBaseURL } from 'utils/api/api';
import store from 'store/store';
import { setIsLoadingPage } from 'store/Loading/Loading';

const url1 = getApiBaseURL() + '/api/applications/';
const url2 = '/invite-to-vertragscenter';

export const postApplicationsInviteToVertragscenter = async (
	successCb: Function = () => {},
	errorCb: Function = () => {}
) => {
	store.dispatch(setIsLoadingPage(true));
	await axios
		.post(
			url1 + store.getState().Bco.applicationId + url2,
			{},
			{
				headers: {
					vcJwtToken: store.getState().Token.vcJwtToken
				}
			}
		)
		.then((res) => {
			onSuccess(successCb);
		})
		.catch((err: any) => {
			onError(err, errorCb);
		});
};

const onSuccess = (successCb: Function = () => {}) => {
	store.dispatch(setIsLoadingPage(false));
	successCb();
};

const onError = (err: any, errorCb: Function = () => {}) => {
	store.dispatch(setIsLoadingPage(false));
	errorCb();
};
