import React, { useEffect } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc-context';
import axios from 'axios';
import { initConsent } from 'utils/tracking/tracking';
import { isAuthEnabled } from 'utils/env/env';
import { getCompaniesSelf } from 'api';

import { Col, Container, Row } from 'components/Atoms/Grid';

import styles from './MainContent.module.scss';

type Props = {
	children: any;
};

const EmptySetDefaultAuthHeaderComponent = () => {
	const { accessToken } = useOidcAccessToken();

	useEffect(() => {
		if (accessToken) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
			getCompaniesSelf();
		} else {
			delete axios.defaults.headers.common['Authorization'];
		}
	}, [accessToken]);

	return <></>;
};

const MainContent = (props: Props) => {
	useEffect(() => {
		initConsent();
		if (!isAuthEnabled()) {
			getCompaniesSelf();
		}
	}, []);

	return (
		<main className={styles.mainContent}>
			{isAuthEnabled() && <EmptySetDefaultAuthHeaderComponent />}
			<Container>
				<Row justifyContent="center">
					<Col xs={12} md={10} lg={8} xl={7} xxl={6} xxxl={5}>
						<div className={styles.mainContentInner}>{props.children}</div>
					</Col>
				</Row>
			</Container>
		</main>
	);
};
export default MainContent;
