import React, { useRef, useState } from 'react';
import m from 'utils/messages/messages';
import { continueValid } from 'utils/tracking/tracking';
import { isEmpty } from 'utils/validation/validation';
import { goToPage } from 'utils/pages/pages';

import Page from 'components/Organisms/Page/Page';
import { Title, Text } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import SvgAgentCircle from 'components/Atoms/SVG/SvgAgentCircle';
import { ColumnInput } from 'components/Atoms/Form';
import DownloadContracts from 'components/Atoms/Partials/DownloadContracts/DownloadContracts';

import SvgCustomerCircle from 'components/Atoms/SVG/SvgCustomerCircle';
//import SvgCombinationCircle from 'components/Atoms/SVG/SvgCombinationCircle';

import { useDispatch } from 'react-redux';
import * as trackingStore from 'store/Tracking/Tracking';

import Subpage from 'types/enums/Subpage.d';
import ProcessOption from 'types/enums/ProcessOption.d';

const FinishProcess = () => {
	const dispatch = useDispatch();
	const [process, setProcess] = useState<string>(null);

	const customerRef = useRef<HTMLInputElement>(null);
	//const combinationRef = useRef<HTMLInputElement>(null);
	const agentRef = useRef<HTMLInputElement>(null);
	const submitRef = useRef<HTMLInputElement>(null);

	const items = [
		{
			ref: customerRef,
			prevRef: submitRef,
			nextRef: agentRef, // combinationRef,
			value: ProcessOption.CUSTOMER,
			testId: 'customer',
			title: m('page.finishProcess.options.customer.title', 'global'),
			subtitle: m('page.finishProcess.options.customer.subtitle', 'global'),
			iconHeadlinePrepend: <SvgCustomerCircle color="#9E3667" />
		},
		/*{
			ref: combinationRef,
			prevRef: customerRef,
			nextRef: agentRef,
			value: ProcessOption.COMBINATION,
			testId: 'combination',
			title: m('page.finishProcess.options.combination.title', 'global'),
			subtitle: m('page.finishProcess.options.combination.subtitle', 'global', null, true),
			iconHeadlinePrepend: <SvgCombinationCircle color="#9E3667" />
		},*/
		{
			ref: agentRef,
			prevRef: customerRef, // combinationRef,
			nextRef: submitRef,
			value: ProcessOption.AGENT,
			testId: 'agent',
			title: m('page.finishProcess.options.agent.title', 'global'),
			subtitle: m('page.finishProcess.options.agent.subtitle', 'global'),
			iconHeadlinePrepend: <SvgAgentCircle color="#9E3667" />
		}
	];

	const onSubmit = () => {
		continueValid();
		if (process === ProcessOption.COMBINATION) {
			dispatch(trackingStore.setGtmSubstatus('kombination'));
			goToPage(Subpage.UPLOAD_CONTRACT_DOCUMENTS + '/kombination');
		} else if (process === ProcessOption.AGENT) {
			dispatch(trackingStore.setGtmSubstatus('mitarbeiter'));
			goToPage(Subpage.UPLOAD_CONTRACT_DOCUMENTS + '/mitarbeiter');
		} else {
			goToPage(Subpage.EMAIL_SENT_TO_CUSTOMER);
		}
	};

	const submitDisabled = () => {
		return isEmpty(process);
	};

	return (
		<Page>
			<Title>{m('page.finishProcess.title', 'global')}</Title>
			<Text isSubtitle>{m('page.finishProcess.subtitle', 'global')}</Text>
			<Form
				showSubmitDivider
				onSubmit={onSubmit}
				submitDisabled={submitDisabled()}
				prevButtonExtension={<DownloadContracts />}
				tabindex={10}
			>
				<ColumnInput
					items={items}
					currentValue={process}
					onChange={setProcess}
					stretchItems
					testId="finish-process"
				/>
			</Form>
		</Page>
	);
};
export default FinishProcess;
