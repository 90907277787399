import React, { useEffect } from 'react';
import { OidcProvider } from '@axa-fr/react-oidc-context';
import oidcConfig from 'config/oidc';
import { initConsent } from 'utils/tracking/tracking';
import { getCurrentEnvironment, isAuthEnabled } from 'utils/env/env';

import Navbar from 'components/Organisms/Navbar/Navbar';
import MainContent from 'components/Organisms/MainContent/MainContent';
import Footer from 'components/Organisms/Footer/Footer';
import Routes from 'components/Routes/Routes';
import InvisibleLoading from 'components/Atoms/Loading/InvisibleLoading/InvisibleLoading';
import Dev from 'components/Dev/Dev';
import ErrorSession from 'components/Pages/Error/ErrorSession';
import Loading from 'components/Pages/Loading/Loading';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

const App = () => {
	const isLoadingPage = useSelector((state: RootState) => state.Loading.isLoadingPage);
	const isLoadingForm = useSelector((state: RootState) => state.Loading.isLoadingForm);

	useEffect(() => {
		initConsent();
	}, []);

	const content = () => {
		return (
			<>
				{getCurrentEnvironment().showDebugServer && <Dev />}
				<Navbar />
				<MainContent>
					<Routes />
				</MainContent>
				<Footer />
				{(isLoadingPage || isLoadingForm) && <InvisibleLoading />}
			</>
		);
	};

	const ErrorComponent = () => {
		return (
			<>
				<Navbar />
				<MainContent>
					<ErrorSession />
				</MainContent>
				<Footer />
			</>
		);
	};

	const LoadingComponent = () => {
		return (
			<>
				<Navbar />
				<MainContent>
					<Loading />
				</MainContent>
				<Footer />
			</>
		);
	};

	return (
		<>
			{isAuthEnabled() ? (
				<OidcProvider
					configuration={oidcConfig}
					authenticatingComponent={LoadingComponent}
					serviceWorkerNotSupportedComponent={ErrorComponent}
					authenticatingErrorComponent={ErrorComponent}
					sessionLostComponent={ErrorComponent}
					callbackSuccessComponent={LoadingComponent}
				>
					{content()}
				</OidcProvider>
			) : (
				<>{content()}</>
			)}
		</>
	);
};
export default App;
