import React from 'react';
import { OidcSecure } from '@axa-fr/react-oidc-context';
import { isAuthEnabled } from 'utils/env/env';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import PageLoading from 'components/Atoms/Loading/PageLoading/PageLoading';

type Props = {
	customProgressBarCategories?: Array<string>;
	children: any;
	loadingTitle?: string;
	loadingIcon?: JSX.Element;
};

const Page = (props: Props) => {
	const isLoadingPage = useSelector((state: RootState) => state.Loading.isLoadingPage);

	const content = () => {
		return (
			<>
				{isLoadingPage ? (
					<PageLoading title={props.loadingTitle} icon={props.loadingIcon} />
				) : (
					props.children
				)}
			</>
		);
	};

	return isAuthEnabled() ? <OidcSecure>{content()}</OidcSecure> : content();
};
export default Page;
