import React from 'react';
import m from 'utils/messages/messages';
import { DIGIT } from 'components/Atoms/Form/TextInput/utils/regex';

import { Text } from 'components/Atoms/Text';
import { TextInput } from 'components/Atoms/Form';
import { Col, Row } from 'components/Atoms/Grid';

type Props = {
	customerNumber: string;
	setCustomerNumber: Function;
};

const CustomerNumber = (props: Props) => {
	return (
		<Row>
			<Col xs={12}>
				<Text isSubtitle>{m('page.customerData.customerNumber.title', 'global')}</Text>
				<TextInput
					label={m('customerNumber.label', 'fields')}
					value={props.customerNumber}
					setValue={props.setCustomerNumber}
					regex={DIGIT}
					maxLength={10}
					tabindex={8}
					testId="customer-number"
				/>
			</Col>
		</Row>
	);
};
export default CustomerNumber;
