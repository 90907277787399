import React, { useState } from 'react';
import m from 'utils/messages/messages';

import { Popup } from 'components/Molecules/Overlay';
import { Text } from 'components/Atoms/Text';

import PopupSize from 'components/Molecules/Overlay/Popup/types/PopupSize.d';

const AcceptSchufaLabelWithPopup = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const openPopup = (event: any) => {
		event.preventDefault();
		setIsOpen(true);
	};

	return (
		<>
			{m('acceptSchufa.text', 'fields')}{' '}
			<span className="link" onClick={openPopup}>
				{m('acceptSchufa.linkText', 'fields')}
			</span>{' '}
			{m('acceptSchufa.text2', 'fields')}
			<Popup
				show={isOpen}
				close={() => {
					setIsOpen(false);
				}}
				size={PopupSize.LG}
			>
				<>
					<Text hasMarginBottom>
						<strong>{m('acceptSchufa.popup.headline', 'fields')}</strong>
					</Text>
					<Text hasMarginBottom>{m('acceptSchufa.popup.text1', 'fields')}</Text>
					<Text hasMarginBottom>{m('acceptSchufa.popup.text2', 'fields')}</Text>
					<Text hasMarginBottom>
						{m('acceptSchufa.popup.text3', 'fields', null, true)}
					</Text>
				</>
			</Popup>
		</>
	);
};
export default AcceptSchufaLabelWithPopup;
