import { combineReducers } from '@reduxjs/toolkit';

import Page from 'store/Page/Page';
import Loading from 'store/Loading/Loading';
import Company from 'store/Company/Company';
import Token from 'store/Token/Token';
import Form from 'store/Form/Form';
import Tracking from 'store/Tracking/Tracking';
import Overlay from 'store/Overlay/Overlay';
import OptIn from 'store/OptIn/OptIn';
import Applicant from 'store/Applicant/Applicant';
import Name from 'store/Name/Name';
import Birthdate from 'store/Birthdate/Birthdate';
import Customer from 'store/Customer/Customer';
import Email from 'store/Email/Email';
import Phone from 'store/Phone/Phone';
import Nationality from 'store/Nationality/Nationality';
import Address from 'store/Address/Address';
import MaritalStatus from 'store/MaritalStatus/MaritalStatus';
import Occupation from 'store/Occupation/Occupation';
import FinancialStatus from 'store/FinancialStatus/FinancialStatus';
import Credit from 'store/Credit/Credit';
import Bco from 'store/Bco/Bco';
import Contract from 'store/Contract/Contract';
import PPI from 'store/PPI/PPI';
import Applications from 'store/Applications/Applications';

export const slices = {
	Page,
	Loading,
	Company,
	Token,
	Form,
	Tracking,
	Overlay,
	OptIn,
	Applicant,
	Name,
	Birthdate,
	Customer,
	Email,
	Phone,
	Nationality,
	Address,
	MaritalStatus,
	Occupation,
	FinancialStatus,
	Credit,
	Bco,
	Contract,
	PPI,
	Applications
};

const rootReducer = combineReducers(slices);

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
