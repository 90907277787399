import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Birthdate from './Birthdate.d';
import { isEmpty } from 'utils/validation/validation';

const initialState: Birthdate = {
	day: null,
	month: null,
	year: null,
	birthdate: null,
	day2: null,
	month2: null,
	year2: null,
	birthdate2: null
};

export const slice = createSlice({
	name: 'Birthdate',
	initialState: initialState,
	reducers: {
		setDay: (state, action: PayloadAction<string>) => {
			state.day = !isEmpty(action.payload) ? action.payload : null;
		},
		setMonth: (state, action: PayloadAction<string>) => {
			state.month = !isEmpty(action.payload) ? action.payload : null;
		},
		setYear: (state, action: PayloadAction<string>) => {
			state.year = !isEmpty(action.payload) ? action.payload : null;
		},
		setBirthdate: (state, action: PayloadAction<string>) => {
			state.birthdate = !isEmpty(action.payload) ? action.payload : null;
		},
		setDay2: (state, action: PayloadAction<string>) => {
			state.day2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setMonth2: (state, action: PayloadAction<string>) => {
			state.month2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setYear2: (state, action: PayloadAction<string>) => {
			state.year2 = !isEmpty(action.payload) ? action.payload : null;
		},
		setBirthdate2: (state, action: PayloadAction<string>) => {
			state.birthdate2 = !isEmpty(action.payload) ? action.payload : null;
		},
		reset: () => initialState
	}
});

export const {
	setDay,
	setMonth,
	setYear,
	setBirthdate,
	setDay2,
	setMonth2,
	setYear2,
	setBirthdate2,
	reset
} = slice.actions;
export default slice.reducer;
