import React, { useEffect, useMemo, useState } from 'react';
import m from 'utils/messages/messages';
import { getLoanRates } from 'api';
import { continueInvalid, continueValid } from 'utils/tracking/tracking';
import { goToPage } from 'utils/pages/pages';
import {
	clear,
	hasAmountTooHighError,
	hasExternalCreditsError,
	isEmpty
} from 'utils/validation/validation';
import { getConditionProgramItems } from 'utils/items/items';

import Page from 'components/Organisms/Page/Page';
import { Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { DropdownInput } from 'components/Atoms/Form';
import CreditCalculator from 'components/Molecules/CreditCalculator/CreditCalculator';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { setAmount, setExternalCredits, setRuntime } from 'store/Credit/Credit';
import * as company from 'store/Company/Company';

import ExternalCredit from 'types/interfaces/ExternalCredit.d';
import Subpage from 'types/enums/Subpage.d';

import styles from './CreditDetails.module.scss';

const CreditDetails = () => {
	const dispatch = useDispatch();

	const storeAmount = useSelector((state: RootState) => state.Credit.amount);
	const storeRuntime = useSelector((state: RootState) => state.Credit.runtime);
	const storeExternalCredits = useSelector((state: RootState) => state.Credit.externalCredits);

	const storeConditionProgram = useSelector((state: RootState) => state.Company.conditionProgram);
	const storeConditionPrograms = useSelector(
		(state: RootState) => state.Company.conditionPrograms
	);

	const [chosenAmount, setChosenAmount] = useState<number>(storeAmount);
	const [chosenRuntime, setChosenRuntime] = useState<number>(storeRuntime);
	const [chosenExternalCredits, setChosenExternalCredits] =
		useState<Array<ExternalCredit>>(storeExternalCredits);
	const [hasIbanError, setHasIbanError] = useState<boolean>(false);

	const [conditionProgram, setConditionProgram] = useState<string>(
		storeConditionProgram ? storeConditionProgram + '' : null
	);
	const [conditionProgramItems, setConditionProgramItems] = useState<any>([]);

	useEffect(() => {
		getLoanRates();
	}, []);

	useEffect(() => {
		if (storeConditionPrograms.length > 0) {
			setConditionProgramItems(getConditionProgramItems());
		}
	}, [storeConditionPrograms]);

	const onFormSubmit = () => {
		clear();

		const hasExternalCreditsErr: boolean = hasExternalCreditsError(chosenExternalCredits);
		setHasIbanError(hasExternalCreditsErr);

		const hasAmountTooHighErr: boolean = hasAmountTooHighError(
			chosenAmount,
			chosenExternalCredits
		);

		if (!hasExternalCreditsErr && !hasAmountTooHighErr) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();

		dispatch(setAmount(chosenAmount));
		dispatch(setRuntime(chosenRuntime));
		dispatch(setExternalCredits(chosenExternalCredits));

		dispatch(company.setConditionProgram(parseInt(conditionProgram)));

		goToPage(Subpage.CUSTOMER_DATA);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const submitDisabled = useMemo(() => {
		let emptyFields = false;
		for (const externalCredit of chosenExternalCredits) {
			if (
				isEmpty(externalCredit.iban) ||
				(!externalCredit.remainingBalance && externalCredit.remainingBalance !== 0)
			) {
				emptyFields = true;
			}
		}
		return emptyFields || (conditionProgramItems.length > 0 && isEmpty(conditionProgram));
	}, [chosenExternalCredits, conditionProgramItems, conditionProgram]);

	return (
		<Page>
			<Title hasMarginBottom>{m('page.creditDetails.title', 'global')}</Title>
			<Form
				showSubmitDivider
				onSubmit={onFormSubmit}
				submitDisabled={submitDisabled}
				tabindex={22}
			>
				<CreditCalculator
					chosenAmount={chosenAmount}
					setChosenAmount={setChosenAmount}
					chosenRuntime={chosenRuntime}
					setChosenRuntime={setChosenRuntime}
					externalCredits={chosenExternalCredits}
					setExternalCredits={setChosenExternalCredits}
					hasIbanError={hasIbanError}
				/>
				{conditionProgramItems.length > 0 && (
					<>
						<div className={styles.spacer} />
						<DropdownInput
							label={m('condition.label', 'fields')}
							value={conditionProgram}
							setValue={setConditionProgram}
							items={conditionProgramItems}
							tabindex={21}
							testId="condition-program"
						/>
					</>
				)}
			</Form>
		</Page>
	);
};
export default CreditDetails;
