import React from 'react';

type Props = {
	color?: string;
};

const SvgLoader = (props: Props) => {
	const width = 10;
	const height = 10;
	const defaultColor = '#000000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'nonzero'
				}}
				d="M5 10a.536.536 0 010-1.071A3.933 3.933 0 008.929 5 3.933 3.933 0 005 1.071 3.933 3.933 0 001.071 5 .536.536 0 010 5c0-2.757 2.243-5 5-5s5 2.243 5 5-2.243 5-5 5"
			/>
		</svg>
	);
};
export default SvgLoader;
