import React from 'react';

type Props = {
	color?: string;
};

const SvgDocument = (props: Props) => {
	const width = 24;
	const height = 24;
	const defaultColor = '#9BC3D3';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M12.476 2h.021c.186 0 .349.1.436.25l4.871 4.87a.494.494 0 01.19.472l.006.904a.5.5 0 01-1 .008l-.004-.49L12.522 8a.5.5 0 01-.49-.408l-.008-.09L12.001 3H5v18h4.5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5zm4.405 8.404a1.387 1.387 0 011.956 0l.76.76c.538.54.538 1.416-.001 1.955l-6.735 6.735a.5.5 0 01-.354.146H10.5a.5.5 0 01-.5-.5v-2.007c0-.132.053-.26.146-.353zm1.249.708a.383.383 0 00-.542 0L11 17.7V19h1.3l6.588-6.588a.381.381 0 000-.541zm-5.125-7.375l.017 3.265 3.259.01-3.276-3.275z"
			/>
		</svg>
	);
};
export default SvgDocument;
