export enum BcoResultCode {
	BCO_APPROVED = 'BCO_APPROVED',
	BCO_REJECTED = 'BCO_REJECTED',
	BCO_CLOSED = 'BCO_CLOSED',
	BCO_IN_WORK = 'BCO_IN_WORK',
	BCO_DELAYED_GOT_DOCUMENTS = 'BCO_DELAYED_GOT_DOCUMENTS',
	BCO_DELAYED_MISSING_DOCUMENTS = 'BCO_DELAYED_MISSING_DOCUMENTS',
	BCO_DELAYED_OTHER = 'BCO_DELAYED_OTHER'
}

export default BcoResultCode;
