import React, { useState } from 'react';
import classNames from 'classnames';

import LoadData from 'components/Dev/LoadData/LoadData';
import Pagination from 'components/Dev/Pagination/Pagination';
import SubpageSelector from 'components/Dev/SubpageSelector/SubpageSelector';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import styles from './Dev.module.scss';

const Dev = () => {
	const currentSubpage = useSelector((state: RootState) => state.Page.currentSubpage);

	const [showTerminal, setShowTerminal] = useState<boolean>(false);

	const toggleTerminal = () => {
		setShowTerminal(!showTerminal);
	};

	return (
		<>
			{currentSubpage && <LoadData />}
			<div className={styles.devTerminal}>
				<div
					className={classNames([
						styles.devTerminalInner,
						showTerminal ? styles.active : null
					])}
				>
					<Pagination />
					<SubpageSelector />
				</div>
				<div className={styles.mobileTrigger} onClick={toggleTerminal}>
					<span>&lt;/&gt;</span>
				</div>
			</div>
		</>
	);
};
export default Dev;
