import React from 'react';

type Props = {
	color?: string;
};

const SvgCross = (props: Props) => {
	const width = 10;
	const height = 10;
	const defaultColor = '#000000';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M5.92,5,9.81,1.11a.66.66,0,0,0,0-.92.66.66,0,0,0-.92,0L5,4.08,1.11.19a.66.66,0,0,0-.92,0,.66.66,0,0,0,0,.92L4.08,5,.19,8.89a.66.66,0,0,0,0,.92A.65.65,0,0,0,.65,10a.62.62,0,0,0,.46-.19L5,5.92,8.89,9.81a.62.62,0,0,0,.46.19.65.65,0,0,0,.46-.19.66.66,0,0,0,0-.92Z"
			/>
		</svg>
	);
};
export default SvgCross;
