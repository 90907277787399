import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Form from './Form.d';
import FormMessage from 'types/interfaces/FormMessage.d';

const initialState: Form = {
	errorMessages: [],
	successMessages: []
};

export const slice = createSlice({
	name: 'Form',
	initialState: initialState,
	reducers: {
		setErrorMessages: (state, action: PayloadAction<Array<FormMessage>>) => {
			state.errorMessages = action.payload;
		},
		pushErrorMessage: (state, action: PayloadAction<FormMessage>) => {
			const newErrorMessages = [...state.errorMessages];
			newErrorMessages.push(action.payload);
			state.errorMessages = newErrorMessages;
		},
		setSuccessMessages: (state, action: PayloadAction<Array<FormMessage>>) => {
			state.successMessages = action.payload;
		},
		pushSuccessMessage: (state, action: PayloadAction<FormMessage>) => {
			const newSuccessMessages = [...state.successMessages];
			newSuccessMessages.push(action.payload);
			state.successMessages = newSuccessMessages;
		},
		reset: () => initialState
	}
});

export const { setErrorMessages, pushErrorMessage, setSuccessMessages, pushSuccessMessage, reset } =
	slice.actions;
export default slice.reducer;
