import React, { useEffect } from 'react';
import m from 'utils/messages/messages';
import { postApplicationsInviteToVertragscenter } from 'api';

import Page from 'components/Organisms/Page/Page';
import { Row, Col } from 'components/Atoms/Grid';
import { Title } from 'components/Atoms/Text';
import SvgCheckCircle from 'components/Atoms/SVG/SvgCheckCircle';

import styles from './EmailSentToCustomer.module.scss';

const EmailSentToCustomer = () => {
	useEffect(() => {
		postApplicationsInviteToVertragscenter();
	}, []);

	return (
		<Page>
			<Row justifyContent="center">
				<Col textAlign="center">
					<div className={styles.iconWrapper}>
						<SvgCheckCircle />
					</div>
					<Title hasMarginBottom>{m('page.emailSentToCustomer.title', 'global')}</Title>
				</Col>
			</Row>
		</Page>
	);
};
export default EmailSentToCustomer;
