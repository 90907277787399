import store from 'store/store';
import { setSanc, setUid } from 'store/Tracking/Tracking';

export const setUrlParamsToStore = (location: any) => {
	if (location && location.search !== undefined) {
		const search = new URLSearchParams(location.search);
		setSancToStore(search);
		setUidToStore(search);
	}
};

const setSancToStore = (search: any) => {
	const sanc = search.get('sanc');
	if (sanc) {
		store.dispatch(setSanc(sanc));
	}
};

const setUidToStore = (search: any) => {
	const uid = search.get('uid');
	if (uid) {
		store.dispatch(setUid(uid));
	}
};
