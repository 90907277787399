import React from 'react';
import m from 'utils/messages/messages';

import { Title } from 'components/Atoms/Text';
import SvgUpload from 'components/Atoms/SVG/SvgUpload';
import SvgDocument from 'components/Atoms/SVG/SvgDocument';
import SvgPayout from 'components/Atoms/SVG/SvgPayout';
import SvgArrowRight from 'components/Atoms/SVG/SvgArrowRight';

import styles from './NextSteps.module.scss';

const NextSteps = () => {
	return (
		<>
			<Title isSmall hasMarginBottom>
				{m('partial.nextSteps.title', 'global')}
			</Title>
			<div className={styles.steps}>
				<div className={styles.item}>
					<div className={styles.icon}>
						<SvgUpload />
					</div>
					<div className={styles.title}>
						{m('partial.nextSteps.uploadDocuments', 'global')}
					</div>
				</div>
				<div className={styles.arrow}>
					<SvgArrowRight color="#63818E" />
				</div>
				<div className={styles.item}>
					<div className={styles.icon}>
						<SvgDocument />
					</div>
					<div className={styles.title}>
						{m('partial.nextSteps.identificationAndSigning', 'global')}
					</div>
				</div>
				<div className={styles.arrow}>
					<SvgArrowRight color="#63818E" />
				</div>
				<div className={styles.item}>
					<div className={styles.icon}>
						<SvgPayout />
					</div>
					<div className={styles.title}>{m('partial.nextSteps.payout', 'global')}</div>
				</div>
			</div>
		</>
	);
};
export default NextSteps;
