import React from 'react';

import styles from './ColumnInput.module.scss';
import ColumnInputItem from './ColumnInputItem';

type Props = {
	items: Array<any>;
	stretchItems?: boolean;
	currentValue: string | null;
	onChange: Function;
	testId?: string;
};

const ColumnInput = (props: Props) => {
	return (
		<div className={styles.wrapper}>
			{props.items.map((item: any, index: number) => (
				<ColumnInputItem
					key={'column-input-item-' + index}
					prevRef={item.prevRef}
					ref={item.ref}
					nextRef={item.nextRef}
					title={item.title}
					subtitle={item.subtitle}
					iconHeadlinePrepend={item.iconHeadlinePrepend}
					stretch={props.stretchItems}
					currentValue={props.currentValue}
					value={item.value}
					onChange={props.onChange}
					testId={
						item.testId
							? (props.testId ? props.testId + '-' : '') + item.testId
							: undefined
					}
				/>
			))}
		</div>
	);
};
export default ColumnInput;
