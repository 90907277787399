import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Contract from './Contract.d';

const initialState: Contract = {
	contracts: []
};

export const slice = createSlice({
	name: 'Conditions',
	initialState: initialState,
	reducers: {
		setContracts: (state, action: PayloadAction<Array<any>>) => {
			state.contracts = action.payload;
		},
		reset: () => initialState
	}
});

export const { setContracts, reset } = slice.actions;
export default slice.reducer;
