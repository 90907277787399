import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setUrlParamsToStore } from 'utils/url/url';
import { setDataToWindowObject } from 'utils/tracking/tracking';
import { clearSuccesses, clearErrors } from 'utils/validation/validation';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { setCurrentSubpage, setHideBackLink, setPrevHistorySubpage } from 'store/Page/Page';
import {
	setGtmType,
	setGtmSubtype,
	setGtmStep,
	setGtmStatus,
	setGtmSubstatus
} from 'store/Tracking/Tracking';

import Subpage from 'types/enums/Subpage.d';
import GtmType from 'types/enums/GtmType.d';
import GtmSubtype from 'types/enums/GtmSubtype.d';

type Props = {
	subpage?: Subpage | string;
	gtmType?: GtmType;
	gtmSubtype?: GtmSubtype;
	gtmStep?: string;
	gtmStatus?: string;
	gtmSubstatus?: string;
	hideBackLink?: boolean;
	children: any;
};

const RouteInit = (props: Props) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentSubpage = useSelector((state: RootState) => state.Page.currentSubpage);
	const gtmType = useSelector((state: RootState) => state.Tracking.gtmType);
	const gtmSubtype = useSelector((state: RootState) => state.Tracking.gtmSubtype);
	const gtmStep = useSelector((state: RootState) => state.Tracking.gtmStep);
	const gtmStatus = useSelector((state: RootState) => state.Tracking.gtmStatus);
	const gtmSubstatus = useSelector((state: RootState) => state.Tracking.gtmSubstatus);

	useEffect(() => {
		window.scrollTo(0, 0);

		setUrlParamsToStore(location);

		clearSuccesses();
		clearErrors();
	}, []);

	useEffect(() => {
		if (currentSubpage) {
			dispatch(setPrevHistorySubpage(currentSubpage));
		}
	}, [location]);

	useEffect(() => {
		dispatch(setCurrentSubpage(props.subpage ? props.subpage : null));
		dispatch(setHideBackLink(props.hideBackLink ? props.hideBackLink : false));

		if (!gtmType) {
			dispatch(setGtmType(props.gtmType ? props.gtmType : null));
		}
		if (!gtmSubtype) {
			dispatch(setGtmSubtype(props.gtmSubtype ? props.gtmSubtype : null));
		}
		if (!gtmStep) {
			dispatch(setGtmStep(props.gtmStep ? props.gtmStep : null));
		}
		if (!gtmStatus) {
			dispatch(setGtmStatus(props.gtmStatus ? props.gtmStatus : null));
		}
		if (!gtmSubstatus) {
			dispatch(setGtmSubstatus(props.gtmSubstatus ? props.gtmSubstatus : null));
		}
		setDataToWindowObject();
	}, [props.subpage]);

	return <>{props.children}</>;
};
export default RouteInit;
