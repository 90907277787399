import * as React from 'react';

const SvgProtectRespiteCare = () => {
	const width = 30;
	const height = 30;
	const style1 = { fill: '#9b3667' };
	const style2 = { fill: '#ffffff' };
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<rect style={style1} width="30" height="30" rx="4.62" />
			<path
				style={style2}
				d="M24.85,19.94l-.11-.3a1.71,1.71,0,0,0-1.81-.95l-5.26.92a2.24,2.24,0,0,0-1.33-2.16L12,15.53a5.81,5.81,0,0,0-4.66,0L4.84,16.61A.39.39,0,0,0,4.6,17v5.69a.4.4,0,0,0,.4.41H7a3.64,3.64,0,0,1,1.09.16L13.81,25a3,3,0,0,0,1,.17,3.08,3.08,0,0,0,1.12-.2l8.44-3.75a1,1,0,0,0,.49-.54A.92.92,0,0,0,24.85,19.94Zm-8.06,0a.87.87,0,0,1-1,.6L9.72,19.19a.37.37,0,0,0-.31,0,.39.39,0,0,0-.17.25.41.41,0,0,0,.3.49l6.12,1.33a1.7,1.7,0,0,0,1.77-.83l5.64-1A.86.86,0,0,1,24,20l.11.3a.15.15,0,0,1,0,.13.07.07,0,0,1-.06.06l-8.4,3.74a2.37,2.37,0,0,1-1.62,0L8.31,22.47A4.61,4.61,0,0,0,7,22.27H5.4v-5l2.22-1a5.1,5.1,0,0,1,4,0L16,18.2A1.4,1.4,0,0,1,16.79,19.92Z"
			/>
			<path
				style={style2}
				d="M16.86,14.58a5.06,5.06,0,1,0-5-5.06A5.06,5.06,0,0,0,16.86,14.58Zm0-9.26a4.21,4.21,0,1,1-4.2,4.2A4.2,4.2,0,0,1,16.86,5.32Z"
			/>
			<path
				style={style2}
				d="M15.68,11.51a.51.51,0,0,0,.34-.14L17.34,10h0l0,0a.83.83,0,0,0,.08-.12.53.53,0,0,0,0-.12s0,0,0-.06h0l.05-3a.5.5,0,0,0-.48-.5.49.49,0,0,0-.49.48l0,2.82-1.18,1.19a.48.48,0,0,0,.34.82Z"
			/>
		</svg>
	);
};

export default SvgProtectRespiteCare;
