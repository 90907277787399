import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import styles from './KoopLogo.module.scss';

const KoopLogo = () => {
	const logoFilename = useSelector((state: RootState) => state.Company.logoFilename);

	const getSvg = () => {
		if (logoFilename) {
			return <></>;
		} else {
			return <></>;
		}
	};

	return <div className={styles.logo}>{getSvg()}</div>;
};
export default KoopLogo;
