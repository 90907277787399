import React, { useEffect, useRef } from 'react';
import { m } from 'utils/messages/messages';
import { roundUp } from 'utils/numbers/numbers';
// import { getAge } from 'utils/date/date';

import { Col, Row } from 'components/Atoms/Grid';
import Table from 'components/Molecules/Form/PPI/Protect/Table/Table';

import { useDispatch, useSelector } from 'react-redux';
import { setProtect, setProtectMonthlyRate, setProtectRuntime } from 'store/PPI/PPI';
import { RootState } from 'store/rootReducer';

import ProtectType from 'types/enums/Protect.d';
import { Text } from 'components/Atoms/Text';
import { DropdownInput, RadioButtonInput } from 'components/Atoms/Form';

const Protect = () => {
	const dispatch = useDispatch();
	const storeProtect = useSelector((state: RootState) => state.PPI.protect);
	const storeProtectRuntime = useSelector((state: RootState) => state.PPI.protectRuntime);
	const storeProtectMonthlyRate = useSelector((state: RootState) => state.PPI.protectMonthlyRate);
	const storeIncome = useSelector((state: RootState) => state.FinancialStatus.income);

	/*const storeDay = useSelector((state: RootState) => state.Birthdate.day);
	const storeMonth = useSelector((state: RootState) => state.Birthdate.month);
	const storeYear = useSelector((state: RootState) => state.Birthdate.year);*/

	// const age = getAge(storeYear, storeMonth, storeDay);

	const formatRuntimeDropdown = () => {
		/*if (age >= 58) {
			runtimeItems.pop();
		}
		if (age >= 59) {
			runtimeItems.pop();
		}
		if (age >= 60) {
			runtimeItems.pop();
		}
		if (age >= 61) {
			runtimeItems.pop();
		}*/
		/*if (age >= 64) {
			runtimeItems.pop();
		}*/
	};

	const runtimeItems = [
		{ value: '12', text: '12 ' + m('month.plural', 'fields') },
		{ value: '24', text: '24 ' + m('month.plural', 'fields') },
		{ value: '36', text: '36 ' + m('month.plural', 'fields') },
		{ value: '48', text: '48 ' + m('month.plural', 'fields') },
		{ value: '60', text: '60 ' + m('month.plural', 'fields') }
		/*{ value: '72', text: '72 ' + m('month.plural', 'fields') },
		{ value: '84', text: '84 ' + m('month.plural', 'fields') },
		{ value: '96', text: '96 ' + m('month.plural', 'fields') },
		{ value: '108', text: '108 ' + m('month.plural', 'fields') },
		{ value: '120', text: '120 ' + m('month.plural', 'fields') }*/
	];

	formatRuntimeDropdown();

	const monthlyProtectItems = [
		/*{ value: '100', text: '100 €' },*/
		{ value: '200', text: '200 €' },
		{ value: '300', text: '300 €' },
		{ value: '400', text: '400 €' },
		{ value: '500', text: '500 €' },
		{ value: '600', text: '600 €' },
		{ value: '700', text: '700 €' },
		{ value: '800', text: '800 €' },
		{ value: '900', text: '900 €' },
		{ value: '1000', text: '1.000 €' }
	];

	useEffect(() => {
		setDropdowns();
	}, []);

	const isInitialMount = useRef(true);
	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			setDropdowns(true);
		}
	}, [storeIncome]);

	const setDropdowns = (allowReset: boolean = false) => {
		if (storeProtectRuntime === null || allowReset) {
			if (runtimeItems.length >= 3) {
				dispatch(setProtectRuntime(parseInt(runtimeItems[2].value)));
			} else {
				dispatch(
					setProtectRuntime(
						runtimeItems.length <= 0
							? null
							: parseInt(runtimeItems[runtimeItems.length - 1].value)
					)
				);
			}
		}
		if (storeProtectMonthlyRate === null || allowReset) {
			if (storeIncome) {
				dispatch(setProtectMonthlyRate(roundUp(storeIncome * 0.3, 100, 1000)));
			} else {
				dispatch(setProtectMonthlyRate(parseInt(monthlyProtectItems[0].value)));
			}
		}
	};

	const setNoProtect = () => {
		dispatch(setProtect(ProtectType.NO_PROTECT));
	};

	const setProtectRuntimeDropdown = (value: string) => {
		dispatch(setProtectRuntime(parseInt(value)));
	};

	const setProtectMonthlyRateDropdown = (value: string) => {
		dispatch(setProtectMonthlyRate(parseInt(value)));
	};

	return (
		<>
			<Text isBlack>{m('page.customerData.ppi.protect.body.title', 'global')}:</Text>
			<Text hasMarginBottom>
				{m('page.customerData.ppi.protect.body.subtitle', 'global')}
			</Text>
			<Row>
				<Col xs={12} sm={6}>
					<DropdownInput
						label={m('page.customerData.ppi.protect.body.runtime.label', 'global')}
						value={storeProtectRuntime + ''}
						setValue={setProtectRuntimeDropdown}
						items={runtimeItems}
						size="s"
						tabindex={41}
					/>
				</Col>
				<Col xs={12} sm={6}>
					<DropdownInput
						label={m(
							'page.customerData.ppi.protect.body.monthlyProtect.label',
							'global'
						)}
						value={storeProtectMonthlyRate + ''}
						setValue={setProtectMonthlyRateDropdown}
						items={monthlyProtectItems}
						size="s"
						tabindex={42}
					/>
				</Col>
			</Row>
			<Text hasMarginBottom>
				{m('page.customerData.ppi.protect.body.selectTitle', 'global')}:
			</Text>
			<Table />
			<RadioButtonInput
				value={storeProtect}
				setValue={setNoProtect}
				items={[
					{
						text: m('page.customerData.ppi.protect.body.table.noProtect', 'global'),
						value: ''
					}
				]}
				tabindex={43}
				testId="no-protect"
			/>
		</>
	);
};
export default Protect;
