import React, { useMemo, useState } from 'react';
import { clone, findIndex } from 'lodash';
import m from 'utils/messages/messages';
import { amountMaxValue, amountMinValue, runtimeSteps } from 'constants/Credit';
import {
	getEffectiveYearlyInterestRate,
	getMonthlyRate,
	getNormalizedRuntime,
	getSummedExternalCreditAmount
} from 'utils/credit/credit';
import { formatMoneyValue } from 'utils/numbers/numbers';

import { SliderInput } from 'components/Atoms/Form';
import SvgPlus from 'components/Atoms/SVG/SvgPlus';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';

import ExternalCredit from 'types/interfaces/ExternalCredit.d';

import styles from './CreditCalculator.module.scss';
import ExternalCreditInputs from 'components/Molecules/Form/ExternalCreditInputs/ExternalCreditInputs';
import { getExternalCreditsErrorNumbers } from 'utils/validation/validation';

type Props = {
	chosenAmount: number;
	setChosenAmount: Function;
	chosenRuntime: number;
	setChosenRuntime: Function;
	externalCredits?: Array<ExternalCredit>;
	setExternalCredits?: Function;
	hasIbanError?: boolean;
};

const CreditCalculator = (props: Props) => {
	const [toggleRerenderExternalCredits, setToggleRerenderExternalCredits] =
		useState<boolean>(false);

	const setAmountOneStepDown = (value: number) => {
		if (value > amountMinValue) {
			props.setChosenAmount(value - 500);
		}
	};

	const setAmountOneStepUp = (value: number) => {
		if (value < amountMaxValue) {
			props.setChosenAmount(value + 500);
		}
	};

	const setRuntime = (value: number) => {
		const normalizedRuntime = getNormalizedRuntime(value);
		props.setChosenRuntime(normalizedRuntime);
	};

	const setRuntimeOneStepDown = (value: number) => {
		const normalizedRuntime = getNormalizedRuntime(value);
		const index = findIndex(runtimeSteps, (runtimeStep: number) => {
			return runtimeStep === normalizedRuntime;
		});
		if (index !== -1 && index !== 0) {
			props.setChosenRuntime(runtimeSteps[index - 1]);
		}
	};

	const setRuntimeOneStepUp = (value: number) => {
		const normalizedRuntime = getNormalizedRuntime(value);
		const index = findIndex(runtimeSteps, (runtimeStep: number) => {
			return runtimeStep === normalizedRuntime;
		});
		if (index !== -1 && index !== runtimeSteps.length - 1) {
			props.setChosenRuntime(runtimeSteps[index + 1]);
		}
	};

	const addExternalCreditFields = () => {
		const tempExternalCredits = clone(props.externalCredits);
		tempExternalCredits.push({ iban: null, remainingBalance: null });
		props.setExternalCredits(tempExternalCredits);
	};

	const removeExternalCreditFields = (index: number) => {
		const tempExternalCredits = clone(props.externalCredits);
		tempExternalCredits.splice(index, 1);
		props.setExternalCredits(tempExternalCredits);
		// Necessary to rerender in the correct order
		setToggleRerenderExternalCredits(true);
		setTimeout(() => {
			setToggleRerenderExternalCredits(false);
		}, 1);
	};

	const onChangeCreditField = (index: number, newIban: string, newRemainingBalance: string) => {
		const tempExternalCredits = clone(props.externalCredits);
		tempExternalCredits.splice(index, 1, {
			iban: newIban,
			remainingBalance: newRemainingBalance ? parseInt(newRemainingBalance) : null
		});
		props.setExternalCredits(tempExternalCredits);
	};

	const getFormattedMonthlyRate = () => {
		return formatMoneyValue(
			getMonthlyRate(
				props.chosenAmount,
				props.chosenRuntime,
				getSummedExternalCreditAmount(props.externalCredits)
			),
			true,
			true
		);
	};

	const interest = useMemo(() => {
		return getEffectiveYearlyInterestRate();
	}, [props.chosenAmount, props.chosenRuntime]);

	return (
		<div className={styles.wrapper}>
			<SliderInput
				label={m('creditCalculator.creditAmount.label', 'fields')}
				min={amountMinValue}
				max={amountMaxValue}
				value={props.chosenAmount}
				onChange={props.setChosenAmount}
				setOneStepDown={setAmountOneStepDown}
				setOneStepUp={setAmountOneStepUp}
				valueSuffix="€"
				valueIsMoney
				step={500}
				highlightLabel
				tabindex={1}
				testId="credit-calculator-amount"
			/>

			{props.externalCredits && props.setExternalCredits && (
				<>
					{!toggleRerenderExternalCredits && props.externalCredits.length > 0 && (
						<div>
							{props.externalCredits.map((externalCredit, index) => {
								return (
									<ExternalCreditInputs
										key={'external-credit-' + index}
										externalCredit={externalCredit}
										onChange={onChangeCreditField}
										index={index}
										hasIbanErr={
											props.hasIbanError &&
											getExternalCreditsErrorNumbers(
												props.externalCredits
											).includes(index)
										}
										removeItem={removeExternalCreditFields}
									/>
								);
							})}
						</div>
					)}

					{props.externalCredits.length < 5 && (
						<div
							className={styles.addExternalCreditButton}
							onClick={addExternalCreditFields}
						>
							{m('externalCredit.add', 'fields')}
							<SvgPlus />
						</div>
					)}
				</>
			)}

			<SliderInput
				label={m('creditCalculator.wishRuntime.label', 'fields')}
				min={runtimeSteps[0]}
				max={runtimeSteps[runtimeSteps.length - 1]}
				value={props.chosenRuntime}
				onChange={setRuntime}
				setOneStepDown={setRuntimeOneStepDown}
				setOneStepUp={setRuntimeOneStepUp}
				valueSuffix={m('creditCalculator.wishRuntime.extension', 'fields')}
				tabindex={20}
				testId="credit-calculator-runtime"
			/>

			<div className={styles.row}>
				<div className={styles.label}>
					<span className={styles.labelTitle}>
						{m('creditCalculator.monthlyRate.label', 'fields')}:
					</span>
					<TooltipIcon
						text={m('creditCalculator.monthlyRate.info', 'fields', {
							interest: formatMoneyValue(interest, true)
						})}
					/>
				</div>
				<div className={styles.value}>{getFormattedMonthlyRate()}</div>
			</div>
		</div>
	);
};
export default CreditCalculator;
