import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Email from './Email.d';
import { isEmpty } from 'utils/validation/validation';

const initialState: Email = {
	email: null
};

export const slice = createSlice({
	name: 'Email',
	initialState: initialState,
	reducers: {
		setEmail: (state, action: PayloadAction<string>) => {
			state.email = !isEmpty(action.payload) ? action.payload : null;
		},
		reset: () => initialState
	}
});

export const { setEmail, reset } = slice.actions;
export default slice.reducer;
