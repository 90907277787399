import React, { useMemo, useState } from 'react';
import m from 'utils/messages/messages';
import { continueInvalid, continueValid } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { goToPage } from 'utils/pages/pages';

import Page from 'components/Organisms/Page/Page';
import { Col, Row } from 'components/Atoms/Grid';
import { Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import Divider from 'components/Atoms/Divider/Divider';
import { CheckboxInput } from 'components/Atoms/Form';

// import Autofill from 'components/Dev/Autofill/Autofill';

import Name from 'components/Molecules/Form/Name/Name';
import Birthdate from 'components/Molecules/Form/Birthdate/Birthdate';
import Address from 'components/Molecules/Form/Address/Address';
import Contact from 'components/Molecules/Form/Contact/Contact';
import PersonalData from 'components/Molecules/Form/PersonalData/PersonalData';
import Occupation from 'components/Molecules/Form/Occupation/Occupation';
import FinancialStatus from 'components/Molecules/Form/FinancialStatus/FinancialStatus';
import AcceptSchufaLabelWithPopup from 'components/Atoms/Partials/AcceptSchufaLabelWithPopup/AcceptSchufaLabelWithPopup';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

import * as nameStore from 'store/Name/Name';
import * as birthdateStore from 'store/Birthdate/Birthdate';
import * as addressStore from 'store/Address/Address';
import * as phoneStore from 'store/Phone/Phone';
import * as nationalityStore from 'store/Nationality/Nationality';
import * as maritalStatusStore from 'store/MaritalStatus/MaritalStatus';
import * as occupationStore from 'store/Occupation/Occupation';
import * as financialStatusStore from 'store/FinancialStatus/FinancialStatus';

import Salutation from 'types/enums/Salutation.d';
import MaritalStatus from 'types/enums/MaritalStatus.d';
import OccupationType from 'types/enums/Occupation.d';
import TypeOfLiving from 'types/enums/TypeOfLiving.d';
import Subpage from 'types/enums/Subpage.d';
import BcoResultCode from 'types/enums/BcoResultCode.d';

import { postApplications } from 'api';
import * as optInStore from 'store/OptIn/OptIn';
import { formatFullDate } from 'utils/date/date';

const CustomerData = () => {
	const {
		isEmpty,
		hasEmptyFieldsError,
		hasInvalidFieldsError,
		focusFirstErrorInput,
		isBirthdateValid,
		isMobileNumberValid,
		hasSameMobileNumberError,
		hasDateMonthError,
		hasDateYearError,
		hasOccupationSinceError,
		hasOccupationUntilError
	} = useValidationUtils();

	const dispatch = useDispatch();

	const storeIsEdit = useSelector((state: RootState) => state.Applications.isEdit);
	const applicationId = useSelector((state: RootState) => state.Bco.applicationId);

	const sameHousehold = useSelector((state: RootState) => state.Applicant.sameHousehold);

	const salutation = useSelector((state: RootState) => state.Name.salutation2);
	const setSalutation = (val: Salutation) => {
		dispatch(nameStore.setSalutation2(val));
	};
	const firstName = useSelector((state: RootState) => state.Name.firstName2);
	const setFirstName = (val: string) => {
		dispatch(nameStore.setFirstName2(val));
	};
	const lastName = useSelector((state: RootState) => state.Name.lastName2);
	const setLastName = (val: string) => {
		dispatch(nameStore.setLastName2(val));
	};

	const birthdate = useSelector((state: RootState) => state.Birthdate.birthdate2);
	const setBirthdate = (val: string) => {
		const formattedBirthdate = formatFullDate(val);
		dispatch(birthdateStore.setBirthdate2(formattedBirthdate));
	};

	const postalCode = useSelector((state: RootState) => state.Address.postalCode2);
	const setPostalCode = (val: string) => {
		dispatch(addressStore.setPostalCode2(val));
	};
	const city = useSelector((state: RootState) => state.Address.city2);
	const setCity = (val: string) => {
		dispatch(addressStore.setCity2(val));
	};
	const street = useSelector((state: RootState) => state.Address.street2);
	const setStreet = (val: string) => {
		dispatch(addressStore.setStreet2(val));
	};
	const streetNumber = useSelector((state: RootState) => state.Address.streetNumber2);
	const setStreetNumber = (val: string) => {
		dispatch(addressStore.setStreetNumber2(val));
	};
	const hasPreviousAddress = useSelector((state: RootState) => state.Address.hasPreviousAddress2);
	const setHasPreviousAddress = (val: boolean) => {
		dispatch(addressStore.setHasPreviousAddress2(val));
	};
	const previousPostalCode = useSelector((state: RootState) => state.Address.previousPostalCode2);
	const setPreviousPostalCode = (val: string) => {
		dispatch(addressStore.setPreviousPostalCode2(val));
	};
	const previousCity = useSelector((state: RootState) => state.Address.previousCity2);
	const setPreviousCity = (val: string) => {
		dispatch(addressStore.setPreviousCity2(val));
	};
	const previousStreet = useSelector((state: RootState) => state.Address.previousStreet2);
	const setPreviousStreet = (val: string) => {
		dispatch(addressStore.setPreviousStreet2(val));
	};
	const previousStreetNumber = useSelector(
		(state: RootState) => state.Address.previousStreetNumber2
	);
	const setPreviousStreetNumber = (val: string) => {
		dispatch(addressStore.setPreviousStreetNumber2(val));
	};

	const countryCode = useSelector((state: RootState) =>
		state.Phone.countryCode ? state.Phone.countryCode + '' : null
	);
	const setCountryCode = (val: string) => {
		dispatch(phoneStore.setCountryCode2(parseInt(val)));
	};
	const mobileNumber = useSelector((state: RootState) =>
		state.Phone.mobileNumber2 ? state.Phone.mobileNumber2 + '' : null
	);
	const setMobileNumber = (val: string) => {
		dispatch(phoneStore.setMobileNumber2(parseInt(val)));
	};

	const countryCode1DN = useSelector((state: RootState) => state.Phone.countryCode);
	const mobileNumber1DN = useSelector((state: RootState) => state.Phone.mobileNumber);

	const nationality = useSelector((state: RootState) => state.Nationality.nationality2);
	const setNationality = (val: string) => {
		dispatch(nationalityStore.setNationality2(val));
	};
	const maritalStatus = useSelector((state: RootState) => state.MaritalStatus.maritalStatus2);
	const setMaritalStatus = (val: MaritalStatus) => {
		dispatch(maritalStatusStore.setMaritalStatus2(val));
	};
	const numberAdults = useSelector((state: RootState) => state.MaritalStatus.numberAdults2);
	const setNumberAdults = (val: number) => {
		dispatch(maritalStatusStore.setNumberAdults2(val));
	};
	const numberChildren = useSelector((state: RootState) => state.MaritalStatus.numberChildren2);
	const setNumberChildren = (val: number) => {
		dispatch(maritalStatusStore.setNumberChildren2(val));
	};
	const numberChildrenWithBenefit = useSelector(
		(state: RootState) => state.MaritalStatus.numberChildrenWithBenefit2
	);
	const setNumberChildrenWithBenefit = (val: number) => {
		dispatch(maritalStatusStore.setNumberChildrenWithBenefit2(val));
	};

	const occupation = useSelector((state: RootState) => state.Occupation.occupation2);
	const setOccupation = (val: OccupationType) => {
		dispatch(occupationStore.setOccupation2(val));
	};
	const employer = useSelector((state: RootState) => state.Occupation.employer2);
	const setEmployer = (val: string) => {
		dispatch(occupationStore.setEmployer2(val));
	};
	const occupationMonth = useSelector((state: RootState) => state.Occupation.occupationMonth2);
	const setOccupationMonth = (val: string) => {
		dispatch(occupationStore.setOccupationMonth2(val));
	};
	const occupationYear = useSelector((state: RootState) => state.Occupation.occupationYear2);
	const setOccupationYear = (val: string) => {
		dispatch(occupationStore.setOccupationYear2(val));
	};
	const occupationLimited = useSelector((state: RootState) =>
		state.Occupation.occupationLimited2 !== null
			? state.Occupation.occupationLimited2
				? 'Befristet'
				: 'Unbefristet'
			: null
	);
	const setOccupationLimited = (val: string) => {
		dispatch(occupationStore.setOccupationLimited2(val === 'Befristet'));
	};
	const occupationLimitedMonth = useSelector(
		(state: RootState) => state.Occupation.occupationLimitedMonth2
	);
	const setOccupationLimitedMonth = (val: string) => {
		dispatch(occupationStore.setOccupationLimitedMonth2(val));
	};
	const occupationLimitedYear = useSelector(
		(state: RootState) => state.Occupation.occupationLimitedYear2
	);
	const setOccupationLimitedYear = (val: string) => {
		dispatch(occupationStore.setOccupationLimitedYear2(val));
	};

	const income = useSelector((state: RootState) => state.FinancialStatus.income2);
	const setIncome = (val: string) => {
		dispatch(financialStatusStore.setIncome2(val));
	};
	const additionalIncome = useSelector(
		(state: RootState) => state.FinancialStatus.additionalIncome2
	);
	const setAdditionalIncome = (val: string) => {
		dispatch(financialStatusStore.setAdditionalIncome2(val));
	};
	const livingCosts = useSelector((state: RootState) => state.FinancialStatus.livingCosts2);
	const setLivingCosts = (val: string) => {
		dispatch(financialStatusStore.setLivingCosts2(val));
	};
	const childSupport = useSelector((state: RootState) => state.FinancialStatus.childSupport2);
	const setChildSupport = (val: string) => {
		dispatch(financialStatusStore.setChildSupport2(val));
	};
	const typeOfLiving = useSelector((state: RootState) => state.FinancialStatus.typeOfLiving2);
	const setTypeOfLiving = (val: TypeOfLiving) => {
		dispatch(financialStatusStore.setTypeOfLiving2(val));
	};

	// State
	const acceptTermsAndConditions = useSelector(
		(state: RootState) => state.OptIn.acceptTermsAndConditions2
	);
	const setAcceptTermsAndConditions = (val: boolean) => {
		dispatch(optInStore.setAcceptTermsAndConditions2(val));
	};
	const acceptSchufa = useSelector((state: RootState) => state.OptIn.acceptSchufa2);
	const setAcceptSchufa = (val: boolean) => {
		dispatch(optInStore.setAcceptSchufa2(val));
	};

	// Errors
	const [salutationError, setSalutationError] = useState<string>(null);
	const [firstNameError, setFirstNameError] = useState<string>(null);
	const [lastNameError, setLastNameError] = useState<string>(null);
	const [birthdateError, setBirthdateError] = useState<string>(null);
	const [postalCodeError, setPostalCodeError] = useState<string>(null);
	const [cityError, setCityError] = useState<string>(null);
	const [streetError, setStreetError] = useState<string>(null);
	const [streetNumberError, setStreetNumberError] = useState<string>(null);
	const [previousPostalCodeError, setPreviousPostalCodeError] = useState<string>(null);
	const [previousCityError, setPreviousCityError] = useState<string>(null);
	const [previousStreetError, setPreviousStreetError] = useState<string>(null);
	const [previousStreetNumberError, setPreviousStreetNumberError] = useState<string>(null);
	const [countryCodeError, setCountryCodeError] = useState<string>(null);
	const [mobileNumberError, setMobileNumberError] = useState<string>(null);
	const [nationalityError, setNationalityError] = useState<string>(null);
	const [maritalStatusError, setMaritalStatusError] = useState<string>(null);
	const [occupationError, setOccupationError] = useState<string>(null);
	const [occupationMonthError, setOccupationMonthError] = useState<string>(null);
	const [occupationYearError, setOccupationYearError] = useState<string>(null);
	const [employerError, setEmployerError] = useState<string>(null);
	const [occupationLimitedMonthError, setOccupationLimitedMonthError] = useState<string>(null);
	const [occupationLimitedYearError, setOccupationLimitedYearError] = useState<string>(null);
	const [incomeError, setIncomeError] = useState<string>(null);
	const [livingCostsError, setLivingCostsError] = useState<string>(null);
	const [typeOfLivingError, setTypeOfLivingError] = useState<string>(null);
	const [acceptTermsAndConditionsError, setAcceptTermsAndConditionsError] =
		useState<string>(null);
	const [acceptSchufaError, setAcceptSchufaError] = useState<string>(null);

	const showEmployer = useMemo(() => {
		return (
			occupation === OccupationType.EMPLOYED ||
			occupation === OccupationType.OFFICER ||
			occupation === OccupationType.CIVIL_SERVICE_EMPLOYEE
		);
	}, []);

	const clearErrors = () => {
		setSalutationError(null);
		setFirstNameError(null);
		setLastNameError(null);
		setBirthdateError(null);
		setPostalCodeError(null);
		setCityError(null);
		setStreetError(null);
		setStreetNumberError(null);
		setPreviousPostalCodeError(null);
		setPreviousCityError(null);
		setPreviousStreetError(null);
		setPreviousStreetNumberError(null);
		setCountryCodeError(null);
		setMobileNumberError(null);
		setNationalityError(null);
		setMaritalStatusError(null);
		setOccupationError(null);
		setOccupationMonthError(null);
		setOccupationYearError(null);
		setEmployerError(null);
		setOccupationLimitedMonthError(null);
		setOccupationLimitedYearError(null);
		setIncomeError(null);
		setLivingCostsError(null);
		setTypeOfLivingError(null);
		setAcceptTermsAndConditionsError(null);
		setAcceptSchufaError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let hasInvalidFields = false;
		let isValidBirthdate = true;
		let isValidMobileNumber = true;
		let isNotSameMobileNumber = false;
		let isOccupationMonthValid = true;
		let isOccupationYearValid = true;
		let isOccupationLimitedMonthValid = true;
		let isOccupationLimitedYearValid = true;
		let isOccupationSinceValid = true;
		let isOccupationUntilValid = true;

		if (isEmpty(salutation)) {
			setSalutationError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(firstName)) {
			setFirstNameError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(lastName)) {
			setLastNameError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}

		if (isEmpty(birthdate)) {
			setBirthdateError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else {
			const formattedBirthdate = formatFullDate(birthdate);
			isValidBirthdate = isBirthdateValid(formattedBirthdate, setBirthdateError);
		}

		if (isEmpty(countryCode)) {
			setCountryCodeError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(mobileNumber)) {
			setMobileNumberError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (!isEmpty(countryCode) && !isEmpty(mobileNumber)) {
			isValidMobileNumber = isMobileNumberValid(
				countryCode,
				mobileNumber,
				setMobileNumberError
			);
			if (isValidMobileNumber) {
				isNotSameMobileNumber = !hasSameMobileNumberError(
					countryCode1DN,
					mobileNumber1DN,
					parseInt(countryCode),
					parseInt(mobileNumber)
				);
				if (!isNotSameMobileNumber) {
					setMobileNumberError(m('validation.error.invalid', 'fields') as string);
				}
			}
		}

		if (isEmpty(nationality)) {
			setNationalityError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(maritalStatus)) {
			setMaritalStatusError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}

		if (isEmpty(occupation)) {
			setOccupationError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (isEmpty(occupationMonth)) {
			setOccupationMonthError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else {
			const hasMonthErr = hasDateMonthError(occupationMonth);
			if (hasMonthErr) {
				setOccupationMonthError(m('validation.error.invalid', 'fields') as string);
				isOccupationMonthValid = false;
			}
		}
		if (isEmpty(occupationYear)) {
			setOccupationYearError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		} else {
			const hasYearErr = hasDateYearError(occupationYear);
			if (hasYearErr) {
				setOccupationYearError(m('validation.error.invalid', 'fields') as string);
				isOccupationYearValid = false;
			}
		}
		if (showEmployer) {
			if (isEmpty(employer)) {
				setEmployer(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			}
		}
		if (occupationLimited === 'Befristet') {
			if (isEmpty(occupationLimitedMonth)) {
				setOccupationLimitedMonthError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			} else {
				const hasMonthErr = hasDateMonthError(occupationLimitedMonth);
				if (hasMonthErr) {
					setOccupationLimitedMonthError(
						m('validation.error.invalid', 'fields') as string
					);
					isOccupationLimitedMonthValid = false;
				}
			}
			if (isEmpty(occupationLimitedYear)) {
				setOccupationLimitedYearError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			} else {
				const hasYearErr = hasDateYearError(occupationLimitedYear);
				if (hasYearErr) {
					setOccupationLimitedYearError(
						m('validation.error.invalid', 'fields') as string
					);
					isOccupationLimitedYearValid = false;
				}
			}
		}
		if (isOccupationMonthValid && isOccupationYearValid && birthdate && isValidBirthdate) {
			const birthdateArray = birthdate.split('.');
			isOccupationSinceValid = !hasOccupationSinceError(
				occupationMonth,
				occupationYear,
				birthdateArray[0],
				birthdateArray[1],
				birthdateArray[2]
			);
			if (!isOccupationSinceValid) {
				setOccupationMonthError(m('validation.error.invalid', 'fields') as string);
				setOccupationYearError(m('validation.error.invalid', 'fields') as string);
			}
		}
		if (
			occupationLimited === 'Befristet' &&
			isOccupationLimitedMonthValid &&
			isOccupationLimitedYearValid
		) {
			isOccupationUntilValid = !hasOccupationUntilError(
				occupationMonth,
				occupationYear,
				occupationLimitedMonth,
				occupationLimitedYear
			);
			if (!isOccupationUntilValid) {
				setOccupationLimitedMonthError(m('validation.error.invalid', 'fields') as string);
				setOccupationLimitedYearError(m('validation.error.invalid', 'fields') as string);
			}
		}

		if (isEmpty(income)) {
			setIncomeError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}

		if (!acceptTermsAndConditions) {
			setAcceptTermsAndConditionsError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}
		if (!acceptSchufa) {
			setAcceptSchufaError(m('validation.error.required', 'fields') as string);
			hasEmptyFields = true;
		}

		if (!sameHousehold) {
			if (isEmpty(postalCode)) {
				setPostalCodeError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			} else if (postalCode.length !== 5) {
				setPostalCodeError(m('postalCode.validation.length', 'fields') as string);
				hasInvalidFields = true;
			}
			if (isEmpty(city)) {
				setCityError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			} else if (city.length < 2) {
				setCityError(m('city.validation.length', 'fields') as string);
				hasInvalidFields = true;
			}
			if (isEmpty(street)) {
				setStreetError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			} else if (street.length < 3) {
				setStreetError(m('street.validation.length', 'fields') as string);
				hasInvalidFields = true;
			}
			if (isEmpty(streetNumber)) {
				setStreetNumberError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			}
			if (hasPreviousAddress) {
				if (isEmpty(previousPostalCode)) {
					setPreviousPostalCodeError(m('validation.error.required', 'fields') as string);
					hasEmptyFields = true;
				} else if (previousPostalCode.length !== 5) {
					setPreviousPostalCodeError(
						m('postalCode.validation.length', 'fields') as string
					);
					hasInvalidFields = true;
				}
				if (isEmpty(previousCity)) {
					setPreviousCityError(m('validation.error.required', 'fields') as string);
					hasEmptyFields = true;
				} else if (previousCity.length < 2) {
					setPreviousCityError(m('city.validation.length', 'fields') as string);
					hasInvalidFields = true;
				}
				if (isEmpty(previousStreet)) {
					setPreviousStreetError(m('validation.error.required', 'fields') as string);
					hasEmptyFields = true;
				} else if (previousStreet.length < 3) {
					setPreviousStreetError(m('street.validation.length', 'fields') as string);
					hasInvalidFields = true;
				}
				if (isEmpty(previousStreetNumber)) {
					setPreviousStreetNumberError(
						m('validation.error.required', 'fields') as string
					);
					hasEmptyFields = true;
				}
			}

			if (isEmpty(livingCosts)) {
				setLivingCostsError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			}
			if (isEmpty(typeOfLiving)) {
				setTypeOfLivingError(m('validation.error.required', 'fields') as string);
				hasEmptyFields = true;
			}
		}

		await hasEmptyFieldsError(hasEmptyFields);
		await hasInvalidFieldsError(hasInvalidFields);

		const isValid =
			!hasEmptyFields &&
			!hasInvalidFields &&
			isValidBirthdate &&
			isValidMobileNumber &&
			isNotSameMobileNumber &&
			isOccupationMonthValid &&
			isOccupationYearValid &&
			isOccupationLimitedMonthValid &&
			isOccupationLimitedYearValid &&
			isOccupationSinceValid &&
			isOccupationUntilValid;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = async () => {
		continueValid();

		await postApplications(
			(resultCode: BcoResultCode, skipContract: boolean) => {
				if (skipContract) {
					goToPage(Subpage.SKIP_CONTRACT);
				} else if (resultCode === BcoResultCode.BCO_APPROVED) {
					goToPage(Subpage.APPLICATION_RECEIVED);
				} else {
					goToPage(Subpage.APPLICATION_REJECTED);
				}
			},
			() => {
				goToPage(Subpage.APPLICATION_ERROR);
			}
		);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	// Only for dev
	/*const autofill = () => {
		setSalutation(Salutation.MALE);
		setFirstName('Max');
		setLastName('Mustermann');
		setDay('31');
		setMonth('12');
		setYear('1990');
		setPostalCode('41069');
		setCity('Mönchengladbach');
		setStreet('Madrider Straße');
		setStreetNumber('1');
		setCountryCode('49');
		setMobileNumber('163123457');
		setNationality('DE');
		setMaritalStatus(MaritalStatus.SINGLE);
		setNumberAdults(1);
		setNumberChildren(0);
		setNumberChildrenWithBenefit(0);
		setOccupation(OccupationType.EMPLOYED);
		setEmployer('Santander');
		setOccupationMonth('01');
		setOccupationYear('2010');
		setOccupationLimited(false);
		setIncome('5000');
		setLivingCosts('500');
		setTypeOfLiving(TypeOfLiving.MIETWOHNUNG);
		setAcceptTermsAndConditions(true);
		setAcceptSchufa(true);
	};*/

	return (
		<Page>
			<Title hasMarginBottom>
				{m('page.customerData.title', 'global')}
				{/*<Autofill autofill={autofill} />*/}
			</Title>
			<Title isSubtitle>{m('page.customerData.customer.two', 'global')}</Title>
			<Divider noMarginTop />
			<Form
				showSubmitDivider
				onSubmit={onSubmit}
				submitDisabled={!!applicationId && !storeIsEdit}
				onPrevButton={() => {
					goToPage(Subpage.CUSTOMER_DATA);
				}}
				clearErrors={clearErrors}
				tabindex={60}
				disableAutocomplete
			>
				<Name
					salutation={salutation}
					setSalutation={setSalutation}
					salutationError={salutationError}
					firstName={firstName}
					setFirstName={setFirstName}
					firstNameError={firstNameError}
					lastName={lastName}
					setLastName={setLastName}
					lastNameError={lastNameError}
				/>
				<Birthdate
					birthdate={birthdate}
					setBirthdate={setBirthdate}
					birthdateError={birthdateError}
				/>
				{!sameHousehold && (
					<Address
						postalCode={postalCode}
						setPostalCode={setPostalCode}
						postalCodeError={postalCodeError}
						city={city}
						setCity={setCity}
						cityError={cityError}
						street={street}
						setStreet={setStreet}
						streetError={streetError}
						streetNumber={streetNumber}
						setStreetNumber={setStreetNumber}
						streetNumberError={streetNumberError}
						hasPreviousAddress={hasPreviousAddress}
						setHasPreviousAddress={setHasPreviousAddress}
						previousPostalCode={previousPostalCode}
						setPreviousPostalCode={setPreviousPostalCode}
						previousPostalCodeError={previousPostalCodeError}
						previousCity={previousCity}
						setPreviousCity={setPreviousCity}
						previousCityError={previousCityError}
						previousStreet={previousStreet}
						setPreviousStreet={setPreviousStreet}
						previousStreetError={previousStreetError}
						previousStreetNumber={previousStreetNumber}
						setPreviousStreetNumber={setPreviousStreetNumber}
						previousStreetNumberError={previousStreetNumberError}
					/>
				)}
				<Contact
					countryCode={countryCode}
					setCountryCode={setCountryCode}
					countryCodeError={countryCodeError}
					mobileNumber={mobileNumber}
					setMobileNumber={setMobileNumber}
					mobileNumberError={mobileNumberError}
				/>
				<PersonalData
					nationality={nationality}
					setNationality={setNationality}
					nationalityError={nationalityError}
					maritalStatus={maritalStatus}
					setMaritalStatus={setMaritalStatus}
					maritalStatusError={maritalStatusError}
					numberAdults={!sameHousehold ? numberAdults : undefined}
					setNumberAdults={!sameHousehold ? setNumberAdults : undefined}
					numberChildren={!sameHousehold ? numberChildren : undefined}
					setNumberChildren={!sameHousehold ? setNumberChildren : undefined}
					numberChildrenWithBenefit={
						!sameHousehold ? numberChildrenWithBenefit : undefined
					}
					setNumberChildrenWithBenefit={
						!sameHousehold ? setNumberChildrenWithBenefit : undefined
					}
				/>
				<Occupation
					occupation={occupation}
					setOccupation={setOccupation}
					occupationError={occupationError}
					employer={employer}
					setEmployer={setEmployer}
					employerError={employerError}
					occupationMonth={occupationMonth}
					setOccupationMonth={setOccupationMonth}
					occupationMonthError={occupationMonthError}
					occupationYear={occupationYear}
					setOccupationYear={setOccupationYear}
					occupationYearError={occupationYearError}
					occupationLimited={occupationLimited}
					setOccupationLimited={setOccupationLimited}
					occupationLimitedMonth={occupationLimitedMonth}
					setOccupationLimitedMonth={setOccupationLimitedMonth}
					occupationLimitedMonthError={occupationLimitedMonthError}
					occupationLimitedYear={occupationLimitedYear}
					setOccupationLimitedYear={setOccupationLimitedYear}
					occupationLimitedYearError={occupationLimitedYearError}
				/>
				<FinancialStatus
					income={income}
					setIncome={setIncome}
					incomeError={incomeError}
					additionalIncome={additionalIncome}
					setAdditionalIncome={setAdditionalIncome}
					livingCosts={!sameHousehold ? livingCosts : undefined}
					setLivingCosts={!sameHousehold ? setLivingCosts : undefined}
					livingCostsError={livingCostsError}
					childSupport={!sameHousehold ? childSupport : undefined}
					setChildSupport={!sameHousehold ? setChildSupport : undefined}
					typeOfLiving={!sameHousehold ? typeOfLiving : undefined}
					setTypeOfLiving={!sameHousehold ? setTypeOfLiving : undefined}
					typeOfLivingError={typeOfLivingError}
				/>
				<Row>
					<Col xs={12}>
						<CheckboxInput
							label={m('acceptTermsAndConditions.label', 'fields', null, true)}
							value={acceptTermsAndConditions}
							setValue={setAcceptTermsAndConditions}
							hasError={!!acceptTermsAndConditionsError}
							message={acceptTermsAndConditionsError}
							tabindex={50}
							testId="accept-privacy-policies"
						/>
					</Col>
					<Col xs={12}>
						<CheckboxInput
							label={<AcceptSchufaLabelWithPopup />}
							value={acceptSchufa}
							setValue={setAcceptSchufa}
							hasError={!!acceptSchufaError}
							message={acceptSchufaError}
							tabindex={51}
							testId="accept-schufa"
						/>
					</Col>
				</Row>
			</Form>
		</Page>
	);
};
export default CustomerData;
