import React from 'react';

type Props = {
	color?: string;
};

const SvgArrowLeft = (props: Props) => {
	const width = 12;
	const height = 25;
	const defaultColor = '#9e3667';
	return (
		<svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
			<path
				style={{
					fill: props.color ? props.color : defaultColor,
					fillRule: 'evenodd'
				}}
				d="M11,25a1,1,0,0,1-.74-.34L.25,13.18a1.06,1.06,0,0,1,0-1.36L10.27.34a1,1,0,0,1,1.4-.08,1,1,0,0,1,.08,1.44L2.33,12.5l9.42,10.79a1.06,1.06,0,0,1-.08,1.45A1,1,0,0,1,11,25Z"
			/>
		</svg>
	);
};
export default SvgArrowLeft;
